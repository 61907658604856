
import Vue from 'vue';
import { DatatableMixin, DatatableConfig } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { DateTime } from 'luxon';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../components/layout/portal/modals';
import { Core } from '../../../services/core';
import { SunbedModelEntity, SunbedModelsStore } from '../../../services/sunbeds';

export default Vue.extend({
  name: 'configurationsOverviewView',
  mixins: [DatatableMixin],
  data() {
    return {
      datatable: {
        url: '/sunbeds/models',
        fields: [
          {
            name: 'name',
            title: 'Model',
            sortField: 'name',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'unit_type',
            title: 'Unit type',
            sortField: 'unit_type',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
            formatter: (value: string) => this.$t(`sunbeds.unit_types.${value}.name`),
          },
          {
            name: '_meta.created',
            title: 'Date of creation',
            sortField: '_meta.created',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
            formatter: (value: number) => DateTime.fromMillis(value).toFormat('dd-MM-yyyy'),
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-125px',
            dataClass: 'w-125px',
          },
        ],

        filters: {},
        sort: [{ field: 'name', direction: 'asc' }],
        searchFields: ['name'],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },
  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Configurations' }]);
  },

  methods: {
    removeConfiguration(model: SunbedModelEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove configuration',
        message: `Are you sure that you want to delete <strong>${model.name}</strong>?<br />All packages of this configuration will be deleted.<br />Please enter the name of the configuration to confirm.`,
        verifyString: model.name,
        buttonText: 'Confirm',
        async onSubmit() {
          await SunbedModelsStore.delete(model._meta.guid, { persistent: true });

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (vm.$refs.datatable as any).refresh();
        },
      });
    },
  },
});
