import { Entity } from '@vedicium/vue-core';
import { ManufacturingSubline } from './manufacturing-subline.interface';

export enum FilterPartConfigurationID {
  BPS = 'BPS',
  HPS = 'HPS',
  IPS = 'IPS',
  HIGHBRID = 'HIGHBRID',
}

export class FilterPartDto {
  description?: string = undefined;
  article_number?: string = undefined;
  configuration_id?: FilterPartConfigurationID = undefined;

  manufacturing: {
    sublines: Array<ManufacturingSubline>;
  } = { sublines: [] };
}

export interface FilterPartEntity extends Entity, FilterPartDto {}
