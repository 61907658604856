
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { Validation } from 'vuelidate';
import { PackagePartDto, PackagePartsStore } from '../../../../services/parts';

import { PartsPackagesInformationForm, PartsPackagesOptionsForm } from './components';

export default Vue.extend({
  name: 'partsPackagesCreate',
  mixins: [Mixins.KTWizardV4],
  components: {
    PartsPackagesInformationForm,
    PartsPackagesOptionsForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      entity: new PackagePartDto(),

      metadata: {
        wizard: {
          index: 0,
        },
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Packages', route: '/parts/packages' },
      { title: 'Create package' },
    ]);
  },
  async mounted() {
    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        // Copy package, so we can modify it freely
        const dto: PackagePartDto = JSON.parse(JSON.stringify(this.entity));

        // Create the package
        await PackagePartsStore.create(dto, {
          persistent: true,
        });
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push('/parts/packages');
    },

    async onWizardStepChange(step: number, oldStep: number) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number) {
      const validationArray: Array<Validation> = [];

      // Add validators based by step
      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      if (step === undefined || step === 1) {
        validationArray.push((this.$refs.optionsForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(
        validationArray.filter((row) => row !== undefined, []),
        {
          reset: true,
          pending_delay: 50,
        },
      );
    },
  },
});
