var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modal",attrs:{"title":_vm.options.title || 'Available light sources',"size":"xl","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(!_vm.uv_configurations || _vm.uv_configurations.length === 0)?[_c('div',{staticClass:"alert alert-custom alert-light-info"},[_c('div',{staticClass:"alert-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'question']}})],1)]),_c('div',{staticClass:"alert-text"},[_c('span',{staticClass:"font-weight-bold d-block"},[_vm._v("No light sources available.")]),_c('span',{staticClass:"d-block"},[_vm._v(" No light source are currently available. ")])])])]:_c('div',{staticClass:"row"},_vm._l((_vm.uv_configurations),function(uv_configuration,idx){return _c('div',{key:`uv_configuration.${idx}`,staticClass:"col-12"},[_c('label',{class:[
            'option cursor-pointer',
            { 'border-danger': _vm.$v.metadata.uv_configuration.$error },
          ]},[_c('span',{staticClass:"option-control my-auto"},[_c('span',{staticClass:"radio radio-primary"},[_c('input',{attrs:{"type":"radio","name":"uv_configuration"},domProps:{"checked":uv_configuration.identifier === _vm.metadata.uv_configuration.identifier},on:{"change":function($event){$event.preventDefault();return _vm.onUVConfigurationChange(uv_configuration)}}}),_c('span')])]),_c('span',{staticClass:"option-label"},[_c('span',{staticClass:"option-head d-flex justify-content-between"},[_c('span',{staticClass:"option-title",domProps:{"textContent":_vm._s(`${uv_configuration.description} (UV-type ${uv_configuration.uv_type})`)}}),(_vm.getPrice(uv_configuration) !== undefined)?_c('span',{staticClass:"text-muted",domProps:{"textContent":_vm._s(_vm.FormatUtils.currency(_vm.getPrice(uv_configuration)))}}):_vm._e()]),_c('span',{staticClass:"option-body text-reset"},[_c('div',{staticClass:"row"},[_vm._l((_vm.metadata.light_sources),function(light_source_type){return _c('div',{key:`light_source.${light_source_type}`,staticClass:"col-12 col-lg-4"},[_c('label',{staticClass:"d-block font-weight-bold",domProps:{"textContent":_vm._s(
                      `Light sources ${
                        _vm.$tu(
                          `sunbeds.unit_types.${_vm.options.unit_type}.light_sources.${light_source_type}`,
                        ) || light_source_type
                      }`
                    )}}),_vm._l((uv_configuration.light_sources.filter(
                      (row) => row.location === light_source_type,
                      [],
                    )),function(light_source,idx){return _c('div',{key:`light_source.${light_source_type}.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(
                      `${light_source.amount}x ${light_source.description} (${light_source.article_number})`
                    )}})})],2)}),(uv_configuration.facetanner.enabled === true)?_c('div',{staticClass:"col-12 mt-3 col-lg-4 mt-lg-0"},[_c('label',{staticClass:"d-block font-weight-bold"},[_vm._v("Face tanner")]),_vm._l(([uv_configuration.facetanner.light_source]),function(light_source,idx){return _c('div',{key:`light_source.facetanner.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(
                      `${light_source.amount}x ${light_source.description} (${light_source.article_number})`
                    )}})}),_vm._l((uv_configuration.facetanner.filters),function(filter,idx){return _c('div',{key:`filters.facetanner.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(`${filter.description} (${filter.power}W)`)}})})],2):_vm._e()],2)])])])])}),0)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary w-100px",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-success w-100px",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Select ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }