import { HttpRequestConfig, OrganizationEntity } from '@vedicium/vue-core';
import Vue from 'vue';
import { nanoid } from 'nanoid';
import { Core } from '../../core';
import { OrganizationInformationEntity, OrganizationsStore } from '../../organizations';
import { FilterPartConfigurationID, OptionPartConfigurationID } from '../../parts';
import { SessionStorage } from '../../session-storage';
import {
  ConfiguratorAddOptionOptions,
  ConfiguratorAddPackageOptions,
  ConfiguratorRemoveOptionOptions,
  ConfiguratorRemovePackageOptions,
  SunbedConfigurationConflictAction,
  SunbedConfigurationConflictError,
  SunbedConfigurationConflictProperty,
  SunbedConfigurationConflictType,
  SunbedConfigurationID,
  SunbedConfigurationSearchResult,
  SunbedConfigurationSession,
  SunbedConfigurationSessionError,
  SunbedConfigurationType,
  SunbedConfigurationTypeColor,
  SunbedConfigurationTypeOption,
  SunbedConfigurationTypePackage,
  SunbedConfigurationTypeUVConfiguration,
  SunbedModelTypeColorMode,
  SunbedModelTypeEntity,
  SunbedModelTypeFacetannerFilterRow,
  SunbedModelTypeOptionMode,
  SunbedModelTypePackageMode,
  SunbedModelTypeUVConfigurationMode,
  SunbedOrderEntity,
  SunbedVoltage,
} from '../interfaces';
import { SUNBED_CONFIGURATION_SESSION_KEY } from '../sunbeds.constants';
import { SunbedUtils } from '../utils';

export class ConfiguratorService {
  public static session: SunbedConfigurationSession | undefined = Vue.observable(undefined);

  /**
   * Creates a new configuration session.
   *
   * @returns SunbedConfigurationSession
   */
  static createSession(): SunbedConfigurationSession {
    this.session = {
      organization: undefined,
      organization_information: undefined,

      country: undefined,
      language: undefined,
      smartvoice_language: undefined,
      uv_type: undefined,
      rated_voltage: undefined,

      live_pricing: true,

      model: undefined,
      type: undefined,

      configuration: {
        type: undefined,
        uv_configuration: undefined,
        color: undefined,
        packages: [],
        options: [],

        amount: undefined,
        custom_price: undefined,
        reference: undefined,
        remark: undefined,
      },

      desired_delivery_week: 'asap',
    };

    return this.session;
  }

  /**
   * Set the configuration session.
   *
   * @param session SunbedConfigurationSession
   */
  static setSession(session: SunbedConfigurationSession): void {
    this.session = session;
  }

  /**
   * Create a sunbed configuration session from an order.
   */
  static async createSessionFromOrder(
    order: SunbedOrderEntity,
  ): Promise<SunbedConfigurationSession> {
    // Create session
    this.createSession();

    // Set organization
    await this.setOrganization(await OrganizationsStore.get(order.organization.guid));

    // Set known information of details
    this.session!.country = order.country;
    this.session!.language = order.configuration.language;
    this.session!.smartvoice_language = order.configuration.smartvoice_language;
    this.session!.uv_type = order.configuration.uv_configuration.uv_type;
    this.session!.rated_voltage = order.configuration.rated_voltage.rated_voltage;

    // Get sunbed models, filtered by current model.
    const models = await this.getSunbedModels(
      this.session!.organization?._meta.guid as string,
      [this.session!.uv_type as string],
      this.session!.rated_voltage as string,
      {
        params: {
          'filter[_meta.guid]': order.configuration.model.guid,
        },
      },
    );

    // Find the current model
    const model = models.find((row) => row._meta.guid === order.configuration.model.guid);
    if (model === undefined) {
      throw new Error('Sunbed model not found');
    }

    // Set the model
    this.setSunbedModel(model);

    // Get the sunbed type
    const type = model.types.find((row) => row._meta.guid === order.configuration.type.guid);
    if (type === undefined) {
      throw new Error('Sunbed model type not found');
    }

    // Set sunbed type
    await this.setSunbedType(type);

    // Set light source, based on article number
    this.session!.configuration.uv_configuration =
      this.session!.configuration.type?.uv_configurations.find(
        (row) => row.identifier === order.configuration.uv_configuration.identifier,
      ) || this.session!.configuration.uv_configuration;

    // Set color, based on guid
    this.session!.configuration.color =
      this.session!.configuration.type?.parts.colors.find(
        (row) => row.guid === order.configuration.color?.guid,
      ) || this.session!.configuration.color;

    // Set packages, based on guid
    order.configuration.packages
      .map((row) => this.getPackage(row.guid), [])
      .filter((row) => row !== undefined, [])
      .forEach((row) =>
        this.addPackage(row as SunbedConfigurationTypePackage, {
          resolve_options_conflicts: true,
        }),
      );

    // Set options, based on guid
    order.configuration.options
      .filter((row) => row.from_package === undefined, [])
      .map((row) => this.getOption(row.guid), [])
      .filter((row) => row !== undefined)
      .forEach((row) =>
        this.addOption(row as SunbedConfigurationTypeOption, {
          skip_together_with_check: true,
          resolve_not_together_with_conflicts: true,
        }),
      );

    // Add order data
    this.session!.configuration.amount = order.amount;
    this.session!.configuration.reference = order.reference;
    this.session!.configuration.remark = order.remark;
    this.session!.configuration.custom_price = order.pricing.custom_price;
    this.session!.desired_delivery_week = order.schedule.desired_delivery_week;

    // Copy session and clear it
    const session = JSON.parse(JSON.stringify(this.session)) as SunbedConfigurationSession;
    this.createSession();

    return session;
  }

  /**
   * Save session in memory.
   *
   * @param session SunbedConfigurationSession
   * @returns session key
   */
  static saveSession(session: SunbedConfigurationSession): string {
    const key = nanoid();
    SessionStorage.save(`${SUNBED_CONFIGURATION_SESSION_KEY}:${key}`, session);
    return key;
  }

  /**
   * Get session from session storage.
   *
   * @param key session key
   * @returns SunbedConfigurationSession
   */
  static getSession(key: string): SunbedConfigurationSession {
    const session = SessionStorage.get<SunbedConfigurationSession>(
      `${SUNBED_CONFIGURATION_SESSION_KEY}:${key}`,
    );

    if (session === undefined) {
      throw new Error(`Session not found`);
    }

    return session;
  }

  /**
   * Sets the organization of the session.
   *
   * @param organization OrganizationEntity
   */
  static async setOrganization(organization: OrganizationEntity | undefined): Promise<void> {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Reset configuration, model & type
    this.session.model = undefined;
    this.session.type = undefined;
    this.resetConfiguration();

    // Reset everything if the organization is set to undefined.
    if (organization === undefined) {
      this.session.organization_information = undefined;
      this.session.uv_type = undefined;
      this.session.rated_voltage = undefined;
      return;
    }

    // Get organization information
    const response = await Core.getAdapter().get<OrganizationInformationEntity>(
      `/organizations/${organization._meta.guid}/information`,
    );

    this.session.organization = organization;
    this.session.organization_information = response.data;
    this.session.country = response.data.preferences.country;
    this.session.language = response.data.preferences.language;
    this.session.smartvoice_language = response.data.preferences.smartvoice_language;
    this.session.uv_type = response.data.preferences.uv_type;
    this.session.rated_voltage = response.data.preferences.rated_voltage;
  }

  /**
   * Get sunbed models.
   *
   * @param organization Guid of organization
   * @param uv_types UV-types
   * @param rated_voltage Rated voltage
   * @param options HttpRequestConfig
   *
   * @returns Array of SunbedConfigurationSearchResult
   */
  static async getSunbedModels(
    organization: string,
    uv_types: Array<string>,
    rated_voltage: string,
    options?: HttpRequestConfig,
  ): Promise<Array<SunbedConfigurationSearchResult>> {
    const response = await Core.getAdapter().post<Array<SunbedConfigurationSearchResult>>(
      '/sunbeds/configuration/models/search',
      {
        organization,
        uv_types,
        rated_voltage,
      },
      options,
    );

    return response.data;
  }

  /**
   * Sets the model of the session and resets current configuration.
   *
   * @param model SunbedConfigurationSearchResult
   */
  static setSunbedModel(model: SunbedConfigurationSearchResult | undefined): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Set the current session model and remove the selected type.
    this.session.model = model;
    this.session.type = undefined;
    this.resetConfiguration();
  }

  /**
   * Reset current configuration options.
   */
  static resetConfiguration(): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    this.session.configuration = {
      type: undefined,
      uv_configuration: undefined,
      color: undefined,
      packages: [],
      options: [],

      amount: undefined,
      custom_price: undefined,
      reference: undefined,
      remark: undefined,
    };
  }

  /**
   * When live pricing is enabled, all prices should be shown.
   *
   * @returns boolean
   */
  static isLivePricingEnabled(): boolean {
    return this.session?.live_pricing === true;
  }

  /**
   * Gets the purchase price of the configuration.
   *
   * @returns Purchase price
   */
  static getPurchasePrice(): number {
    return this.getPriceByCategory('purchase_price');
  }

  /**
   * Gets the retail selling price of the configuration.
   *
   * @returns Retail selling price
   */
  static getRetailSellingPrice(): number {
    return this.getPriceByCategory('retail_selling_price');
  }

  /**
   * Get the total price of the configuration by category.
   *
   * @returns Category price
   */
  private static getPriceByCategory(category: 'purchase_price' | 'retail_selling_price'): number {
    let categoryPrice = 0;

    // Start with the sunbed type itself (by rated voltage)
    if (this.session?.configuration.rated_voltage) {
      categoryPrice += this.session.configuration.rated_voltage[category] || 0;
    }

    // Add the light sources
    if (this.session?.configuration.uv_configuration) {
      categoryPrice += this.session.configuration.uv_configuration[category] || 0;
    }

    // Add the color, but not if it's included
    if (
      this.session?.configuration.color &&
      this.isColorIncluded(this.session?.configuration.color) === false
    ) {
      categoryPrice += this.session.configuration.color[category] || 0;
    }

    // Add all packages
    (this.session?.configuration.packages || []).forEach((entity) => {
      // Get the package entity
      const packageEntity = this.getPackage(entity.guid);

      // Don't add included packages
      if (this.isPackageIncluded(packageEntity as SunbedConfigurationTypePackage) === true) {
        return;
      }

      // Add RSP to the total price
      categoryPrice += packageEntity?.[category] || 0;
    });

    // Add all options
    (this.session?.configuration.options || []).forEach((entity) => {
      // Don't add entities added by a package
      if (entity.package !== undefined) {
        return;
      }

      // Get the option entity
      const optionEntity = this.getOption(entity.guid);

      // Don't add included & package-only options
      if (
        this.isOptionIncluded(optionEntity as SunbedConfigurationTypeOption) === true ||
        optionEntity?.mode === SunbedModelTypeOptionMode.PACKAGE_ONLY
      ) {
        return;
      }

      // Add RSP to the total price
      categoryPrice += optionEntity?.[category] || 0;
    });

    return categoryPrice;
  }

  /**
   * Get the power consumption of the configuration
   *
   * @returns Array of power consumption by wattage.
   */
  static getPowerConsumption(): Array<{ voltage: SunbedVoltage; wattage: number }> {
    return (
      this.session?.configuration.rated_voltage?.power_consumption.map(
        (row) => ({
          voltage: row.voltage,
          wattage: this.getPowerConsumptionByVoltage(row.voltage),
        }),
        [],
      ) || []
    );
  }

  /**
   * Get the power consumption of the configuration by voltage.
   *
   * @param voltage SunbedVoltage
   * @returns Wattage.
   */
  static getPowerConsumptionByVoltage(voltage: SunbedVoltage): number {
    let powerConsumption = 0;

    // Add power consumption of type
    powerConsumption +=
      this.session?.configuration.rated_voltage?.power_consumption.find(
        (row) => row.voltage === voltage,
      )?.wattage || 0;

    // Add power consumption of selected options
    powerConsumption +=
      this.session?.configuration.type?.parts.options
        .filter((row) => this.isOptionSelected(row) === true, [])
        .filter((row) => row.power_consumption !== undefined, [])
        .flatMap((row) => row.power_consumption, [])
        .filter((row) => row?.rated_voltage === this.session?.rated_voltage, [])
        .flatMap((row) => row?.voltages, [])
        .filter((row) => row?.voltage === voltage, [])
        .map((row) => row?.wattage || 0, [])
        .reduce((a: number, b: number) => a + b, 0) || 0;

    return powerConsumption;
  }

  /**
   * Get default UV configuration.
   */
  static getDefaultUVConfiguration(): SunbedConfigurationTypeUVConfiguration {
    if (!this.session || this.session?.configuration.type?.uv_configurations?.length === 0) {
      throw new SunbedConfigurationSessionError();
    }

    // Get UV configurations of UV type.
    const uvConfigurationsOfUVType =
      this.session?.configuration.type?.uv_configurations.filter(
        (row) => row.uv_type === this.session?.uv_type,
        [],
      ) || [];

    if (uvConfigurationsOfUVType?.length > 0) {
      return (
        uvConfigurationsOfUVType.find(
          (row) =>
            [
              SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT,
              SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT,
            ].includes(row.mode) === true,
        ) || uvConfigurationsOfUVType[0]
      );
    }

    // If no UV configuration is found with the preferred UV-type, search through all UV configurations.
    return (this.session?.configuration?.type?.uv_configurations.find(
      (row) =>
        [
          SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT,
          SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT,
        ].includes(row.mode) === true,
    ) ||
      this.session?.configuration?.type
        ?.uv_configurations[0]) as SunbedConfigurationTypeUVConfiguration;
  }

  /**
   * Select sunbed model type entity.
   * This will also gather the SunbedConfigurationType and adds the default configuration.
   *
   * @param type SunbedModelTypeEntity
   */
  static async setSunbedType(type: SunbedModelTypeEntity): Promise<void> {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Set the current type
    this.session.type = type;
    this.resetConfiguration();

    // Get configuration type
    this.session.configuration.type = await this.getSunbedConfigurationType(type, {
      params: {
        organization: this.session.organization?._meta.guid,
      },
    });

    // Set rated voltage
    this.session.configuration.rated_voltage = this.session.configuration.type.rated_voltages.find(
      (row) => row.rated_voltage === this.session?.rated_voltage,
    );

    // Set default UV configuration of type
    this.session.configuration.uv_configuration = this.getDefaultUVConfiguration();

    // Set default color
    this.session.configuration.color =
      this.session.configuration.type.parts.colors.find(
        (row) =>
          [
            SunbedModelTypeColorMode.INCLUDED_BY_DEFAULT,
            SunbedModelTypeColorMode.SELECTED_BY_DEFAULT,
          ].includes(row.mode) === true,
      ) ||
      this.session.configuration.type.parts.colors[0] ||
      undefined;

    // Apply included parts
    this.applyIncludedParts();
  }

  /**
   * Apply included parts of type.
   */
  static applyIncludedParts(): void {
    if (!this.session || !this.session.configuration.type) {
      throw new SunbedConfigurationSessionError();
    }

    // Add included options
    this.session.configuration.type.parts.options
      .filter(
        (row) =>
          [
            SunbedModelTypeOptionMode.SELECTED_BY_DEFAULT,
            SunbedModelTypeOptionMode.INCLUDED_BY_DEFAULT,
          ].includes(row.mode) === true,
        [],
      )
      .forEach((row) => this.addOption(row, { skip_together_with_check: true }));

    // Add included packages
    this.session.configuration.type.parts.packages
      .filter((row) => this.isPackageIncluded(row) === true, [])
      .forEach((row) => this.addPackage(row, { resolve_options_conflicts: true }));
  }

  /**
   * Verify if color is included.
   *
   * @param color SunbedConfigurationTypeColor
   * @returns Boolean
   */
  static isColorIncluded(color: SunbedConfigurationTypeColor): boolean {
    return color.mode === SunbedModelTypeColorMode.INCLUDED_BY_DEFAULT;
  }

  /**
   * Verify if option is available for loose sale.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns boolean
   */
  static isOptionAvailable(option: SunbedConfigurationTypeOption): boolean {
    return option.available === true;
  }

  /**
   * Concludes whether an option is selected.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns boolean
   */
  static isOptionSelected(option: SunbedConfigurationTypeOption): boolean {
    return this.session?.configuration.options.some((row) => row.guid === option.guid) || false;
  }

  /**
   * Verify if option is included.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns boolean
   */
  static isOptionIncluded(option: SunbedConfigurationTypeOption): boolean {
    return option.mode === SunbedModelTypeOptionMode.INCLUDED_BY_DEFAULT;
  }

  /**
   * Verify if option is selected by default.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns boolean
   */
  static isOptionSelectedByDefault(option: SunbedConfigurationTypeOption): boolean {
    return option.mode === SunbedModelTypeOptionMode.SELECTED_BY_DEFAULT;
  }

  /**
   * Concludes whether the selected option is from a package.
   * When a package is given, it'll verify if it's selected by the given package.
   *
   * @param option SunbedConfigurationTypeOption
   * @param entity SunbedConfigurationTypePackage
   * @returns boolean
   */
  static isOptionSelectedFromPackage(
    option: SunbedConfigurationTypeOption,
    entity?: SunbedConfigurationTypePackage,
  ): boolean {
    if (this.isOptionSelected(option) === false) {
      return false;
    }

    const selectedOption = this.getSelectedOption(option);

    // Verify if it's linked to the given package.
    if (entity) {
      return selectedOption?.package === entity.guid;
    }

    // Verify if it's linked to a package.
    return selectedOption?.package !== undefined;
  }

  /**
   * Get all selected configuration ID's.
   *
   * @returns Array of configuration ID's
   */
  static getConfigurationIDs(): Array<SunbedConfigurationID> {
    const configurationIDs: Array<SunbedConfigurationID> = [];

    // Get configuration ID of type
    if (this.session?.configuration.type?.configuration_id !== undefined) {
      configurationIDs.push(this.session.configuration.type.configuration_id);
    }

    // Get configuration ID of facetanner
    if (this.session?.configuration.uv_configuration?.facetanner.enabled === true) {
      this.session.configuration.uv_configuration.facetanner.filters
        ?.filter(
          (row: SunbedModelTypeFacetannerFilterRow & { configuration_id: string | undefined }) =>
            row.configuration_id !== undefined,
          [],
        )
        .forEach(
          (row) => configurationIDs.push(row.configuration_id as FilterPartConfigurationID),
          [],
        );
    }

    // Get configuration ID of colors
    if (this.session?.configuration.color?.configuration_id !== undefined) {
      configurationIDs.push(this.session.configuration.color.configuration_id);
    }

    // Get configuration IDs of options
    this.session?.configuration.options
      .filter((row) => row.configuration_id !== undefined, [])
      .forEach((row) => configurationIDs.push(row?.configuration_id as OptionPartConfigurationID));

    // Get configuration IDs of filters of options
    this.session?.configuration.options
      .map((row) => this.getOption(row.guid), [])
      .filter((row) => row?.filter?.configuration_id !== undefined, [])
      .forEach((row) =>
        configurationIDs.push(row?.filter?.configuration_id as FilterPartConfigurationID),
      );

    return configurationIDs.filter((row, idx, self) => self.indexOf(row) === idx, []);
  }

  /**
   * Get an option by guid.
   *
   * @param guid Guid of option.
   * @returns SunbedConfigurationTypeOption | undefined
   */
  static getOption(guid: string): SunbedConfigurationTypeOption | undefined {
    return this.session?.configuration.type?.parts.options.find((row) => row.guid === guid);
  }

  /**
   * Gets the package of a selected option, which is part in a package.
   *
   * Returns undefined when:
   * - The option isn't selected
   * - The selected option isn't part of a package.
   * - The package can not be found.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns SunbedConfigurationTypePackage | undefined
   */
  static getPackageOfSelectedOption(
    option: SunbedConfigurationTypeOption,
  ): SunbedConfigurationTypePackage | undefined {
    if (this.isOptionSelected(option) === false) {
      return undefined;
    }

    const selectedOption = this.getSelectedOption(option);
    if (selectedOption?.package === undefined) {
      return undefined;
    }

    return this.getPackage(selectedOption?.package);
  }

  /**
   * Get selected option.
   *
   * @param option SunbedConfigurationTypeOption
   * @returns SunbedConfigurationSessionOption
   */
  private static getSelectedOption(
    option: SunbedConfigurationTypeOption,
  ): SunbedConfigurationSession['configuration']['options'][number] | undefined {
    return this.session?.configuration.options.find((row) => row.guid === option.guid) || undefined;
  }

  /**
   * Add option to configuration.
   *
   * @param option SunbedConfigurationTypeOption
   * @param options Options
   */
  static addOption(
    option: SunbedConfigurationTypeOption,
    options?: ConfiguratorAddOptionOptions,
  ): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Don't add the option if it's already selected.
    if (this.isOptionSelected(option) === true) {
      return;
    }

    // Verify whether it conflicts with another option.
    if (options?.skip_not_together_with_check !== true && option?.not_together_with !== undefined) {
      try {
        this.isCombinationAllowed('not_together_with', 'add', { type: 'option', entity: option });
      } catch (e: any) {
        if (
          options?.resolve_not_together_with_conflicts === true &&
          e instanceof SunbedConfigurationConflictError
        ) {
          e.conflicts.forEach((row) => this.resolveConflict('not_together_with', row));
        } else {
          throw e;
        }
      }
    }

    // Verify wether options should be combined.
    if (options?.skip_together_with_check !== true && option.together_with !== undefined) {
      this.isCombinationAllowed('together_with', 'add', { type: 'option', entity: option });
    }

    this.session.configuration.options.push({
      guid: option.guid,
      article_number: option.article_number as string,
      configuration_id: option.configuration_id,
      package: options?.package_guid,
    });
  }

  /**
   * Remove option.
   *
   * @param option SunbedConfigurationTypeOption
   */
  static removeOption(
    option: SunbedConfigurationTypeOption,
    options?: ConfiguratorRemoveOptionOptions,
  ): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Don't try to remove an option if it isn't selected.
    if (this.isOptionSelected(option) === false) {
      return;
    }

    // Verify whether other options should also be removed
    if (options?.skip_together_with_check !== true && option.together_with !== undefined) {
      this.isCombinationAllowed('together_with:remove', 'remove', {
        type: 'option',
        entity: option,
      });
    }

    // Filter out current option in order to remove it
    this.session.configuration.options = this.session.configuration.options.filter(
      (row) => row.guid !== option.guid,
      [],
    );

    // Add included option with the same 'not_together_with' property
    if (
      options?.skip_included_option !== true &&
      this.isOptionIncluded(option) === false &&
      option.not_together_with !== undefined
    ) {
      const includedOption = this.session.configuration.type?.parts.options.find(
        (row) =>
          row.mode === SunbedModelTypeOptionMode.SELECTED_BY_DEFAULT &&
          row.not_together_with === option.not_together_with,
      );

      if (includedOption) {
        this.addOption(includedOption);
      }
    }
  }

  /**
   * Concludes whether an package is selected.
   *
   * @param entity SunbedConfigurationTypePackage
   * @returns boolean
   */
  static isPackageSelected(entity: SunbedConfigurationTypePackage): boolean {
    if (this.session?.configuration.packages.some((row) => row.guid === entity.guid) === true) {
      // TODO: Check if all options linked to this package are also selected.
      return true;
    }

    return false;
  }

  /**
   * Verify if option is included.
   *
   * @param entity SunbedConfigurationTypePackage
   * @returns boolean
   */
  static isPackageIncluded(entity: SunbedConfigurationTypePackage): boolean {
    return entity.mode === SunbedModelTypePackageMode.INCLUDED_BY_DEFAULT;
  }

  /**
   * Get a package by guid.
   *
   * @param guid Guid of package.
   * @returns SunbedConfigurationTypePackage | undefined
   */
  static getPackage(guid: string): SunbedConfigurationTypePackage | undefined {
    return this.session?.configuration.type?.parts.packages.find((row) => row.guid === guid);
  }

  /**
   * Get all options of a package.
   *
   * @param entity SunbedConfigurationTypePackage
   * @returns Array of SunbedConfigurationTypeOption
   */
  static getOptionsOfPackage(
    entity: SunbedConfigurationTypePackage,
  ): Array<SunbedConfigurationTypeOption> {
    return entity.options
      .map((row) => this.getOption(row), [])
      .filter((row) => row !== undefined, []) as Array<SunbedConfigurationTypeOption>;
  }

  /**
   * Add package to configuration.
   *
   * @param entity SunbedConfigurationTypePackage
   */
  static addPackage(
    entity: SunbedConfigurationTypePackage,
    options?: ConfiguratorAddPackageOptions,
  ): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Don't add package if it's already selected.
    if (this.isPackageSelected(entity) === true) {
      return;
    }

    // Verify whether it conflicts with another package.
    if (options?.skip_not_together_with_check !== true && entity?.not_together_with !== undefined) {
      this.isCombinationAllowed('not_together_with', 'add', { type: 'package', entity });
    }

    // Verify whether it should be combined.
    if (options?.skip_together_with_check !== true && entity?.together_with !== undefined) {
      this.isCombinationAllowed('together_with', 'add', { type: 'package', entity });
    }

    // Verify whether there are options with conflicts
    const optionConflicts = entity.options
      // Loop through all options that'll be added, whether they have conflicts.
      .flatMap((option_guid) => {
        const option = this.getOption(option_guid);
        if (!option || option.not_together_with === undefined) {
          return [];
        }

        return this.getCombinationConflicts('not_together_with', {
          type: 'option',
          entity: option,
        });
      })
      // Filter out the constraints that will be added by this package.
      .filter((row) => entity.options.includes(row.entity.guid) === false, []);

    // Throw a combination error when there are constraints
    if (options?.resolve_options_conflicts !== true && optionConflicts.length > 0) {
      throw new SunbedConfigurationConflictError(
        'not_together_with',
        'add',
        { type: 'package', entity },
        optionConflicts,
      );
    }

    // Resolve conflicts
    if (options?.resolve_options_conflicts === true && optionConflicts.length > 0) {
      optionConflicts.forEach((row) => this.resolveConflict('not_together_with', row), []);
    }

    // Add package
    this.session.configuration.packages.push({
      guid: entity.guid,
      article_number: entity.article_number as string,
    });

    // Add all options of package
    this.addOptionsOfPackage(entity);
  }

  static removePackage(
    entity: SunbedConfigurationTypePackage,
    options?: ConfiguratorRemovePackageOptions,
  ): void {
    if (!this.session) {
      throw new SunbedConfigurationSessionError();
    }

    // Don't remove package if it's not selected.
    if (this.isPackageSelected(entity) === false) {
      this.removeOptionsOfPackage(entity);
      return;
    }

    // Don't remove package if it's included.
    if (this.isPackageIncluded(entity) === true) {
      return;
    }

    // Verify whether other packages should also be removed.
    if (options?.skip_together_with_check !== true && entity.together_with !== undefined) {
      this.isCombinationAllowed('together_with:remove', 'remove', { type: 'package', entity });
    }

    // Remove package from selected packages.
    this.session.configuration.packages = this.session.configuration.packages.filter(
      (row) => row.guid !== entity.guid,
      [],
    );

    // Remove options linked to package.
    this.removeOptionsOfPackage(entity);
  }

  /**
   * Concludes wheter a combination is allowed.
   * Throws an error when conflicts are found.
   *
   * @param type SunbedConfigurationConflictType
   * @param action SunbedConfigurationConflictAction
   * @param property SunbedConfigurationConflictProperty
   * @return boolean
   */
  static isCombinationAllowed(
    type: SunbedConfigurationConflictType,
    action: SunbedConfigurationConflictAction,
    property: SunbedConfigurationConflictProperty,
  ): boolean {
    if (
      (type === 'together_with' && property.entity.together_with === undefined) ||
      (type === 'together_with:remove' && property.entity.together_with === undefined) ||
      (type === 'not_together_with' && property.entity.not_together_with === undefined)
    ) {
      return true;
    }

    // Get entities that should or shouldn't be combined, whereas the entity checked is filtered out.
    const conflicts = this.getCombinationConflicts(type, property).filter(
      (row) => row.entity !== property.entity,
      [],
    );

    // If there are still entities left, it can't be combined.
    if (conflicts.length > 0) {
      throw new SunbedConfigurationConflictError(type, action, property, conflicts);
    }

    return true;
  }

  /**
   * Resolve a conflict based on type and the conflict itself.
   *
   * @param type SunbedConfigurationConflictType
   * @param conflict SunbedConfigurationConflictProperty
   */
  static resolveConflict(
    type: SunbedConfigurationConflictType,
    conflict: SunbedConfigurationConflictProperty,
  ): void {
    /**
     * Resolve the conflict based on the conflict type.
     */
    switch (type) {
      /**
       * When the type is 'together with',
       * to resolve the conflict, it should add the option / package that
       * should be combined.
       */
      case 'together_with': {
        if (conflict.type === 'option') {
          this.addOption(conflict.entity, {
            skip_together_with_check: true,
          });
        }

        if (conflict.type === 'package') {
          this.addPackage(conflict.entity);
        }
        break;
      }

      /**
       * When the type is 'together with - remove',
       * the option / package should be removed when a combination requires it.
       */
      case 'together_with:remove':

      /**
       * When the type is 'not together with',
       * the option / package should be removed with the conflicting option / package.
       */
      case 'not_together_with': {
        if (conflict.type === 'option') {
          this.removeOption(conflict.entity, {
            skip_together_with_check: type === 'together_with:remove',
            skip_included_option: true,
          });
        }

        if (conflict.type === 'package') {
          this.removePackage(conflict.entity);
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  /**
   * Concludes whether the given error is a conflict error.
   *
   * @param error Error
   * @returns boolean
   */
  static isConflictError(error: unknown): boolean {
    return error instanceof SunbedConfigurationConflictError;
  }

  /**
   * Get the SunbedConfigurationType, which also filters the UV types.
   *
   * @param type SunbedModelTypeEntity
   * @returns SunbedConfigurationType
   */
  private static async getSunbedConfigurationType<T = SunbedConfigurationType>(
    type: SunbedModelTypeEntity,
    options?: HttpRequestConfig,
  ): Promise<T> {
    const response = await Core.getAdapter().get<T>(
      `/sunbeds/configuration/models/type/${type._meta.guid}`,
      {
        ...(options || {}),
        params: {
          ...(options?.params || {}),
          'filter[uv_type]': SunbedUtils.getCompatibleUVTypes(this.session?.uv_type as string),
        },
      },
    );

    return response.data;
  }

  /**
   * Add all options of given package.
   *
   * @param entity SunbedConfigurationTypePackage
   */
  private static addOptionsOfPackage(entity: SunbedConfigurationTypePackage): void {
    this.getOptionsOfPackage(entity).forEach((option) => {
      // Remove the option first, if it was already selected.
      this.removeOption(option, { skip_together_with_check: true });

      // Add the option and link it to the current package
      this.addOption(option, {
        package_guid: entity.guid,
        skip_together_with_check: true,
        skip_not_together_with_check: true,
      });
    });
  }

  /**
   * Remove all options of given package.
   *
   * @param entity SunbedConfigurationTypePackage
   */
  private static removeOptionsOfPackage(entity: SunbedConfigurationTypePackage): void {
    entity.options.forEach((option_guid) => {
      const option = this.getOption(option_guid);

      // When the option is not found, continue to the next option.
      if (option === undefined) {
        return;
      }

      // Remove the option, but only if it's linked to the current package.
      if (this.isOptionSelectedFromPackage(option, entity) === true) {
        this.removeOption(option, { skip_together_with_check: true });
      }
    });
  }

  private static getCombinationConflicts(
    type: SunbedConfigurationConflictType,
    property: SunbedConfigurationConflictProperty,
  ): Array<SunbedConfigurationConflictProperty> {
    let entities: Array<SunbedConfigurationTypeOption | SunbedConfigurationTypePackage> = [];

    // Determine which entities to get
    switch (property.type) {
      case 'option': {
        entities = [...(this.session?.configuration.type?.parts.options || [])];
        break;
      }

      case 'package': {
        entities = [...(this.session?.configuration.type?.parts.packages || [])];
        break;
      }

      default: {
        break;
      }
    }

    const conflicts = entities
      .filter((row) => {
        // Filter by type
        switch (type) {
          case 'together_with': {
            // Verify whether the value is the same
            if (row.together_with === property.entity.together_with) {
              /**
               * Then, verify when:
               * option: whether the option is selected.
               * package: whether the package is selected.
               *
               * If not, then the entity is msising.
               */
              return (
                (property.type === 'option' &&
                  this.isOptionSelected(row as SunbedConfigurationTypeOption) === false) ||
                (property.type === 'package' &&
                  this.isPackageSelected(row as SunbedConfigurationTypePackage) === false)
              );
            }

            return false;
          }

          case 'together_with:remove': {
            // Verify whether the value is the same
            if (row.together_with === property.entity.together_with) {
              /**
               * Then, verify when:
               * option: whether the option is selected.
               * package: whether the package is selected.
               *
               * If they are, they should be removed.
               */
              return (
                (property.type === 'option' &&
                  this.isOptionSelected(row as SunbedConfigurationTypeOption) === true) ||
                (property.type === 'package' &&
                  this.isPackageSelected(row as SunbedConfigurationTypePackage) === true)
              );
            }

            return false;
          }

          case 'not_together_with': {
            // Verify whether the value is the same
            if (row.not_together_with === property.entity.not_together_with) {
              /**
               * Then, verify when:
               * option: whether the option is selected.
               * package: whether the package is selected.
               *
               * If they are, then the entity is should be removed.
               */
              return (
                (property.type === 'option' &&
                  this.isOptionSelected(row as SunbedConfigurationTypeOption) === true) ||
                (property.type === 'package' &&
                  this.isPackageSelected(row as SunbedConfigurationTypePackage) === true)
              );
            }

            return false;
          }

          default: {
            return false;
          }
        }
      })
      .map((row) => ({
        type: property.type,
        entity: row,
      })) as Array<SunbedConfigurationConflictProperty>;

    /**
     * Detect when entity type is an option, whether packages should also be deleted.
     *
     * Scenario:
     * - The option 'Sound Plus' & 'SoundAround Plus' can not be combined.
     * - The package 'Exclusvie Package' includes 'SoundAround Plus' and is selected.
     * - User selects the option 'Sound Plus'
     * -
     */
    if (type === 'not_together_with' && property.type === 'option') {
      const packageConstraints: Array<SunbedConfigurationConflictProperty> = [];
      conflicts.forEach((conflict) => {
        if (conflict.type === 'package') {
          return;
        }

        if (this.isOptionSelectedFromPackage(conflict.entity) === true) {
          const link = this.session?.configuration.options.find(
            (row) => row.guid === conflict.entity.guid,
          );

          if (link === undefined) {
            return;
          }

          const packageEntity = this.getPackage(link.package as string);
          if (packageEntity === undefined) {
            return;
          }

          packageConstraints.push({ type: 'package', entity: packageEntity });
        }
      });

      // Return the package constraints only when there are package constraints.
      if (packageConstraints.length > 0) {
        return packageConstraints;
      }
    }

    return conflicts;
  }
}
