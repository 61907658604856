import Vue, { PropType, VueConstructor } from 'vue';

import { SunbedOrderEntity } from '../../../../../../services/sunbeds';
import { StateFormInstance } from '../interfaces';

export const StateFormMixin = (Vue as VueConstructor<Vue & StateFormInstance>).extend({
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
});
