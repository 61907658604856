import { RawLocation } from 'vue-router';

export function getAuthenticationRoute(options?: { prompt?: 'login' | 'none' }): RawLocation {
  const location: RawLocation = { path: '/authentication' };

  if (options?.prompt) {
    location.query = { prompt: options.prompt };
  }

  return location;
}
