import { Entity } from '@vedicium/vue-core';

export class LightSourcePartDto {
  description?: string = undefined;
  article_number?: string = undefined;

  type: 'low_pressure' | 'low_pressure_facial' | 'LED' | 'high_pressure' = 'low_pressure';

  power?: number = undefined;
  ballast_power?: number = undefined;
  x_code?: number = undefined;
  y_code?: number = undefined;

  reflector?: boolean = true;

  format(): LightSourcePartDto {
    const entity: LightSourcePartDto = JSON.parse(JSON.stringify(this));

    if (entity.type === 'high_pressure' || entity.type === 'LED') {
      delete this.ballast_power;
      delete this.x_code;
      delete this.y_code;
      delete this.reflector;
    }

    return entity;
  }
}

export interface ILightSourcePart<T> {
  description: string;
  article_number: string;

  type: T;

  /**
   * Light source power (in watt)
   */
  power: number;
}

export type HighPressureLightSourcePart = ILightSourcePart<'high_pressure'>;
export type LEDLightSourcePart = ILightSourcePart<'LED'>;

export interface LowPressureLightSourcePart
  extends ILightSourcePart<'low_pressure' | 'low_pressure_facial'> {
  /**
   * Light source power (in watt)
   */
  ballast_power: number;

  x_code: number;
  y_code: number;

  reflector: boolean;
}

export type LightSourcePart =
  | HighPressureLightSourcePart
  | LowPressureLightSourcePart
  | LEDLightSourcePart;

// Entities
export interface LEDLightSourcePartEntity extends LEDLightSourcePart, Entity {}

export interface HighPressureLightSourcePartEntity extends HighPressureLightSourcePart, Entity {}

export interface LowPressureLightSourcePartEntity extends LowPressureLightSourcePart, Entity {}

export type LightSourcePartEntity =
  | HighPressureLightSourcePartEntity
  | LowPressureLightSourcePartEntity
  | LEDLightSourcePartEntity;
