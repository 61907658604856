
import { FormMixin } from '@vedicium/vue-core';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  SunbedConfigurationSession,
  SunbedModelTypeEntity,
  ConfiguratorService,
  SunbedModelTypeUVConfigurationMode,
} from '../../../../services/sunbeds';
import {
  CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN,
  ConfiguratorUVConfigurationsModalOptions,
  ConfiguratorUVConfigurationsModal,
  ConfiguratorConflictErrorModal,
} from '../modals';
import { FormatUtils } from '../../../../utils';
import SunbedModelImage from './model.image.vue';
import UvConfigurationConfigurator from './uv-configuration.configuration.vue';
import ColorsConfigurator from './colors.configurator.vue';
import PackagesConfigurator from './packages.configurator.vue';
import OptionsConfigurator from './options.configurator.vue';

export default Vue.extend({
  name: 'ordersConfigurationForm',
  mixins: [FormMixin],
  components: {
    SunbedModelImage,
    UvConfigurationConfigurator,
    ColorsConfigurator,
    PackagesConfigurator,
    OptionsConfigurator,
    ConfiguratorConflictErrorModal,
    ConfiguratorUvConfigurationsModal: ConfiguratorUVConfigurationsModal,
  },
  data() {
    return {
      isLoading: false,

      session: ConfiguratorService.session as SunbedConfigurationSession,

      FormatUtils,
    };
  },
  computed: {
    title(): string {
      if (this.session.type) {
        return [this.session.model?.name, this.session.type.name].join(' ');
      }

      return this.session.model?.name as string;
    },
    types(): Array<SunbedModelTypeEntity> {
      return this.session.model?.types || [];
    },
    configurationIDs(): Array<string> {
      return ConfiguratorService.getConfigurationIDs();
    },
  },
  validations: {
    session: {
      type: {
        required,
      },
    },
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      await this.onMounted();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
    } finally {
      this.$set(this, 'isLoading', false);
    }
  },

  methods: {
    getTotalPrice(): number {
      return ConfiguratorService.getPurchasePrice();
    },

    hasColors(): boolean {
      return (this.session.configuration.type?.parts.colors || []).length > 0;
    },
    hasOptions(): boolean {
      return (
        (this.session.configuration.type?.parts.options || []).filter(
          (row) => ConfiguratorService.isOptionAvailable(row),
          [],
        ).length > 0
      );
    },
    hasPackages(): boolean {
      return (this.session.configuration.type?.parts.packages || []).length > 0;
    },

    isUVConfigurationEditingAvailable(): boolean {
      // When there are no UV configurations, editing is disabled
      if (
        this.session.configuration.type === undefined ||
        this.session.configuration.type?.uv_configurations.length === 0
      ) {
        return false;
      }

      // When there's an included UV configuration, editing is also disabled
      if (
        this.session.configuration.type.uv_configurations.some(
          (row) => row.mode === SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT,
        ) === true
      ) {
        return false;
      }

      return true;
    },
    isLivePricingEnabled(): boolean {
      return ConfiguratorService.isLivePricingEnabled();
    },

    async onTypeChange(type: SunbedModelTypeEntity): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        await ConfiguratorService.setSunbedType(type);
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
    onEditLightsources() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      Core.Eventhub.emit(CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN, {
        title: 'Available light sources',
        value: this.session.configuration.uv_configuration,
        include_prices: ConfiguratorService.isLivePricingEnabled(),
        unit_type: this.session.model?.unit_type,
        light_sources:
          this.session.model?.unit_type === 'vertical' ? ['bench'] : ['bench', 'canopy'],
        onSubmit: (uv_configuration) => {
          vm.$set(vm.session.configuration, 'uv_configuration', uv_configuration);
        },
      } as ConfiguratorUVConfigurationsModalOptions);
    },

    async onMounted(): Promise<void> {
      if (this.session.type) {
        // await this.onTypeChange(this.session.type);
      }
    },
  },
});
