
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate';

import { SunbedModelTypeUVTypeConfigurationOptions } from '../../interfaces';
import {
  SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN,
  SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_CLOSE,
} from '../../constants';
import {
  SunbedModelTypeFacetannerFilterRow,
  SunbedModelTypeUVConfiguration,
  SunbedModelTypeUVConfigurationDto,
  SunbedModelTypeUVConfigurationMode,
} from '../../../../../../../../services/sunbeds';
import { PARTS_UV_TYPES } from '../../../../../../../../services/parts';
import {
  SearchLightSourceModalOptions,
  SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN,
} from '../../../../../../parts';

import LightSourceForm from './marking-light-source.form.vue';
import FacetannerFilterForm from './facetanner-filter.form.vue';

export default Vue.extend({
  mixins: [Mixins.Modal],
  components: {
    LightSourceForm,
    FacetannerFilterForm,
  },
  data() {
    return {
      options: undefined as SunbedModelTypeUVTypeConfigurationOptions | undefined,

      configuration: undefined as SunbedModelTypeUVConfiguration | undefined,

      isDisabled: false,

      metadata: {
        light_sources: [] as Array<'bench' | 'canopy'>,
        uv_types: PARTS_UV_TYPES,
        modes: Object.values(SunbedModelTypeUVConfigurationMode),
      },
    };
  },
  validations: {
    configuration: {
      uv_type: {
        required,
      },
      description: {
        required,
      },
      article_number: {
        required,
      },
      production_article_number: {
        required,
      },

      facetanner: {
        light_source: {
          article_number: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
            required: requiredIf(function (this: any) {
              const document = this.configuration as SunbedModelTypeUVConfiguration;
              return document.facetanner.enabled === true;
            }),
          },
          amount: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
            required: requiredIf(function (this: any) {
              const document = this.configuration as SunbedModelTypeUVConfiguration;
              return document.facetanner.enabled === true;
            }),
          },
        },
      },
    },
  },

  mounted() {
    this.$eventhub.on(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN, this.open);
    this.$eventhub.on(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN, this.open);
    this.$eventhub.off(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_CLOSE, this.close);
  },

  methods: {
    onFacetannerChange(value: boolean): void {
      if (value === true) {
        this.$set(this.configuration as SunbedModelTypeUVConfiguration, 'facetanner', {
          enabled: true,
          light_source: {
            article_number: '',
            amount: 1,
          },
          filters: [
            {
              article_number: '',
              power: null,
            },
          ],
        });
      }

      if (value === false) {
        this.$set(this.configuration as SunbedModelTypeUVConfiguration, 'facetanner', {
          enabled: false,
        });
      }
    },
    openSearchLightSourceModal(property: 'facetanner'): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN, {
        title: 'Search light source',
        async onSubmit(entity) {
          if (property === 'facetanner') {
            vm.$set(
              (vm.configuration as SunbedModelTypeUVConfiguration).facetanner
                .light_source as Record<string, unknown>,
              'article_number',
              entity?.article_number || undefined,
            );

            await vm.$nextTick();

            (vm.$refs['input:facetanner:light_source:amount'] as HTMLInputElement).focus();
          }
        },
      } as SearchLightSourceModalOptions);
    },

    addFacetannerFilter(): void {
      if (!this.configuration || !this.configuration.facetanner.filters) {
        return;
      }

      this.configuration.facetanner.filters.push({ article_number: '', power: 0 });
    },
    onRemoveFacetannerFilter(filter: SunbedModelTypeFacetannerFilterRow): void {
      if (!this.configuration || !this.configuration.facetanner.filters) {
        return;
      }

      this.$set(
        this.configuration.facetanner,
        'filters',
        this.configuration.facetanner.filters.filter((row) => row !== filter, []),
      );
    },

    getLightSourcesOfLocation(
      location: SunbedModelTypeUVConfiguration['light_sources'][number]['location'],
    ): SunbedModelTypeUVConfiguration['light_sources'] {
      return this.configuration?.light_sources.filter((row) => row.location === location, []) || [];
    },
    addLightSource(
      location: SunbedModelTypeUVConfiguration['light_sources'][number]['location'],
    ): void {
      if (!this.configuration) {
        return;
      }

      this.configuration.light_sources.push({ location, article_number: '', amount: 1 });
    },
    onRemoveLightSource(
      light_source: SunbedModelTypeUVConfiguration['light_sources'][number],
    ): void {
      if (!this.configuration) {
        return;
      }

      this.$set(
        this.configuration,
        'light_sources',
        this.configuration.light_sources.filter((row) => row !== light_source, []),
      );
    },

    async validate(): Promise<void> {
      const validationArray: Array<Validation> = [this.$v];

      // Add facetanner filters
      if (this.configuration?.facetanner.enabled === true) {
        (this.$refs['input:facetanner:filters'] as Array<Vue>).forEach(
          (row) => validationArray.push(row.$v),
          [],
        );
      }

      // Add light sources
      (this.$refs['input:light_sources'] as Array<Vue>).forEach(
        (row) => validationArray.push(row.$v),
        [],
      );

      await ValidatorUtil.validateArray(validationArray);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isDisabled', true);
      try {
        await this.validate();

        const configuration = JSON.parse(
          JSON.stringify(this.configuration),
        ) as SunbedModelTypeUVConfiguration;

        // Remove facetanner filters when they are empty
        if (
          configuration.facetanner.enabled === true &&
          configuration.facetanner.filters?.length !== 0
        ) {
          configuration.facetanner.filters = (configuration.facetanner.filters || []).filter(
            (row) => row.article_number !== undefined && row.article_number.length !== 0,
            [],
          );
        }

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(configuration);
        }
      } catch (e) {
        console.error(e);
        this.addFacetannerFilterWhenEmpty();
        return;
      } finally {
        this.$set(this, 'isDisabled', false);
      }

      this.close();
    },

    async open(options?: SunbedModelTypeUVTypeConfigurationOptions): Promise<void> {
      this.$set(this, 'options', options || undefined);
      this.$set(this.metadata, 'light_sources', options?.light_sources || ['bench', 'canopy']);
      this.$set(
        this,
        'configuration',
        options?.configuration || new SunbedModelTypeUVConfigurationDto(),
      );

      // Add facetanner empty face tanner filter when face tanner is enabled, so UI will show
      this.addFacetannerFilterWhenEmpty();

      await this.$nextTick();

      this.$v.$reset();
      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    addFacetannerFilterWhenEmpty(): void {
      // Add facetanner empty face tanner filter when face tanner is enabled, so UI will show
      if (
        this.configuration?.facetanner?.enabled === true &&
        (this.configuration?.facetanner?.filters === undefined ||
          this.configuration?.facetanner?.filters.length === 0)
      ) {
        // Cast to 'unknown', so we can set 'power' to undefined.
        this.configuration.facetanner.filters = [
          { article_number: '', power: null },
        ] as unknown as Array<SunbedModelTypeFacetannerFilterRow>;
      }
    },

    async onShown() {
      await this.$nextTick();

      (this.$refs['input:description'] as HTMLInputElement).focus();
    },
  },
});
