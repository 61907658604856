export class I18nUtils {
  /**
   * Capitalize the first letter of a string.
   *
   * @param string String
   * @returns String with first letter capitalized.
   */
  static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
