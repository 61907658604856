
import Vue, { PropType } from 'vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { MutationsService } from '@vedicium/object-mutations';
import { ConfigurationViewTypesInformationForm } from '../components';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../services/sunbeds';
import { Core } from '../../../../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../../../../constants';

export default Vue.extend({
  name: 'configurationViewTypesViewInformation',
  components: {
    ConfigurationViewTypesInformationForm,
  },
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([(this.$refs.informationForm as Vue).$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(this.type, this.metadata.type, [
          'name',
          'configuration_id',
          'production_time_in_days',
          'special_preferences.enabled',
        ]);

        if (mutations.length > 0) {
          const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
            `/sunbeds/models/${this.configuration._meta.guid}/types/${this.type._meta.guid}`,
            mutations,
          );
          this.$emit('update:type', response.data);
          this.$set(this.metadata, 'type', JSON.parse(JSON.stringify(response.data)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
