
import Vue, { PropType } from 'vue';
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { Mutation, MutationsService } from '@vedicium/object-mutations';

import {
  SunbedModelEntity,
  SunbedModelTypeDto,
  SunbedModelTypeEntity,
  SunbedModelTypePackageMode,
} from '../../../../../../../services/sunbeds';
import { Core } from '../../../../../../../services/core';
import { PackagePartEntity, PackagePartsStore } from '../../../../../../../services/parts';

export default Vue.extend({
  name: 'configurationViewTypesPackagesCompatibilityForm',
  mixins: [DatatableMixin],
  props: {
    model: {
      type: Object as PropType<SunbedModelEntity>,
    },
    document: {
      type: Object as PropType<SunbedModelTypeDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: PackagePartsStore.url,
        fields: [
          {
            name: 'description',
            title: 'Description',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'article_number',
            title: 'Article number',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'mode',
            title: 'Mode',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
          {
            name: 'only_together',
            title: 'Only together',
            titleClass: 'w-50px',
            dataClass: 'w-50px',
          },
          {
            name: 'not_together_with',
            title: 'Not together',
            titleClass: 'w-50px',
            dataClass: 'w-50px',
          },
        ],

        fetch: DatatableUtil.fetch(Core),
        httpOptions: {
          params: {
            'filter[model]': this.model._meta.guid,
          },
        },
      } as DatatableConfig,

      metadata: {
        modes: Object.values(SunbedModelTypePackageMode),
      },
    };
  },

  methods: {
    getPackageCompatibilityItem(
      entity: PackagePartEntity,
    ): SunbedModelTypeEntity['parts']['packages'][number] | undefined {
      return this.document.parts.packages.find((row) => row.guid === entity._meta.guid);
    },
    getMode(entity: PackagePartEntity): SunbedModelTypePackageMode {
      return (
        this.getPackageCompatibilityItem(entity)?.mode || SunbedModelTypePackageMode.NOT_AVAILABLE
      );
    },
    getTogetherWith(entity: PackagePartEntity): number | undefined {
      return this.document.parts.packages.find((row) => row.guid === entity._meta.guid)
        ?.together_with;
    },
    getNotTogetherWith(entity: PackagePartEntity): number | undefined {
      return this.document.parts.packages.find((row) => row.guid === entity._meta.guid)
        ?.not_together_with;
    },

    isAvailable(entity: PackagePartEntity): boolean {
      return this.getPackageCompatibilityItem(entity) !== undefined;
    },
    isTogetherWithAvailable(entity: PackagePartEntity): boolean {
      return [SunbedModelTypePackageMode.AVAILABLE].includes(this.getMode(entity));
    },
    isNotTogetherWithAvailable(entity: PackagePartEntity): boolean {
      return [SunbedModelTypePackageMode.AVAILABLE].includes(this.getMode(entity));
    },

    onModeChange(entity: PackagePartEntity, value: SunbedModelTypePackageMode): void {
      const mutations: Array<Mutation<SunbedModelTypeDto>> = [];

      switch (value) {
        case SunbedModelTypePackageMode.AVAILABLE:
        case SunbedModelTypePackageMode.INCLUDED_BY_DEFAULT: {
          if (this.getPackageCompatibilityItem(entity) !== undefined) {
            mutations.push({
              action: 'array:objects:set_field',
              field: 'parts.packages',
              query: {
                filter: 'eq',
                field: 'guid',
                value: entity._meta.guid,
              },
              mutation: {
                field: 'mode',
                value: value,
              },
            });
          } else {
            mutations.push({
              action: 'array:push_unique',
              field: 'parts.packages',
              value: {
                guid: entity._meta.guid,
                mode: value,
                together_with: undefined,
                not_together_with: undefined,
              },
            });
          }

          // Remove 'together with' & 'not together with' fields when included by default is selected
          if (value === SunbedModelTypePackageMode.INCLUDED_BY_DEFAULT) {
            mutations.push(
              {
                action: 'array:objects:set_field',
                field: 'parts.packages',
                query: {
                  filter: 'eq',
                  field: 'guid',
                  value: entity._meta.guid,
                },
                mutation: {
                  field: 'together_with',
                  value: undefined,
                },
              },
              {
                action: 'array:objects:set_field',
                field: 'parts.packages',
                query: {
                  filter: 'eq',
                  field: 'guid',
                  value: entity._meta.guid,
                },
                mutation: {
                  field: 'not_together_with',
                  value: undefined,
                },
              },
            );
          }

          break;
        }

        case SunbedModelTypePackageMode.NOT_AVAILABLE:
        default: {
          mutations.push({
            action: 'array:objects:remove',
            field: 'parts.packages',
            query: {
              filter: 'eq',
              field: 'guid',
              value: entity._meta.guid,
            },
          });

          break;
        }
      }

      const document = MutationsService.apply(this.document, mutations);
      this.$set(this.document.parts, 'packages', document.parts.packages);
    },
    onTogetherWithChange(entity: PackagePartEntity, value: string | undefined): void {
      const document = MutationsService.apply(this.document, [
        {
          action: 'array:objects:set_field',
          field: 'parts.packages',
          query: { filter: 'eq', field: 'guid', value: entity._meta.guid },
          mutation: {
            field: 'together_with',
            value: Number(value) || undefined,
          },
        },
      ]);

      this.$set(this.document.parts, 'packages', document.parts.packages);
    },
    onNotTogetherWithChange(entity: PackagePartEntity, value: string | undefined): void {
      const document = MutationsService.apply(this.document, [
        {
          action: 'array:objects:set_field',
          field: 'parts.packages',
          query: { filter: 'eq', field: 'guid', value: entity._meta.guid },
          mutation: {
            field: 'not_together_with',
            value: Number(value) || undefined,
          },
        },
      ]);

      this.$set(this.document.parts, 'packages', document.parts.packages);
    },
  },
});
