
import Vue, { PropType } from 'vue';
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { MutationsService } from '@vedicium/object-mutations';

import { ColorPartDto } from '../../../../../services/parts';
import { SunbedModelEntity, SunbedModelsStore } from '../../../../../services/sunbeds';
import { Core } from '../../../../../services/core';

export default Vue.extend({
  name: 'partsColorsCompatibilityForm',
  mixins: [DatatableMixin],
  props: {
    document: {
      type: Object as PropType<ColorPartDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: SunbedModelsStore.url,
        fields: [
          {
            name: 'name',
            title: 'Name',
            titleClass: 'w-300px',
            dataClass: 'w-300px',
          },
          {
            name: 'unit_type',
            title: 'Unit type',
            sortField: 'unit_type',
            titleClass: 'w-300px',
            dataClass: 'w-300px',
            formatter: (value: string) => this.$t(`sunbeds.unit_types.${value}.name`),
          },
          {
            name: 'compatible',
            title: 'Compatible',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
        ],

        sort: [{ field: 'name', direction: 'asc' }],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },

  methods: {
    setCompatibility(model: SunbedModelEntity, value: boolean): void {
      if (value === true) {
        const { models } = MutationsService.apply(this.document, [
          { action: 'array:push_unique', field: 'models', value: model._meta.guid },
        ]);

        this.$set(this.document, 'models', models);
      } else {
        const { models } = MutationsService.apply(this.document, [
          { action: 'array:remove', field: 'models', value: model._meta.guid },
        ]);

        this.$set(this.document, 'models', models);
      }
    },
  },
});
