
import Vue, { VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { ValidatorUtil } from '@vedicium/vue-core';

import { StateFormMixin } from '../mixins';
import { StateFormInstance } from '../interfaces';
import { Core } from '../../../../../../services/core';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../../services/sunbeds';
import { WeekCalendarComponent } from '../../../../../../components/week-calendar';
import { FormatUtils } from '@/utils';

export default (Vue as VueConstructor<Vue & StateFormInstance>).extend({
  name: 'schedulingDeliveryStatusFormComponent',
  mixins: [StateFormMixin],
  components: {
    WeekCalendarComponent,
  },
  data() {
    return {
      isLoading: false,

      metadata: {
        form: {
          production_order_number: undefined as string | undefined,
          production_week: undefined as string | undefined,
          order_calculation: 0,
          material_purchasing: 0,
        },
      },

      FormatUtils,
    };
  },
  computed: {
    humanReadableState(): string {
      return (
        this.$tu(`sunbeds.orders.states.${SunbedOrderState.SCHEDULING_DELIVERY}`)?.toString() ||
        SunbedOrderState.SCHEDULING_DELIVERY
      );
    },
    additionalCost(): number {
      return this.metadata.form.order_calculation - this.metadata.form.material_purchasing;
    },
  },
  validations: {
    metadata: {
      form: {
        production_order_number: {
          required,
        },
        production_week: {
          required,
        },
        order_calculation: {
          required,
        },
        material_purchasing: {
          required,
        },
      },
    },
  },

  beforeMount() {
    this.$set(this.metadata, 'form', {
      production_order_number: this.order.production_order_number || undefined,
      production_week: this.order.schedule.production_week || undefined,
      order_calculation: this.order.pricing.order_calculation || undefined,
      material_purchasing: this.order.pricing.material_purchasing || undefined,
    });
  },
  mounted() {
    setTimeout(
      () => (this.$refs['input:production_order_number'] as HTMLInputElement).focus(),
      100,
    );
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<SunbedOrderEntity> {
      this.$set(this, 'isLoading', true);
      try {
        // Validate form
        await this.validate();

        // Update state
        const response = await Core.getAdapter().put<SunbedOrderEntity>(
          `/sunbeds/orders/${this.order._meta.guid}/state`,
          {
            state: SunbedOrderState.SCHEDULING_DELIVERY,
            production_order_number: this.metadata.form.production_order_number,
            production_week: this.metadata.form.production_week,
            order_calculation: Number(this.metadata.form.order_calculation),
            material_purchasing: Number(this.metadata.form.material_purchasing),
          },
        );

        return response.data;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
