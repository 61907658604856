
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import {
  SUNBED_ORDER_INTERNAL_REMARK_MODAL_CLOSE,
  SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN,
} from './constants';
import { SunbedOrderInternalRemarkModalOptions } from './interfaces';

export default Vue.extend({
  name: 'sunbedOrderInternalRemark',
  mixins: [Mixins.Modal],
  data() {
    return {
      isLoading: false,

      options: undefined as SunbedOrderInternalRemarkModalOptions | undefined,

      metadata: {
        remark: '',
      },
    };
  },

  mounted() {
    this.$eventhub.on(SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN, this.open);
    this.$eventhub.on(SUNBED_ORDER_INTERNAL_REMARK_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN, this.open);
    this.$eventhub.off(SUNBED_ORDER_INTERNAL_REMARK_MODAL_CLOSE, this.close);
  },

  methods: {
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.metadata.remark);
        }
      } catch (e) {
        console.error(e);
        this.options?.onError?.(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: SunbedOrderInternalRemarkModalOptions): void {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', options || undefined);
      this.$set(this.metadata, 'remark', options?.value || undefined);

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();

      (this.$refs['input:remark'] as HTMLTextAreaElement).focus();
    },
  },
});
