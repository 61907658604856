
import { DatatableConfig, DatatableInstance, DatatableMixin } from '@vedicium/metronic-vue';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  DragDropUploadModal,
  DragDropUploadModalOptions,
  DRAG_DROP_UPLOAD_MODAL_OPEN,
} from '../../../../components/drag-drop-upload-modal';

import {
  PricelistEntity,
  PricelistItemWithProperties,
  PricelistsStore,
} from '../../../../services/pricelists';
import { SunbedModelTypeEntity } from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';

export default Vue.extend({
  name: 'pricelistsViewInformation',
  mixins: [DatatableMixin],
  props: {
    pricelist: {
      type: Object as PropType<PricelistEntity>,
    },
  },
  components: {
    DragDropUploadModal,
  },

  computed: {
    pricelistItems(): PricelistEntity<PricelistItemWithProperties>['items'] {
      return [...this.metadata.pricelist.items].sort((a, b) =>
        a.description.localeCompare(b.description),
      );
    },
  },

  data() {
    return {
      isLoadingForm: false,

      metadata: {
        pricelist: JSON.parse(
          JSON.stringify(this.pricelist),
        ) as PricelistEntity<PricelistItemWithProperties>,

        datatable: {
          fields: [
            {
              name: 'description',
              title: 'Description',
            },
            {
              name: 'article_number',
              title: 'Article number',
            },
            {
              name: 'purchase_price',
              title: 'Purchase price',
              formatter: (value: number) => FormatUtils.currency(value),
            },
            {
              name: 'retail_selling_price',
              title: 'Retail selling price',
              formatter: (value: number) => FormatUtils.currency(value),
            },
          ],

          sort: [{ field: 'description', direction: 'asc' }],
        } as DatatableConfig,
      },
    };
  },

  methods: {
    getItemIcon(item: PricelistItemWithProperties): Array<string> {
      switch (item.type) {
        case 'color': {
          return ['fas', 'tint'];
        }

        case 'filter': {
          return ['fas', 'shield'];
        }

        case 'light source': {
          return ['fas', 'lightbulb'];
        }

        case 'package': {
          return ['fas', 'layer-plus'];
        }

        case 'option': {
          return ['fas', 'plus-circle'];
        }

        case 'sunbed model type': {
          return ['fas', 'tag'];
        }

        case 'uv configuration': {
          return ['fas', 'sun'];
        }

        case 'unknown':
        default: {
          return ['fas', 'question'];
        }
      }
    },
    getItemRoute(item: PricelistItemWithProperties): string | undefined {
      if (item.entity === undefined) {
        return undefined;
      }

      switch (item.type) {
        case 'color': {
          return `/parts/colors/view/${item.entity._meta.guid}`;
        }

        case 'filter': {
          return `/parts/filters/view/${item.entity._meta.guid}`;
        }

        case 'light source': {
          return `/parts/light-sources/view/${item.entity._meta.guid}`;
        }

        case 'option': {
          return `/parts/options/view/${item.entity._meta.guid}`;
        }

        case 'package': {
          return `/parts/packages/view/${item.entity._meta.guid}`;
        }

        case 'sunbed model type': {
          const entity = item.entity as SunbedModelTypeEntity;
          if (entity.model === undefined) {
            return undefined;
          }

          return `/configurations/view/${entity.model._meta.guid}/types/${entity._meta.guid}`;
        }

        case 'uv configuration': {
          const entity = item.entity as SunbedModelTypeEntity;
          if (entity.model === undefined) {
            return undefined;
          }

          return `/configurations/view/${entity.model._meta.guid}/types/${entity._meta.guid}/uv-configurations`;
        }

        default: {
          return undefined;
        }
      }
    },

    async onImportButton() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      Core.Eventhub.emit(DRAG_DROP_UPLOAD_MODAL_OPEN, {
        title: 'Upload items CSV',
        instructions: 'Only CSV files are accepted',
        multiple: false,
        accept: '.csv',
        async onSubmit(files) {
          const formData = new FormData();
          formData.append('file', files[0]);

          await Core.getAdapter('http').put(
            `/pricelists/${vm.metadata.pricelist._meta.guid}/items`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );

          vm.reloadItems();
        },
      } as DragDropUploadModalOptions);
    },
    async onExportButton() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await Core.getAdapter('http').get(
          `/pricelists/${this.metadata.pricelist._meta.guid}/items/export`,
          {
            responseType: 'blob',
            options: {
              interceptors: {
                download_attachment: true,
              },
            },
          },
        );

        // Use a timeout because of the download
        setTimeout(() => this.$set(this, 'isLoadingForm', false), 1000);
      } catch (e) {
        this.$set(this, 'isLoadingForm', false);
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      }
    },

    async reloadItems(): Promise<void> {
      (this.$refs.datatable as DatatableInstance).setLoading(true);
      try {
        const pricelist = await PricelistsStore.get(this.metadata.pricelist._meta.guid, {
          params: { include_item_properties: true },
        });

        this.$emit('update:pricelist', pricelist);
        this.$set(this.metadata, 'pricelist', JSON.parse(JSON.stringify(pricelist)));
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        (this.$refs.datatable as DatatableInstance).setLoading(false);
      }
    },
  },
});
