
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import {
  ManufacturingSublineFilterModalOptions,
  MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN,
} from '../..';
import { Core } from '../../../../../../services/core';
import {
  ManufacturingSubline,
  ManufacturingSublineFilter,
  ManufacturingSublineFilterDomain,
  ManufacturingSublineFilterType,
} from '../../../../../../services/parts';
import { SunbedOrderUtils } from '../../../../../../services/sunbeds';

export default Vue.extend({
  name: 'manufacturingSublineFiltersTableComponent',
  mixins: [DatatableMixin],
  props: {
    subline: {
      type: Object as PropType<ManufacturingSubline>,
    },
  },
  data() {
    return {
      metadata: {
        subline: JSON.parse(JSON.stringify(this.subline)) as ManufacturingSubline,

        datatable: {
          fields: [
            {
              name: 'domain',
              title: 'Domain',
            },
            {
              name: 'type',
              title: 'Type',
            },
            {
              name: 'values',
              title: 'Values',
            },
            {
              name: 'actions',
              title: 'Actions',
            },
          ],
        } as DatatableConfig,
      },
    };
  },

  methods: {
    getValueOfFilter(filter: ManufacturingSublineFilter, value: string): string {
      switch (filter.domain) {
        case ManufacturingSublineFilterDomain.COLOR: {
          const translation = this.$t(`parts.colors.configuration_ids.${value}`) as unknown as {
            name: string;
            category: string;
          };

          return `${translation.category} - ${translation.name}`;
        }

        case ManufacturingSublineFilterDomain.OPTION: {
          const translation = this.$t(`parts.options.configuration_ids.${value}`) as unknown as {
            name: string;
            category: string;
          };

          return `${translation.category} - ${translation.name}`;
        }

        case ManufacturingSublineFilterDomain.COUNTRY: {
          return SunbedOrderUtils.getCountryNameByCode(value) || value;
        }
      }
    },

    onAddSublineFilter(): void {
      const vm = this;
      Core.Eventhub.emit(MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN, {
        title: 'Add filter',
        filter: {
          domain: ManufacturingSublineFilterDomain.COLOR,
          type: ManufacturingSublineFilterType.OR,
          values: [],
        },
        onSubmit(entity) {
          vm.subline.filters.push(entity);
        },
      } as ManufacturingSublineFilterModalOptions);
    },
    onModifySublineFilter(filter: ManufacturingSublineFilter): void {
      const vm = this;
      Core.Eventhub.emit(MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN, {
        title: 'Edit filter',
        filter: JSON.parse(JSON.stringify(filter)),
        onSubmit(entity) {
          const idx = vm.subline.filters.indexOf(filter);
          vm.$set(vm.subline.filters, idx, entity);
        },
      } as ManufacturingSublineFilterModalOptions);
    },
    onRemoveSublineFilter(filter: ManufacturingSublineFilter): void {
      const vm = this;
      vm.$set(
        vm.subline,
        'filters',
        vm.subline.filters.filter((row) => row !== filter, []),
      );
    },
  },
});
