export enum SunbedModelConfigurationID {
  LUXURA_JEWEL = 'luxura_jewel',
  LUXURA_VEGAZ_9200 = 'luxura_vegaz_9200',
  LUXURA_VEGAZ_8200 = 'luxura_vegaz_8200',
  LUXURA_X10 = 'luxura_x10',
  LUXURA_X7 = 'luxura_x7',
  LUXURA_X5 = 'luxura_x5',
  LUXURA_X3 = 'luxura_x3',
  LUXURA_V8 = 'luxura_v8',
  LUXURA_V6 = 'luxura_v6',
  SEECRET = 'seecret',
  UNKNOWN = 'unknown',
}

export interface SunbedModelShippingBox {
  /**
   * Content of the box.
   */
  content: string;

  /**
   * When instructions are included, it should be shown on the shipping box labels.
   */
  instructions_included: boolean;
}

export interface SunbedModelProductionChecklistItem {
  /**
   * Item of the checklist.
   */
  item: string;
}

export class SunbedModelShippingBoxDto {
  content: SunbedModelShippingBox['content'] | undefined = undefined;
  instructions_included: SunbedModelShippingBox['instructions_included'] = false;
}

export class SunbedModelProductionChecklistItemDto {
  item: SunbedModelProductionChecklistItem['item'] | undefined = undefined;
}
