
import Vue, { PropType } from 'vue';
import { DateTime } from 'luxon';
import { Mutation } from '@vedicium/object-mutations';

import {
  SunbedOrderEntity,
  SunbedOrderState,
  SunbedOrderUtils,
} from '../../../../../services/sunbeds';
import {
  WeekCalendarModalOptions,
  WeekCalendarUtils,
  WEEK_CALENDAR_MODAL_OPEN,
} from '../../../../../components/week-calendar';
import { Core } from '../../../../../services/core';
import {
  SunbedOrderProductionRemarkModal,
  SUNBED_ORDER_PRODUCTION_REMARK_MODAL_OPEN,
} from '../../modals';
import { APP_ERROR_EVENT } from '@/constants';

type SunbedOrderDate = 'creation' | 'confirmation' | 'production' | 'ready_to_ship' | 'desired_by';

export default Vue.extend({
  name: 'ordersViewGeneral',
  components: {
    ProductionRemarkModal: SunbedOrderProductionRemarkModal,
  },
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  data() {
    return {
      isDownloadingOrderForm: false,

      metadata: {
        dates: [
          'creation',
          'confirmation',
          'production',
          'ready_to_ship',
          'desired_by',
        ] as Array<SunbedOrderDate>,
      },
    };
  },

  methods: {
    onEditProductionDateClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(WEEK_CALENDAR_MODAL_OPEN, {
        title: 'Modify production week',
        label: 'Production week',
        value: this.order.schedule.production_week,
        minDate: DateTime.now().startOf('week').startOf('day').plus({ weeks: 1 }).toJSDate(),

        async onSubmit(isoWeek: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/production_week`,
            { production_week: isoWeek },
          );

          vm.$emit('update:order', response.data);
        },
      } as WeekCalendarModalOptions);
    },
    onEditProductionRemarkClick(): void {
      if (this.isEditingProductionRemarkAllowed() === false) {
        return;
      }

      const vm = this;
      this.$eventhub.emit(SUNBED_ORDER_PRODUCTION_REMARK_MODAL_OPEN, {
        title: 'Modify production remark',
        value: this.order.production.remark?.toString() || '',

        async onSubmit(remark: string) {
          // Only perform request when remark has changed
          if (remark !== vm.order.production.remark) {
            const response = await Core.getAdapter().patch<SunbedOrderEntity>(
              `/sunbeds/orders/${vm.order._meta.guid}`,
              [
                {
                  action: 'set_field',
                  field: 'production.remark',
                  value: remark,
                },
              ] as Array<Mutation<SunbedOrderEntity>>,
            );

            vm.$emit('update:order', response.data);
          }
        },
      } as WeekCalendarModalOptions);
    },

    isEditingProductionRemarkAllowed(): boolean {
      return [
        SunbedOrderState.MODIFICATION_IN_PROCESS,
        SunbedOrderState.REQUESTED,
        SunbedOrderState.SCHEDULING_PRODUCTION,
        SunbedOrderState.SCHEDULING_DELIVERY,
        SunbedOrderState.READY_FOR_PRODUCTION,
        SunbedOrderState.IN_PRODUCTION,
      ].includes(this.order.state);
    },

    getProgressInPercentage(): number {
      switch (this.order.state) {
        case SunbedOrderState.REQUESTED:
        case SunbedOrderState.SCHEDULING_PRODUCTION:
        case SunbedOrderState.SCHEDULING_DELIVERY: {
          return 0.1;
        }

        case SunbedOrderState.READY_FOR_PRODUCTION: {
          return 0.3;
        }

        case SunbedOrderState.IN_PRODUCTION: {
          return 0.5;
        }

        case SunbedOrderState.READY_TO_SHIP: {
          return 0.7;
        }

        case SunbedOrderState.SHIPPED: {
          return 1;
        }

        case SunbedOrderState.CANCELLED:
        case SunbedOrderState.MODIFICATION_IN_PROCESS: {
          return 1;
        }

        default: {
          return 0;
        }
      }
    },
    getProgressVariant(): string {
      return SunbedOrderUtils.getVariantByState(this.order.state);
    },

    getDate(date: SunbedOrderDate): string | undefined {
      switch (date) {
        case 'creation': {
          return DateTime.fromMillis(this.order._meta.created).toFormat('dd-MM-yyyy');
        }

        case 'confirmation': {
          return this.order.schedule.confirmation_date
            ? DateTime.fromMillis(this.order.schedule.confirmation_date).toFormat('dd-MM-yyyy')
            : undefined;
        }

        case 'production': {
          return this.order.schedule.production_week
            ? WeekCalendarUtils.getHumanReadableISOWeek(this.order.schedule.production_week)
            : undefined;
        }

        case 'ready_to_ship': {
          return this.order.schedule.shipping_week
            ? WeekCalendarUtils.getHumanReadableISOWeek(this.order.schedule.shipping_week)
            : undefined;
        }

        case 'desired_by': {
          if (this.order.schedule.desired_delivery_week === 'asap') {
            return 'As soon as possible';
          }

          return WeekCalendarUtils.getHumanReadableISOWeek(
            this.order.schedule.desired_delivery_week,
          );
        }

        default: {
          return undefined;
        }
      }
    },
    getHumanReadableSunbedOrderDate(date: SunbedOrderDate): string {
      switch (date) {
        case 'creation': {
          return 'Creation date';
        }

        case 'confirmation': {
          return 'Confirmation date';
        }

        case 'production': {
          return 'Production week';
        }

        case 'ready_to_ship': {
          return 'Ready to ship';
        }

        case 'desired_by': {
          return 'Desired by';
        }

        default: {
          return '';
        }
      }
    },

    async onDownloadOrderForm(): Promise<void> {
      this.$set(this, 'isDownloadingOrderForm', true);
      try {
        await Core.Http.get(`/sunbeds/orders/${this.order._meta.guid}/document/order-form`, {
          responseType: 'blob',
        });
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isDownloadingOrderForm', false);
      }
    },
  },
});
