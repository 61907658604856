import {
  SunbedModelConfigurationID,
  SunbedModelTypeColorMode,
  SunbedModelTypeConfigurationID,
  SunbedModelTypeOptionMode,
  SunbedModelTypePackageMode,
  SunbedModelTypeUVConfigurationMode,
} from '../../../sunbeds';

export default {
  unit_types: {
    horizontal: {
      name: 'Horizontal',

      light_sources: {
        bench: 'bench',
        canopy: 'canopy',
      },
    },
    vertical: {
      name: 'Vertical',

      light_sources: {
        bench: 'panels and doors',
      },
    },
  },

  models: {
    configuration_ids: {
      [SunbedModelConfigurationID.LUXURA_JEWEL]: 'Luxura Jewel',
      [SunbedModelConfigurationID.LUXURA_VEGAZ_9200]: 'Luxura Vegaz 9200',
      [SunbedModelConfigurationID.LUXURA_VEGAZ_8200]: 'Luxura Vegaz 8200',
      [SunbedModelConfigurationID.LUXURA_X10]: 'Luxura X10',
      [SunbedModelConfigurationID.LUXURA_X7]: 'Luxura X7',
      [SunbedModelConfigurationID.LUXURA_X5]: 'Luxura X5',
      [SunbedModelConfigurationID.LUXURA_X3]: 'Luxura X3',
      [SunbedModelConfigurationID.LUXURA_V8]: 'Luxura V8',
      [SunbedModelConfigurationID.LUXURA_V6]: 'Luxura V6',
      [SunbedModelConfigurationID.SEECRET]: 'Seecret',
      [SunbedModelConfigurationID.UNKNOWN]: 'Unknown',
    },

    types: {
      configuration_ids: {
        // Luxura Jewel
        [SunbedModelTypeConfigurationID.LUXURA_JEWEL_EQ45]: 'EQ45',
        [SunbedModelTypeConfigurationID.LUXURA_JEWEL_EQ55]: 'EQ55',

        // Luxura Vegaz 9200
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_BALANCE]: 'Balance',
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_INTELLIGENT]: 'Intelligent',
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_HIGHBRID]: 'Highbrid',

        // Luxura Vegaz 8200
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_BALANCE]: 'Balance',
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_INTELLIGENT]: 'Intelligent',
        [SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_HIGHBRID]: 'Highbrid',

        // Luxura X7
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI]: '38 SLi',
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_INTENSIVE]: '38 SLi Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_HIGH_INTENSIVE]: '38 SLi High Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_BALANCE]: '38 SLi Balance',
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_INTELLIGENT]: '38 SLi Intelligent',
        [SunbedModelTypeConfigurationID.LUXURA_X7_38_HIGHBRID]: '38 Highbrid',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI]: '42 SLi',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_INTENSIVE]: '42 SLi Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_HIGH_INTENSIVE]: '42 SLi High Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_BALANCE]: '42 SLi Balance',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_INTELLIGENT]: '42 SLi Intelligent',
        [SunbedModelTypeConfigurationID.LUXURA_X7_42_HIGHBRID]: '42 Highbrid',

        // Luxura X5
        [SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI]: '34 SLi',
        [SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_INTENSIVE]: '34 SLi Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_HIGH_INTENSIVE]: '34 SLi High Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_BALANCE]: '34 SLi Balance',
        [SunbedModelTypeConfigurationID.LUXURA_X5_38_SLI_HIGH_INTENSIVE]: '38 SLi High Intensive',

        // Luxura X3
        [SunbedModelTypeConfigurationID.LUXURA_X3_32_SLI]: '32 SLi',
        [SunbedModelTypeConfigurationID.LUXURA_X3_32_SLI_INTENSIVE]: '32 SLi Intensive',
        [SunbedModelTypeConfigurationID.LUXURA_X3_COLLAGEN_SUN]: 'Collagen Sun',

        // Luxura V6
        [SunbedModelTypeConfigurationID.LUXURA_V6_42_XL]: '42 XL',
        [SunbedModelTypeConfigurationID.LUXURA_V6_44_XL_BALANCE]: '44 XL Balance',
        [SunbedModelTypeConfigurationID.LUXURA_V6_44_XL_BALANCE_ULTRA]: '44 XL Balance Ultra',
        [SunbedModelTypeConfigurationID.LUXURA_V6_48_XL_BALANCE]: '48 XL Balance',
        [SunbedModelTypeConfigurationID.LUXURA_V6_48_XL_BALANCE_ULTRA]: '48 XL Balance Ultra',

        // Luxura V8
        [SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_BALANCE]: '48 XL Balance',
        [SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_BALANCE_ULTRA]: '48 XL Balance Ultra',
        [SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_INTELLIGENT]: '48 XL Intelligent',
        [SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_HIGHBRID]: '48 XL Highbrid',

        // Seecret
        [SunbedModelTypeConfigurationID.SEECRET_C700]: 'Seecret C700',
      },

      uv_configurations: {
        modes: {
          [SunbedModelTypeUVConfigurationMode.AVAILABLE]: '-',
          [SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT]: 'Selected by default',
          [SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT]: 'Included by default',
        },
      },

      parts: {
        options: {
          modes: {
            [SunbedModelTypeOptionMode.NOT_AVAILABLE]: 'Not available',
            [SunbedModelTypeOptionMode.AVAILABLE]: 'Available',
            [SunbedModelTypeOptionMode.PACKAGE_ONLY]: 'Package only',
            [SunbedModelTypeOptionMode.SELECTED_BY_DEFAULT]: 'Selected by default',
            [SunbedModelTypeOptionMode.INCLUDED_BY_DEFAULT]: 'Included by default',
          },
        },

        colors: {
          modes: {
            [SunbedModelTypeColorMode.NOT_AVAILABLE]: 'Not available',
            [SunbedModelTypeColorMode.AVAILABLE]: 'Available',
            [SunbedModelTypeColorMode.SELECTED_BY_DEFAULT]: 'Selected by default',
            [SunbedModelTypeColorMode.INCLUDED_BY_DEFAULT]: 'Included by default',
          },
        },

        packages: {
          modes: {
            [SunbedModelTypePackageMode.NOT_AVAILABLE]: 'Not available',
            [SunbedModelTypePackageMode.AVAILABLE]: 'Available',
            [SunbedModelTypePackageMode.INCLUDED_BY_DEFAULT]: 'Included by default',
          },
        },
      },
    },
  },

  orders: {
    states: {
      requested: 'Requested',
      scheduling_production: 'Scheduling production',
      scheduling_delivery: 'Scheduling delivery',
      ready_for_production: 'Ready for production',
      in_production: 'In production',
      ready_to_ship: 'Ready to ship',
      shipped: 'Shipped',
      modification_in_process: 'Modification in process',
      cancelled: 'Cancelled',
    },
  },
};
