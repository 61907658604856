
import Vue from 'vue';
import { BDropdown } from 'bootstrap-vue';
import { LogoutOptions } from '@auth0/auth0-spa-js';

import { CONFIRMATION_MODAL_EVENT_OPEN, SWITCH_ORGANIZATION_MODAL_EVENT_OPEN } from '../modals';
import { getUserInitials, UserEntity } from '../../../../services/users';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';

export default Vue.extend({
  name: 'PortalLayoutTopbar',
  computed: {
    initials() {
      return getUserInitials(this.$authentication.entity as UserEntity);
    },
  },
  watch: {
    $route(): void {
      if (!this.$refs.userDropdown) {
        return;
      }
      if ((this.$refs.userDropdown as BDropdown).visible === true) {
        (this.$refs.userDropdown as BDropdown).hide();
      }
    },
  },

  methods: {
    async openOrganizationModal(): Promise<void> {
      this.$eventhub.emit(SWITCH_ORGANIZATION_MODAL_EVENT_OPEN);
    },
    onLeaveOrganizationClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: `Leave ${Core.Organizations.organization.name}`,
        message: `Are you sure that you want to leave <strong>${Core.Organizations.organization.name}</strong>?<br /><br />Please type the name of the organization to confirm:`,
        verifyString: Core.Organizations.organization.name,
        buttonText: 'Confirm',
        variant: 'danger',
        async onSubmit() {
          try {
            await Core.getAdapter().put('/entity/organizations/leave', undefined, {
              params: { persistent: true },
            });

            // Reload entity, because it's probably modified.
            await Core.Authentication.getEntity();
            Core.Organizations.remove();
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            console.error(e);
          }
        },
      });
    },
    async logout(): Promise<void> {
      try {
        await Core.getAdapter()
          .request({
            method: 'POST',
            url: '/entity/logout',
          })
          .catch((e) => console.error(e));

        await Core.Authentication.logout<LogoutOptions>({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
      }
    },
  },
});
