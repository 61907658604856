import { Entity } from '@vedicium/vue-core';
import { OptionPartEntity } from './options.interface';

export class PackagePartDto {
  description?: string = undefined;
  article_number?: string = undefined;
  model?: string = undefined;
  options: Array<OptionPartEntity['_meta']['guid']> = [];
}

export interface PackagePartEntity extends Entity, PackagePartDto {}
