
import Vue from 'vue';
import { Core } from '../../../../services/core';
import { SunbedModelEntity, SunbedModelsStore } from '../../../../services/sunbeds';
import { CONFIGURATION_VIEW_MODEL_KEY } from '../constants';

export default Vue.extend({
  name: 'configurationsView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        model: undefined as SunbedModelEntity | undefined,
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Configurations', route: '/configurations/overview' },
    ]);
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      const model = await SunbedModelsStore.get(this.$route.params.guid);
      this.$set(this.metadata, 'model', model);
      Core.Memory.save(CONFIGURATION_VIEW_MODEL_KEY(model._meta.guid), model);
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
      this.$set(this, 'isLoading', false);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({ title: this.metadata.model?.name as string });
  },
});
