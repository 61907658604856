
import { FormMixin } from '@vedicium/vue-core';
import Vue from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { required } from 'vuelidate/lib/validators';

import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  ConfiguratorService,
  SunbedConfigurationSearchResult,
  SunbedConfigurationSession,
  SunbedModelEntity,
  SunbedUtils,
  SunbedModelConfigurationID,
} from '../../../../services/sunbeds';
import SunbedModelCard from './model.card.vue';

export default Vue.extend({
  name: 'ordersModelsForm',
  mixins: [FormMixin],
  props: {
    action: {
      type: String as PropType<'CREATE' | 'UPDATE'>,
      default: 'CREATE',
    },
  },
  components: {
    SunbedModelCard,
  },
  computed: {
    horizontalModels(): Array<SunbedConfigurationSearchResult> {
      return this.metadata.models
        .filter((model) => model.unit_type === 'horizontal', [])
        .sort(this.modelSort('horizontal'));
    },
    verticalModels(): Array<SunbedConfigurationSearchResult> {
      return this.metadata.models
        .filter((model) => model.unit_type === 'vertical', [])
        .sort(this.modelSort('vertical'));
    },
  },
  data() {
    return {
      isMounted: false,
      isLoading: false,

      session: ConfiguratorService.session as SunbedConfigurationSession,

      metadata: {
        models: [] as Array<SunbedConfigurationSearchResult>,
      },
    };
  },
  validations: {
    session: {
      model: {
        required,
      },
    },
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      await this.onMounted();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
    } finally {
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();
    this.$set(this, 'isMounted', true);
  },

  methods: {
    setModel(model: SunbedConfigurationSearchResult | undefined): void {
      // Only trigger this event on change
      if (model?._meta.guid === this.session.model?._meta.guid) {
        return;
      }

      ConfiguratorService.setSunbedModel(model);
    },

    modelSort(
      unit_type: SunbedModelEntity['unit_type'],
    ): (model: SunbedModelEntity, compare: SunbedModelEntity) => number {
      const sortingArray: Array<string> =
        unit_type === 'horizontal'
          ? [
              SunbedModelConfigurationID.LUXURA_VEGAZ_9200,
              SunbedModelConfigurationID.LUXURA_VEGAZ_8200,
              SunbedModelConfigurationID.LUXURA_X10,
              SunbedModelConfigurationID.LUXURA_X7,
              SunbedModelConfigurationID.LUXURA_X5,
              SunbedModelConfigurationID.LUXURA_X3,
              SunbedModelConfigurationID.SEECRET,
            ]
          : [SunbedModelConfigurationID.LUXURA_V8, SunbedModelConfigurationID.LUXURA_V6];

      return (model, compare) => {
        if (sortingArray.includes(model.configuration_id) === false) {
          return 0;
        }

        return (
          sortingArray.indexOf(model.configuration_id) -
          sortingArray.indexOf(compare.configuration_id)
        );
      };
    },

    async onMounted() {
      // Gather all sunbed models by rated voltage & UV type
      const results = await ConfiguratorService.getSunbedModels(
        this.session.organization?._meta.guid as string,
        SunbedUtils.getCompatibleUVTypes(this.session.uv_type as string),
        this.session.rated_voltage as string,
        {
          params: {
            'filter[_meta.guid]':
              this.action === 'UPDATE' ? this.session.model?._meta.guid : undefined,
          },
        },
      );

      // Set the current models
      this.$set(this.metadata, 'models', results);

      // Check if a model is already selected
      if (this.session.model !== undefined) {
        const updatedModel = results.find(
          (row) => row._meta.guid === this.session.model?._meta.guid,
        );

        // When the updated model is not found, reset the sunbed model
        if (updatedModel === undefined) {
          ConfiguratorService.setSunbedModel(undefined);
          return;
        }

        // If the updated model is found, update it so it'll use the new found types.
        this.session.model = updatedModel;
      }
    },
  },
});
