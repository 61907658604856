var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 form-group"},[_c('label',[_vm._v("Name "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'industry']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.name.$model),expression:"$v.document.name.$model",modifiers:{"trim":true}}],class:['form-control form-control-lg', { 'is-invalid': _vm.$v.document.name.$error }],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-6 form-group"},[_c('label',[_vm._v("Organization ID "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'hashtag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.organization_id.$model),expression:"$v.document.information.organization_id.$model",modifiers:{"trim":true}}],class:[
          'form-control form-control-lg',
          { 'is-invalid': _vm.$v.document.information.organization_id.$error },
        ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.information.organization_id.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.information.organization_id, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-6 form-group"},[_c('label',[_vm._v("Administration email address "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.administration_email.$model),expression:"$v.document.information.administration_email.$model",modifiers:{"trim":true}}],class:[
          'form-control form-control-lg',
          { 'is-invalid': _vm.$v.document.information.administration_email.$error },
        ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.information.administration_email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.information.administration_email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(`All order updates will be sent to this email address.`),expression:"`All order updates will be sent to this email address.`",modifiers:{"hover":true}}],staticClass:"text-hover-info cursor-pointer"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'info-circle']}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }