
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { APP_ERROR_EVENT } from '../../constants';
import { Core } from '../../services/core';

import {
  DRAG_DROP_UPLOAD_MODAL_OPEN,
  DRAG_DROP_UPLOAD_MODAL_CLOSE,
} from './drag-drop-upload-modal.constants';
import { DragDropUploadModalOptions } from './drag-drop-upload-modal.interface';

export default Vue.extend({
  mixins: [Mixins.Modal],
  data() {
    return {
      options: {} as DragDropUploadModalOptions,

      isLoading: false,

      metadata: {
        dragover: false,
        files: [] as Array<File>,
      },
    };
  },
  validations: {
    metadata: {
      files: {
        required,
      },
    },
  },

  mounted() {
    Core.Eventhub.on(DRAG_DROP_UPLOAD_MODAL_OPEN, this.open);
    Core.Eventhub.on(DRAG_DROP_UPLOAD_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    Core.Eventhub.off(DRAG_DROP_UPLOAD_MODAL_OPEN, this.open);
    Core.Eventhub.off(DRAG_DROP_UPLOAD_MODAL_CLOSE, this.close);
  },

  methods: {
    onDropzoneClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
    onDropzoneDrop(event: DragEvent) {
      this.$set(this.metadata, 'dragover', false);
      this.setFiles(event.dataTransfer?.files || undefined);
    },
    onDropzoneDragover() {
      this.$set(this.metadata, 'dragover', true);
    },
    onDropzoneDragleave() {
      this.$set(this.metadata, 'dragover', false);
    },

    onFileChange(event: Event) {
      const target = event.target as HTMLInputElement;
      this.setFiles(target.files || undefined);
    },
    onFileRemoval(file: File): void {
      (this.$refs.fileInput as HTMLInputElement).value = '';
      this.$set(
        this.metadata,
        'files',
        this.metadata.files.filter((row) => row !== file, []),
      );
    },
    setFiles(fileList?: FileList): Array<File> {
      const files: Array<File> = [];

      if (fileList !== undefined) {
        Array.from(fileList).forEach((file) => files.push(file));
      }

      this.$set(this.metadata, 'files', files);

      return files;
    },

    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          this.options.onSubmit(this.metadata.files);
        }
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
        this.close();
      }
    },

    async open(options?: DragDropUploadModalOptions): Promise<void> {
      this.$set(this, 'options', options || {});
      this.$set(this.metadata, 'dragover', false);
      this.$set(this.metadata, 'files', []);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },
  },
});
