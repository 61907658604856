
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';

import { SEARCH_FILTER_MODAL_EVENT_CLOSE, SEARCH_FILTER_MODAL_EVENT_OPEN } from '../constants';
import { SearchFilterModalOptions } from '../interfaces';
import PartsFiltersVueSelect from './filters.vue-select.vue';
import { FilterPartEntity } from '../../../../../services/parts';

export default Vue.extend({
  mixins: [Mixins.Modal],
  name: 'SearchFilterModal',
  components: {
    PartsFiltersVueSelect,
  },
  data() {
    return {
      entity: undefined as FilterPartEntity | undefined,

      options: undefined as SearchFilterModalOptions | undefined,
    };
  },

  mounted() {
    this.$eventhub.on(SEARCH_FILTER_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.on(SEARCH_FILTER_MODAL_EVENT_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(SEARCH_FILTER_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.off(SEARCH_FILTER_MODAL_EVENT_CLOSE, this.close);
  },

  methods: {
    async onSubmit(): Promise<void> {
      if (typeof this.options?.onSubmit === 'function') {
        await this.options.onSubmit(this.entity);
      }

      this.close();
    },

    open(options?: SearchFilterModalOptions): void {
      this.$set(this, 'options', options || undefined);
      this.$set(this, 'entity', undefined);

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    onShown(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs['input:filter'] as any).focus();
    },
  },
});
