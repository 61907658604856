var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card card-custom"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-auto mt-3 my-md-auto text-right"},[_c('span',{class:[
            'ml-auto switch',
            {
              'switch-success':
                _vm.$v.metadata.information.settings.regular_products_available.$model === true,
            },
          ]},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.metadata.information.settings.regular_products_available.$model),expression:"$v.metadata.information.settings.regular_products_available.$model"}],attrs:{"name":"regular_products_available","type":"checkbox","disabled":_vm.$permissions.hasPermission('organizations.update') === false || _vm.isLoadingForm},domProps:{"checked":Array.isArray(_vm.$v.metadata.information.settings.regular_products_available.$model)?_vm._i(_vm.$v.metadata.information.settings.regular_products_available.$model,null)>-1:(_vm.$v.metadata.information.settings.regular_products_available.$model)},on:{"change":function($event){var $$a=_vm.$v.metadata.information.settings.regular_products_available.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.metadata.information.settings.regular_products_available, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.metadata.information.settings.regular_products_available, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.metadata.information.settings.regular_products_available, "$model", $$c)}}}}),_c('span')])])])])]),(_vm.$permissions.hasPermission('organizations.update'))?_c('div',{staticClass:"card-footer py-3"},[_c('button',{staticClass:"btn btn-warning w-150px d-block ml-auto",attrs:{"disabled":_vm.isLoadingForm},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Update ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title align-items-start flex-column"},[_c('h3',{staticClass:"card-label font-weight-bolder text-primary"},[_vm._v("Product preferences")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-sm mt-1"},[_vm._v(" View and update product preferences of the current organization. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-md"},[_c('h5',{staticClass:"overflow-ellipsis"},[_vm._v("Regular products available")]),_c('span',[_vm._v(" When this option is disabled, regular products can't be configured for the organization. ")])])
}]

export { render, staticRenderFns }