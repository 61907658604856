
import { FormMixin } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { requiredIf } from 'vuelidate/lib/validators';

import { SunbedModelTypeFacetannerFilterRow } from '../../../../../../../../services/sunbeds';
import { SearchFilterModalOptions, SEARCH_FILTER_MODAL_EVENT_OPEN } from '../../../../../../parts';

export default Vue.extend({
  mixins: [FormMixin],
  props: {
    filter: {
      type: Object as PropType<SunbedModelTypeFacetannerFilterRow>,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    filter: {
      article_number: {
        // required,
      },
      power: {
        required: requiredIf(function (this: any) {
          return this.isFilterPowerRequired();
        }),
      },
    },
  },

  methods: {
    isFilterPowerRequired(): boolean {
      return this.filter.article_number.length > 0;
    },

    openSearchFilterModal(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SEARCH_FILTER_MODAL_EVENT_OPEN, {
        title: 'Search filter glass',
        async onSubmit(entity) {
          vm.$set(vm.filter, 'article_number', entity?.article_number || undefined);

          await vm.$nextTick();

          (vm.$refs['input:amount'] as HTMLInputElement).focus();
        },
      } as SearchFilterModalOptions);
    },

    onRemove(): void {
      this.$emit('remove', this.filter);
    },
  },
});
