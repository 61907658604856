
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { Validation } from 'vuelidate';
import { RouterUtil, ValidatorUtil } from '@vedicium/vue-core';

import {
  ConfigurationViewTypesInformationForm,
  ConfigurationViewTypesRatedVoltagesForm,
  ConfigurationViewTypesUVConfigurationsForm,
  ConfigurationViewTypesColorsCompatibilityForm,
  ConfigurationViewTypesPackagesCompatibilityForm,
  ConfigurationViewTypesOptionsCompatibilityForm,
} from './components';
import {
  SunbedModelEntity,
  SunbedModelsStore,
  SunbedModelTypeDto,
  SunbedModelTypeEntity,
} from '../../../../../../services/sunbeds';
import { Core } from '../../../../../../services/core';
import { CONFIGURATION_VIEW_MODEL_KEY } from '../../../constants';
import { SUNBED_MODEL_TYPE_DUPLICATE } from './constants';

export default Vue.extend({
  name: 'ConfigurationViewTypesCreate',
  mixins: [Mixins.KTWizardV4],
  components: {
    ConfigurationViewTypesInformationForm,
    ConfigurationViewTypesRatedVoltagesForm,
    ConfigurationViewTypesUVConfigurationsForm,
    ConfigurationViewTypesColorsCompatibilityForm,
    ConfigurationViewTypesPackagesCompatibilityForm,
    ConfigurationViewTypesOptionsCompatibilityForm,
  },
  data() {
    return {
      isMounted: false,
      isLoading: false,
      isLoadingForm: false,

      entity: new SunbedModelTypeDto(),

      metadata: {
        model: undefined as SunbedModelEntity | undefined,
        wizard: {
          index: 0,
        },
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Configurations', route: '/configurations/overview' },
    ]);
  },
  async mounted(): Promise<void> {
    this.$set(this, 'isLoading', true);
    try {
      const model =
        Core.Memory.get(CONFIGURATION_VIEW_MODEL_KEY(this.$route.params.guid)) ||
        (await SunbedModelsStore.get(this.$route.params.guid));

      this.$set(this.metadata, 'model', model);
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();

    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Configurations', route: '/configurations/overview' },
      {
        title: this.metadata.model?.name as string,
        route: `/configurations/view/${this.metadata.model?._meta.guid}`,
      },
      { title: 'Add type' },
    ]);

    // Set duplicate
    if (this.$route.query.duplicate !== undefined) {
      const duplicate = Core.Memory.get<SunbedModelTypeEntity>(SUNBED_MODEL_TYPE_DUPLICATE);
      if (duplicate !== undefined) {
        this.$set(this, 'entity', this.createDtoFromEntity(duplicate));
      }

      RouterUtil.omitQueryFromRoute(this.$router, 'duplicate');
    }

    this.$set(this, 'isMounted', true);
    await this.$nextTick();
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    /**
     * When an UV configuration is updated, it expects a callback.
     * This is a placeholder for this callback.
     * This should not be removed, because else the modals will be stuck.
     */
    onUVConfigurationsUpdate(
      _uvConfigurations: SunbedModelTypeEntity['uv_configurations'],
      cb?: (err: Error | null) => void,
    ) {
      if (cb === undefined) {
        return;
      }

      cb(null);
    },

    createDtoFromEntity(entity: SunbedModelTypeEntity): SunbedModelTypeDto {
      const dto = new SunbedModelTypeDto();

      // Name should be filled in
      dto.name = undefined;

      // Copy properties from entity
      dto.special_preferences = entity.special_preferences;

      dto.production_time_in_days = entity.production_time_in_days;

      dto.rated_voltages = entity.rated_voltages;
      dto.uv_configurations = entity.uv_configurations;

      dto.parts = entity.parts;

      return dto;
    },

    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);

      let type: SunbedModelTypeEntity;
      try {
        await this.validate();

        const entity = JSON.parse(JSON.stringify(this.entity)) as SunbedModelTypeEntity;

        // Only add rated voltages with power & article number
        entity.rated_voltages = entity.rated_voltages.filter(
          (row) => row.article_number !== undefined,
          [],
        );

        const response = await Core.getAdapter().post<SunbedModelTypeEntity>(
          `/sunbeds/models/${this.metadata.model?._meta.guid}/types`,
          entity,
        );

        type = response.data;
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      // Redirect to the 'special preferences' tab when special mode is enabled.
      if (type.special_preferences.enabled === true) {
        this.$router.push(
          `/configurations/view/${this.metadata.model?._meta.guid}/types/${type._meta.guid}/special-preferences`,
        );
        return;
      }

      // Redirect to the type view
      this.$router.push(
        `/configurations/view/${this.metadata.model?._meta.guid}/types/${type._meta.guid}`,
      );
    },

    async onWizardStepChange(step: number, oldStep: number): Promise<void> {
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number): Promise<void> {
      const validationArray: Array<Validation> = [];

      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      if (step === undefined || step === 1) {
        // validationArray.push((this.$refs.ratedVoltagesForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray.filter((row) => row !== undefined, []));
    },
  },
});
