
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';

import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../../components/layout/portal/modals';
import { Core } from '../../../../../../services/core';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../services/sunbeds';
import { SUNBED_MODEL_TYPE_DUPLICATE } from './constants';

export default Vue.extend({
  name: 'configurationViewTypesOverview',
  mixins: [DatatableMixin],
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
  },
  data() {
    return {
      datatable: {
        url: `/sunbeds/models/${this.configuration._meta.guid}/types`,
        fields: [
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'rated_voltages',
            title: 'Rated voltages',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
            formatter: (value: SunbedModelTypeEntity['rated_voltages']) =>
              value.map((row) => row.rated_voltage, []).join(', '),
          },
          {
            name: 'production_time_in_days',
            title: 'Production time',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-125px',
            dataClass: 'w-125px',
          },
        ],
        sort: [{ field: 'name', direction: 'asc' }],
        searchFields: ['name', 'article_number'],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },

  methods: {
    onDuplicateType(entity: SunbedModelTypeEntity): void {
      Core.Memory.save(SUNBED_MODEL_TYPE_DUPLICATE, entity, { ttl: 60 });
      this.$router.push(
        `/configurations/view/${this.configuration._meta.guid}/types/create?duplicate`,
      );
    },
    removeType(entity: SunbedModelTypeEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove configuration',
        message: `Are you sure that you want to remove the sunbed type <strong>${entity.name}</strong>?<br />Please type the name of the sunbed type to confirm.`,
        verifyString: entity.name,
        buttonText: 'Confirm',
        async onSubmit() {
          await Core.getAdapter().delete(
            `/sunbeds/models/${vm.configuration._meta.guid}/types/${entity._meta.guid}`,
            { params: { persistent: true } },
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (vm.$refs.datatable as any).refresh();
        },
      });
    },
  },
});
