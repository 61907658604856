
import { FormMixin } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { required } from 'vuelidate/lib/validators';

import { SunbedModelTypeUVConfiguration } from '../../../../../../../../services/sunbeds';
import {
  SearchLightSourceModalOptions,
  SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN,
} from '../../../../../../parts';

export default Vue.extend({
  mixins: [FormMixin],
  props: {
    light_source: {
      type: Object as PropType<SunbedModelTypeUVConfiguration['light_sources'][number]>,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    light_source: {
      article_number: {
        required,
      },
      amount: {
        required,
      },
    },
  },

  methods: {
    openSearchLightSourceModal(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN, {
        title: 'Search light source',
        async onSubmit(entity) {
          vm.$set(vm.light_source, 'article_number', entity?.article_number || undefined);

          await vm.$nextTick();

          (vm.$refs['input:amount'] as HTMLInputElement).focus();
        },
      } as SearchLightSourceModalOptions);
    },

    onRemove(): void {
      this.$emit('remove', this.light_source);
    },
  },
});
