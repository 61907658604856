
import { DatatableConfig, DatatableInstance, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import Vue, { VueConstructor, PropType } from 'vue';
import { MutationsService } from '@vedicium/object-mutations';
import { OptionPartDto, OptionPartPowerConsumption } from '../../../../../services/parts';
import { Core } from '../../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../../constants';
import {
  getRatedVoltages,
  SunbedRatedVoltageOption,
  SunbedVoltage,
} from '../../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  mixins: [DatatableMixin, FormMixin],
  props: {
    document: {
      type: Object as PropType<OptionPartDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'rated_voltage',
            title: () => 'Rated voltage',
            dataClass: 'type:light type:power',
          },
          {
            name: 'power:230V',
            title: 'Power 230V',
            titleClass: 'w-250px',
            dataClass: 'w-250px type:power type:light',
          },
          {
            name: 'power:400V',
            title: 'Power 400V',
            titleClass: 'w-250px',
            dataClass: 'w-250px type:power type:light',
          },
        ],
      } as DatatableConfig,

      metadata: {
        voltages: ['230V', '400V'] as Array<SunbedVoltage>,
        rated_voltages: [] as Array<SunbedRatedVoltageOption>,
      },
    };
  },
  computed: {
    isRatedVoltageType(): boolean {
      return ['power', 'light'].includes(this.document.type as string);
    },

    datatableFields(): DatatableConfig['fields'] {
      return this.datatable.fields.filter((row) =>
        row.dataClass?.includes(`type:${this.document.type as string}`),
      );
    },
  },

  async mounted() {
    (this.$refs.datatable as DatatableInstance).setLoading(true);
    try {
      await this.applyRatedVoltages();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
      return;
    }

    await this.$nextTick();

    (this.$refs.datatable as DatatableInstance).setLoading(false);
  },

  methods: {
    getWattageOfVoltage(
      rated_voltage: OptionPartPowerConsumption,
      voltage: SunbedVoltage,
    ): number | undefined {
      return rated_voltage.voltages?.find((row) => row.voltage === voltage)?.wattage;
    },
    hasVoltage(
      rated_voltage: OptionPartPowerConsumption['rated_voltage'],
      voltage: SunbedVoltage,
    ): boolean {
      return (
        this.metadata.rated_voltages
          .find((row) => row.rated_voltage === rated_voltage)
          ?.voltages.includes(voltage) === true
      );
    },
    onPowerChange(
      rated_voltage: OptionPartPowerConsumption['rated_voltage'],
      voltage: SunbedVoltage,
      value: string,
    ): void {
      const powerValue = Number.isFinite(parseInt(value, 10)) ? parseInt(value, 10) : undefined;

      this.document.power_consumption = this.document.power_consumption.map((row) => {
        // Change rated voltage
        if (row.rated_voltage === rated_voltage) {
          return MutationsService.apply(row, [
            {
              action: 'array:objects:set_field',
              field: 'voltages',
              query: {
                filter: 'eq',
                field: 'voltage',
                value: voltage,
              },
              mutation: {
                field: 'wattage',
                value: powerValue,
              },
            },
          ]);
        }

        return row;
      }, []);
    },

    async applyRatedVoltages(): Promise<void> {
      const ratedVoltages = await getRatedVoltages();
      this.$set(this.metadata, 'rated_voltages', ratedVoltages);

      this.metadata.rated_voltages.forEach((voltage) => {
        // Don't add the rated voltage when it already exists
        if (
          this.document.power_consumption.some(
            (row) => row.rated_voltage === voltage.rated_voltage,
          ) === true
        ) {
          return;
        }

        this.document.power_consumption.push(
          new OptionPartPowerConsumption({
            rated_voltage: voltage.rated_voltage,
            voltages: voltage.voltages.map((row) => ({
              voltage: row,
              wattage: undefined,
            })),
          }),
        );
      });
    },
  },
});
