import { HttpRequestConfig } from '@vedicium/vue-core';
import { Core } from '../../core';
import { SunbedRatedVoltageOption } from '../interfaces';

export async function getRatedVoltages(
  options?: HttpRequestConfig,
): Promise<Array<SunbedRatedVoltageOption>> {
  const response = await Core.getAdapter().get<Array<SunbedRatedVoltageOption>>(
    '/sunbeds/rated_voltages',
    options,
  );

  return response.data;
}
