
import { OrganizationEntity } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { OrganizationInformationEntity } from '../../../../../services/organizations';
import { OrganizationViewPreferencesCard, OrganizationViewProductPreferencesCard } from './cards';

export default Vue.extend({
  name: 'organizationsViewPreferences',
  props: {
    organization: {
      type: Object as PropType<OrganizationEntity>,
    },
    information: {
      type: Object as PropType<OrganizationInformationEntity>,
    },
  },

  components: {
    PreferencesCard: OrganizationViewPreferencesCard,
    ProductPreferencesCard: OrganizationViewProductPreferencesCard,
  },
});
