
import Vue, { VueConstructor } from 'vue';
import { StateFormMixin } from '../mixins';
import { StateFormInstance } from '../interfaces';
import { Core } from '../../../../../../services/core';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../../services/sunbeds';
import { WeekCalendarUtils } from '../../../../../../components/week-calendar';
import { requiredIf } from 'vuelidate/lib/validators';
import { ValidatorUtil } from '@vedicium/vue-core';
import { OrganizationInformationEntity } from '../../../../../../services/organizations';
import { APP_ERROR_EVENT } from '../../../../../../constants';

export default (Vue as VueConstructor<Vue & StateFormInstance>).extend({
  name: 'readyForProductionStatusFormComponent',
  mixins: [StateFormMixin],
  data() {
    return {
      isLoading: false,

      metadata: {
        // This will only contain an organization information entity when UV type is 5.
        organizationInformation: undefined as OrganizationInformationEntity | undefined,

        form: {
          uvType5Check: undefined as boolean | undefined,
          doNotSendEmail: false,
        },
      },
    };
  },
  computed: {
    humanReadableState(): string {
      return (
        this.$tu(`sunbeds.orders.states.${SunbedOrderState.READY_FOR_PRODUCTION}`)?.toString() ||
        SunbedOrderState.READY_FOR_PRODUCTION
      );
    },
    shouldShowUVType5Checkmark(): boolean {
      return (
        this.order.configuration.uv_configuration.uv_type === '5' &&
        (this.metadata.organizationInformation === undefined ||
          this.metadata.organizationInformation.settings.prohibit_uv_type_5 === true)
      );
    },
  },
  validations: {
    metadata: {
      form: {
        uvType5Check: {
          required: requiredIf(function (this: any) {
            return this.shouldShowUVType5Checkmark === true;
          }),
          isTrue: function (this: any, value: boolean) {
            // When the UV Type 5 Checkmark should not be shown, don't check if the value is true.
            if (this.shouldShowUVType5Checkmark === false) {
              return true;
            }

            return value === true;
          },
        },
      },
    },
  },

  async mounted() {
    if (this.order.configuration.uv_configuration.uv_type === '5') {
      this.$set(this, 'isLoading', true);
      try {
        const response = await Core.getAdapter().get<OrganizationInformationEntity>(
          `/organizations/${this.order.organization.guid}/information`,
        );

        this.$set(this.metadata, 'organizationInformation', response.data);
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    }
  },

  methods: {
    getDesiredDelivery(): string {
      if (this.order.schedule.desired_delivery_week === 'asap') {
        return 'As soon as possible';
      }

      return WeekCalendarUtils.getHumanReadableISOWeek(this.order.schedule.desired_delivery_week);
    },
    getShippingWeek(): string | undefined {
      if (this.order.schedule.shipping_week === undefined) {
        return undefined;
      }

      return WeekCalendarUtils.getHumanReadableISOWeek(this.order.schedule.shipping_week);
    },

    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<SunbedOrderEntity> {
      this.$set(this, 'isLoading', true);
      try {
        // Validate form
        await this.validate();

        // Update state
        const response = await Core.getAdapter().put<SunbedOrderEntity>(
          `/sunbeds/orders/${this.order._meta.guid}/state`,
          {
            state: SunbedOrderState.READY_FOR_PRODUCTION,
            skip_email: this.metadata.form.doNotSendEmail,
          },
        );

        return response.data;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
