
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';

import { SunbedModelEntity, SunbedOrderEntity } from '../../../../services/sunbeds';

export default Vue.extend({
  name: 'sunbedModelImage',
  props: {
    model: {
      type: Object as PropType<SunbedModelEntity | SunbedOrderEntity['configuration']['model']>,
    },
    configurationId: {
      type: Array as PropType<Array<string> | undefined>,
    },
  },

  watch: {
    configurationId(): void {
      this.onImageRequest();
    },
  },

  data() {
    return {
      image: undefined,
    };
  },

  mounted() {
    this.onImageRequest();
  },

  methods: {
    async onImageRequest(): Promise<void> {
      try {
        const image = await this.getImage();
        this.$set(this, 'image', image);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      }
    },

    async getImage(): Promise<string> {
      const response = await Core.getAdapter().get(
        `/sunbeds/image/${this.model.configuration_id}`,
        {
          params: {
            configuration_id: (this.configurationId || []).join(','),
          },
        },
      );

      return response.data;
    },
  },
});
