import { Entity } from '@vedicium/vue-core';
import { SunbedModelEntity } from '../../sunbeds/interfaces/sunbed_model.entity';
import { ManufacturingSubline } from './manufacturing-subline.interface';

export enum ColorPartConfigurationID {
  LUXURA_VEGAZ_GOLD = 'LUXURA_VEGAZ_GOLD',
  LUXURA_VEGAZ_SILVER = 'LUXURA_VEGAZ_SILVER',
  LUXURA_VEGAZ_RED = 'LUXURA_VEGAZ_RED',
  LUXURA_VEGAZ_CRYSTAL_WHITE = 'LUXURA_VEGAZ_CRYSTAL_WHITE',
}

export class ColorPartDto {
  description?: string = undefined;
  article_number?: string = undefined;
  configuration_id?: ColorPartConfigurationID = undefined;
  display_colors: [string, string] = ['', ''];
  models: Array<SunbedModelEntity['_meta']['guid']> = [];

  manufacturing: {
    sublines: Array<ManufacturingSubline>;
  } = { sublines: [] };
}

export interface ColorPartEntity extends Entity, ColorPartDto {}
