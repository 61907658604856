
import Vue from 'vue';
import { APP_ERROR_EVENT } from '../../../constants';
import { Core } from '../../../services/core';
import { SunbedOrderEntity, SunbedOrdersStore } from '../../../services/sunbeds';
import { OrdersViewTopbar } from './components';
import { WeekCalendarModalComponent } from '../../../components/week-calendar';
import { UpdateOrderNumberModal, SetSunbedOrderStateModal } from './modals';

export default Vue.extend({
  name: 'ordersView',
  components: {
    OrdersViewTopbar,
    WeekCalendarModalComponent,
    UpdateOrderNumberModal,
    SetSunbedOrderStateModal,
  },
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        order: undefined as SunbedOrderEntity | undefined,
      },
    };
  },
  watch: {
    'metadata.order.sales_order_number': {
      handler(
        value: SunbedOrderEntity['sales_order_number'],
        oldValue: SunbedOrderEntity['sales_order_number'],
      ) {
        if (this.isMounted === false) {
          return;
        }

        if (value === undefined || value === oldValue) {
          return;
        }

        // Change breadcrumbs
        this.$metronic.breadcrumbs.setBreadcrumbs([
          ...this.$metronic.breadcrumbs.breadcrumbs.slice(0, -1),
          { title: `Order ${value || ''}` },
        ]);
      },
    },
  },

  beforeMount(): void {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Orders', route: '/orders/overview' }]);
  },
  async mounted(): Promise<void> {
    try {
      await this.onMount();
    } catch (e) {
      console.error(e);
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
    } finally {
      this.$set(this, 'isMounted', true);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: `Order ${this.metadata.order?.sales_order_number || ''}`,
    });
  },

  methods: {
    async onMount(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        const order = await SunbedOrdersStore.get(this.$route.params.guid);

        this.$set(this.metadata, 'order', order);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
