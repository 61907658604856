var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header flex-wrap"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[(_vm.$permissions.hasPermission('organizations.create'))?_c('router-link',{staticClass:"btn btn-primary w-175px font-weight-bolder",attrs:{"to":"/organizations/create"}},[_c('span',{staticClass:"svg-icon align-middle"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1),_c('span',[_vm._v("Add organization")])]):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-7"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-lg"},[_c('div',{staticClass:"input-icon"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Zoeken..."},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.datatable.search.apply(null, arguments)},_vm.searchDebounced]}}),_c('span',[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'search']}})],1)])])])])]),_c('datatable',{ref:"datatable",attrs:{"url":_vm.datatable.url,"fields":_vm.datatable.fields,"search-fields":_vm.datatable.searchFields,"on-pagination-data":_vm.onPaginationData,"filters":_vm.datatable.filters,"sort-order":_vm.datatable.sort,"http-fetch":_vm.datatable.fetch,"loading-template":_vm.$t('datatable.loading'),"no-data-template":_vm.$t('datatable.no_data')},scopedSlots:_vm._u([{key:"name",fn:function(props){return [_c('router-link',{staticClass:"text-brand",attrs:{"to":`/organizations/view/${props.rowData._meta.guid}`},domProps:{"textContent":_vm._s(props.rowData.name)}})]}},{key:"actions",fn:function(props){return _c('div',{},[(
                _vm.isLinkedToOrganization(props.rowData) ||
                _vm.$permissions.hasPermission('organizations.delete') ||
                _vm.$permissions.hasPermission('organizations.access')
              )?_c('b-dropdown',{ref:"actions-dropdown",staticClass:"dropdown-inline mr-2",attrs:{"variant":"clean btn-sm btn-icon","right":"","lazy":"","no-caret":"","boundary":"window","menu-class":"dropdown-menu-md","disabled":props.rowData._meta.guid === _vm.$organizations.organization._meta.guid},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"svg-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cog']}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('ul',{staticClass:"navi flex-column navi-hover py-2"},[_c('li',{staticClass:"navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2",domProps:{"textContent":_vm._s(_vm.$t('datatable.actions.choose_action'))}}),_c('li',{staticClass:"navi-separator my-0"}),(
                      props.rowData._meta.guid !== _vm.$organizations.organization._meta.guid &&
                      _vm.isLinkedToOrganization(props.rowData)
                    )?[_c('li',{staticClass:"navi-separator my-0"}),_c('li',{staticClass:"navi-item"},[_c('a',{staticClass:"navi-link cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.switchToOrganization(props.rowData)}}},[_c('span',{staticClass:"navi-icon"},[_c('i',[_c('font-awesome-icon',{staticClass:"w-15px h-15px",attrs:{"icon":['fas', 'sign-in-alt']}})],1)]),_c('span',{staticClass:"navi-text"},[_vm._v("Switch to organization")])])])]:_vm._e(),(
                      props.rowData._meta.guid !== _vm.$organizations.organization._meta.guid &&
                      _vm.isLinkedToOrganization(props.rowData) === false &&
                      _vm.$permissions.hasPermission('organizations.access')
                    )?[_c('li',{staticClass:"navi-separator my-0"}),_c('li',{staticClass:"navi-item"},[_c('a',{staticClass:"navi-link cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.gainAccessToOrganization(props.rowData)}}},[_c('span',{staticClass:"navi-icon"},[_c('i',[_c('font-awesome-icon',{staticClass:"w-15px h-15px",attrs:{"icon":['fas', 'lock-alt']}})],1)]),_c('span',{staticClass:"navi-text"},[_vm._v("Gain access")])])])]:_vm._e(),(
                      props.rowData._meta.guid !== _vm.$organizations.organization._meta.guid &&
                      _vm.$permissions.hasPermission('organizations.delete')
                    )?[_c('li',{staticClass:"navi-separator my-0"}),_c('li',{staticClass:"navi-item"},[_c('a',{staticClass:"navi-link cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.removeOrganization(props.rowData)}}},[_c('span',{staticClass:"navi-icon"},[_c('i',[_c('font-awesome-icon',{staticClass:"w-15px h-15px",attrs:{"icon":['fas', 'trash']}})],1)]),_c('span',{staticClass:"navi-text"},[_vm._v("Delete organization")])])])]:_vm._e()],2)]},proxy:true}],null,true)}):_vm._e()],1)}}])}),_c('div',{staticClass:"datatable datatable-default"},[_c('div',{staticClass:"datatable-pager datatable-paging-loaded"},[_c('datatable-pagination',{ref:"pagination",on:{"vuetable-pagination:change-page":_vm.onChangePage}}),_c('datatable-pagination-info',{ref:"pagination.info",attrs:{"info-template":_vm.$t('datatable.info')},on:{"vuetable-paginationinfo:change-rowcount":_vm.onChangeRowCount}})],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Overview "),_c('span',{staticClass:"text-muted pt-2 font-size-sm d-block"},[_vm._v(" Organizations associated to this organization. ")])])])
}]

export { render, staticRenderFns }