import { render, staticRenderFns } from "./organization-preferences.form.vue?vue&type=template&id=0e8ef8a2&"
import script from "./organization-preferences.form.vue?vue&type=script&lang=ts&"
export * from "./organization-preferences.form.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports