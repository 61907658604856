import { Entity } from '@vedicium/vue-core';
import {
  SunbedModelConfigurationID,
  SunbedModelProductionChecklistItem,
  SunbedModelShippingBox,
} from './sunbed_models.interface';

export interface SunbedModelEntity extends Entity {
  name: string;
  configuration_id: SunbedModelConfigurationID;
  unit_type: 'horizontal' | 'vertical';
  acrylic: Array<{
    location: 'bench' | 'canopy';
    thickness: number;
  }>;

  manufacturing: {
    shipping_boxes: Array<SunbedModelShippingBox>;
    checklist_items: Array<SunbedModelProductionChecklistItem>;
  };
}

export class SunbedModelDto {
  name?: SunbedModelEntity['name'] = undefined;
  configuration_id?: SunbedModelEntity['configuration_id'] = undefined;
  unit_type?: SunbedModelEntity['unit_type'] = undefined;
  acrylic: SunbedModelEntity['acrylic'] = [];
  manufacturing: SunbedModelEntity['manufacturing'] = {
    shipping_boxes: [],
    checklist_items: [],
  };
}
