var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row border-bottom pb-3"},[_c('div',{staticClass:"col-12"},[_vm._v(" Are you sure you want to change the order status to "),_c('strong',[_vm._v(_vm._s(_vm.humanReadableState))]),_vm._v("?"),_c('br'),_vm._v(" Please complete and review the fields below, carry out order cost changes, then click "),_c('strong',[_vm._v("Confirm")]),_vm._v(". ")])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Production order number")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.metadata.form.production_order_number.$model),expression:"$v.metadata.form.production_order_number.$model",modifiers:{"trim":true}}],ref:"input:production_order_number",class:[
              'form-control',
              { 'is-invalid': _vm.$v.metadata.form.production_order_number.$error },
            ],attrs:{"type":"text","disabled":_vm.isLoading},domProps:{"value":(_vm.$v.metadata.form.production_order_number.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.metadata.form.production_order_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Order calculation (price per unit)")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'euro-sign']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.metadata.form.order_calculation.$model),expression:"$v.metadata.form.order_calculation.$model",modifiers:{"trim":true}}],class:['form-control', { 'is-invalid': _vm.$v.metadata.form.order_calculation.$error }],attrs:{"type":"number","disabled":_vm.isLoading},domProps:{"value":(_vm.$v.metadata.form.order_calculation.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.metadata.form.order_calculation, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Material purchasing (price per unit)")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'euro-sign']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.metadata.form.material_purchasing.$model),expression:"$v.metadata.form.material_purchasing.$model",modifiers:{"trim":true}}],class:[
              'form-control',
              { 'is-invalid': _vm.$v.metadata.form.material_purchasing.$error },
            ],attrs:{"type":"number","disabled":_vm.isLoading},domProps:{"value":(_vm.$v.metadata.form.material_purchasing.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.metadata.form.material_purchasing, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Production week")]),_c('div',{staticClass:"input-group"},[_c('week-calendar-component',{staticClass:"form-control w-auto",model:{value:(_vm.metadata.form.production_week),callback:function ($$v) {_vm.$set(_vm.metadata.form, "production_week", $$v)},expression:"metadata.form.production_week"}})],1)])]),_c('div',{staticClass:"border-top col-12"},[_c('div',{staticClass:"form-group mt-4 mb-0"},[_c('p',[_vm._v("Additional order cost (price per unit)")]),_c('p',[_vm._v(" Deduct "),_c('strong',[_vm._v(" "+_vm._s(_vm.additionalCost < 0 ? '-' : '')+" "+_vm._s(_vm.FormatUtils.currency(_vm.additionalCost || 0))+" ")]),_vm._v(" from production order "),_c('strong',[_vm._v(_vm._s(_vm.metadata.form.production_order_number))]),_vm._v(" (code 35600) ")]),_c('p',[_vm._v(" Add "),_c('strong',[_vm._v(" "+_vm._s(_vm.additionalCost < 0 ? '-' : '')+" "+_vm._s(_vm.FormatUtils.currency(_vm.additionalCost || 0))+" ")]),_vm._v(" to sales order "),_c('strong',[_vm._v(_vm._s(_vm.order.sales_order_number))]),_vm._v(" (code 35601) ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }