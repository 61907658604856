
import { MutationsService } from '@vedicium/object-mutations';
import Vue, { PropType } from 'vue';
import { Core } from '../../../../../../../services/core';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../services/sunbeds';
import { ConfigurationViewTypesUVConfigurationsForm } from '../components';

export default Vue.extend({
  name: 'configurationViewTypesViewUVConfigurations',
  components: {
    ConfigurationViewTypesUVConfigurationsForm,
  },
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },

  methods: {
    onAddMarking(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.uvConfigurationsForm as any).onAddMarking();
    },
    async onUVConfigurationsUpdate(
      _uvConfigurations: SunbedModelTypeEntity['uv_configurations'],
      cb?: (err: Error | null) => void,
    ): Promise<void> {
      try {
        await this.onSubmit();
        cb?.(null);
      } catch (e) {
        cb?.(e as Error);
      }
    },

    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        const mutations = MutationsService.getMutationsByComparison(this.type, this.metadata.type, [
          'uv_configurations',
        ]);

        if (mutations.length > 0) {
          const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
            `/sunbeds/models/${this.configuration._meta.guid}/types/${this.type._meta.guid}`,
            mutations,
          );
          this.$emit('update:type', response.data);
          this.$set(this.metadata, 'type', JSON.parse(JSON.stringify(response.data)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
