
import Vue, { PropType } from 'vue';
import { MutationsService } from '@vedicium/object-mutations';
import { SunbedModelEntity, SunbedModelsStore } from '../../../../../services/sunbeds';
import { SunbedModelProductionForm } from '../../components';
import { Core } from '../../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../../constants';

export default Vue.extend({
  name: 'configurationViewProduction',
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        configuration: JSON.parse(JSON.stringify(this.configuration)) as SunbedModelEntity,
      },
    };
  },
  components: {
    SunbedModelProductionForm,
  },

  methods: {
    onAddChecklistItemClick(): void {
      (this.$refs.productionForm as any).onAddChecklistItem();
    },
    async onChecklistItemUpdate(): Promise<void> {
      try {
        await this.onSubmit();
      } catch (e) {
        this.$set(this.metadata, 'configuration', JSON.parse(JSON.stringify(this.configuration)));
      }
    },

    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        const mutations = MutationsService.getMutationsByComparison(
          this.configuration,
          this.metadata.configuration,
          ['manufacturing.checklist_items'],
        );

        if (mutations.length > 0) {
          const entity = await SunbedModelsStore.update(this.configuration._meta.guid, mutations);
          this.$emit('update:configuration', entity);
          this.$set(this.metadata, 'configuration', JSON.parse(JSON.stringify(entity)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
