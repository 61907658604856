import { OrganizationEntity } from '@vedicium/vue-core';
import { UserEntity } from '../interfaces';

export function getOrganizationLink(
  user: UserEntity,
  organization_guid: OrganizationEntity['_meta']['guid'],
  options?: { include_invited?: boolean },
): UserEntity['organizations'][number] | undefined {
  return (user.organizations || []).find(
    (link) =>
      link.organization === organization_guid &&
      (link.invite_pending !== true || options?.include_invited === true),
  );
}
