
import Vue from 'vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { Mixins } from '@vedicium/metronic-vue';
import { OrganizationInviteDto, ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import {
  MODIFY_PERMISSION_GROUP_MODAL_EVENT_OPEN,
  MODIFY_PERMISSION_GROUP_MODAL_EVENT_CLOSE,
} from './constants';
import { ModifyPermissionGroupModalOptions } from './interfaces';

import permissionGroupForm from '../components/permission-group.form.vue';

export default Vue.extend({
  mixins: [Mixins.Modal],
  name: 'modifyPermissionGroupModal',
  components: {
    permissionGroupForm,
  },
  data() {
    return {
      options: undefined as ModifyPermissionGroupModalOptions | undefined,
      isLoadingForm: null,

      user: undefined as ModifyPermissionGroupModalOptions['user'] | undefined,
      organization: undefined as ModifyPermissionGroupModalOptions['organization'] | undefined,
      invite: new OrganizationInviteDto(),

      form: {
        value: undefined as string | undefined,
      },
    };
  },
  validations: {
    form: {
      value: {
        // eslint-disable-next-line func-names
        required: requiredIf(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (this as any).options && (this as any).options.required === true;
        }),
      },
    },
  },

  mounted() {
    // Listeners
    this.$eventhub.on(MODIFY_PERMISSION_GROUP_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.on(MODIFY_PERMISSION_GROUP_MODAL_EVENT_CLOSE, this.close);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off(MODIFY_PERMISSION_GROUP_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.off(MODIFY_PERMISSION_GROUP_MODAL_EVENT_CLOSE, this.close);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([(this.$refs.permissionGroupForm as Vue).$v]);
    },
    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.options.onSubmit(this.invite.permission_id);
        }
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open(options: ModifyPermissionGroupModalOptions) {
      if (options?.user === undefined) {
        throw new Error('User undefined');
      }

      if (options?.organization === undefined) {
        throw new Error('Organization undefined');
      }

      // Reset options
      this.$set(this, 'options', options || {});

      // Set user & company
      this.$set(this, 'user', this.options?.user);
      this.$set(this, 'organization', this.options?.organization);

      // Create invite DTO, which acts as the document
      const organizationLink = options.user.organizations.find(
        (link) => link.organization === options.organization._meta.guid,
      );

      const invite = new OrganizationInviteDto();
      invite.permission_id = organizationLink?.permission_id;
      this.$set(this, 'invite', invite);

      // Reset form
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close() {
      (this.$refs.modal as BModal).hide();
    },
  },
});
