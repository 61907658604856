import {
  SunbedModelEntity,
  SunbedModelTypeUVConfiguration,
  SunbedOrderEntity,
} from '../interfaces';

export class SunbedUtils {
  static getLightSourceLocationsByModel(
    model: SunbedModelEntity | SunbedOrderEntity['configuration']['model'],
  ): Array<SunbedModelTypeUVConfiguration['light_sources'][number]['location']> {
    if (model.unit_type === 'vertical') {
      return ['bench'];
    }

    return ['bench', 'canopy'];
  }

  /**
   * Get compatible UV types based on a given UV type.
   *
   * @param uv_type UV-type
   * @returns Array of UV-types
   */
  static getCompatibleUVTypes(uv_type: string): Array<string> {
    switch (uv_type) {
      // When UV-type 2 is selected, UV-type 3 is also compatible.
      case '2': {
        return ['3', '2'];
      }

      /**
       * When UV-type 5 is selected, UV-type 3 and UV-type 2 are also compatible.
       */
      case '5': {
        return ['3', '2', '5'];
      }

      // When there are no exceptions, just return the current UV-type.
      default: {
        return [uv_type];
      }
    }
  }
}
