
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';
import {
  SunbedModelConfigurationID,
  SunbedModelDto,
  SUNBED_UNIT_TYPES,
} from '../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<SunbedModelDto>,
    },
  },
  data() {
    return {
      metadata: {
        configuration_ids: Object.values(SunbedModelConfigurationID),
      },
    };
  },
  computed: {
    unitTypes(): Array<string> {
      if (this.action === 'VIEW' || this.action === 'UPDATE') {
        return [this.document?.unit_type as string];
      }

      return SUNBED_UNIT_TYPES;
    },
  },
  validations: {
    document: {
      name: {
        required,
      },
      configuration_id: {
        required,
      },
      unit_type: {
        required,
      },
      acrylic: {
        $each: {
          location: {
            required,
          },
          thickness: {
            required,
          },
        },
      },
    },
  },

  async mounted() {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:name'] as HTMLInputElement).focus();
    }
  },

  methods: {
    onUnitTypeChange(value: SunbedModelDto['unit_type']): void {
      switch (value) {
        case 'horizontal': {
          this.$set(this.document, 'acrylic', [
            { location: 'bench', thickness: 0 },
            { location: 'canopy', thickness: 0 },
          ]);
          break;
        }

        case 'vertical': {
          this.$set(this.document, 'acrylic', [{ location: 'bench', thickness: 0 }]);
          break;
        }

        default: {
          break;
        }
      }
    },
  },
});
