import { CoreModule, Auth0AuthenticationModule } from '@vedicium/vue-core';
import { API_URL, API_WEBSOCKET_PATH, API_WEBSOCKET_URL } from '../constants';
import { auth0 } from './auth0';

export const Core = new CoreModule({
  adapter: 'http',
  authentication: {
    module: new Auth0AuthenticationModule(auth0),
  },
  http: {
    baseURL: API_URL,
  },
  websocket: {
    enabled: true,

    uri: API_WEBSOCKET_URL,
    path: API_WEBSOCKET_PATH,
  },
  permissions: {
    scope: 'organizations',
  },
});
