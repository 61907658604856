
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';
import { ValidatorUtil } from '@vedicium/vue-core';
import { VueSelectInstance } from 'vue-select';

import { Core } from '../../../../../services/core';

import { SearchPricelistModalOptions } from './search-pricelist-modal.interface';
import {
  SEARCH_PRICELIST_MODAL_OPEN,
  SEARCH_PRICELIST_MODAL_CLOSE,
} from './search-pricelist-modal.constants';
import { PricelistEntity } from '../../../../../services/pricelists';
import { PricelistsVueSelect } from '../../components';
import { APP_ERROR_EVENT } from '../../../../../constants';

export default Vue.extend({
  name: 'searchPricelistModal',
  mixins: [Mixins.Modal],
  components: {
    PricelistsVueSelect,
  },
  data() {
    return {
      options: {} as SearchPricelistModalOptions,

      isLoading: false,

      metadata: {
        pricelist: undefined as PricelistEntity | undefined,
      },
    };
  },
  validations: {
    metadata: {
      pricelist: {
        required,
      },
    },
  },

  mounted() {
    Core.Eventhub.on(SEARCH_PRICELIST_MODAL_OPEN, this.open);
    Core.Eventhub.on(SEARCH_PRICELIST_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    Core.Eventhub.off(SEARCH_PRICELIST_MODAL_OPEN, this.open);
    Core.Eventhub.off(SEARCH_PRICELIST_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.metadata.pricelist as PricelistEntity);
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    async open(options?: SearchPricelistModalOptions): Promise<void> {
      this.$set(this, 'options', options);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },

    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    onShown() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((this.$refs.input as any).input as VueSelectInstance).searchEl.focus();
    },
  },
});
