import { LightSourcePartEntity, LightSourcePartDto } from '../interfaces';

export function isLowPressureLightSource(
  light_source: LightSourcePartEntity | LightSourcePartDto,
): boolean {
  const lowPressureTypes: Array<LightSourcePartEntity['type']> = [
    'low_pressure',
    'low_pressure_facial',
  ];

  return lowPressureTypes.includes(light_source.type);
}
