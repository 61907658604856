
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { Validation } from 'vuelidate';
import { OrganizationEntity, ValidatorUtil } from '@vedicium/vue-core';
import { OrganizationEntityDto, OrganizationsStore } from '../../../services/organizations';

import {
  OrganizationSpecificationsForm,
  OrganizationPreferencesForm,
  OrganizationTypeForm,
} from './components';

export default Vue.extend({
  mixins: [Mixins.KTWizardV4],
  components: {
    OrganizationSpecificationsForm,
    OrganizationPreferencesForm,
    OrganizationTypeForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      wizardIndex: 0,

      organization: new OrganizationEntityDto(),
    };
  },
  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Organizations', route: '/organizations/overview' },
      { title: 'Create' },
    ]);

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async submitForm() {
      this.$set(this, 'isLoadingForm', false);

      let organization: OrganizationEntity;
      try {
        await this.validate();

        organization = await OrganizationsStore.create(
          JSON.parse(JSON.stringify(this.organization)),
          {
            persistent: true,
          },
        );
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push(`/organizations/view/${organization._meta.guid}`);
    },

    async onWizardStepChange(step: number, oldStep: number): Promise<void> {
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number): Promise<void> {
      const validationArray: Array<Validation> = [];

      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.specificationsForm as Vue).$v);
      }

      if (step === undefined || step === 1) {
        validationArray.push((this.$refs.preferencesForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray);
    },
  },
});
