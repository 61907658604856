
import { ConfirmationModalOptions, DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import Vue from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../components/layout/portal/modals';
import {
  SunbedModelDto,
  SunbedModelShippingBox,
  SunbedModelShippingBoxDto,
} from '../../../../services/sunbeds';
import {
  ConfigurationShippingBoxModal,
  ConfigurationShippingBoxModalOptions,
  CONFIGURATIONS_SHIPPING_BOX_MODAL_OPEN,
} from '../modals/shipping-box';

export default Vue.extend({
  name: 'sunbedModelShippingBoxesForm',
  mixins: [FormMixin, DatatableMixin],
  props: {
    document: {
      type: Object as PropType<SunbedModelDto>,
    },
  },
  components: {
    ConfigurationShippingBoxModal,
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'content',
            title: 'Box content',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
        ],
      } as DatatableConfig,
    };
  },

  methods: {
    onAddShippingBox(): void {
      const vm = this;
      this.$eventhub.emit(CONFIGURATIONS_SHIPPING_BOX_MODAL_OPEN, {
        title: 'Add shipping box',
        entity: new SunbedModelShippingBoxDto(),
        onSubmit: (entity: SunbedModelShippingBox) => {
          vm.document.manufacturing.shipping_boxes.push(entity);

          vm.$emit('update', vm.document.manufacturing.shipping_boxes);
        },
      } as ConfigurationShippingBoxModalOptions);
    },
    onModifyShippingBox(box: SunbedModelShippingBox): void {
      const vm = this;
      this.$eventhub.emit(CONFIGURATIONS_SHIPPING_BOX_MODAL_OPEN, {
        title: 'Edit shipping box',
        entity: JSON.parse(JSON.stringify(box)),
        onSubmit: (entity: SunbedModelShippingBox) => {
          const idx = vm.document.manufacturing.shipping_boxes.indexOf(box);
          vm.$set(vm.document.manufacturing.shipping_boxes, idx, entity);

          vm.$emit('update', vm.document.manufacturing.shipping_boxes);
        },
      } as ConfigurationShippingBoxModalOptions);
    },
    onRemoveShippingBox(box: SunbedModelShippingBox): void {
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove shipping box',
        message: `Are you sure that you want to delete the shipping box with content <strong>${box.content}</strong?`,
        buttonText: 'Confirm',
        onSubmit: () => {
          vm.$set(
            vm.document.manufacturing,
            'shipping_boxes',
            vm.document.manufacturing.shipping_boxes.filter((row) => row !== box, []),
          );

          vm.$emit('update', vm.document.manufacturing.shipping_boxes);
        },
      } as ConfirmationModalOptions);
    },
  },
});
