
import { FormMixin, OrganizationEntity } from '@vedicium/vue-core';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { ConfirmationModalOptions, InformationModalOptions } from '@vedicium/metronic-vue';
import world from '@/assets/js/world.json';

import { OrganizationVueSelect } from '../../organizations';
import { PARTS_UV_TYPES } from '../../../../services/parts';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';
import {
  ConfiguratorService,
  getRatedVoltages,
  SunbedConfigurationSession,
  SUNBED_LANGUAGES,
  SUNBED_SMARTVOICE_LANGUAGES,
} from '../../../../services/sunbeds';
import {
  CONFIRMATION_MODAL_EVENT_OPEN,
  INFORMATION_MODAL_EVENT_OPEN,
} from '../../../../components/layout/portal/modals';

export default Vue.extend({
  name: 'ordersDetailsForm',
  mixins: [FormMixin],
  components: {
    OrganizationVueSelect,
  },
  data() {
    return {
      isLoading: false,

      session: ConfiguratorService.session as SunbedConfigurationSession,

      metadata: {
        countries: world.map(
          (row) => ({
            code: row.alpha3.toUpperCase(),
            name: row.en,
          }),
          [],
        ),

        languages: SUNBED_LANGUAGES,
        smartvoice_languages: SUNBED_SMARTVOICE_LANGUAGES,
        rated_voltages: [],
        uv_types: PARTS_UV_TYPES,
      },
    };
  },
  validations: {
    session: {
      organization: {
        required,
      },
      organization_information: {
        required,
      },
      uv_type: {
        required,
      },
      rated_voltage: {
        required,
      },
      language: {
        required,
      },
      smartvoice_language: {
        required,
      },
    },
  },

  async mounted() {
    try {
      await this.onMount();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
    }
  },

  methods: {
    async onMount(): Promise<void> {
      const [rated_voltages] = await Promise.all([getRatedVoltages()]);

      this.$set(this.metadata, 'rated_voltages', rated_voltages);
    },

    async onOrganization(organization: OrganizationEntity | undefined): Promise<void> {
      // Get the information of the selected organization
      this.$set(this, 'isLoading', true);
      try {
        await ConfiguratorService.setOrganization(organization);
        await this.$nextTick();
        this.onUVTypeChange(this.session.uv_type);
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },

    onRatedVoltageChange(value: string | undefined): void {
      // Trigger a warning for data loss when the UV-type is changed while a UV configuration is already set.
      if (
        this.session.configuration.type !== undefined &&
        this.session.configuration.rated_voltage?.rated_voltage !== value
      ) {
        const vm = this;
        const oldValue = this.session.configuration.rated_voltage?.rated_voltage;
        this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
          title: 'This change resets your configuration',
          variant: 'warning',
          message: `
              <div class="alert alert-custom alert-light-warning mb-0">
                <div class="alert-text">
                  <span>Changing the UV-type or rated voltage will cause your configuration to restore to its default state. Any choices you made will be lost. Click <strong>confirm</strong> to reset your configuration.</span>
                </div>
              </div>
          `,
          onSubmit() {
            // Reset the configuration
            vm.$set(vm.session, 'type', undefined);
            ConfiguratorService.resetConfiguration();
          },
          onCancel() {
            // Set rated voltage to old one
            vm.$set(vm.session, 'rated_voltage', oldValue);
          },
          cancelButtonText: 'Cancel',
        } as ConfirmationModalOptions);
      }
    },
    onUVTypeChange(value: string | undefined): void {
      // Trigger a warning for data loss when the UV-type is changed while a UV configuration is already set.
      if (
        this.session.configuration.type !== undefined &&
        this.session.configuration.uv_configuration?.uv_type !== value
      ) {
        const vm = this;
        const oldValue = this.session.configuration.uv_configuration?.uv_type;
        this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
          title: 'This change resets your configuration',
          variant: 'warning',
          message: `
              <div class="alert alert-custom alert-light-warning mb-0">
                <div class="alert-text">
                  <span>Changing the UV-type or rated voltage will cause your configuration to restore to its default state. Any choices you made will be lost. Click <strong>confirm</strong> to reset your configuration.</span>
                </div>
              </div>
          `,
          onSubmit() {
            // Reset the configuration
            vm.$set(vm.session, 'type', undefined);
            ConfiguratorService.resetConfiguration();
          },
          onCancel() {
            // Set UV type to old one
            vm.$set(vm.session, 'uv_type', oldValue);
          },
          cancelButtonText: 'Cancel',
        } as ConfirmationModalOptions);
      }

      if (this.session.organization_information !== undefined) {
        // When UV-Type 5 is selected and is prohibited for organization,
        // a modal should be shown.
        if (
          value === '5' &&
          this.session.organization_information.settings.prohibit_uv_type_5 === true
        ) {
          this.$metronic.eventhub.emit(INFORMATION_MODAL_EVENT_OPEN, {
            title: 'Let op!',
            classes: 'd-block',
            message: `
              <div class="alert alert-custom alert-light-info mb-0">
                <div class="alert-text">
                  <span>Je maakt een order aan met een UV-type 5 apparaat voor een klant die dit vanwege zijn vestigingsland niet mag bestellen. Zorg dat de klant een uitvoerverklaring naar buiten de EU ondertekend en upload dit document in VBS, of kies een ander UV-type voor deze order.</span>
                </div>
              </div>
            `,
            closeButtonText: 'Close',
          } as InformationModalOptions);
        }
      }
    },
  },
});
