
import Vue from 'vue';
import { ConfirmationModal, InformationModal } from '@vedicium/metronic-vue';
import { OrganizationEntity, ORGANIZATIONS_CHANGE_EVENT, RouterUtil } from '@vedicium/vue-core';
import PortalLayoutMenuAside from './components/menu.aside.vue';
import PortalLayoutTopbar from './components/topbar.vue';
import PortalLayoutMenuTopbar from './components/menu.topbar.vue';
import PortalLayoutFooter from './components/footer.vue';

import {
  SwitchOrganizationModal,
  SwitchOrganizationModalOptions,
  SWITCH_ORGANIZATION_MODAL_EVENT_OPEN,
  CONFIRMATION_MODAL_EVENT,
  INFORMATION_MODAL_EVENT,
  SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE,
} from './modals';

import LayoutConfig from './config';
import { Core } from '../../../services/core';

export default Vue.extend({
  name: 'PortalLayout',
  data() {
    return {
      pageTitle: this.$metronic.breadcrumbs.pageTitle(),
      breadcrumbs: this.$metronic.breadcrumbs.breadcrumbs,

      isMounted: false,

      CONFIRMATION_MODAL_EVENT,
      INFORMATION_MODAL_EVENT,
    };
  },
  components: {
    PortalLayoutMenuAside,
    PortalLayoutTopbar,
    PortalLayoutMenuTopbar,
    PortalLayoutFooter,

    SwitchOrganizationModal,
    ConfirmationModal,
    InformationModal,
  },

  beforeMount() {
    this.$metronic.eventhub.on('layout:breadcrumbs', this.onBreadcrumbs.bind(this));
    this.$eventhub.on(ORGANIZATIONS_CHANGE_EVENT, this.onOrganizationChange.bind(this));

    this.$metronic.config.layout.setConfig(LayoutConfig);
    this.$metronic.config.classes.init();

    Core.Websocket.getSocket().on('connect', this.onWebsocketReconnect.bind(this));
  },
  async mounted() {
    if (Core.Authentication.isEntityOfType('users')) {
      // Open company modal, if no company is set
      this.onOrganizationChange(await this.$organizations.get());

      // Accept invite
      if (this.$route.query?.invite?.toString().length > 0) {
        try {
          const invite = await Core.Organizations.getOrganizationInvite(
            this.$route.query.invite.toString(),
          );

          // Accept invite
          await Core.Organizations.respondToOrganizationInvite(invite._meta.guid, 'accept', {
            params: {
              persistent: true,
            },
          });

          // Set organization to accepted invite
          await Core.Organizations.set(invite.organization.guid);
          RouterUtil.omitQueryFromRoute(this.$router, 'invite');

          this.$bvToast.toast(`You've gained access to ${invite.organization.name}`, {
            title: 'Invite accepted',
            variant: 'success',
            solid: true,
          });
        } catch (e) {
          console.error(e);
          Core.Eventhub.emit('error', e);
        }
      }
    }
  },
  beforeDestroy() {
    this.$metronic.eventhub.off('layout:breadcrumbs', this.onBreadcrumbs.bind(this));
    this.$eventhub.off(ORGANIZATIONS_CHANGE_EVENT, this.onOrganizationChange.bind(this));

    Core.Websocket.getSocket().off('connect');
  },

  methods: {
    isLayoutKeyEnabled(key: string): boolean {
      return !!this.$metronic.config.layout.get(key);
    },

    onBreadcrumbs(crumbs: unknown): void {
      this.$set(this, 'breadcrumbs', crumbs);
      this.$set(this, 'pageTitle', this.$metronic.breadcrumbs.pageTitle());
    },

    async onWebsocketReconnect(): Promise<void> {
      // Subscribe to events
      Core.Websocket.put('/entity/websocket/subscribe');
    },

    async onOrganizationChange(organization: OrganizationEntity | undefined): Promise<void> {
      if (organization) {
        Core.Websocket.put('/entity/websocket/subscribe');
        this.$eventhub.emit(SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE);
      }

      // If no organization is found, but the user is still logged in
      // Show the organization modal
      if (!organization && this.$authentication.isAuthenticatedSync() === true) {
        this.$eventhub.emit(SWITCH_ORGANIZATION_MODAL_EVENT_OPEN, {
          required: true,
          auto_select: this.$organizations.organization === undefined,
          redirect: undefined,
        } as SwitchOrganizationModalOptions);
      }
    },
  },
});
