
import { ConfirmationModalOptions, DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DateTime } from 'luxon';
import Vue, { PropType } from 'vue';
import { BrowserPrint, ZebraPrinterState } from '@vedicium/zebra-browser-print';
import { BDropdown } from 'bootstrap-vue';

import { Core } from '../../../../../services/core';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../services/sunbeds';
import { APP_ERROR_EVENT } from '../../../../../constants';
import {
  SunbedOrderGenerateSerialNumberModal,
  SunbedOrderGenerateSerialNumberModalOptions,
  SunbedOrderPrintIdentificationLabelModal,
  SunbedOrderPrintIdentificationLabelOptions,
  SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN,
  SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN,
} from '../../modals';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../components/layout/portal/modals';

export default Vue.extend({
  name: 'ordersViewIdentification',
  mixins: [DatatableMixin],
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  components: {
    GenerateSerialNumberModal: SunbedOrderGenerateSerialNumberModal,
    PrintIdentificationLabelModal: SunbedOrderPrintIdentificationLabelModal,
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],

        fields: [
          {
            name: 'serial',
            title: 'Serial number',
          },
          {
            name: 'payload.myluxura_module_serial',
            title: 'MyLuxura Module',
            titleClass: 'w-200px',
          },
          {
            name: 'generation_date',
            title: 'Generated on',
            titleClass: 'w-200px',
            formatter: (value: number) =>
              DateTime.fromMillis(value, { zone: 'UTC' }).toFormat('dd-MM-yyyy'),
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-200px',
          },
        ],
      } as DatatableConfig,

      metadata: {
        isPrintingAvailable: false,
      },
    };
  },
  computed: {
    datatableData(): Array<{
      serial: string;
      generation_date: number;
      payload?: { myluxura_guid?: string; myluxura_module_serial?: string };
    }> {
      return (
        this.order.production?.identification?.serial_numbers.map(
          (row) => ({
            serial: row.serial_number,
            generation_date: this.order.production?.identification?.generation_date as number,
            payload: row.payload,
          }),
          [],
        ) || []
      );
    },
  },

  async mounted() {
    if (this.$permissions.hasPermission('sunbed_orders.print_identification_sticker')) {
      this.$set(this.metadata, 'isPrintingAvailable', await this.isBrowserPrintAvailable());
    }
  },

  methods: {
    canGenerateSerialNumbers(): boolean {
      return this.order.state === SunbedOrderState.IN_PRODUCTION;
    },

    onClickGenerateSerialNumbers(): void {
      if (this.canGenerateSerialNumbers() === false) {
        return;
      }

      const vm = this;
      this.$eventhub.emit(SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN, {
        title: 'Generate serial number(s)',
        serial_numbers: this.order.production?.identification?.serial_numbers.map(
          (row) => row.serial_number,
          [],
        ),
        amount: this.order.amount,

        async onSubmit(serial_numbers) {
          console.log('new serials', serial_numbers);
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/identification/serial-numbers`,
            { serial_numbers },
          );

          vm.$emit('update:order', response.data);
        },
        onError(e: Error) {
          Core.Eventhub.emit(APP_ERROR_EVENT, e);
        },
      } as SunbedOrderGenerateSerialNumberModalOptions);
    },

    async isBrowserPrintAvailable(): Promise<boolean> {
      return BrowserPrint.isAvailable();
    },
    async onPrintZPLFile(serial: string): Promise<void> {
      // Determine whether browser print is available
      if (this.metadata.isPrintingAvailable === false) {
        return;
      }

      // Open modal
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN, {
        title: `Print identification label`,
        serial: serial,

        async onSubmit(dto) {
          try {
            // Get default printer
            const printer = await BrowserPrint.getDefaultDevice('printer');

            // Get identification ZPL
            const response = await Core.Http.post(
              `/sunbeds/orders/${vm.order._meta.guid}/identification/zpl`,
              {
                serial_number: serial,
              },
              {
                params: {
                  count: dto.count,
                },
                options: {
                  interceptors: {
                    download_attachment: false,
                  },
                },
              },
            );

            // Write to printer, if ready
            const status = await printer.getStatus();
            if (status.state === ZebraPrinterState.READY) {
              // Print identification sticker.
              await printer.send(response.data);

              // Show toast.
              vm.$bvToast.toast(`Identification sticker printed for '${serial}'.`, {
                title: 'Printing successful',
                variant: 'success',
                solid: true,
              });
            } else {
              vm.$bvToast.toast(`Printer is not ready (state: '${status.state}').`, {
                title: 'Printing failed',
                variant: 'danger',
                solid: true,
              });
            }
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            console.error(e);
          }
        },
      } as SunbedOrderPrintIdentificationLabelOptions);
    },

    async onDownloadShippingLabel(serial: string): Promise<void> {
      (this.$refs['dropdown:actions'] as BDropdown).hide();

      await Core.Http.post(
        `/sunbeds/orders/${this.order._meta.guid}/document/shipping-label`,
        {
          serial: serial,
        },
        {
          responseType: 'blob',
        },
      );
    },

    async onSynchronizeWithMyLuxura(serial: string): Promise<void> {
      (this.$refs['dropdown:actions'] as BDropdown).hide();

      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Synchronize sunbed with Myluxura',
        message: `Synchronizing this sunbed with MyLuxura will set serial number, model, type and configuration of this sunbed to the values presented on LuCAS. LuCAS will retrieve the linked MLM from MyLuxura. This action cannot be reversed. Are you sure you want to perform this action?`,
        buttonText: 'Confirm',
        variant: 'warning',
        async onSubmit() {
          try {
            await Core.Http.put(`/sunbeds/orders/${vm.order._meta.guid}/identification/myluxura`, {
              serial,
            });

            // Show toast.
            vm.$bvToast.toast(`Sunbed '${serial}' synchronized with MyLuxura.`, {
              title: 'Printing successful',
              variant: 'success',
              solid: true,
            });
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }
        },
      } as ConfirmationModalOptions);
    },

    async onDownloadZPLFile(serial: string): Promise<void> {
      (this.$refs['dropdown:actions'] as BDropdown).hide();

      await Core.Http.post(
        `/sunbeds/orders/${this.order._meta.guid}/identification/zpl`,
        {
          serial_number: serial,
        },
        {
          params: {
            count: 1,
          },
        },
      );
    },
  },
});
