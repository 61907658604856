
import Vue, { PropType } from 'vue';
import { SunbedOrderEntity } from '../../../../../services/sunbeds';
import { FormatUtils } from '@/utils';

export default Vue.extend({
  name: 'ordersViewFinancial',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },

  data() {
    return {
      FormatUtils,
    };
  },

  computed: {
    discount(): number | undefined {
      if (
        this.order.pricing.custom_price === undefined ||
        this.order.pricing.custom_price === null
      ) {
        return undefined;
      }

      return this.order.pricing.purchase_price - this.order.pricing.custom_price;
    },
    additional_cost(): number | undefined {
      if (
        this.order.pricing?.order_calculation === undefined ||
        this.order.pricing?.material_purchasing === undefined
      ) {
        return undefined;
      }

      const additional_cost =
        (this.order.pricing?.order_calculation || 0) -
        (this.order.pricing?.material_purchasing || 0);

      if (additional_cost === undefined || additional_cost === null || isNaN(additional_cost)) {
        return undefined;
      }

      return additional_cost;
    },
  },
});
