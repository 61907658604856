
import Vue from 'vue';
import { Core } from '../../../../../../services/core';
import {
  SunbedModelEntity,
  SunbedModelsStore,
  SunbedModelTypeEntity,
} from '../../../../../../services/sunbeds';
import { CONFIGURATION_VIEW_MODEL_KEY } from '../../../constants';

export default Vue.extend({
  name: 'configurationsView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        model: undefined as SunbedModelEntity | undefined,
        type: undefined as SunbedModelTypeEntity | undefined,
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Configurations', route: '/configurations/overview' },
    ]);
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      const [model, typeResponse] = await Promise.all([
        Core.Memory.get(CONFIGURATION_VIEW_MODEL_KEY(this.$route.params.configuration)) ||
          SunbedModelsStore.get(this.$route.params.configuration),

        Core.getAdapter().get<SunbedModelTypeEntity>(
          `/sunbeds/models/${this.$route.params.configuration}/types/${this.$route.params.guid}`,
        ),
      ]);

      this.$set(this.metadata, 'model', model);
      this.$set(this.metadata, 'type', typeResponse.data);
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
      this.$set(this, 'isLoading', false);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: this.metadata.model?.name as string,
      route: `/configurations/view/${this.metadata.model?._meta.guid}`,
    });

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: 'Types',
      route: `/configurations/view/${this.metadata.model?._meta.guid}/types`,
    });

    this.$metronic.breadcrumbs.appendBreadcrumb({ title: this.metadata.type?.name as string });
  },
});
