
import { MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../constants';
import { Core } from '../../../../../services/core';

import { ColorPartEntity, ColorPartsStore } from '../../../../../services/parts';
import { PartsColorsCompatibilityForm } from '../components';

export default Vue.extend({
  name: 'partsColorsViewInformation',
  props: {
    color: {
      type: Object as PropType<ColorPartEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        color: JSON.parse(JSON.stringify(this.color)) as ColorPartEntity,
      },
    };
  },
  components: {
    PartsColorsCompatibilityForm,
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(
          this.color,
          this.metadata.color,
          ['models'],
        );

        if (mutations.length > 0) {
          const entity = await ColorPartsStore.update(this.color._meta.guid, mutations);

          this.$emit('update:color', entity);
          this.$set(this.metadata, 'color', JSON.parse(JSON.stringify(entity)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
