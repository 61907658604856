
import Vue, { PropType } from 'vue';
import { OrganizationEntity } from '@vedicium/vue-core';

// import Form from '@/mixins/form';
// import { errorComponentMixin } from '@vedicium/core-vue';
// import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
// import errorTranslationAlert from '@/components/errors/translation.alert.vue';
import { Core } from '../../../../services/core';
import { OrganizationEntityDto, OrganizationsStore } from '../../../../services/organizations';

export default Vue.extend({
  mixins: [],
  components: {
    // errorTranslationAlert,
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled === true;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object as PropType<OrganizationEntityDto>,
      default: null,
    },
  },
  data() {
    return {
      isMounted: false,
      isErrorActive: false,

      organizationTypes: [] as Array<OrganizationEntity['type']>,
    };
  },

  async mounted() {
    try {
      const response = await Core.getAdapter().get<Array<OrganizationEntity['type']>>(
        `${OrganizationsStore.url}/types`,
      );

      this.$set(this, 'organizationTypes', response.data);
    } catch (e) {
      // this.$errors.handle(e, {
      //   ui_element: false,
      // });

      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }

    await this.$nextTick();

    if (!this.document.type && this.organizationTypes) {
      // eslint-disable-next-line prefer-destructuring
      this.document.type = this.organizationTypes[0];
    }
  },
});
