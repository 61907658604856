
import { MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../constants';
import { Core } from '../../../../../services/core';

import { PackagePartEntity, PackagePartsStore } from '../../../../../services/parts';
import { PartsPackagesOptionsForm } from '../components';

export default Vue.extend({
  name: 'partsPackagesViewOptions',
  props: {
    package: {
      type: Object as PropType<PackagePartEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        package: JSON.parse(JSON.stringify(this.package)) as PackagePartEntity,
      },
    };
  },
  components: {
    PartsPackagesOptionsForm,
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([(this.$refs.optionsForm as Vue).$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(
          this.package,
          this.metadata.package,
          ['options'],
        );

        if (mutations.length > 0) {
          const entity = await PackagePartsStore.update(this.package._meta.guid, mutations);

          this.$emit('update:package', entity);
          this.$set(this.metadata, 'package', JSON.parse(JSON.stringify(entity)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
