
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { Validation } from 'vuelidate';
import { FilterPartDto, FilterPartsStore } from '../../../../services/parts';

import { PartsFiltersInformationForm, PartsFiltersSublinesForm } from './components';

export default Vue.extend({
  name: 'partsFiltersCreate',
  mixins: [Mixins.KTWizardV4],
  components: {
    PartsFiltersInformationForm,
    PartsFiltersSublinesForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      filter: new FilterPartDto(),

      metadata: {
        wizard: {
          index: 0,
        },
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Filter glasses', route: '/parts/filters' },
      { title: 'Create filter glass' },
    ]);
  },
  async mounted() {
    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    onAddSubline(): void {
      (this.$refs.sublinesForm as any).onAddSubline();
    },

    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();
        await FilterPartsStore.create(JSON.parse(JSON.stringify(this.filter)), {
          persistent: true,
        });
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push('/parts/filters');
    },

    async onWizardStepChange(step: number, oldStep: number) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number) {
      const validationArray: Array<Validation> = [];

      // Add validators based by step
      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray.filter((row) => row !== undefined, []));
    },
  },
});
