
import Vue, { PropType, VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { OrganizationEntity, FormMixin } from '@vedicium/vue-core';
import { Core } from '../../../../services/core';
import { UserInviteDto } from '../../../../services/users';
import { OrganizationsStore } from '../../../../services/organizations';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<UserInviteDto>,
      default: null,
    },
    organization: {
      type: Object as PropType<OrganizationEntity>,
      default: null,
    },
  },
  data() {
    return {
      permissionGroups: [],
    };
  },
  validations: {
    document: {
      permission_id: {
        required,
      },
    },
  },

  async mounted() {
    // this.$errors.clear({ component: this });

    try {
      const response = await Core.getAdapter().get(`${OrganizationsStore.url}/permissions`);
      this.$set(this, 'permissionGroups', response.data);
    } catch (e) {
      console.error(e);
      // this.$errors.handle(e, { component: this, ui_element: false });
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },
});
