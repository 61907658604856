
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';

import { Core } from '../../../../services/core';
import {
  ConfiguratorService,
  SunbedConfigurationConflictError,
  SunbedConfigurationConflictProperty,
  SunbedConfigurationConflictType,
} from '../../../../services/sunbeds';
import {
  CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN,
  CONFIGURATOR_CONFLICT_ERROR_MODAL_CLOSE,
} from './constants';
import { ConfiguratorConflictErrorModalOptions } from './interfaces';

export default Vue.extend({
  name: 'configuratorConflictErrorModal',
  mixins: [Mixins.Modal],
  data() {
    return {
      options: undefined as ConfiguratorConflictErrorModalOptions | undefined,

      isLoading: false,

      metadata: {
        queue: [] as Array<{ action: 'add' | 'remove' } & SunbedConfigurationConflictProperty>,
      },
    };
  },
  computed: {
    title(): string {
      if (this.options?.title) {
        return this.options.title;
      }

      return this.options?.error.property.entity.description || 'Combination error';
    },
  },

  mounted() {
    Core.Eventhub.on(CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN, this.open);
    Core.Eventhub.on(CONFIGURATOR_CONFLICT_ERROR_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    Core.Eventhub.off(CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN, this.open);
    Core.Eventhub.off(CONFIGURATOR_CONFLICT_ERROR_MODAL_CLOSE, this.close);
  },

  methods: {
    onSubmit(): void {
      this.$set(this, 'isLoading', true);
      try {
        // Solve the conflict error
        if (this.options) {
          // Add current entity to the queue
          this.metadata.queue.unshift({
            action: this.options.error.action,
            ...this.options.error.property,
          });

          // Handle all conflicts
          this.options.error.conflicts.forEach(
            (conflict) =>
              ConfiguratorService.resolveConflict(
                this.options?.error.type as SunbedConfigurationConflictType,
                conflict,
              ),
            [],
          );
        }

        // Handle queue
        [...this.metadata.queue].forEach((item, idx) => {
          // Add entity, based on entity type
          if (item.action === 'add') {
            if (item.type === 'option') {
              ConfiguratorService.addOption(item.entity);
            }

            if (item.type === 'package') {
              ConfiguratorService.addPackage(item.entity);
            }
          }

          // Remove entity, based on entity type
          if (item.action === 'remove') {
            if (item.type === 'option') {
              ConfiguratorService.removeOption(item.entity);
            }

            if (item.type === 'package') {
              ConfiguratorService.removePackage(item.entity);
            }
          }

          // Remove item from queue
          this.metadata.queue.splice(idx, 1);
        });
      } catch (e) {
        // When it's not a ConflictError, handle it.
        if (ConfiguratorService.isConflictError(e) === false) {
          console.error(e);
          Core.Eventhub.emit(APP_ERROR_EVENT, e);
          return;
        }

        // Cast typing
        const conflictError = e as SunbedConfigurationConflictError;

        // If it's another ConflictError, handle that.
        this.$set(this.options as ConfiguratorConflictErrorModalOptions, 'error', conflictError);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: ConfiguratorConflictErrorModalOptions) {
      if (!options || !options.error) {
        throw new Error('Missing ConflictError');
      }

      this.$set(this, 'options', options);
      this.$set(this.metadata, 'queue', []);

      (this.$refs.modal as BModal).show();
    },
    close() {
      (this.$refs.modal as BModal).hide();
    },
  },
});
