
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { OrganizationEntity } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import { LogoutOptions } from '@auth0/auth0-spa-js';

import {
  SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE,
  SWITCH_ORGANIZATION_MODAL_EVENT_OPEN,
} from './constants';
import { SwitchOrganizationModalOptions } from './interfaces';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';

export default Vue.extend({
  mixins: [Mixins.Modal],
  name: 'switchOrganizationModal',
  data() {
    return {
      options: undefined as undefined | SwitchOrganizationModalOptions,
      isLoading: false,

      organizations: [] as Array<OrganizationEntity>,
      currentCompany: null,
    };
  },

  mounted() {
    // Listeners
    this.$eventhub.on(SWITCH_ORGANIZATION_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.on(SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE, this.close);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off(SWITCH_ORGANIZATION_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.off(SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE, this.close);
  },

  methods: {
    async getOrganizations(options?: {
      auto_select?: boolean;
    }): Promise<Array<OrganizationEntity>> {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'organizations', []);

      try {
        const organizations = await Core.Organizations.getOrganizations();
        this.$set(this, 'organizations', organizations);
      } catch (e) {
        console.error(e);
        this.$set(this, 'isLoading', false);
        return this.organizations;
      }

      // If only one company is found, select that one
      if (options?.auto_select === true && this.organizations?.length === 1) {
        await this.setOrganization(this.organizations[0]);
      }

      this.$set(this, 'isLoading', false);
      return this.organizations;
    },

    async setOrganization(organization: OrganizationEntity) {
      if (!organization) {
        return;
      }

      await Core.Organizations.set(organization._meta.guid);

      const redirectPath = this.options?.redirect;
      if (redirectPath && this.$route.path !== redirectPath) {
        this.$router.push(redirectPath);
      }

      this.close();
    },

    async logout() {
      try {
        await Core.Authentication.logout<LogoutOptions>({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      }

      this.close();
    },

    open(options?: SwitchOrganizationModalOptions) {
      // Reset options
      this.$set(this, 'options', options);
      this.$set(this, 'organizations', []);

      // Get organizations
      this.getOrganizations({
        auto_select: this.options?.required === true && this.options?.auto_select !== false,
      });

      (this.$refs.modal as BModal).show();
    },
    close() {
      (this.$refs.modal as BModal).hide();
    },
  },
});
