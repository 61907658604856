export enum SunbedModelTypeUVConfigurationMode {
  /**
   * UV configuration is available for sunbed model type.
   */
  AVAILABLE = 'available',

  /**
   * UV configuration is available for sunbed model type and selected by default.
   * The UV configuration can be charged for.
   */
  SELECTED_BY_DEFAULT = 'selected_by_default',

  /**
   * UV configuration is available for sunbed model type and selected by default.
   * However, this can not be changed, as there can be only one included for an UV type.
   */
  INCLUDED_BY_DEFAULT = 'included_by_default',
}

export enum SunbedModelTypeOptionMode {
  /**
   * Option is not available for sunbed model type.
   */
  NOT_AVAILABLE = 'not_available',

  /**
   * Option is available for sunbed model type, but not selected.
   * The option can be charged for.
   */
  AVAILABLE = 'available',

  /**
   * Option is available for sunbed model type, but only via a package.
   * This means it can not be selected on its own.
   * The option can not be charged for.
   */
  PACKAGE_ONLY = 'package_only',

  /**
   * Option is available for sunbed model type and selected by default.
   * It can not be turned off, only via an option that can not be combined with this option.
   * The option can be charged for.
   */
  SELECTED_BY_DEFAULT = 'selected_by_default',

  /**
   * Option is available for sunbed model type and is selected by default.
   * However, it can not be turned off and can not be charged for.
   */
  INCLUDED_BY_DEFAULT = 'included_by_default',
}

export enum SunbedModelTypeColorMode {
  /**
   * Color is not available for sunbed model type
   */
  NOT_AVAILABLE = 'not_available',

  /**
   * Color is available for sunbed model type, but not selected.
   * This color can be charged for.
   */
  AVAILABLE = 'available',

  /**
   * Color is available for the sunbed model type and is selected by default.
   * This option can be charged for.
   */
  SELECTED_BY_DEFAULT = 'selected_by_default',

  /**
   * Color is available for sunbed model type and is selected by default.
   * However, it can not be turned off and can not be charged for.
   * When this is selected, no other color can be available.
   */
  INCLUDED_BY_DEFAULT = 'included_by_default',
}

export enum SunbedModelTypePackageMode {
  /**
   * Package is not available for sunbed model type
   */
  NOT_AVAILABLE = 'not_available',

  /**
   * Package is available for sunbed model type, but not selected.
   * This package can be charged for.
   */
  AVAILABLE = 'available',

  /**
   * Package is available for sunbed model type and is selected by default.
   * However, it can not be turned off and can not be charged for.
   */
  INCLUDED_BY_DEFAULT = 'included_by_default',
}

export enum SunbedModelTypeConfigurationID {
  // Luxura Jewel
  LUXURA_JEWEL_EQ45 = 'LUXURA_JEWEL_EQ45',
  LUXURA_JEWEL_EQ55 = 'LUXURA_JEWEL_EQ55',

  // Luxura Vegaz 9200
  LUXURA_VEGAZ_9200_BALANCE = 'LUXURA_VEGAZ_9200_BALANCE',
  LUXURA_VEGAZ_9200_INTELLIGENT = 'LUXURA_VEGAZ_9200_INTELLIGENT',
  LUXURA_VEGAZ_9200_HIGHBRID = 'LUXURA_VEGAZ_9200_HIGHBRID',

  // Luxura Vegaz 8200
  LUXURA_VEGAZ_8200_BALANCE = 'LUXURA_VEGAZ_8200_BALANCE',
  LUXURA_VEGAZ_8200_INTELLIGENT = 'LUXURA_VEGAZ_8200_INTELLIGENT',
  LUXURA_VEGAZ_8200_HIGHBRID = 'LUXURA_VEGAZ_8200_HIGHBRID',

  // Luxura X7
  LUXURA_X7_38_SLI = 'LUXURA_X7_38_SLI',
  LUXURA_X7_38_SLI_INTENSIVE = 'LUXURA_X7_38_SLI_INTENSIVE',
  LUXURA_X7_38_SLI_HIGH_INTENSIVE = 'LUXURA_X7_38_SLI_HIGH_INTENSIVE',
  LUXURA_X7_38_SLI_BALANCE = 'LUXURA_X7_38_SLI_BALANCE',
  LUXURA_X7_38_SLI_INTELLIGENT = 'LUXURA_X7_38_SLI_INTELLIGENT',
  LUXURA_X7_38_HIGHBRID = 'LUXURA_X7_38_HIGHBRID',
  LUXURA_X7_42_SLI = 'LUXURA_X7_42_SLI',
  LUXURA_X7_42_SLI_INTENSIVE = 'LUXURA_X7_42_SLI_INTENSIVE',
  LUXURA_X7_42_SLI_HIGH_INTENSIVE = 'LUXURA_X7_42_SLI_HIGH_INTENSIVE',
  LUXURA_X7_42_SLI_BALANCE = 'LUXURA_X7_42_SLI_BALANCE',
  LUXURA_X7_42_SLI_INTELLIGENT = 'LUXURA_X7_42_SLI_INTELLIGENT',
  LUXURA_X7_42_HIGHBRID = 'LUXURA_X7_42_SLI_HIGHBRID',

  // Luxura X5
  LUXURA_X5_34_SLI = 'LUXURA_X5_34_SLI',
  LUXURA_X5_34_SLI_INTENSIVE = 'LUXURA_X5_34_SLI_INTENSIVE',
  LUXURA_X5_34_SLI_HIGH_INTENSIVE = 'LUXURA_X5_34_SLI_HIGH_INTENSIVE',
  LUXURA_X5_34_SLI_BALANCE = 'LUXURA_X5_34_SLI_BALANCE',
  LUXURA_X5_38_SLI_HIGH_INTENSIVE = 'LUXURA_X5_38_SLI_HIGH_INTENSIVE',

  // Luxura X3
  LUXURA_X3_32_SLI = 'LUXURA_X3_32_SLI',
  LUXURA_X3_32_SLI_INTENSIVE = 'LUXURA_X3_32_SLI_INTENSIVE',
  LUXURA_X3_COLLAGEN_SUN = 'LUXURA_X3_COLLAGEN_SUN',

  // Luxura V6
  LUXURA_V6_42_XL = 'LUXURA_V6_42_XL',
  LUXURA_V6_44_XL_BALANCE = 'LUXURA_V6_44_XL_BALANCE',
  LUXURA_V6_44_XL_BALANCE_ULTRA = 'LUXURA_V6_44_XL_BALANCE_ULTRA',
  LUXURA_V6_48_XL_BALANCE = 'LUXURA_V6_48_XL_BALANCE',
  LUXURA_V6_48_XL_BALANCE_ULTRA = 'LUXURA_V6_48_XL_BALANCE_ULTRA',

  // Luxura V8
  LUXURA_V8_48_XL_BALANCE = 'LUXURA_V8_48_XL_BALANCE',
  LUXURA_V8_48_XL_BALANCE_ULTRA = 'LUXURA_V8_48_XL_BALANCE_ULTRA',
  LUXURA_V8_48_XL_INTELLIGENT = 'LUXURA_V8_48_XL_INTELLIGENT',
  LUXURA_V8_48_XL_HIGHBRID = 'LUXURA_V8_48_XL_HIGHBRID',

  // Seecret
  SEECRET_C700 = 'SEECRET_C700',
}
