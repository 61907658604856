
import { FormMixin, OrganizationEntity } from '@vedicium/vue-core';
import Vue, { PropType, VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';

import {
  SunbedModelConfigurationID,
  SunbedModelEntity,
  SunbedModelTypeConfigurationID,
  SunbedModelTypeDto,
} from '../../../../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'configurationViewTypesInformationForm',
  mixins: [FormMixin],
  props: {
    model: {
      type: Object as PropType<SunbedModelEntity>,
    },
    document: {
      type: Object as PropType<SunbedModelTypeDto>,
    },
  },
  data() {
    return {
      metadata: {
        client: undefined as OrganizationEntity | undefined,
        configuration_ids: [
          {
            group: 'Luxura Jewel',
            model: SunbedModelConfigurationID.LUXURA_JEWEL,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_JEWEL_EQ45,
              SunbedModelTypeConfigurationID.LUXURA_JEWEL_EQ55,
            ],
          },
          {
            group: 'Luxura Vegaz 9200',
            model: SunbedModelConfigurationID.LUXURA_VEGAZ_9200,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_INTELLIGENT,
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_9200_HIGHBRID,
            ],
          },
          {
            group: 'Luxura Vegaz 8200',
            model: SunbedModelConfigurationID.LUXURA_VEGAZ_8200,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_INTELLIGENT,
              SunbedModelTypeConfigurationID.LUXURA_VEGAZ_8200_HIGHBRID,
            ],
          },
          {
            group: 'Luxura X7',
            model: SunbedModelConfigurationID.LUXURA_X7,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI,
              SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_HIGH_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_X7_38_SLI_INTELLIGENT,
              SunbedModelTypeConfigurationID.LUXURA_X7_38_HIGHBRID,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_HIGH_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_SLI_INTELLIGENT,
              SunbedModelTypeConfigurationID.LUXURA_X7_42_HIGHBRID,
            ],
          },
          {
            group: 'Luxura X5',
            model: SunbedModelConfigurationID.LUXURA_X5,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI,
              SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_HIGH_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X5_34_SLI_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_X5_38_SLI_HIGH_INTENSIVE,
            ],
          },
          {
            group: 'Luxura X3',
            model: SunbedModelConfigurationID.LUXURA_X3,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_X3_32_SLI,
              SunbedModelTypeConfigurationID.LUXURA_X3_32_SLI_INTENSIVE,
              SunbedModelTypeConfigurationID.LUXURA_X3_COLLAGEN_SUN,
            ],
          },
          {
            group: 'Luxura V6',
            model: SunbedModelConfigurationID.LUXURA_V6,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_V6_42_XL,
              SunbedModelTypeConfigurationID.LUXURA_V6_44_XL_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_V6_44_XL_BALANCE_ULTRA,
              SunbedModelTypeConfigurationID.LUXURA_V6_48_XL_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_V6_48_XL_BALANCE_ULTRA,
            ],
          },
          {
            group: 'Luxura V8',
            model: SunbedModelConfigurationID.LUXURA_V8,
            values: [
              SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_BALANCE,
              SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_BALANCE_ULTRA,
              SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_INTELLIGENT,
              SunbedModelTypeConfigurationID.LUXURA_V8_48_XL_HIGHBRID,
            ],
          },
          {
            group: 'Seecret',
            model: SunbedModelConfigurationID.SEECRET,
            values: [SunbedModelTypeConfigurationID.SEECRET_C700],
          },
        ],
      },
    };
  },
  computed: {
    modelConfigurationIDs(): Array<{ group: string; model: string; values: Array<string> }> {
      return this.metadata.configuration_ids.filter(
        (row) => row.model === this.model.configuration_id,
        [],
      );
    },
  },
  validations: {
    document: {
      name: {
        required,
      },
      production_time_in_days: {
        required,
      },
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      // eslint-disable-next-line no-unused-expressions
      (this.$refs['input:description'] as HTMLInputElement)?.focus();
    }
  },
});
