import { Entity } from '@vedicium/vue-core';
import { SunbedVoltage } from '../../sunbeds/interfaces/rated_voltages.interface';
import { SunbedModelEntity } from '../../sunbeds/interfaces/sunbed_model.entity';
import { ManufacturingSubline } from './manufacturing-subline.interface';

export class OptionPartPowerConsumption {
  rated_voltage?: string = undefined;
  voltages?: Array<{
    voltage: SunbedVoltage;
    wattage: number | undefined;
  }> = [];

  constructor(marking?: OptionPartPowerConsumption) {
    this.rated_voltage = marking?.rated_voltage || this.rated_voltage;
    this.voltages = marking?.voltages || this.voltages;
  }
}

export enum OptionPartConfigurationID {
  // Luxura Jewel
  LUXURA_JEWEL_UVLED_SHOULDER_TANNER = 'LUXURA_JEWEL_UVLED_SHOULDER_TANNER',
  LUXURA_JEWEL_SOUNDAROUND_PLUS = 'LUXURA_JEWEL_SOUNDAROUND_PLUS',
  LUXURA_JEWEL_CLIMATE_CONTROL = 'LUXURA_JEWEL_CLIMATE_CONTROL',
  LUXURA_JEWEL_SENS = 'LUXURA_JEWEL_SENS',
  LUXURA_JEWEL_COMPACT_AIR_EXHAUST = 'LUXURA_JEWEL_COMPACT_AIR_EXHAUST',
  LUXURA_JEWEL_AIR_EXHAUST_TOWER = 'LUXURA_JEWEL_AIR_EXHAUST_TOWER',
  LUXURA_JEWEL_AIR_EXHAUST_TOWER_SYSTEM_WITH_WARS = 'LUXURA_JEWEL_AIR_EXHAUST_TOWER_SYSTEM_WITH_WARS',

  // Luxura Vegaz
  LUXURA_VEGAZ_UVLED_TANNING_BOOSTER = 'LUXURA_VEGAZ_UVLED_TANNING_BOOSTER',
  LUXURA_VEGAZ_EXTENSION = 'LUXURA_VEGAZ_EXTENSION',
  LUXURA_VEGAZ_COMPACT_AIR_EXHAUST = 'LUXURA_VEGAZ_COMPACT_AIR_EXHAUST',
  LUXURA_VEGAZ_AIR_EXHAUST_TOWER = 'LUXURA_VEGAZ_AIR_EXHAUST_TOWER',
  LUXURA_VEGAZ_AIR_EXHAUST_WARS = 'LUXURA_VEGAZ_AIR_EXHAUST_WARS',
  LUXURA_VEGAZ_QSENS_XSENS_III = 'LUXURA_VEGAZ_QSENS_XSENS_III',
  LUXURA_VEGAZ_CLIMATE_CONTROL = 'LUXURA_VEGAZ_CLIMATE_CONTROL',
  LUXURA_VEGAZ_LUXURY_FRONT_PANEL = 'LUXURA_VEGAZ_LUXURY_FRONT_PANEL',
  LUXURA_VEGAZ_SOUND_PLUS = 'LUXURA_VEGAZ_SOUND_PLUS',
  LUXURA_VEGAZ_BASIC_AUDIO = 'LUXURA_VEGAZ_BASIC_AUDIO',

  // Luxura X-line
  LUXURA_X_AMBIENT_FLOWLIGHT_EYELINER = 'LUXURA_X_AMBIENT_FLOWLIGHT_EYELINER',
  LUXURA_X_INOX_DECOSHIELD_BRANDSHIELD = 'LUXURA_X_INOX_DECOSHIELD_BRANDSHIELD',
  LUXURA_X_SOUNDAROUND_PLUS = 'LUXURA_X_SOUNDAROUND_PLUS',
  LUXURA_X_QSENS = 'LUXURA_X_QSENS',
  LUXURA_X_AMBIENT_FLOWLIGHT_CONNECT_LINE = 'LUXURA_X_AMBIENT_FLOWLIGHT_CONNECT_LINE',
  LUXURA_X_LUXURY_FRONT_PANEL = 'LUXURA_X_LUXURY_FRONT_PANEL',
  LUXURA_X_AMBIENT_FLOWLIGHT_CENTRE_LINE = 'LUXURA_X_AMBIENT_FLOWLIGHT_CENTRE_LINE',

  // Luxura V-line
  LUXURA_V_TWIN_COLOUR_GOLD = 'LUXURA_V_TWIN_COLOUR_GOLD',
  LUXURA_V_QSENS = 'LUXURA_V_QSENS',
  LUXURA_V_SOUNDAROUND_PLUS = 'LUXURA_V_SOUNDAROUND_PLUS',

  // Other
  MYLUXURA = 'MYLUXURA',
}

export class OptionPartLightsource {
  article_number: string | null = null;
  amount: number | null = null;
  filter_article_number: string | null = null;
  filter_power: number | null = null;
}

export class OptionPartDto {
  description?: string = undefined;
  article_number?: string = undefined;

  type?: 'standard' | 'power' | 'light' = 'standard';
  internal_only: boolean = false;

  configuration_id?: OptionPartConfigurationID | undefined;

  /**
   * The order sequence, which is used to order the option on the order form and production form.
   */
  order_sequence: number | undefined;

  power_consumption: Array<OptionPartPowerConsumption> = [];
  light_source?: OptionPartLightsource = new OptionPartLightsource();
  acrylic_thickness?: number = undefined;
  models: Array<SunbedModelEntity['_meta']['guid']> = [];

  manufacturing: {
    sublines: Array<ManufacturingSubline>;
  } = { sublines: [] };
}

export interface OptionPartEntity extends Entity, OptionPartDto {}
