var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4 form-group"},[_c('label',[_vm._v("Country "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'globe']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.preferences.country.$model),expression:"$v.document.information.preferences.country.$model",modifiers:{"trim":true}}],class:[
          'form-control',
          { 'is-invalid': _vm.$v.document.information.preferences.country.$error },
        ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.document.information.preferences.country, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Select a country...")]),_vm._l((_vm.metadata.countries),function(row,idx){return _c('option',{key:`country:${idx}`,domProps:{"value":row.code,"textContent":_vm._s(row.name)}})})],2)])]),_c('div',{staticClass:"col-12 col-lg-4 form-group"},[_c('label',[_vm._v("Language "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'language']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.preferences.language.$model),expression:"$v.document.information.preferences.language.$model",modifiers:{"trim":true}}],class:[
          'form-control',
          { 'is-invalid': _vm.$v.document.information.preferences.language.$error },
        ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.document.information.preferences.language, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Select language...")]),_vm._l((_vm.metadata.languages),function(row,idx){return _c('option',{key:`language.${idx}`,domProps:{"value":row,"textContent":_vm._s(row)}})})],2)])]),_c('div',{staticClass:"col-12 col-lg-4 form-group"},[_c('label',[_vm._v("SmartVoice language "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'volume']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.preferences.smartvoice_language.$model),expression:"$v.document.information.preferences.smartvoice_language.$model",modifiers:{"trim":true}}],class:[
          'form-control',
          { 'is-invalid': _vm.$v.document.information.preferences.smartvoice_language.$error },
        ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.document.information.preferences.smartvoice_language, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":0}},[_vm._v("Select SmartVoice language...")]),_vm._l((_vm.metadata.smartvoice_languages),function(row,idx){return _c('option',{key:`smartvoice-language.${idx}`,domProps:{"value":row.value,"textContent":_vm._s(row.language)}})})],2)])]),_c('div',{staticClass:"col-12 col-lg-4 form-group"},[_c('label',[_vm._v("Rated voltage "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bolt']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.preferences.rated_voltage.$model),expression:"$v.document.information.preferences.rated_voltage.$model",modifiers:{"trim":true}}],class:[
          'form-control',
          { 'is-invalid': _vm.$v.document.information.preferences.rated_voltage.$error },
        ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.document.information.preferences.rated_voltage, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Select rated voltage...")]),_vm._l((_vm.metadata.rated_voltages),function(row,idx){return _c('option',{key:`rated_voltage.${idx}`,domProps:{"value":row.rated_voltage,"textContent":_vm._s(row.rated_voltage)}})})],2)])]),_c('div',{staticClass:"col-12 col-lg-4 form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('parts.uv_types.text'))+" "),_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}})]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'sun']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.information.preferences.uv_type.$model),expression:"$v.document.information.preferences.uv_type.$model",modifiers:{"trim":true}}],class:[
          'form-control',
          { 'is-invalid': _vm.$v.document.information.preferences.uv_type.$error },
        ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.document.information.preferences.uv_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Select "+_vm._s(_vm.$t('parts.uv_types.text'))+"...")]),_vm._l((_vm.metadata.uv_types),function(row,idx){return _c('option',{key:`uv_type.${idx}`,domProps:{"value":row,"textContent":_vm._s(_vm.$t(`parts.uv_types.types.${row}`))}})})],2)])]),_c('div',{staticClass:"col-12 col-lg form-group"},[_c('label',[_vm._v("Tanning intensity")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'sunrise']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.document.information.preferences.tanning_intensity),expression:"document.information.preferences.tanning_intensity",modifiers:{"trim":true}}],class:['form-control'],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.document.information.preferences, "tanning_intensity", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":undefined}},[_vm._v("-")]),_vm._l((_vm.metadata.tanning_intensities),function(row,idx){return _c('option',{key:`tanning_intensity.${idx}`,domProps:{"value":row,"textContent":_vm._s(row)}})})],2)])]),_c('div',{staticClass:"col-12 col-lg form-group"},[_c('label',[_vm._v("Prohibit UV-Type 5")]),_c('div',{staticClass:"input-group"},[_c('span',{class:[
          'switch',
          { 'switch-success': _vm.document.information.settings.prohibit_uv_type_5 },
        ]},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.information.settings.prohibit_uv_type_5),expression:"document.information.settings.prohibit_uv_type_5"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"checked":Array.isArray(_vm.document.information.settings.prohibit_uv_type_5)?_vm._i(_vm.document.information.settings.prohibit_uv_type_5,null)>-1:(_vm.document.information.settings.prohibit_uv_type_5)},on:{"change":function($event){var $$a=_vm.document.information.settings.prohibit_uv_type_5,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.document.information.settings, "prohibit_uv_type_5", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.document.information.settings, "prohibit_uv_type_5", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.document.information.settings, "prohibit_uv_type_5", $$c)}}}}),_c('span')])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }