var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row border-bottom pb-3"},[_c('div',{staticClass:"col-12"},[_vm._v(" Are you sure you want to change the order status to "),_c('strong',[_vm._v(_vm._s(_vm.humanReadableState))]),_vm._v("?"),_c('br'),_vm._v(" Please make sure the desired delivery week and scheduled shipping week meet the acceptable deviation criteria, then click "),_c('strong',[_vm._v("Confirm")]),_vm._v(". ")])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 border-bottom pb-3 no-gutter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-lg-7 my-auto"},[_vm._v("Desired delivery week")]),_c('div',{staticClass:"col d-flex font-weight-bold overflow-ellipsis"},[_c('span',{staticClass:"flex-grow-1 my-auto",domProps:{"textContent":_vm._s(_vm.getDesiredDelivery())}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-lg-7 my-auto"},[_vm._v("Scheduled shipping week")]),_c('div',{staticClass:"col d-flex font-weight-bold overflow-ellipsis"},[_c('span',{staticClass:"flex-grow-1 my-auto",domProps:{"textContent":_vm._s(_vm.getShippingWeek())}})])])]),_c('div',{staticClass:"col-12 mt-5"},[_c('div',{staticClass:"alert alert-custom alert-light-warning mb-0"},[_c('div',{staticClass:"alert-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle']}})],1)]),_vm._m(0)])]),(_vm.shouldShowUVType5Checkmark === true)?_c('div',{staticClass:"col-12 mt-5 border-top pt-5"},[_c('label',{class:[
          'option cursor-pointer',
          { 'border-danger': _vm.$v.metadata.form.uvType5Check.$error },
        ]},[_c('span',{staticClass:"option-control"},[_c('span',{staticClass:"checkbox checkbox-primary"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.metadata.form.uvType5Check.$model),expression:"$v.metadata.form.uvType5Check.$model"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.metadata.form.uvType5Check.$model)?_vm._i(_vm.$v.metadata.form.uvType5Check.$model,null)>-1:(_vm.$v.metadata.form.uvType5Check.$model)},on:{"change":function($event){var $$a=_vm.$v.metadata.form.uvType5Check.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.metadata.form.uvType5Check, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.metadata.form.uvType5Check, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.metadata.form.uvType5Check, "$model", $$c)}}}}),_c('span')])]),_vm._m(1)])]):_vm._e(),_c('div',{staticClass:"col-12 mt-5 border-top pt-5"},[_c('label',{class:['option cursor-pointer']},[_vm._m(2),_c('span',{staticClass:"option-control w-50px"},[_c('span',{class:['switch', { 'switch-warning': _vm.metadata.form.doNotSendEmail }]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.metadata.form.doNotSendEmail),expression:"metadata.form.doNotSendEmail"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.metadata.form.doNotSendEmail)?_vm._i(_vm.metadata.form.doNotSendEmail,null)>-1:(_vm.metadata.form.doNotSendEmail)},on:{"change":function($event){var $$a=_vm.metadata.form.doNotSendEmail,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.metadata.form, "doNotSendEmail", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.metadata.form, "doNotSendEmail", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.metadata.form, "doNotSendEmail", $$c)}}}}),_c('span')])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row ml-1 alert-text"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Warning")]),_c('span',[_vm._v(" when clicking confirm, the customer's administrator will receive an order confirmation which includes the order configuration, price and delivery week. Please make sure all information is filled out correctly and set criteria are met. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"option-label my-auto"},[_c('span',{staticClass:"option-body pt-0 text-body"},[_vm._v(" I have received a valid export declaration for this order from the buying organization and uploaded this in VBS. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"option-label my-auto"},[_c('span',{staticClass:"option-body pt-0 text-body"},[_vm._v(" Don't send a confirmation email to the customer. ")])])
}]

export { render, staticRenderFns }