var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 border-right-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-10 offset-lg-1"},[_c('div',{staticClass:"row row-cols-1 row-cols-lg-2"},[_c('div',{staticClass:"col form-group border-bottom pb-3 mb-3"},[_c('label',[_vm._v(" Description "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'text']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.description.$model),expression:"$v.document.description.$model",modifiers:{"trim":true}}],ref:"input:description",class:[
                  'form-control form-control-lg',
                  { 'is-invalid': _vm.$v.document.description.$error },
                ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col form-group border-bottom pb-3 mb-3"},[_c('label',[_vm._v(" Article number "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.article_number.$model),expression:"$v.document.article_number.$model",modifiers:{"trim":true}}],class:[
                  'form-control form-control-lg',
                  { 'is-invalid': _vm.$v.document.article_number.$error },
                ],attrs:{"type":"text","disabled":_vm.isDisabled || _vm.action !== 'CREATE',"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.article_number.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.article_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col form-group border-bottom pb-3 mb-3"},[_c('label',[_vm._v(" Display color 1 "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'hashtag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy.trim",value:(_vm.$v.displayColor1.$model),expression:"$v.displayColor1.$model",modifiers:{"lazy":true,"trim":true}}],class:[
                  'form-control form-control-lg',
                  { 'is-invalid': _vm.$v.displayColor1.$error },
                ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.displayColor1.$model)},on:{"change":function($event){_vm.$set(_vm.$v.displayColor1, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col form-group border-bottom pb-3 mb-3"},[_c('label',[_vm._v(" Display color 2 "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'hashtag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy.trim",value:(_vm.$v.displayColor2.$model),expression:"$v.displayColor2.$model",modifiers:{"lazy":true,"trim":true}}],class:[
                  'form-control form-control-lg',
                  { 'is-invalid': _vm.$v.displayColor2.$error },
                ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.displayColor2.$model)},on:{"change":function($event){_vm.$set(_vm.$v.displayColor2, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col form-group"},[_c('label',[_vm._v("Configuration ID")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tools']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.configuration_id),expression:"document.configuration_id"}],staticClass:"form-control form-control-lg",attrs:{"disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.document, "configuration_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":undefined}},[_vm._v("-")]),_vm._l((_vm.metadata.configuration_ids),function([group, values]){return _c('optgroup',{key:`parts:colors:configuration_id:${group}`,attrs:{"label":group}},_vm._l((values),function(value){return _c('option',{key:`parts:colors:configuration_id:${group}:${value}`,domProps:{"value":value,"textContent":_vm._s(_vm.$t(`parts.colors.configuration_ids.${value}.name`))}})}),0)})],2)])])])])])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('parts-colors-color-display-component',{staticClass:"w-200px h-200px mx-auto",attrs:{"color":_vm.document}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }