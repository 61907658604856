
import Vue from 'vue';
import VueSelect from 'vue-select';
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';
import world from '@/assets/js/world.json';

import { ManufacturingSublineFilterModalOptions } from './interfaces';
import {
  ColorPartConfigurationID,
  ManufacturingSublineFilter,
  ManufacturingSublineFilterDomain,
  ManufacturingSublineFilterType,
  OptionPartConfigurationID,
} from '../../../../../services/parts';
import {
  MANUFACTURING_SUBLINE_FILTER_MODAL_CLOSE,
  MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN,
} from './subline-filter-modal.constants';

interface VueSelectOption {
  label: string;
  value: string;
}

export default Vue.extend({
  name: 'manufacturingSublineFilterModal',
  mixins: [Mixins.Modal],
  components: {
    VueSelect,
  },
  data() {
    return {
      options: undefined as ManufacturingSublineFilterModalOptions | undefined,

      document: undefined as ManufacturingSublineFilter | undefined,

      isLoadingForm: false,

      metadata: {
        domains: Object.values(ManufacturingSublineFilterDomain),
        types: Object.values(ManufacturingSublineFilterType),
        options: [] as Array<VueSelectOption>,
      },
    };
  },

  validations: {
    document: {
      domain: {
        required,
      },
      type: {
        required,
      },
      values: {
        required,
      },
    },
  },

  mounted(): void {
    this.$eventhub.on(MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN, this.open);
    this.$eventhub.on(MANUFACTURING_SUBLINE_FILTER_MODAL_CLOSE, this.close);
  },
  beforeDestroy(): void {
    this.$eventhub.off(MANUFACTURING_SUBLINE_FILTER_MODAL_OPEN, this.open);
    this.$eventhub.off(MANUFACTURING_SUBLINE_FILTER_MODAL_CLOSE, this.close);
  },

  methods: {
    onDomainChange(domain: ManufacturingSublineFilterDomain): void {
      this.$set(this.metadata, 'options', this.getOptionsByDomain(domain));
      this.$set(this.document as ManufacturingSublineFilter, 'values', []);
    },
    onValueInput(value: Array<VueSelectOption>): void {
      this.$set(this.document as ManufacturingSublineFilter, 'values', value);
    },

    getIconByDomain(domain: ManufacturingSublineFilterDomain): string {
      switch (domain) {
        case ManufacturingSublineFilterDomain.COLOR: {
          return 'tint';
        }

        case ManufacturingSublineFilterDomain.OPTION: {
          return 'plus-circle';
        }

        case ManufacturingSublineFilterDomain.COUNTRY: {
          return 'globe';
        }

        default: {
          return 'list';
        }
      }
    },
    getOptionsByDomain(domain: ManufacturingSublineFilterDomain): Array<VueSelectOption> {
      switch (domain) {
        case ManufacturingSublineFilterDomain.COLOR: {
          return Object.values(ColorPartConfigurationID).map((row) => {
            const translation = this.$t(`parts.colors.configuration_ids.${row}`) as unknown as {
              name: string;
              category: string;
            };

            return {
              label: `${translation.category} - ${translation.name}`,
              value: row,
            };
          }, []);
        }

        case ManufacturingSublineFilterDomain.OPTION: {
          return Object.values(OptionPartConfigurationID).map((row) => {
            const translation = this.$t(`parts.options.configuration_ids.${row}`) as unknown as {
              name: string;
              category: string;
            };

            return {
              label: `${translation.category} - ${translation.name}`,
              value: row,
            };
          }, []);
        }

        case ManufacturingSublineFilterDomain.COUNTRY: {
          return world.map(
            (row) => ({
              label: row.en,
              value: row.alpha3.toUpperCase(),
            }),
            [],
          );
        }
      }
    },

    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.document as ManufacturingSublineFilter);
        }
      } catch (e) {
        console.error(e);
        this.options?.onError?.(e as Error);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    async open(options?: ManufacturingSublineFilterModalOptions): Promise<void> {
      this.$set(this, 'options', options || undefined);
      this.$set(this, 'document', JSON.parse(JSON.stringify(options?.filter)) || undefined);

      await this.$nextTick();
      this.$v.$reset();

      // Set options
      this.$set(
        this.metadata,
        'options',
        this.getOptionsByDomain(this.document?.domain as ManufacturingSublineFilterDomain),
      );

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();
    },
  },
});
