
import { MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../../../constants';
import { Core } from '../../../../../../../services/core';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../services/sunbeds';
import { ConfigurationViewTypesRatedVoltagesForm } from '../components';

export default Vue.extend({
  name: 'configurationViewTypesViewRatedVoltages',
  components: {
    ConfigurationViewTypesRatedVoltagesForm,
  },
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        // Filter out all rated voltages without power and article number
        const entity = JSON.parse(JSON.stringify(this.metadata.type)) as SunbedModelTypeEntity;
        entity.rated_voltages = entity.rated_voltages.filter(
          (row) => row.power_consumption && row.article_number,
          [],
        );

        const mutations = MutationsService.getMutationsByComparison(this.type, entity, [
          'rated_voltages',
        ]);

        if (mutations.length > 0) {
          const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
            `/sunbeds/models/${this.configuration._meta.guid}/types/${this.type._meta.guid}`,
            mutations,
          );
          this.$emit('update:type', response.data);
          this.$set(this.metadata, 'type', JSON.parse(JSON.stringify(response.data)));

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.ratedVoltagesForm as any).applyRatedVoltages();
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
