export const MODALS_EVENT = 'modals:layout';

export const SWITCH_ORGANIZATION_MODAL_EVENT = `${MODALS_EVENT}:switch-organization`;
export const SWITCH_ORGANIZATION_MODAL_EVENT_OPEN = `${SWITCH_ORGANIZATION_MODAL_EVENT}:open`;
export const SWITCH_ORGANIZATION_MODAL_EVENT_CLOSE = `${SWITCH_ORGANIZATION_MODAL_EVENT}:close`;

export const ORGANIZATION_INVITE_MODAL_EVENT = `${MODALS_EVENT}:organization-invite`;
export const ORGANIZATION_INVITE_MODAL_EVENT_OPEN = `${ORGANIZATION_INVITE_MODAL_EVENT}:open`;
export const ORGANIZATION_INVITE_MODAL_EVENT_CLOSE = `${ORGANIZATION_INVITE_MODAL_EVENT}:close`;

export const CONFIRMATION_MODAL_EVENT = `${MODALS_EVENT}:confirmation`;
export const CONFIRMATION_MODAL_EVENT_OPEN = `${CONFIRMATION_MODAL_EVENT}:open`;
export const CONFIRMATION_MODAL_EVENT_CLOSE = `${CONFIRMATION_MODAL_EVENT}:close`;

export const INFORMATION_MODAL_EVENT = `${MODALS_EVENT}:information`;
export const INFORMATION_MODAL_EVENT_OPEN = `${INFORMATION_MODAL_EVENT}:open`;
export const INFORMATION_MODAL_EVENT_CLOSE = `${INFORMATION_MODAL_EVENT}:close`;

export const PERSONNEL_PIN_CHANGE_MODEL_EVENT = `${MODALS_EVENT}:personnel:pin-change`;
export const PERSONNEL_PIN_CHANGE_MODEL_EVENT_OPEN = `${PERSONNEL_PIN_CHANGE_MODEL_EVENT}:open`;
export const PERSONNEL_PIN_CHANGE_MODEL_EVENT_CLOSE = `${PERSONNEL_PIN_CHANGE_MODEL_EVENT}:close`;
