
import Vue, { PropType } from 'vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { MutationsService } from '@vedicium/object-mutations';

import { PricelistsInformationForm } from '../components';
import { PricelistEntity, PricelistsStore } from '../../../../services/pricelists';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';

export default Vue.extend({
  name: 'pricelistsViewInformation',
  props: {
    pricelist: {
      type: Object as PropType<PricelistEntity>,
    },
  },
  components: {
    PricelistsInformationForm,
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        pricelist: JSON.parse(JSON.stringify(this.pricelist)) as PricelistEntity,
      },
    };
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([(this.$refs.informationForm as Vue).$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(
          this.pricelist,
          this.metadata.pricelist,
          ['description'],
        );

        if (mutations.length > 0) {
          const entity = await PricelistsStore.update(this.pricelist._meta.guid, mutations);

          this.$emit('update:pricelist', entity);
          this.$set(this.metadata, 'pricelist', JSON.parse(JSON.stringify(entity)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
