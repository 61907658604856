var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[(_vm.isMounted === false || _vm.isLoading === true)?[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',{staticClass:"float-left",domProps:{"textContent":_vm._s(_vm.$t('datatable.loading'))}}),_c('span',{staticClass:"spinner spinner-primary ml-auto"})])])])])]:[(_vm.metadata.models.length === 0)?[_c('div',{staticClass:"col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"},[_c('div',{staticClass:"alert alert-custom alert-light-info",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tools']}})],1)]),_c('div',{staticClass:"alert-text"},[_c('span',{staticClass:"font-weight-bold d-block"},[_vm._v("No compatible models found.")]),_c('span',{staticClass:"d-block"},[_vm._v(" There are no compatible models found which meet the following criteria:"),_c('br'),_vm._v(" - UV type: "+_vm._s(_vm.$t(`parts.uv_types.types.${_vm.session.uv_type}`))),_c('br'),_vm._v(" - Rated voltage: "+_vm._s(_vm.session.rated_voltage)+" ")])])])])]:_vm._e(),(_vm.horizontalModels.length > 0)?_c('div',{staticClass:"col-12 border-bottom pb-1"},[_c('h3',[_vm._v("Horizontal units")]),_c('div',{staticClass:"row"},_vm._l((_vm.horizontalModels),function(model){return _c('div',{key:`model.horizontal.${model._meta.guid}`,staticClass:"col-12 col-md-6 col-lg-4 cursor-pointer pb-8",on:{"click":function($event){$event.preventDefault();return _vm.setModel(model)}}},[_c('sunbed-model-card',{class:[
              {
                'border border-1 border-info':
                  _vm.session.model && _vm.session.model._meta.guid === model._meta.guid,
              },
              { 'border border-danger border-1': _vm.$v.session.model.$error },
            ],attrs:{"model":model,"types":model.types}})],1)}),0)]):_vm._e(),(_vm.verticalModels.length > 0)?_c('div',{staticClass:"col-12 mt-5"},[_c('h3',[_vm._v("Vertical units")]),_c('div',{staticClass:"row"},_vm._l((_vm.verticalModels),function(model){return _c('div',{key:`model.vertical.${model._meta.guid}`,staticClass:"col-12 col-md-6 col-lg-4 cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.setModel(model)}}},[_c('sunbed-model-card',{class:[
              {
                'border border-1 border-info':
                  _vm.session.model && _vm.session.model._meta.guid === model._meta.guid,
              },
              { 'border border-danger border-1': _vm.$v.session.model.$error },
            ],attrs:{"model":model,"types":model.types}})],1)}),0)]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }