import { Entity } from '@vedicium/vue-core';
import { SunbedVoltage } from './rated_voltages.interface';
import { SunbedModelEntity } from './sunbed_model.entity';
import {
  SunbedModelTypeColorMode,
  SunbedModelTypeConfigurationID,
  SunbedModelTypeOptionMode,
  SunbedModelTypePackageMode,
  SunbedModelTypeUVConfigurationMode,
} from './sunbed_model_types.interface';

export class SunbedModelTypeRatedVoltageDto {
  rated_voltage?: string = undefined;
  article_number?: string = undefined;
  power_consumption: Array<{
    voltage: SunbedVoltage;
    wattage: number | undefined;
  }> = [];

  constructor(rated_voltage?: SunbedModelTypeRatedVoltageDto) {
    this.rated_voltage = rated_voltage?.rated_voltage || this.rated_voltage;
    this.power_consumption = rated_voltage?.power_consumption || this.power_consumption;
    this.article_number = rated_voltage?.article_number || this.article_number;
  }
}

export type SunbedModelTypeRatedVoltage = SunbedModelTypeRatedVoltageDto;

export interface SunbedModelTypeFacetannerFilterRow {
  article_number: string;
  power: number;
}

export class SunbedModelTypeUVConfigurationDto {
  uv_type?: string = undefined;
  description?: string = undefined;
  article_number?: string = undefined;
  production_article_number?: string = undefined;
  identifier: string | undefined = undefined;
  mode: SunbedModelTypeUVConfigurationMode = SunbedModelTypeUVConfigurationMode.AVAILABLE;

  facetanner: {
    enabled: boolean;
    light_source?: {
      article_number: string;
      amount: number;
    };

    filters?: Array<SunbedModelTypeFacetannerFilterRow>;
  } = {
    enabled: false,
  };

  light_sources: Array<{
    location: 'bench' | 'canopy';
    article_number: string;
    amount: number;
  }> = [];
}

export type SunbedModelTypeUVConfiguration = SunbedModelTypeUVConfigurationDto;

export class SunbedModelTypeDto {
  name?: string = undefined;
  special_preferences: {
    enabled: boolean;
    organizations?: Array<string>;
  } = {
    enabled: false,
  };

  configuration_id?: SunbedModelTypeConfigurationID = undefined;
  production_time_in_days?: number = undefined;

  rated_voltages: Array<SunbedModelTypeRatedVoltage> = [];
  uv_configurations: Array<SunbedModelTypeUVConfiguration> = [];

  parts: {
    colors: Array<{
      guid: string;
      mode: SunbedModelTypeColorMode;
    }>;

    options: Array<{
      guid: string;
      mode: SunbedModelTypeOptionMode;
      together_with?: number;
      not_together_with?: number;
    }>;

    packages: Array<{
      guid: string;
      mode: SunbedModelTypePackageMode;
      together_with?: number;
      not_together_with?: number;
    }>;
  } = {
    colors: [],
    options: [],
    packages: [],
  };
}

export interface SunbedModelTypeEntity extends Entity, SunbedModelTypeDto {
  model?: SunbedModelEntity;
}
