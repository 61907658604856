
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';

import { ColorPartDto, ColorPartConfigurationID } from '../../../../../services/parts';
import { hexColor } from '../../../../../utils/validators';

import PartsColorsColorDisplayComponent from './color-display.component.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'partsColorsInformationForm',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<ColorPartDto>,
    },
  },
  components: {
    PartsColorsColorDisplayComponent,
  },
  data() {
    const configurationIDs: Map<string, Array<ColorPartConfigurationID>> = new Map();
    Object.values(ColorPartConfigurationID).forEach((row) => {
      // Get the translation
      const translation = this.$t(`parts.colors.configuration_ids.${row}`) as {
        name: string;
        category: string;
      };

      // When the category is already known, add it to the same category.
      if (configurationIDs.has(translation.category) === true) {
        configurationIDs.set(translation.category, [
          ...(configurationIDs.get(translation.category) || []),
          row,
        ]);
        return;
      }

      // If the category isn't known, create it.
      configurationIDs.set(translation.category, [row]);
    });

    return {
      metadata: {
        configuration_ids: configurationIDs,
      },
    };
  },
  computed: {
    displayColor1: {
      get(): string {
        return this.document.display_colors[0];
      },
      set(value: string): void {
        this.$set(this.document.display_colors, 0, value);
      },
    },
    displayColor2: {
      get(): string {
        return this.document.display_colors[1];
      },
      set(value: string): void {
        this.$set(this.document.display_colors, 1, value);
      },
    },
  },
  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
    },

    displayColor1: {
      required,
      hexColor,
    },
    displayColor2: {
      required,
      hexColor,
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:description'] as HTMLInputElement).focus();
    }
  },
});
