var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body py-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-auto"},[_c('ul',{staticClass:"nav nav-tabs nav-tabs-line border-bottom-0 pt-3",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item mr-14"},[_c('router-link',{staticClass:"nav-link font-weight-bold border-2 pb-6 mx-0",attrs:{"to":{
                      name: '/configurations/view/:configuration/types/:guid/compatibility/colors',
                    },"data-toggle":"tab","active-class":"active"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Colors")])])],1),_c('li',{staticClass:"nav-item mr-14"},[_c('router-link',{staticClass:"nav-link font-weight-bold border-2 pb-6 mx-0",attrs:{"to":{
                      name: '/configurations/view/:configuration/types/:guid/compatibility/packages',
                    },"data-toggle":"tab","active-class":"active"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Packages")])])],1),_c('li',{staticClass:"nav-item mr-14"},[_c('router-link',{staticClass:"nav-link font-weight-bold border-2 pb-6 mx-0",attrs:{"to":{
                      name: '/configurations/view/:configuration/types/:guid/compatibility/options',
                    },"data-toggle":"tab","active-class":"active"}},[_c('span',{staticClass:"nav-text"},[_vm._v("Options")])])],1)])])])])])])]),_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view',{ref:"router-view",attrs:{"configuration":_vm.configuration,"type":_vm.type}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header pt-3"},[_c('div',{staticClass:"card-title align-items-start flex-column"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Compatibility")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-sm mt-1"},[_vm._v(" View compatibility of the current type. ")])])])
}]

export { render, staticRenderFns }