var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modal",attrs:{"title":_vm.options.title || 'Upload file',"size":"xl","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('input',{ref:"fileInput",attrs:{"id":"fileInput","type":"file","name":"fields[files][]","hidden":"","multiple":_vm.options && _vm.options.multiple === true,"accept":(_vm.options && _vm.options.accept) || '.csv',"disabled":_vm.isLoading},on:{"change":_vm.onFileChange}}),_c('div',{staticClass:"flex w-full h-screen items-center justify-center text-center"},[_c('div',{class:[
          'p-12 bg-gray-100 border-gray-100 border border-gray',
          { 'border-info': _vm.metadata.dragover, 'border-danger': _vm.$v.metadata.files.$error },
        ],on:{"drop":function($event){$event.preventDefault();return _vm.onDropzoneDrop.apply(null, arguments)},"dragenter":function($event){$event.preventDefault();return _vm.onDropzoneDragover.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.onDropzoneDragover.apply(null, arguments)},"dragleave":_vm.onDropzoneDragleave}},[_c('label',{class:[
            'block cursor-pointer',
            { 'opacity-50': _vm.metadata.dragover || _vm.isLoading === true },
          ],attrs:{"for":"fileInput"}},[(_vm.metadata.files.length === 0)?[_c('h4',[_vm._v("Drop files here or click to upload")]),(_vm.options.instructions)?_c('span',{staticClass:"text-muted",domProps:{"textContent":_vm._s(_vm.options.instructions)}}):_vm._e()]:[_c('h4',[_vm._v("Selected files")]),_c('span',{staticClass:"text-muted"},[_vm._v("Files shown below are going to be uploaded")]),_c('table',{staticClass:"table text-left mt-3"},[_c('tbody',_vm._l((_vm.metadata.files),function(file,idx){return _c('tr',{key:`file:${idx}`,staticClass:"bg-light-info"},[_c('td',{staticClass:"align-middle"},[_c('div',{staticClass:"symbol symbol-25 symbol-light mr-1"},[_c('span',{staticClass:"symbol-label"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'file']}})],1)])]),_c('td',{staticClass:"align-middle"},[_c('span',{staticClass:"text-info",domProps:{"textContent":_vm._s(file.name)}})]),_c('td',{staticClass:"align-middle"},[_c('button',{staticClass:"btn btn-sm",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.onFileRemoval(file)}}},[_c('i',{staticClass:"svg-icon text-danger"},[_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":['fas', 'times']}})],1)])])])}),0)])]],2)])])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary w-100px",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-warning w-100px",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Upload ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }