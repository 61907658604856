
import Vue, { PropType } from 'vue';
import VueSelect, { VueSelectInstance } from 'vue-select';

import { SunbedModelEntity, SunbedModelsStore } from '../../../../services/sunbeds';

export default Vue.extend({
  name: 'SunbedModelVueSelect',
  components: {
    VueSelect,
  },
  props: {
    value: {
      type: Object as PropType<SunbedModelEntity | undefined>,
      default: undefined,
    },
    searchFields: {
      type: Array as PropType<Array<string>>,
      default: () => ['name'],
    },
    onSearchError: {
      type: Function as PropType<(error?: Error | undefined) => Promise<void> | void | undefined>,
      default: undefined,
    },
    loadOnMounted: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metadata: {
        options: [] as Array<SunbedModelEntity>,
        timeout: undefined as number | undefined,
      },
    };
  },
  computed: {
    input(): VueSelectInstance {
      return this.$refs.input as VueSelectInstance;
    },
  },

  mounted() {
    if (this.loadOnMounted === true) {
      this.onSearch(undefined, { allow_empty_query: true, timeout: 0 });
    }
  },

  methods: {
    onInput(entity: SunbedModelEntity | null): void {
      this.$emit('input', entity || undefined);
    },
    onSearch(query?: string, options?: { allow_empty_query?: boolean; timeout?: number }): void {
      clearTimeout(this.metadata.timeout);

      // Stop searching when query is empty
      if (options?.allow_empty_query !== true && !query) {
        this.input.toggleLoading(false);
        return;
      }

      this.input.toggleLoading(true);
      this.metadata.timeout = setTimeout(async () => {
        this.$set(this.metadata, 'options', []);
        try {
          const sunbedModels = await SunbedModelsStore.search({
            params: {
              limit: 10,
              search_fields: this.searchFields.join(','),
              search: (query || '')
                .split(' ')
                .map((row) => `*${row}*`, [])
                .join(' '),
            },
          });

          this.$set(this.metadata, 'options', sunbedModels);
        } catch (e) {
          console.error(e);
          if (typeof this.onSearchError === 'function') {
            await this.onSearchError(e as Error);
          }
        } finally {
          this.input.toggleLoading(false);
        }
      }, options?.timeout || 600);
    },

    focus(): void {
      (this.input.$refs.search as HTMLInputElement).focus();
    },
  },
});
