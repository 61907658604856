
import { OrganizationEntity } from '@vedicium/vue-core';
import Vue from 'vue';
import { Core } from '../../../services/core';
import { OrganizationInformationEntity } from '../../../services/organizations';

export default Vue.extend({
  name: 'configurationsView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        organization: undefined as OrganizationEntity | undefined,
        information: undefined as OrganizationInformationEntity | undefined,
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Organizations', route: '/organizations/overview' },
    ]);
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      const [organizationResponse, informationResponse] = await Promise.all([
        Core.getAdapter().get<OrganizationEntity>(`/organizations/${this.$route.params.guid}`),
        Core.getAdapter().get<OrganizationInformationEntity>(
          `/organizations/${this.$route.params.guid}/information`,
        ),
      ]);

      this.$set(this.metadata, 'organization', organizationResponse.data);
      this.$set(this.metadata, 'information', informationResponse.data);
    } catch (e) {
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
      this.$set(this, 'isLoading', false);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: this.metadata.organization?.name as string,
    });
  },
});
