
import Vue from 'vue';
import { AuthorizationParams } from '@auth0/auth0-spa-js';
import { PublicLayout } from '@/components/layout';
import { auth0 } from '@/services/auth0';

export default Vue.extend({
  data() {
    return {
      isMounted: false,
    };
  },
  components: {
    PublicLayout,
  },

  async mounted() {
    await auth0.loginWithRedirect({
      authorizationParams: {
        prompt: this.$route.query.prompt?.toString() as AuthorizationParams['prompt'],
      },
      appState: this.$route.query.state?.toString(),
    });
  },
});
