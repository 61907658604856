
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import Vue from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../components/layout/portal/modals';
import {
  FilterPartDto,
  ManufacturingSubline,
  ManufacturingSublineDto,
} from '../../../../../services/parts';

import {
  ManufacturingSublineModal,
  ManufacturingSublineModalOptions,
  MANUFACTURING_SUBLINE_MODAL_OPEN,
} from '../../components';

export default Vue.extend({
  name: 'partsFiltersSublinesForm',
  mixins: [FormMixin, DatatableMixin],
  components: {
    ManufacturingSublineModal,
  },
  props: {
    document: {
      type: Object as PropType<FilterPartDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'description',
            title: 'Description',
          },
          {
            name: 'article_number',
            title: 'Article number',
          },
          {
            name: 'amount',
            title: 'No. of items',
            formatter: (value: number) => value.toLocaleString(),
          },
          {
            name: 'actions',
            title: 'Actions',
          },
        ],
      } as DatatableConfig,
    };
  },

  methods: {
    onAddSubline(): void {
      const vm = this;
      this.$eventhub.emit(MANUFACTURING_SUBLINE_MODAL_OPEN, {
        type: 'filter',
        entity: new ManufacturingSublineDto(),
        onSubmit: (entity: ManufacturingSubline) => {
          vm.document.manufacturing.sublines.push(entity);

          vm.$emit('update', vm.document.manufacturing.sublines);
        },
      } as ManufacturingSublineModalOptions);
    },
    onModifySubline(subline: ManufacturingSubline): void {
      const vm = this;
      this.$eventhub.emit(MANUFACTURING_SUBLINE_MODAL_OPEN, {
        type: 'filter',
        entity: JSON.parse(JSON.stringify(subline)),
        onSubmit: (entity: ManufacturingSubline) => {
          const idx = vm.document.manufacturing.sublines.indexOf(subline);
          vm.$set(vm.document.manufacturing.sublines, idx, entity);

          vm.$emit('update', vm.document.manufacturing.sublines);
        },
      } as ManufacturingSublineModalOptions);
    },
    onRemoveSubline(subline: ManufacturingSubline): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove subline',
        message: `Are you sture that you want to remove this subline?`,
        onSubmit() {
          vm.$set(
            vm.document.manufacturing,
            'sublines',
            vm.document.manufacturing.sublines.filter((row) => row !== subline, []),
          );

          vm.$emit('update', vm.document.manufacturing.sublines);
        },
      });
    },
  },
});
