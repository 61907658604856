
import Vue, { PropType, watch } from 'vue';
import { CurrencyDisplay, CurrencyInputOptions, useCurrencyInput } from 'vue-currency-input';

export default Vue.extend({
  name: 'currencyInput',
  props: {
    value: {
      type: Number,
    },
    options: {
      type: Object as PropType<CurrencyInputOptions>,
    },
  },
  setup(props) {
    // Set the options
    const options: CurrencyInputOptions = {
      locale: 'nl-NL',
      currency: 'EUR',
      currencyDisplay: CurrencyDisplay.hidden,
      autoDecimalDigits: false,
      ...((props.options as CurrencyInputOptions | undefined) || {}),
    };

    const { inputRef, setValue } = useCurrencyInput(options);

    watch(
      () => props.value,
      (value) => setValue(value as number | null),
    );

    return { inputRef };
  },
});
