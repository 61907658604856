
import Vue from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required, numeric } from 'vuelidate/lib/validators';
import { CurrencyInput } from '../../../../components/currency-input';
import { ConfiguratorService, SunbedConfigurationSession } from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';

import SunbedModelImage from './model.image.vue';

export default Vue.extend({
  name: 'ordersPriceAndAmountForm',
  mixins: [FormMixin],
  components: {
    SunbedModelImage,
    CurrencyInput,
  },
  data() {
    return {
      isLoading: false,

      session: ConfiguratorService.session as SunbedConfigurationSession,

      FormatUtils,
    };
  },
  computed: {
    title(): string {
      if (this.session.type) {
        return [this.session.model?.name, this.session.type.name].join(' ');
      }

      return this.session.model?.name as string;
    },
    configurationIDs(): Array<string> {
      return ConfiguratorService.getConfigurationIDs();
    },

    total_price(): number {
      return ConfiguratorService.getPurchasePrice() * this.session.configuration.amount;
    },
  },
  validations: {
    session: {
      configuration: {
        amount: {
          required,
          numeric,
        },
        custom_price: {
          numeric,
        },
      },
    },
  },

  methods: {
    isLivePricingEnabled(): boolean {
      return ConfiguratorService.isLivePricingEnabled();
    },
    getTotalPrice(): number | undefined {
      return ConfiguratorService.getPurchasePrice();
    },
  },
});
