
import Vue, { PropType } from 'vue';
import { ColorPartDto, ColorPartEntity } from '../../../../../services/parts';

export default Vue.extend({
  name: 'partsColorsColorDisplay',
  props: {
    color: {
      type: Object as PropType<ColorPartDto | ColorPartEntity>,
    },
  },
  computed: {
    style(): string {
      return [
        `background: #${this.color.display_colors[0]};`,
        `background: linear-gradient(180deg, #${this.color.display_colors[0]} 0%, #${this.color.display_colors[1]} 100%);`,
      ].join(' ');
    },
  },
});
