
import { BModal } from 'bootstrap-vue';
import Vue, { VueConstructor } from 'vue';

import { SET_SUNBED_ORDER_STATE_MODAL_CLOSE, SET_SUNBED_ORDER_STATE_MODAL_OPEN } from './constants';
import { SetSunbedOrderStateModalOptions, StateFormInstance } from './interfaces';
import {
  CancelledStatusFormComponent,
  InProductionStatusFormComponent,
  ModificationInProcessStatusFormComponent,
  ReadyForProductionStatusFormComponent,
  ReadyToShipStatusFormComponent,
  RequestedStatusFormComponent,
  SchedulingDeliveryStatusFormComponent,
  SchedulingProductionStatusFormComponent,
  ShippedStatusFormComponent,
} from './components';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../services/sunbeds';
import { Core } from '../../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../../constants';

export default Vue.extend({
  name: 'setSunbedOrderStateModal',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      options: undefined as SetSunbedOrderStateModalOptions | undefined,
    };
  },
  computed: {
    formComponent(): VueConstructor<StateFormInstance> | undefined {
      switch (this.options?.state) {
        case SunbedOrderState.REQUESTED: {
          return RequestedStatusFormComponent;
        }

        case SunbedOrderState.SCHEDULING_PRODUCTION: {
          return SchedulingProductionStatusFormComponent;
        }

        case SunbedOrderState.SCHEDULING_DELIVERY: {
          return SchedulingDeliveryStatusFormComponent;
        }

        case SunbedOrderState.READY_FOR_PRODUCTION: {
          return ReadyForProductionStatusFormComponent;
        }

        case SunbedOrderState.IN_PRODUCTION: {
          return InProductionStatusFormComponent;
        }

        case SunbedOrderState.READY_TO_SHIP: {
          return ReadyToShipStatusFormComponent;
        }

        case SunbedOrderState.SHIPPED: {
          return ShippedStatusFormComponent;
        }

        case SunbedOrderState.MODIFICATION_IN_PROCESS: {
          return ModificationInProcessStatusFormComponent;
        }

        case SunbedOrderState.CANCELLED: {
          return CancelledStatusFormComponent;
        }

        default: {
          return undefined;
        }
      }
    },

    isStateSupported(): boolean {
      return this.formComponent !== undefined;
    },
  },

  mounted() {
    this.$eventhub.on(SET_SUNBED_ORDER_STATE_MODAL_OPEN, this.open);
    this.$eventhub.on(SET_SUNBED_ORDER_STATE_MODAL_CLOSE, this.close);
  },
  beforeDestroy(): void {
    this.$eventhub.off(SET_SUNBED_ORDER_STATE_MODAL_OPEN, this.open);
    this.$eventhub.off(SET_SUNBED_ORDER_STATE_MODAL_CLOSE, this.close);
  },

  methods: {
    getForm(): (Vue & StateFormInstance) | undefined {
      return this.$refs['form:state'] as (Vue & StateFormInstance) | undefined;
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);

      let entity = this.options?.order as SunbedOrderEntity;
      try {
        // Submit form
        const form = this.getForm();
        if (typeof form?.onSubmit === 'function') {
          entity = await form?.onSubmit();
        }

        // Submit modal
        if (typeof this.options?.onSubmit === 'function') {
          await this.options?.onSubmit(entity);
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    async open(options?: SetSunbedOrderStateModalOptions): Promise<void> {
      this.$set(this, 'isMounted', false);
      this.$set(this, 'options', options);

      // Error when no state is given.
      if (this.options?.state === undefined) {
        throw new Error('State is undefined');
      }

      // Error when order is undefined
      if (this.options?.order === undefined) {
        throw new Error('Order is undefined');
      }

      await this.$nextTick();

      this.$set(this, 'isMounted', true);
      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },
  },
});
