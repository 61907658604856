
import { DatatableConfig, DatatableInstance, Mixins } from '@vedicium/metronic-vue';
import { Mutation } from '@vedicium/object-mutations';
import { DatatableUtil, OrganizationEntity } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';

import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../components/layout/portal/modals';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import { OrganizationInformationEntity } from '../../../../services/organizations';
import { PricelistEntity } from '../../../../services/pricelists';
import { SearchPricelistModal } from '../../pricelists';
import { SEARCH_PRICELIST_MODAL_OPEN } from '../../pricelists/modals/search-pricelist-modal/search-pricelist-modal.constants';
import { SearchPricelistModalOptions } from '../../pricelists/modals/search-pricelist-modal/search-pricelist-modal.interface';

export default Vue.extend({
  name: 'organizationsViewInformation',
  mixins: [Mixins.Datatable],
  props: {
    organization: {
      type: Object as PropType<OrganizationEntity>,
    },
    information: {
      type: Object as PropType<OrganizationInformationEntity>,
    },
  },
  components: {
    SearchPricelistModal,
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        organization: JSON.parse(JSON.stringify(this.organization)) as OrganizationEntity,
        information: JSON.parse(JSON.stringify(this.information)) as OrganizationInformationEntity,

        datatable: {
          url: '/pricelists',
          fields: [
            {
              name: 'description',
              title: 'Description',
              sortField: 'description',
              titleClass: 'w-200px',
              dataClass: 'w-200px',
            },
            {
              name: 'items',
              title: 'Items',
              titleClass: 'w-200px',
              dataClass: 'w-200px',
              formatter: (value: PricelistEntity['items']) => `${value.length} items`,
            },
            {
              name: 'actions',
              title: 'Actions',
              titleClass: 'w-125px',
              dataClass: 'w-125px',
            },
          ],

          filters: {},
          sort: [{ field: 'description', direction: 'asc' }],

          fetch: DatatableUtil.fetch(Core),
        } as DatatableConfig,
      },
    };
  },

  beforeMount() {
    this.$set(this.metadata, 'dto', {
      ...this.metadata.organization,
      information: this.metadata.information,
    });

    this.setDatatableFilters();
  },

  methods: {
    setDatatableFilters() {
      this.$set(this.metadata.datatable, 'filters', {
        '_meta.guid': this.metadata.information.pricelists.join(','),
      });
    },

    onAssignPricelist(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      Core.Eventhub.emit(SEARCH_PRICELIST_MODAL_OPEN, {
        title: 'Assign pricelist',
        requestOptions: {
          params: {
            'filter[_meta.guid|neq]': vm.metadata.information.pricelists.join(','),
          },
        },
        async onSubmit(pricelist) {
          try {
            const mutations: Array<Mutation<OrganizationInformationEntity>> = [
              { action: 'array:push_unique', field: 'pricelists', value: pricelist._meta.guid },
            ];

            const response = await Core.getAdapter().patch<OrganizationInformationEntity>(
              `/organizations/${vm.organization._meta.guid}/information`,
              mutations,
            );

            vm.$emit('update:information', response.data);
            vm.$set(vm.metadata, 'information', JSON.parse(JSON.stringify(response.data)));

            vm.setDatatableFilters();
            await vm.$nextTick();
            (vm.$refs.datatable as DatatableInstance).reload();
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }
        },
      } as SearchPricelistModalOptions);
    },
    onUnassignPricelist(entity: PricelistEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Unassign pricelist',
        message: `Are your sure that you want to unassign the pricelist <strong>${entity.description}</strong>?<br />Please fill in the name to confirm.`,
        verifyString: entity.description,
        buttonText: 'Unassign',
        variant: 'warning',
        async onSubmit() {
          try {
            const mutations: Array<Mutation<OrganizationInformationEntity>> = [
              { action: 'array:remove', field: 'pricelists', value: entity._meta.guid },
            ];

            const response = await Core.getAdapter().patch<OrganizationInformationEntity>(
              `/organizations/${vm.metadata.organization._meta.guid}/information`,
              mutations,
            );

            vm.$emit('update:information', response.data);
            vm.$set(vm.metadata, 'information', JSON.parse(JSON.stringify(response.data)));

            vm.setDatatableFilters();
            await vm.$nextTick();
            (vm.$refs.datatable as DatatableInstance).reload();
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }
        },
      });
    },
  },
});
