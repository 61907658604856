
import Vue, { VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { ValidatorUtil } from '@vedicium/vue-core';

import { StateFormMixin } from '../mixins';
import { StateFormInstance } from '../interfaces';
import { Core } from '../../../../../../services/core';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & StateFormInstance>).extend({
  name: 'schedulingProductionStatusFormComponent',
  mixins: [StateFormMixin],
  data() {
    return {
      isLoading: false,

      metadata: {
        form: {
          sales_order_number: undefined as string | undefined,
        },
      },
    };
  },
  computed: {
    humanReadableState(): string {
      return (
        this.$tu(`sunbeds.orders.states.${SunbedOrderState.SCHEDULING_PRODUCTION}`)?.toString() ||
        SunbedOrderState.SCHEDULING_PRODUCTION
      );
    },
  },
  validations: {
    metadata: {
      form: {
        sales_order_number: {
          required,
        },
      },
    },
  },

  beforeMount() {
    this.$set(this.metadata, 'form', {
      sales_order_number: this.order.sales_order_number || undefined,
    });
  },
  mounted() {
    setTimeout(() => (this.$refs['input:sales_order_number'] as HTMLInputElement).focus(), 100);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<SunbedOrderEntity> {
      this.$set(this, 'isLoading', true);
      try {
        // Validate form
        await this.validate();

        // Update state
        const response = await Core.getAdapter().put<SunbedOrderEntity>(
          `/sunbeds/orders/${this.order._meta.guid}/state`,
          {
            state: SunbedOrderState.SCHEDULING_PRODUCTION,
            sales_order_number: this.metadata.form.sales_order_number,
          },
        );

        return response.data;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
