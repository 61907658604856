import { RouterUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router';
import { getAuthenticationRoute } from './utils';
import { Core } from '../services/core';
import {
  AuthenticationCallbackView,
  AuthenticationView,
  OrganizationInviteView,
} from '../views/standalone';
import { PortalLayout } from '../components/layout';
import {
  ConfigurationsCreateView,
  ConfigurationsOverviewView,
  ConfigurationView,
  ConfigurationViewInformation,
  ConfigurationViewTypesCreate,
  ConfigurationViewTypesOverview,
  ConfigurationViewTypesView,
  ConfigurationViewTypesViewCompatibility,
  ConfigurationViewTypesViewInformation,
  ConfigurationViewTypesViewRatedVoltages,
  ConfigurationViewTypesViewUVConfigurations,
  OrdersConfiguratorView,
  OrdersOverviewView,
  OrdersView,
  OrdersViewGeneral,
  OrdersViewConfiguration,
  OrganizationsCreateView,
  OrganizationsOverviewView,
  OrganizationsView,
  OrganizationsViewInformation,
  OrganizationsViewPreferences,
  OrganizationsViewPricelists,
  PartsColorsCreate,
  PartsColorsOverview,
  PartsColorsView,
  PartsColorsViewCompatibility,
  PartsColorsViewInformation,
  PartsFiltersCreate,
  PartsFiltersOverview,
  PartsFiltersView,
  PartsFiltersViewInformation,
  PartsLightSourcesCreate,
  PartsLightSourcesOverview,
  PartsLightSourcesView,
  PartsLightSourcesViewInformation,
  PartsOptionsCreate,
  PartsOptionsOverview,
  PartsOptionsView,
  PartsOptionsViewCompatibility,
  PartsOptionsViewInformation,
  PartsOptionsViewRatedVoltages,
  PartsPackagesCreate,
  PartsPackagesOverview,
  PartsPackagesView,
  PartsPackagesViewInformation,
  PartsPackagesViewOptions,
  PartsView,
  PricelistsCreateView,
  PricelistsOverview,
  PricelistsView,
  PricelistsViewInformation,
  PricelistsViewItems,
  UsersCreateView,
  UsersOverviewView,
  OrdersViewProduction,
  OrdersViewIdentification,
  ConfigurationViewTypesViewSpecialPreferences,
  ConfigurationViewTypesViewCompatibilityColors,
  ConfigurationViewTypesViewCompatibilityPackages,
  ConfigurationViewTypesViewCompatibilityOptions,
  PartsOptionsViewManufacturing,
  PartsColorsViewManufacturing,
  PartsFiltersViewManufacturing,
  OrdersViewFinancial,
  ConfigurationViewShipping,
  ConfigurationViewProduction,
} from '../views/authenticated';

const routes: Array<RouteConfig> = [
  /**
   * begin::Authenticated
   */
  {
    path: '/',
    redirect: '/orders',
    component: PortalLayout,
    children: [
      /**
       * begin::Orders
       */
      {
        path: '/orders',
        name: '/orders',
        redirect: '/orders/overview',
      },
      {
        path: '/orders/overview',
        name: '/orders/overview',
        component: OrdersOverviewView,
      },
      {
        path: '/orders/create',
        name: '/orders/create',
        component: OrdersConfiguratorView,
        props: {
          action: 'CREATE',
        },
      },
      {
        path: '/orders/update/:guid',
        name: '/orders/update/:guid',
        component: OrdersConfiguratorView,
        props: {
          action: 'UPDATE',
        },
      },
      {
        path: '/orders/view/:guid',
        name: '/orders/view/:guid',
        component: OrdersView,
        redirect: '/orders/view/:guid/general',
        children: [
          {
            path: '/orders/view/:guid/general',
            name: '/orders/view/:guid/general',
            component: OrdersViewGeneral,
          },
          {
            path: '/orders/view/:guid/configuration',
            name: '/orders/view/:guid/configuration',
            component: OrdersViewConfiguration,
          },
          {
            path: '/orders/view/:guid/production',
            name: '/orders/view/:guid/production',
            component: OrdersViewProduction,
          },
          {
            path: '/orders/view/:guid/financial',
            name: '/orders/view/:guid/financial',
            component: OrdersViewFinancial,
          },
          {
            path: '/orders/view/:guid/identification',
            name: '/orders/view/:guid/identification',
            component: OrdersViewIdentification,
          },
        ],
      },

      /**
       * begin::Configurations
       */
      {
        path: '/configurations',
        name: '/configurations',
        redirect: '/configurations/overview',
      },
      {
        path: '/configurations/overview',
        name: '/configurations/overview',
        component: ConfigurationsOverviewView,
      },
      {
        path: '/configurations/create',
        name: '/configurations/create',
        component: ConfigurationsCreateView,
      },
      {
        path: '/configurations/view/:guid',
        name: '/configurations/view/:guid',
        redirect: '/configurations/view/:guid/types',
        component: ConfigurationView,
        children: [
          {
            path: '/configurations/view/:guid/types',
            name: '/configurations/view/:guid/types',
            component: ConfigurationViewTypesOverview,
          },
          {
            path: '/configurations/view/:guid/information',
            name: '/configurations/view/:guid/information',
            component: ConfigurationViewInformation,
          },
          {
            path: '/configurations/view/:guid/shipping',
            name: '/configurations/view/:guid/shipping',
            component: ConfigurationViewShipping,
          },
          {
            path: '/configurations/view/:guid/production',
            name: '/configurations/view/:guid/production',
            component: ConfigurationViewProduction,
          },
        ],
      },
      {
        path: '/configurations/view/:guid/types/create',
        name: '/configurations/view/:guid/types/create',
        component: ConfigurationViewTypesCreate,
      },
      {
        path: '/configurations/view/:configuration/types/:guid',
        name: '/configurations/view/:configuration/types/:guid',
        redirect: '/configurations/view/:configuration/types/:guid/information',
        component: ConfigurationViewTypesView,
        children: [
          {
            path: '/configurations/view/:configuration/types/:guid/information',
            name: '/configurations/view/:configuration/types/:guid/information',
            component: ConfigurationViewTypesViewInformation,
          },
          {
            path: '/configurations/view/:configuration/types/:guid/rated-voltages',
            name: '/configurations/view/:configuration/types/:guid/rated-voltages',
            component: ConfigurationViewTypesViewRatedVoltages,
          },
          {
            path: '/configurations/view/:configuration/types/:guid/uv-configurations',
            name: '/configurations/view/:configuration/types/:guid/uv-configurations',
            component: ConfigurationViewTypesViewUVConfigurations,
          },
          {
            path: '/configurations/view/:configuration/types/:guid/compatibility',
            name: '/configurations/view/:configuration/types/:guid/compatibility',
            redirect: '/configurations/view/:configuration/types/:guid/compatibility/colors',
            component: ConfigurationViewTypesViewCompatibility,
            children: [
              {
                path: '/configurations/view/:configuration/types/:guid/compatibility/colors',
                name: '/configurations/view/:configuration/types/:guid/compatibility/colors',
                component: ConfigurationViewTypesViewCompatibilityColors,
              },
              {
                path: '/configurations/view/:configuration/types/:guid/compatibility/packages',
                name: '/configurations/view/:configuration/types/:guid/compatibility/packages',
                component: ConfigurationViewTypesViewCompatibilityPackages,
              },
              {
                path: '/configurations/view/:configuration/types/:guid/compatibility/options',
                name: '/configurations/view/:configuration/types/:guid/compatibility/options',
                component: ConfigurationViewTypesViewCompatibilityOptions,
              },
            ],
          },
          {
            path: '/configurations/view/:configuration/types/:guid/special-preferences',
            name: '/configurations/view/:configuration/types/:guid/special-preferences',
            component: ConfigurationViewTypesViewSpecialPreferences,
          },
        ],
      },
      /**
       * end::Configurations
       */

      /**
       * begin::Parts
       */
      {
        path: '/parts',
        name: '/parts',
        redirect: '/parts/light-sources',
        component: PartsView,
        children: [
          {
            path: '/parts/light-sources',
            name: '/parts/light-sources',
            component: PartsLightSourcesOverview,
          },
          {
            path: '/parts/filters',
            name: '/parts/filters',
            component: PartsFiltersOverview,
          },
          {
            path: '/parts/colors',
            name: '/parts/colors',
            component: PartsColorsOverview,
          },
          {
            path: '/parts/options',
            name: '/parts/options',
            component: PartsOptionsOverview,
          },
          {
            path: '/parts/packages',
            name: '/parts/packages',
            component: PartsPackagesOverview,
          },
        ],
      },
      {
        path: '/parts/light-sources/create',
        name: '/parts/light-sources/create',
        component: PartsLightSourcesCreate,
      },
      {
        path: '/parts/light-sources/view/:guid',
        name: '/parts/light-sources/view/:guid',
        redirect: '/parts/light-sources/view/:guid/information',
        component: PartsLightSourcesView,
        children: [
          {
            path: '/parts/light-sources/view/:guid/information',
            name: '/parts/light-sources/view/:guid/information',
            component: PartsLightSourcesViewInformation,
          },
        ],
      },
      {
        path: '/parts/filters/create',
        name: '/parts/filters/create',
        component: PartsFiltersCreate,
      },
      {
        path: '/parts/filters/view/:guid',
        name: '/parts/filters/view/:guid',
        redirect: '/parts/filters/view/:guid/information',
        component: PartsFiltersView,
        children: [
          {
            path: '/parts/filters/view/:guid/information',
            name: '/parts/filters/view/:guid/information',
            component: PartsFiltersViewInformation,
          },
          {
            path: '/parts/filters/view/:guid/manufacturing',
            name: '/parts/filters/view/:guid/manufacturing',
            component: PartsFiltersViewManufacturing,
          },
        ],
      },
      {
        path: '/parts/colors/create',
        name: '/parts/colors/create',
        component: PartsColorsCreate,
      },
      {
        path: '/parts/colors/view/:guid',
        name: '/parts/colors/view/:guid',
        redirect: '/parts/colors/view/:guid/information',
        component: PartsColorsView,
        children: [
          {
            path: '/parts/colors/view/:guid/information',
            name: '/parts/colors/view/:guid/information',
            component: PartsColorsViewInformation,
          },
          {
            path: '/parts/colors/view/:guid/compatibility',
            name: '/parts/colors/view/:guid/compatibility',
            component: PartsColorsViewCompatibility,
          },
          {
            path: '/parts/colors/view/:guid/manufacturing',
            name: '/parts/colors/view/:guid/manufacturing',
            component: PartsColorsViewManufacturing,
          },
        ],
      },
      {
        path: '/parts/options/create',
        name: '/parts/options/create',
        component: PartsOptionsCreate,
      },
      {
        path: '/parts/options/view/:guid',
        name: '/parts/options/view/:guid',
        redirect: '/parts/options/view/:guid/information',
        component: PartsOptionsView,
        children: [
          {
            path: '/parts/options/view/:guid/information',
            name: '/parts/options/view/:guid/information',
            component: PartsOptionsViewInformation,
          },
          {
            path: '/parts/options/view/:guid/rated-voltages',
            name: '/parts/options/view/:guid/rated-voltages',
            component: PartsOptionsViewRatedVoltages,
          },
          {
            path: '/parts/options/view/:guid/compatibility',
            name: '/parts/options/view/:guid/compatibility',
            component: PartsOptionsViewCompatibility,
          },
          {
            path: '/parts/options/view/:guid/manufacturing',
            name: '/parts/options/view/:guid/manufacturing',
            component: PartsOptionsViewManufacturing,
          },
        ],
      },
      {
        path: '/parts/packages/create',
        name: '/parts/packages/create',
        component: PartsPackagesCreate,
      },
      {
        path: '/parts/packages/view/:guid',
        name: '/parts/packages/view/:guid',
        redirect: '/parts/packages/view/:guid/information',
        component: PartsPackagesView,
        children: [
          {
            path: '/parts/packages/view/:guid/information',
            name: '/parts/packages/view/:guid/information',
            component: PartsPackagesViewInformation,
          },
          {
            path: '/parts/packages/view/:guid/options',
            name: '/parts/packages/view/:guid/options',
            component: PartsPackagesViewOptions,
          },
        ],
      },
      /**
       * end::Parts
       */

      /**
       * begin::Pricelists
       */
      {
        path: '/pricelists',
        name: '/pricelists',
        redirect: '/pricelists/overview',
      },
      {
        path: '/pricelists/overview',
        name: '/pricelists/overview',
        component: PricelistsOverview,
      },
      {
        path: '/pricelists/create',
        name: '/pricelists/create',
        component: PricelistsCreateView,
      },
      {
        path: '/pricelists/view/:guid',
        name: '/pricelists/view/:guid',
        redirect: '/pricelists/view/:guid/information',
        component: PricelistsView,
        children: [
          {
            path: '/pricelists/view/:guid/information',
            name: '/pricelists/view/:guid/information',
            component: PricelistsViewInformation,
          },
          {
            path: '/pricelists/view/:guid/items',
            name: '/pricelists/view/:guid/items',
            component: PricelistsViewItems,
          },
        ],
      },
      /**
       * end::Pricelists
       */

      /**
       * begin::Organizations
       */
      {
        path: '/organizations',
        name: '/organizations',
        redirect: '/organizations/overview',
      },
      {
        path: '/organizations/overview',
        name: '/organizations/overview',
        component: OrganizationsOverviewView,
      },
      {
        path: '/organizations/create',
        name: '/organizations/create',
        component: OrganizationsCreateView,
      },
      {
        path: '/organizations/view/:guid',
        name: '/organizations/view/:guid',
        redirect: '/organizations/view/:guid/information',
        component: OrganizationsView,
        children: [
          {
            path: '/organizations/view/:guid/information',
            name: '/organizations/view/:guid/information',
            component: OrganizationsViewInformation,
          },
          {
            path: '/organizations/view/:guid/preferences',
            name: '/organizations/view/:guid/preferences',
            component: OrganizationsViewPreferences,
          },
          {
            path: '/organizations/view/:guid/pricelists',
            name: '/organizations/view/:guid/pricelists',
            component: OrganizationsViewPricelists,
          },
        ],
      },
      /**
       * end::Organizations
       */

      /**
       * begin::Users
       */
      {
        path: '/users',
        name: '/users',
        redirect: '/users/overview',
      },
      {
        path: '/users/overview',
        name: '/users/overview',
        component: UsersOverviewView,
      },
      {
        path: '/users/create',
        name: '/users/create',
        component: UsersCreateView,
      },
      /**
       * end::Users
       */
    ],
  },
  /**
   * end::Authenticated
   */

  /**
   * begin::Public
   */
  {
    path: '/authentication',
    component: AuthenticationView,
    meta: {
      public: true,
    },
  },
  {
    path: '/authentication/callback',
    component: AuthenticationCallbackView,
    meta: {
      public: true,
    },
  },
  {
    path: '/invite',
    component: OrganizationInviteView,
    meta: {
      public: true,
    },
  },
  /**
   * end::Public
   */
];

export function Router(options?: RouterOptions): VueRouter {
  Vue.use(VueRouter);

  const router = new VueRouter({
    ...(options || {}),
    routes,
  });

  // Add Authentication Guard
  router.beforeEach(
    RouterUtil.AuthenticationGuard({
      authenticationRoute: getAuthenticationRoute(),
      isAuthenticated(): boolean {
        return Core.Authentication.isAuthenticatedSync();
      },
    }),
  );

  return router;
}
