
import Vue from 'vue';
import { DatatableConfig, DatatableMixin, DatatableInstance } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { BDropdown } from 'bootstrap-vue';

import { Core } from '../../../services/core';
import { PricelistEntity, PricelistsStore } from '../../../services/pricelists';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../components/layout/portal/modals';
import { APP_ERROR_EVENT } from '../../../constants';

export default Vue.extend({
  name: 'pricelistsOverview',
  mixins: [DatatableMixin],
  data() {
    return {
      datatable: {
        url: '/pricelists',
        fields: [
          {
            name: 'description',
            title: 'Description',
            sortField: 'description',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'items',
            title: 'No. of items',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-125px',
            dataClass: 'w-125px',
          },
        ],

        filters: {},
        sort: [{ field: 'description', direction: 'asc' }],
        searchFields: ['description'],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Pricelists' }]);
  },

  methods: {
    async exportPricelistItems(pricelist: PricelistEntity): Promise<void> {
      (this.$refs[`actions-dropdown:${pricelist._meta.guid}`] as BDropdown).hide();

      (this.$refs.datatable as DatatableInstance).setLoading(true);
      try {
        await Core.getAdapter('http').get(`/pricelists/${pricelist._meta.guid}/items/export`, {
          responseType: 'blob',
        });
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        (this.$refs.datatable as DatatableInstance).setLoading(false);
      }
    },
    removePricelist(pricelist: PricelistEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: `Remove pricelist`,
        message: `Are you sure that you want to delete <strong>${pricelist.description}</strong>?<br /> Please fill out the description of the pricelist to confirm.`,
        variant: 'danger',
        verifyString: pricelist.description,
        buttonText: 'Confirm',
        async onSubmit() {
          try {
            await PricelistsStore.delete(pricelist._meta.guid, { persistent: true });
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }

          (vm.$refs.datatable as DatatableInstance).refresh();
        },
      });
    },
  },
});
