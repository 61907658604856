
import { MutationsService } from '@vedicium/object-mutations';
import { OrganizationEntity, ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  OrganizationEntityDto,
  OrganizationInformationEntity,
} from '../../../../services/organizations';
import { OrganizationSpecificationsForm } from '../components';

export default Vue.extend({
  name: 'organizationsViewInformation',
  props: {
    organization: {
      type: Object as PropType<OrganizationEntity>,
    },
    information: {
      type: Object as PropType<OrganizationInformationEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        organization: JSON.parse(JSON.stringify(this.organization)) as OrganizationEntity,
        information: JSON.parse(JSON.stringify(this.information)) as OrganizationInformationEntity,

        dto: undefined as OrganizationEntityDto | undefined,
      },
    };
  },
  components: {
    OrganizationSpecificationsForm,
  },

  beforeMount() {
    this.$set(this.metadata, 'dto', {
      ...this.metadata.organization,
      information: this.metadata.information,
    });
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([(this.$refs.specificationsForm as Vue).$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const organizationMutations = MutationsService.getMutationsByComparison(
          this.organization,
          this.metadata.dto as unknown as OrganizationEntity,
          ['name'],
        );

        if (organizationMutations.length > 0) {
          const organizationResponse = await Core.getAdapter().patch(
            `/organizations/${this.organization._meta.guid}`,
            organizationMutations,
          );

          this.$emit('update:organization', organizationResponse.data);
          this.$set(
            this.metadata,
            'organization',
            JSON.parse(JSON.stringify(organizationResponse.data)),
          );
        }

        const informationMutations = MutationsService.getMutationsByComparison(
          this.information,
          this.metadata.dto?.information as unknown as OrganizationInformationEntity,
          ['organization_id', 'administration_email'],
        );

        if (informationMutations.length > 0) {
          const informationResponse = await Core.getAdapter().patch(
            `/organizations/${this.organization._meta.guid}/information`,
            informationMutations,
          );

          this.$emit('update:information', informationResponse.data);
          this.$set(
            this.metadata,
            'information',
            JSON.parse(JSON.stringify(informationResponse.data)),
          );
        }

        // Create new DTO
        this.$set(this.metadata, 'dto', {
          ...this.metadata.organization,
          information: this.metadata.information,
        });

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
