
import { OrganizationEntity, StoreRequestOptions } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import VueSelect, { VueSelectInstance } from 'vue-select';
import { OrganizationsStore } from '../../../../services/organizations';

export default Vue.extend({
  name: 'OrganizationVueSelect',
  components: {
    VueSelect,
  },
  props: {
    value: {
      type: Object as PropType<OrganizationEntity | undefined>,
      default: undefined,
    },
    searchFields: {
      type: Array as PropType<Array<string>>,
      default: () => ['name'],
    },
    onSearchError: {
      type: Function as PropType<(error?: Error | undefined) => Promise<void> | void | undefined>,
      default: undefined,
    },
    loadOnMounted: {
      type: Boolean,
      default: true,
    },
    requestOptions: {
      type: Object as PropType<StoreRequestOptions>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      metadata: {
        options: [] as Array<OrganizationEntity>,
        timeout: undefined as number | undefined,
      },
    };
  },
  computed: {
    input(): VueSelectInstance {
      return this.$refs.input as VueSelectInstance;
    },
  },

  mounted() {
    if (this.disabled === false && this.loadOnMounted === true) {
      this.onSearch(undefined, { allow_empty_query: true, timeout: 1 });
    }
  },

  methods: {
    async onInput(entity: OrganizationEntity | null): Promise<void> {
      this.$emit('input', entity || undefined);

      // Wait for next tick
      await this.$nextTick();

      // Stop search after input is set
      this.input.toggleLoading(false);
      clearTimeout(this.metadata.timeout);

      // Search again when input is set to 'null'
      if (entity === null) {
        this.onSearch(undefined, { allow_empty_query: true, timeout: 1 });
      }
    },
    onSearch(query?: string, options?: { allow_empty_query?: boolean; timeout?: number }): void {
      clearTimeout(this.metadata.timeout);

      // Stop searching when query is empty
      if (options?.allow_empty_query !== true && !query) {
        this.input.toggleLoading(false);
        return;
      }

      this.input.toggleLoading(true);
      this.metadata.timeout = setTimeout(async () => {
        this.$set(this.metadata, 'options', []);
        try {
          const organizations = await OrganizationsStore.search({
            ...(this.requestOptions || {}),
            params: {
              ...(this.requestOptions?.params || {}),

              limit: 10,
              search_fields: this.searchFields.join(','),
              search: (query || '')
                .split(' ')
                .map((row) => `*${row}*`, [])
                .join(' '),
            },
          });

          this.$set(this.metadata, 'options', organizations);
        } catch (e) {
          console.error(e);
          if (typeof this.onSearchError === 'function') {
            await this.onSearchError(e as Error);
          }
        } finally {
          this.input.toggleLoading(false);
        }
      }, options?.timeout || 600);
    },

    focus(): void {
      (this.input.$refs.search as HTMLInputElement).focus();
    },
  },
});
