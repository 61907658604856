
import Vue, { PropType } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';

import { PricelistDto } from '../../../../services/pricelists';

export default Vue.extend({
  name: 'pricelistsInformationForm',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<PricelistDto>,
    },
  },
  validations: {
    document: {
      description: {
        required,
      },
    },
  },
});
