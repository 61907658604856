
import Vue, { PropType, VueConstructor } from 'vue';
import { DatatableConfig, DatatableInstance, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import { MutationsService } from '@vedicium/object-mutations';
import {
  getRatedVoltages,
  SunbedModelTypeDto,
  SunbedModelTypeRatedVoltage,
  SunbedModelTypeRatedVoltageDto,
  SunbedRatedVoltageOption,
  SunbedVoltage,
} from '../../../../../../../services/sunbeds';
import { APP_ERROR_EVENT } from '../../../../../../../constants';
import { Core } from '../../../../../../../services/core';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  mixins: [DatatableMixin, FormMixin],
  props: {
    document: {
      type: Object as PropType<SunbedModelTypeDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'rated_voltage',
            title: 'Rated voltage',
          },
          {
            name: 'power:230V',
            title: 'Power 230V',
            titleClass: 'w-250px',
            dataClass: 'w-250px',
          },
          {
            name: 'power:400V',
            title: 'Power 400V',
            titleClass: 'w-250px',
            dataClass: 'w-250px',
          },
          {
            name: 'article_number',
            title: 'Article number',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
        ],
      } as DatatableConfig,

      metadata: {
        voltages: ['230V', '400V'] as Array<SunbedVoltage>,
        rated_voltages: [] as Array<SunbedRatedVoltageOption>,
      },
    };
  },

  async mounted() {
    (this.$refs.datatable as DatatableInstance).setLoading(true);
    try {
      await this.applyRatedVoltages();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
      return;
    }

    await this.$nextTick();

    (this.$refs.datatable as DatatableInstance).setLoading(false);
  },

  methods: {
    getWattageOfVoltage(
      rated_voltage: SunbedModelTypeRatedVoltage,
      voltage: SunbedVoltage,
    ): number | undefined {
      return rated_voltage.power_consumption.find((row) => row.voltage === voltage)?.wattage;
    },
    hasVoltage(
      rated_voltage: SunbedRatedVoltageOption['rated_voltage'],
      voltage: SunbedVoltage,
    ): boolean {
      return (
        this.metadata.rated_voltages
          .find((row) => row.rated_voltage === rated_voltage)
          ?.voltages.includes(voltage) === true
      );
    },

    onPowerChange(
      rated_voltage: SunbedRatedVoltageOption['rated_voltage'],
      voltage: SunbedVoltage,
      value: string,
    ): void {
      const powerValue = Number.isFinite(parseInt(value, 10)) ? parseInt(value, 10) : undefined;

      this.document.rated_voltages = this.document.rated_voltages.map((row) => {
        // Change rated voltage
        if (row.rated_voltage === rated_voltage) {
          return MutationsService.apply(row, [
            {
              action: 'array:objects:set_field',
              field: 'power_consumption',
              query: {
                filter: 'eq',
                field: 'voltage',
                value: voltage,
              },
              mutation: {
                field: 'wattage',
                value: powerValue,
              },
            },
          ]);
        }

        return row;
      }, []);
    },

    async applyRatedVoltages(): Promise<void> {
      const ratedVoltages = await getRatedVoltages();

      this.$set(this.metadata, 'rated_voltages', ratedVoltages);

      this.metadata.rated_voltages.forEach((voltage) => {
        // Don't add the rated voltage when it already exists
        if (
          this.document.rated_voltages.some(
            (row) => row.rated_voltage === voltage.rated_voltage,
          ) === true
        ) {
          return;
        }

        this.document.rated_voltages.push(
          new SunbedModelTypeRatedVoltageDto({
            rated_voltage: voltage.rated_voltage,
            power_consumption: voltage.voltages.map((row) => ({
              voltage: row,
              wattage: undefined,
            })),
          }),
        );
      });
    },
  },
});
