
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { Validation } from 'vuelidate';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import { ColorPartDto, ColorPartsStore } from '../../../../services/parts';

import {
  PartsColorsInformationForm,
  PartsColorsCompatibilityForm,
  PartsColorsSublinesForm,
} from './components';

export default Vue.extend({
  name: 'partsColorsCreate',
  mixins: [Mixins.KTWizardV4],
  components: {
    PartsColorsInformationForm,
    PartsColorsCompatibilityForm,
    PartsColorsSublinesForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      color: new ColorPartDto(),

      metadata: {
        wizard: {
          index: 0,
        },
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Colors', route: '/parts/colors' },
      { title: 'Create color' },
    ]);
  },
  async mounted() {
    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    onAddSubline(): void {
      (this.$refs.sublinesForm as any).onAddSubline();
    },

    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();
        await ColorPartsStore.create(JSON.parse(JSON.stringify(this.color)), { persistent: true });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push('/parts/colors');
    },

    async onWizardStepChange(step: number, oldStep: number) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number) {
      const validationArray: Array<Validation> = [];

      // Add validators based by step
      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      if (step === undefined || step === 1) {
        validationArray.push((this.$refs.compatibilityForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray.filter((row) => row !== undefined, []));
    },
  },
});
