
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { OrganizationInviteDto, ValidatorUtil } from '@vedicium/vue-core';
import { Validation } from 'vuelidate';
import { Core } from '../../../services/core';

import specificationsForm from './components/specifications.form.vue';
import permissionGroup from './components/permission-group.form.vue';

export default Vue.extend({
  mixins: [Mixins.KTWizardV4],
  components: {
    specificationsForm,
    permissionGroup,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      wizardIndex: 0,

      user: new OrganizationInviteDto(),
    };
  },
  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Users', route: '/users/overview' },
      { title: 'Add user' },
    ]);

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async onWizardStepChange(step: number, oldStep: number) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number) {
      const validationArray: Array<Validation> = [];

      // Add validators based by step
      if (step === undefined || step === 0) {
        validationArray.push(
          (this.$refs.specificationsForm as Vue).$v,
          (this.$refs.permissionGroup as Vue).$v,
        );
      }

      await ValidatorUtil.validateArray(validationArray);
    },
    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();
        await Core.Organizations.sendOrganizationInvite(this.user, {
          params: {
            persistent: true,
            wait_for_afterhook: true,
          },
        });
      } catch (e) {
        console.error(e);
        // this.$errors.handle(e, { component: this });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push('/users/overview');
    },
  },
});
