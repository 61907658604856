
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { PublicLayout } from '@/components/layout';
import { AuthenticationUtil } from '../../utils';

import { Core } from '../../services/core';
import { parseOrganizationInviteState } from '../../services/organizations';
import { auth0 } from '../../services/auth0';

export default Vue.extend({
  data() {
    return {
      isMounted: false,
    };
  },
  components: {
    PublicLayout,
  },

  async mounted() {
    const invite = this.$route.query?.token?.toString();
    const redirectUri = this.getRedirectUri(invite);

    // Parse state, if defined
    const state =
      (this.$route.query?.state &&
        parseOrganizationInviteState(this.$route.query?.state?.toString())) ||
      undefined;

    // If not authenticated, redirect to authentication route
    if ((await Core.Authentication.isAuthenticated()) === false) {
      await auth0.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
          login_hint: this.$route.query?.email?.toString(),
          screen_hint: state?.action === 'signup' ? 'signup' : undefined,
        },
        appState: AuthenticationUtil.stringifyState({ uri: redirectUri }),
      });

      return;
    }

    // If authenticated, redirect to default route
    this.$router.push(redirectUri);
  },

  methods: {
    getRedirectUri(token: string): RawLocation {
      return `/?invite=${token}`;
    },
  },
});
