
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { Validation } from 'vuelidate';
import { ValidatorUtil } from '@vedicium/vue-core';
import { SunbedModelDto, SunbedModelEntity, SunbedModelsStore } from '../../../services/sunbeds';

import { SunbedModelInformationForm, SunbedModelShippingBoxesForm } from './components';

export default Vue.extend({
  mixins: [Mixins.KTWizardV4],
  components: {
    SunbedModelInformationForm,
    SunbedModelShippingBoxesForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      wizardIndex: 0,

      model: new SunbedModelDto(),
    };
  },

  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Configurations', route: '/configurations/overview' },
      { title: 'Add configuration' },
    ]);

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy(): void {
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    onAddShippingBoxClick(): void {
      (this.$refs['shippingBoxesForm'] as any).onAddShippingBox();
    },

    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      let model: SunbedModelEntity;
      try {
        await this.validate();
        model = await SunbedModelsStore.create(JSON.parse(JSON.stringify(this.model)), {
          persistent: true,
        });
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push(`/configurations/view/${model._meta.guid}`);
    },

    async onWizardStepChange(step: number, oldStep: number): Promise<void> {
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number): Promise<void> {
      const validationArray: Array<Validation> = [];

      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray);
    },
  },
});
