import { render, staticRenderFns } from "./modification-in-process-status-form.component.vue?vue&type=template&id=59502b96&"
import script from "./modification-in-process-status-form.component.vue?vue&type=script&lang=ts&"
export * from "./modification-in-process-status-form.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports