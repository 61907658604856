import { OrganizationEntity } from '@vedicium/vue-core';
import { Core } from '../core';
import { OrganizationInformation } from './interfaces';

export class OrganizationEntityDto {
  name: string | undefined = undefined;
  type: string | undefined = undefined;

  information: OrganizationInformation = {
    organization_id: '',
    administration_email: '',

    preferences: {
      country: '',
      language: '',
      smartvoice_language: 0,
      rated_voltage: '',
      uv_type: '',
      tanning_intensity: undefined,
      warning_text: undefined,
    },

    settings: {
      prohibit_uv_type_5: false,
      regular_products_available: true,
    },

    pricelists: [],
  };

  location: OrganizationEntity['location'] = undefined;
}

export const OrganizationsStore = Core.Stores.create<OrganizationEntity, OrganizationEntityDto>(
  '/organizations',
);
