
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import { requiredIf } from 'vuelidate/lib/validators';
import {
  SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN,
  SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_CLOSE,
} from './constants';
import { SunbedOrderGenerateSerialNumberModalOptions } from './interfaces';

export default Vue.extend({
  name: 'sunbedOrderGenerateSerialNumber',
  mixins: [Mixins.Modal],
  data() {
    return {
      isLoading: false,
      isDisabled: false,

      options: undefined as SunbedOrderGenerateSerialNumberModalOptions | undefined,

      metadata: {
        serial_numbers: [] as Array<{ generate: boolean; serial_number: string | undefined }>,
      },
    };
  },
  validations: {
    metadata: {
      serial_numbers: {
        $each: {
          serial_number: {
            required: requiredIf((row) => row.generate === false),
          },
        },
      },
    },
  },

  mounted() {
    this.$eventhub.on(SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN, this.open);
    this.$eventhub.on(SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN, this.open);
    this.$eventhub.off(SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },

    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        // Validate form
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(
            this.metadata.serial_numbers
              .map((row) => (row.generate === false ? row.serial_number : undefined), [])
              .filter((row) => row !== undefined, []) as Array<string>,
          );
        }
      } catch (e) {
        console.error(e);
        this.options?.onError?.(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    async open(options?: SunbedOrderGenerateSerialNumberModalOptions): Promise<void> {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', options || undefined);

      this.$set(
        this.metadata,
        'serial_numbers',
        Array.from({ length: options?.amount || 0 }).map((_, idx) => {
          const currentSerial = options?.serial_numbers?.[idx];
          return {
            generate: currentSerial !== undefined ? false : true,
            serial_number: currentSerial || undefined,
          };
        }, []),
      );

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();
    },
  },
});
