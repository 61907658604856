
import Vue from 'vue';
import { AuthenticationError, LogoutOptions } from '@auth0/auth0-spa-js';
import { Auth0AuthenticationModule } from '@vedicium/vue-core';
import { PublicLayout } from '@/components/layout';

import { AuthenticationUtil } from '../../utils';
import { Core } from '../../services/core';
import { auth0 } from '../../services/auth0';

export default Vue.extend({
  data() {
    return {
      isMounted: false,

      isLogout: this.$route.query.logout !== undefined,

      error: undefined as AuthenticationError | undefined,
    };
  },
  components: {
    PublicLayout,
  },

  async mounted() {
    try {
      // When it's a logout, handle the error directly
      if (this.isLogout === true) {
        throw new AuthenticationError(
          this.$route.query.error.toString(),
          this.$route.query.error_description.toString(),
          '',
        );
      }

      // Handle redirect callback
      const response = await auth0.handleRedirectCallback(window.location.href);
      const state = AuthenticationUtil.parseState(response.appState);

      await Core.Authentication.getModule<Auth0AuthenticationModule>().init();

      // Verify if authentication succeeded
      if ((await Core.Authentication.isAuthenticated()) === false) {
        await Core.Authentication.logout<LogoutOptions>({
          logoutParams: {
            returnTo: `http://localhost:8080/authentication/callback?error=invalid_user&error_description=${encodeURIComponent(
              'User is unknown or denied access',
            )}&logout`,
          },
        });
        return;
      }

      this.$router.push(state?.uri || '/');
    } catch (e) {
      if (e instanceof AuthenticationError) {
        this.$set(this, 'error', e);
        return;
      }

      console.error(e);
    }
  },
});
