import { Entity } from '@vedicium/vue-core';
import {
  ColorPartConfigurationID,
  FilterPartConfigurationID,
  OptionPartConfigurationID,
  OptionPartEntity,
  OptionPartPowerConsumption,
} from '../../parts';
import { SunbedVoltage } from './rated_voltages.interface';
import { SunbedConfigurationTypeOption } from './sunbed-configuration.interface';
import { SunbedModelConfigurationID } from './sunbed_models.interface';
import {
  SunbedModelTypeConfigurationID,
  SunbedModelTypeUVConfigurationMode,
} from './sunbed_model_types.interface';

export enum SunbedOrderState {
  REQUESTED = 'requested',
  SCHEDULING_PRODUCTION = 'scheduling_production',
  SCHEDULING_DELIVERY = 'scheduling_delivery',
  READY_FOR_PRODUCTION = 'ready_for_production',
  IN_PRODUCTION = 'in_production',
  READY_TO_SHIP = 'ready_to_ship',
  SHIPPED = 'shipped',
  MODIFICATION_IN_PROCESS = 'modification_in_process',
  CANCELLED = 'cancelled',
}

export interface SunbedConfiguration {
  /**
   * Article number of configuration.
   *
   * This comes from the Sunbed Model Type's rated voltage.
   */
  article_number: string;

  /**
   * Sunbed model.
   */
  model: {
    /**
     * Guid of sunbed model.
     */
    guid: string;

    /**
     * Name of sunbed model.
     *
     * **Note** This is copied upon creation / update.
     */
    name: string;

    /**
     * Unit type.
     *
     * **Note** This is copied upon creation / update.
     */
    unit_type: 'horizontal' | 'vertical';

    /**
     * Configuration ID.
     *
     * **Note** This is copied upon creation / update.
     */
    configuration_id: SunbedModelConfigurationID;

    /**
     * Acrylic specifications.
     *
     * **Note** This is copied upon creation / update.
     */
    acrylic: Array<{
      location: 'bench' | 'canopy';

      /**
       * Thickness is millimeters.
       */
      thickness: number;
    }>;
  };

  /**
   * Sunbed model type.
   */
  type: {
    /**
     * Guid of sunbed model type.
     */
    guid: string;

    /**
     * Name of sunbed model.
     *
     * **Note** This is copied upon creation / update.
     */
    name: string;

    /**
     * Whether the sunbed model type is a special.
     *
     * **Note** This is copied upon creation / update.
     */
    special: boolean;

    /**
     * Configuration ID of type.
     */
    configuration_id: SunbedModelTypeConfigurationID | undefined;
  };

  /**
   * Rated voltage specifications of sunbed.
   */
  rated_voltage: {
    /**
     * Rated voltage.
     */
    rated_voltage: string;

    /**
     * Power consumption of sunbed (in wattages).
     */
    power_consumption: Array<{
      voltage: SunbedVoltage;
      wattage: number;
    }>;

    /**
     * Purchase price of rated voltage.
     */
    purchase_price: number;

    /**
     * Retail selling price of rated voltage.
     */
    retail_selling_price?: number;
  };

  /**
   * Chosen UV configuration.
   */
  uv_configuration: {
    /**
     * UV type.
     */
    uv_type: string;

    /**
     * Description of UV configuration.
     *
     * **Note** This is copied upon creation / update.
     */
    description: string;

    /**
     * Article number of UV configuration.
     */
    article_number: string;

    /**
     * Identifier of UV configuration.
     */
    identifier: string;

    /**
     * Mode of UV configuration.
     */
    mode: SunbedModelTypeUVConfigurationMode;

    /**
     * Purchase price of UV configuration.
     */
    purchase_price: number;

    /**
     * Retail selling price of UV configuration.
     */
    retail_selling_price?: number;

    /**
     * Light sources of sunbed.
     *
     * **Note** This is copied upon creation / update.
     */
    light_sources: Array<{
      article_number: string;
      location: 'bench' | 'canopy';
      amount: number;
      description: string;
    }>;

    /**
     * Facetanner configuration.
     *
     * **Note** Only defined when the facetanner is enabled.
     */
    facetanner?: {
      /**
       * Light source of facetanner.
       *
       * **Note** This is copied upon creation / update.
       */
      light_source?: {
        article_number: string;
        amount: number;
        description: string;
      };

      /**
       * Filters of facetanner.
       *
       * **Note** This is copied upon creation / update.
       */
      filters?: Array<{
        article_number: string;
        power: number;
        description: string;
        configuration_id: FilterPartConfigurationID | undefined;
      }>;
    };
  };

  /**
   * Chosen color
   */
  color?: {
    guid: string;
    article_number: string;
    description: string;
    purchase_price?: number;
    retail_selling_price?: number;
    configuration_id: ColorPartConfigurationID | undefined;
  };

  /**
   * Chosen packages
   */
  packages: Array<{
    guid: string;
    article_number: string;
    description: string;
    options: Array<string>;
    purchase_price?: number;
    retail_selling_price?: number;
  }>;

  /**
   * Chosen options and included options from packages.
   */
  options: Array<{
    guid: string;
    included: boolean;

    description: string;
    article_number: string;
    type: OptionPartEntity['type'];

    power_consumption?: OptionPartPowerConsumption['voltages'];
    acrylic_thickness?: number;

    light_source?: SunbedConfigurationTypeOption['light_source'];
    filter?: SunbedConfigurationTypeOption['filter'];

    purchase_price?: number;
    retail_selling_price?: number;
    from_package?: string;

    configuration_id: OptionPartConfigurationID | undefined;
  }>;

  /**
   * Power consumption of the configuration, including options.
   * In wattage.
   */
  power_consumption: Array<{
    voltage: SunbedVoltage;
    wattage: number;
  }>;

  /**
   * Language of the sunbed.
   * Used for the Display Language setting.
   */
  language: string;

  /**
   * Smartvoice language of the sunbed.
   * Used for the Smartvoice Language setting.
   */
  smartvoice_language: number;

  /**
   * The production time of the sunbed in days.
   */
  production_time_in_days: number;

  /**
   * The purchase price of the configuration.
   *
   * **Note** This is different than the purchase price of the order,
   * because this is the purchase price of the configuration itself.
   */
  purchase_price: number;

  /**
   * The retail selling price of the configuration.
   *
   * **Note** This is different than the RSP of the order,
   * because this is the RSP of the configuration itself.
   */
  retail_selling_price: number;

  /**
   * Tanning intensity of the sunbed.
   */
  tanning_intensity?: string;

  /**
   * Warning text, which will be displayed on the nameplates.
   */
  warning_text?: string;
}

export interface SunbedOrder {
  /**
   * State of the sunbed order.
   */
  state: SunbedOrderState;

  /**
   * Amount of sunbeds.
   */
  amount: number;

  /**
   * Reference of the order.
   */
  reference?: string;

  /**
   * Order remark.
   */
  remark?: string;

  /**
   * Internal order remark.
   */
  internal_remark?: string;

  /**
   * Organization details.
   */
  organization: {
    guid: string;
    name: string;
  };

  /**
   * Country code of where the order is going to.
   * ISO 3166 (alpha-3 code)
   */
  country: string;

  /**
   * Configuration of sunbed order.
   */
  configuration: SunbedConfiguration;

  /**
   * Production order number.
   *
   * **Note** This is required in the 'scheduling production' and upper states.
   */
  production_order_number?: string;

  /**
   * Sales order number.
   *
   * **Note** This is required in the 'scheduling production' and upper states.
   */
  sales_order_number?: string;

  /**
   * Pricing of the order.
   */
  pricing: {
    /**
     * The purchase price of the order (in euro's).
     */
    purchase_price: number;

    /**
     * The retail selling price of the order (in euro's).
     */
    retail_selling_price: number;

    /**
     * A custom price of the total order.
     * @optional
     */
    custom_price?: number;

    /**
     * The calcation price of an order per unit (in euro's).
     * @optional
     */
    order_calculation?: number;

    /**
     * The material purchasing price of an order per unit (in euro's).
     * @optional
     */
    material_purchasing?: number;
  };

  /**
   * Schedule of order.
   */
  schedule: {
    /**
     * Desired delivery week.
     * @format kkkk-'W'WW (e.g. 2022-W32)
     */
    desired_delivery_week: 'asap' | string;

    /**
     * Confirmation date.
     * This date is set when the state 'ready for production' is applied.
     */
    confirmation_date?: number;

    /**
     * Production week.
     * @format kkkk-'W'WW (e.g. 2022-W32)
     */
    production_week?: string;

    /**
     * THe scheduled delivery week.
     * @format kkkk-'W'WW (e.g. 2022-W32)
     */
    shipping_week?: string;
  };

  /**
   * Production information.
   */
  production: {
    /**
     * Production remark.
     */
    remark?: string;

    /**
     * Identification of sunbeds.
     */
    identification?: {
      /**
       * Serial numbers of every sunbed.
       */
      serial_numbers: Array<{
        /**
         * Serial number.
         */
        serial_number: string;

        /**
         * Payload of serial number.
         */
        payload?: {
          /**
           * MyLuxura guid.
           */
          myluxura_guid?: string;

          /**
           * MyLuxura Module Serial.
           *
           * This is available when the order status changes to "Shipped".
           */
          myluxura_module_serial?: string;
        };
      }>;

      /**
       * Date of serial number generation.
       */
      generation_date: number;
    };
  };
}

export interface SunbedOrderEntity extends Entity, SunbedOrder {}
