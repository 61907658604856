export class FormatUtils {
  static currency(value: number, options?: Intl.NumberFormatOptions): string {
    const currency = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'EUR',
      currencySign: 'accounting',
      currencyDisplay: 'symbol',
      notation: 'standard',
      signDisplay: 'never',
      ...(options || {}),
    }).format(value);

    if (currency.endsWith(',00')) {
      return `${currency.substring(0, currency.length - 2)}-`;
    }

    return currency;
  }
}
