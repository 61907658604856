
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required, requiredIf } from 'vuelidate/lib/validators';

import {
  isLowPressureLightSource,
  LightSourcePartDto,
  LightSourcePartEntity,
} from '../../../../../services/parts';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'partsLightSourcessInformationForm',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<LightSourcePartDto>,
    },
  },
  data() {
    return {
      metadata: {
        types: ['low_pressure', 'low_pressure_facial', 'high_pressure', 'LED'] as Array<
          LightSourcePartEntity['type']
        >,
      },
    };
  },
  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
      ballast_power: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
        required: requiredIf(function (this: any) {
          const document = this.document as LightSourcePartDto;
          return isLowPressureLightSource(document);
        }),
      },
      power: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
        required: requiredIf(function (this: any) {
          const document = this.document as LightSourcePartDto;
          return isLowPressureLightSource(document);
        }),
      },
      x_code: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
        required: requiredIf(function (this: any) {
          const document = this.document as LightSourcePartDto;
          return isLowPressureLightSource(document);
        }),
      },
      y_code: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
        required: requiredIf(function (this: any) {
          const document = this.document as LightSourcePartDto;
          return isLowPressureLightSource(document);
        }),
      },
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:description'] as HTMLInputElement).focus();
    }
  },
});
