
import Vue, { PropType } from 'vue';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../../services/sunbeds';

export default Vue.extend({
  name: 'configurationViewTypesViewCompatibility',
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },
});
