var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-7 border-bottom pb-3 pb-md-0 border-right-md border-bottom-md-0"},[_c('sunbed-model-image',{key:`configuration.model.${_vm.session.model._meta.guid}`,staticClass:"w-100 h-auto",attrs:{"model":_vm.session.model,"configuration-id":_vm.configurationIDs}}),_c('div',{staticClass:"mt-10 ml-auto w-100"},[_c('h2',{staticClass:"d-block",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.session.type && _vm.isLivePricingEnabled() === true)?_c('h1',{staticClass:"d-block",domProps:{"textContent":_vm._s(_vm.FormatUtils.currency(_vm.getTotalPrice()))}}):_vm._e()])],1),_c('div',{staticClass:"col-12 col-md mt-3 mt-md-0"},[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"mt-md-n10 col-12"},[_c('h3',{domProps:{"textContent":_vm._s(`${_vm.session.model.name} ${_vm.session.configuration.type.name}`)}})]),_c('div',{staticClass:"col-12 col-md-6 mt-5 border-bottom pb-3"},[_c('label',[_vm._v("No. of items")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'hashtag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.$v.session.configuration.amount.$model),expression:"$v.session.configuration.amount.$model",modifiers:{"number":true}}],class:[
              'form-control form-control-lg',
              { 'is-invalid': _vm.$v.session.configuration.amount.$error },
            ],attrs:{"type":"number"},domProps:{"value":(_vm.$v.session.configuration.amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.session.configuration.amount, "$model", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-md-6 mt-5 border-bottom pb-3"},[_c('label',[_vm._v("Customer reference")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'text']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.session.configuration.reference),expression:"session.configuration.reference",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg",attrs:{"type":"text"},domProps:{"value":(_vm.session.configuration.reference)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.session.configuration, "reference", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col mt-3 border-bottom pb-3"},[_c('label',[_vm._v("Price")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'euro-sign']}})],1)])]),_c('currency-input',{staticClass:"form-control form-control-lg",attrs:{"value":_vm.total_price,"disabled":""}})],1)]),(_vm.$permissions.hasPermission('sunbed_models.configure.custom_price'))?_c('div',{staticClass:"col-12 col-md-6 mt-3 border-bottom pb-3"},[_c('label',[_vm._v("Custom price")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'euro-sign']}})],1)])]),_c('currency-input',{class:[
              'form-control form-control-lg',
              { 'is-invalid': _vm.$v.session.configuration.custom_price.$error },
            ],model:{value:(_vm.$v.session.configuration.custom_price.$model),callback:function ($$v) {_vm.$set(_vm.$v.session.configuration.custom_price, "$model", _vm._n($$v))},expression:"$v.session.configuration.custom_price.$model"}})],1)]):_vm._e(),_c('div',{staticClass:"col-12 mt-3"},[_c('label',[_vm._v("Customer remark")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.session.configuration.remark),expression:"session.configuration.remark"}],staticClass:"form-control form-control-lg",attrs:{"rows":"5"},domProps:{"value":(_vm.session.configuration.remark)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.session.configuration, "remark", $event.target.value)}}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }