
import { DatatableConfig, DatatableInstance, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../components/layout/portal/modals';
import { Core } from '../../../../services/core';
import { PackagePartEntity, PackagePartsStore } from '../../../../services/parts';

export default Vue.extend({
  name: 'partsPackagesOverview',
  mixins: [DatatableMixin],
  data() {
    return {
      datatable: {
        url: PackagePartsStore.url,
        fields: [
          {
            name: 'description',
            title: 'Description',
            sortField: 'description',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'article_number',
            title: 'Article number',
            sortField: 'article_number',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'computed_fields.model.name',
            title: 'Configuration',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
        ],

        sort: [{ field: 'description', direction: 'asc' }],
        searchFields: ['description', 'article_number'],

        fetch: DatatableUtil.fetch(Core),

        httpOptions: {
          params: {
            include_model: true,
          },
        },
      } as DatatableConfig,
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Packages', route: '/parts/packages' },
    ]);
  },

  methods: {
    removePackage(entity: PackagePartEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove package',
        message: `Are you sure that you want to delete the package <strong>${entity.description}</strong>?<br />Please type the name of the package to confirm:`,
        verifyString: entity.description,
        async onSubmit() {
          await PackagePartsStore.delete(entity._meta.guid, { persistent: true });

          (vm.$refs.datatable as DatatableInstance).reload();
        },
      });
    },
  },
});
