import { AuthenticationState } from '../interfaces';

export class AuthenticationUtil {
  static parseState(state?: string): AuthenticationState {
    if (!state) {
      return {};
    }

    try {
      return JSON.parse(window.atob(state));
    } catch (e) {
      console.warn('Failed to parse state', e);
      return {};
    }
  }

  static stringifyState(state: AuthenticationState): string {
    return window.btoa(JSON.stringify(state));
  }
}
