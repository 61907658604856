
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { Validation } from 'vuelidate';

import { PricelistsInformationForm } from './components';
import { APP_ERROR_EVENT } from '../../../constants';
import { Core } from '../../../services/core';
import { PricelistDto, PricelistsStore } from '../../../services/pricelists';

export default Vue.extend({
  name: 'pricelistsCreateView',
  mixins: [Mixins.KTWizardV4],
  components: {
    PricelistsInformationForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      pricelist: new PricelistDto(),

      metadata: {
        wizard: {
          index: 0,
        },
      },
    };
  },
  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Pricelists', route: '/pricelists/overview' },
      { title: 'Create' },
    ]);
  },
  mounted() {
    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const pricelist = await PricelistsStore.create(JSON.parse(JSON.stringify(this.pricelist)));
        this.$router.push(`/pricelists/view/${pricelist._meta.guid}`);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },

    async onWizardStepChange(step: number, oldStep: number): Promise<void> {
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          Core.Eventhub.emit(APP_ERROR_EVENT, e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number): Promise<void> {
      const validationArray: Array<Validation> = [];

      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray);
    },
  },
});
