
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';
import { OrganizationEntity, ValidatorUtil } from '@vedicium/vue-core';
import { VueSelectInstance } from 'vue-select';

import { Core } from '../../../../../services/core';

import { SearchOrganizationModalOptions } from './search-organization-modal.interface';
import {
  SEARCH_ORGANIZATION_MODAL_OPEN,
  SEARCH_ORGANIZATION_MODAL_CLOSE,
} from './search-organization-modal.constants';
import { OrganizationVueSelect } from '../../components';
import { APP_ERROR_EVENT } from '../../../../../constants';

export default Vue.extend({
  name: 'searchPricelistModal',
  mixins: [Mixins.Modal],
  components: {
    OrganizationVueSelect,
  },
  data() {
    return {
      options: {} as SearchOrganizationModalOptions,

      isLoading: false,

      metadata: {
        organization: undefined as OrganizationEntity | undefined,
      },
    };
  },
  validations: {
    metadata: {
      organization: {
        required,
      },
    },
  },

  mounted() {
    Core.Eventhub.on(SEARCH_ORGANIZATION_MODAL_OPEN, this.open);
    Core.Eventhub.on(SEARCH_ORGANIZATION_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    Core.Eventhub.off(SEARCH_ORGANIZATION_MODAL_OPEN, this.open);
    Core.Eventhub.off(SEARCH_ORGANIZATION_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.metadata.organization as OrganizationEntity);
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    async open(options?: SearchOrganizationModalOptions): Promise<void> {
      this.$set(this, 'options', options);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },

    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    onShown() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((this.$refs.input as any).input as VueSelectInstance).searchEl.focus();
    },
  },
});
