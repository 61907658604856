
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import { HttpRequestConfig, OrganizationInviteEntity, RouterUtil } from '@vedicium/vue-core';
import {
  ORGANIZATION_INVITE_MODAL_EVENT_CLOSE,
  ORGANIZATION_INVITE_MODAL_EVENT_OPEN,
} from './constants';
import { OrganizationInviteModalOptions } from './interfaces';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';
// import { errorComponentMixin } from '@vedicium/core-vue';
// import { CompanyInviteClass as CompanyInvite } from '@vedicium/core-vue/build/modules/company';
// import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default Vue.extend({
  name: 'organizationInviteModal',
  mixins: [Mixins.Modal],
  components: {
    // errorTranslationAlert,
  },
  data() {
    return {
      options: undefined as OrganizationInviteModalOptions | undefined,
      isLoading: false,
      // isError: null,

      invite: undefined as OrganizationInviteEntity | undefined,
    };
  },

  mounted() {
    // Listeners
    this.$eventhub.on(ORGANIZATION_INVITE_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.on(ORGANIZATION_INVITE_MODAL_EVENT_CLOSE, this.close);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off(ORGANIZATION_INVITE_MODAL_EVENT_OPEN, this.open);
    this.$eventhub.off(ORGANIZATION_INVITE_MODAL_EVENT_CLOSE, this.close);
  },

  methods: {
    async getOrganizationInvite(options?: HttpRequestConfig): Promise<OrganizationInviteEntity> {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'invite', undefined);

      try {
        this.$set(
          this,
          'invite',
          await Core.Organizations.getOrganizationInvite(this.options?.token as string, options),
        );
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.invite as OrganizationInviteEntity;
    },
    async acceptInvite() {
      this.$set(this, 'isLoading', true);

      try {
        await Core.Organizations.respondToOrganizationInvite(
          (this.invite as OrganizationInviteEntity)._meta.guid,
          'accept',
        );

        // Run 'onSubmit'
        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit('accepted', this.invite as OrganizationInviteEntity);
        }
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },
    async declineInvite() {
      this.$set(this, 'isLoading', true);

      try {
        await Core.Organizations.respondToOrganizationInvite(
          (this.invite as OrganizationInviteEntity)._meta.guid,
          'decline',
        );

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit('declined', this.invite as OrganizationInviteEntity);
        }
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: OrganizationInviteModalOptions) {
      // Reset options
      this.$set(this, 'options', options);

      this.getOrganizationInvite();

      (this.$refs.modal as BModal).show();
    },
    close() {
      (this.$refs.modal as BModal).hide();
    },

    onHide() {
      // Remove invite query
      RouterUtil.omitQueryFromRoute(this.$router, ['invite']);
    },
  },
});
