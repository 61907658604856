import urlJoin from 'url-join';

export const APP_NAME = 'VDL Hapro - LuCAS';
export const APP_PREFIX = 'vdlhapro-lucas';
export const APP_ERROR_EVENT = 'app:error';

export const API_URL = process.env.VUE_APP_API_URL || urlJoin(window.location.origin, '/api');
export const API_WEBSOCKET_URL = process.env.VUE_APP_API_WEBSOCKET_URL || window.location.origin;
export const API_WEBSOCKET_PATH = process.env.VUE_APP_API_WEBSOCKET_PATH || '/api/socket.io';

export const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN || 'vedicium.eu.auth0.com';
export const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID;
export const AUTH0_SCOPE = 'profile email offline_access api:read api:write';
export const AUTH0_REDIRECT_URI = urlJoin(window.location.origin, '/authentication/callback');
export const AUTH0_AUDIENCE =
  process.env.VUE_APP_AUTH0_AUDIENCE || 'https://lucas.vdlhapro.com/api/';
