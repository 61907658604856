
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import { UPDATE_ORDER_NUMBER_MODAL_CLOSE, UPDATE_ORDER_NUMBER_MODAL_OPEN } from './constants';
import { UpdateOrderNumberModalOptions } from './interfaces';

export default Vue.extend({
  name: 'updateOrderNumberModal',
  mixins: [Mixins.Modal],
  data() {
    return {
      isLoading: false,

      options: undefined as UpdateOrderNumberModalOptions | undefined,

      metadata: {
        value: undefined as string | undefined,
      },
    };
  },
  validations: {
    metadata: {
      value: {
        required,
      },
    },
  },

  mounted() {
    this.$eventhub.on(UPDATE_ORDER_NUMBER_MODAL_OPEN, this.open);
    this.$eventhub.on(UPDATE_ORDER_NUMBER_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(UPDATE_ORDER_NUMBER_MODAL_OPEN, this.open);
    this.$eventhub.off(UPDATE_ORDER_NUMBER_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      return ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.metadata.value as string);
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: UpdateOrderNumberModalOptions): void {
      this.$set(this, 'options', options || undefined);
      this.$set(this.metadata, 'value', options?.value || undefined);

      this.$nextTick(() => this.$v.$reset());

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();

      (this.$refs['input:order_number'] as HTMLInputElement).focus();
    },
  },
});
