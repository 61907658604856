var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 form-group pb-3 mb-3"},[_c('label',[_vm._v(" Description "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'text']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.description.$model),expression:"$v.document.description.$model",modifiers:{"trim":true}}],ref:"input:description",class:[
              'form-control form-control-lg',
              { 'is-invalid': _vm.$v.document.description.$error },
            ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-3 form-group pb-3 mb-3"},[_c('label',[_vm._v(" Article number "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.article_number.$model),expression:"$v.document.article_number.$model",modifiers:{"trim":true}}],class:[
              'form-control form-control-lg',
              { 'is-invalid': _vm.$v.document.article_number.$error },
            ],attrs:{"type":"text","disabled":_vm.isDisabled || _vm.action !== 'CREATE',"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.article_number.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.article_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-3 form-group pb-3 mb-3"},[_c('label',[_vm._v(" Configuration ID "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tools']}})],1)])]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.document.configuration_id),expression:"document.configuration_id",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg",attrs:{"disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.document, "configuration_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":undefined}},[_vm._v("-")]),_vm._l((_vm.metadata.configuration_ids),function(configuration_id){return _c('option',{key:`filter_glass:configuration_id:option:${configuration_id}`,domProps:{"value":configuration_id,"textContent":_vm._s(_vm.$t(`parts.filters.configuration_ids.${configuration_id}`))}})})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }