export const SUNBED_UNIT_TYPES = ['horizontal', 'vertical'];

export const SUNBED_LANGUAGES = [
  'German',
  'English',
  'Swedish',
  'Danish',
  'Russian',
  'Estonian',
  'Norwegian',
  'Polish',
  'Czech',
  'Dutch',
  'French',
  'Greek',
  'Italian',
  'Latvian',
  'Spanish',
  'Lithuanian',
  'Croatian',
  'Portuguese',
  'Hungarian',
  'Finnish',
];

export const SUNBED_SMARTVOICE_LANGUAGES = [
  { value: 1, language: 'German (1)' },
  { value: 2, language: 'English (2)' },
  { value: 3, language: 'Swedish (3)' },
  { value: 4, language: 'Danish (4)' },
  { value: 5, language: 'Russian (5)' },
  { value: 6, language: 'Estonian (6)' },
  { value: 7, language: 'Norwegian (7)' },
  { value: 8, language: 'Polish (8)' },
  { value: 9, language: 'Czech (9)' },
  { value: 10, language: 'Dutch (10)' },
  { value: 11, language: 'French (11)' },
  { value: 12, language: 'Greek (12)' },
  { value: 13, language: 'Italian (13)' },
  { value: 14, language: 'Latvian (14)' },
  { value: 15, language: 'Spanish (15)' },
  { value: 16, language: 'Lithuanian (16)' },
  { value: 17, language: 'Croatian (17)' },
  { value: 18, language: 'Portuguese (18)' },
  { value: 19, language: 'Hungarian (19)' },
  { value: 20, language: 'Finnish (20)' },
  { value: 21, language: '(21)' },
  { value: 22, language: 'Seecret (22)' },
  { value: 23, language: '(23)' },
  { value: 24, language: 'CLUBTAN (24)' },
  { value: 25, language: "Sunday's (25)" },
  { value: 26, language: '(26)' },
  { value: 27, language: 'Body Boost Bed (27)' },
  { value: 28, language: '(28)' },
  { value: 29, language: '(29)' },
  { value: 30, language: '(30)' },
];

export const SUNBED_TANNING_INTENSITIES = ['0,3', '0,3 plus'];

export const SUNBED_CONFIGURATION_SESSION_KEY = `sunbeds:configuration:session`;
