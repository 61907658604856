
import { FormMixin } from '@vedicium/vue-core';
import Vue, { PropType, VueConstructor } from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { UserInviteDto } from '../../../../services/users';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<UserInviteDto>,
      default: null,
    },
  },
  validations: {
    document: {
      email: {
        required,
        email,
      },
    },
  },
});
