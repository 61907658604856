var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile',{ref:"KTHeaderMobile",scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"h-40px",attrs:{"src":require('@/assets/images/logo.light.svg'),"alt":"Logo"}})])]},proxy:true}])}),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.isLayoutKeyEnabled('aside.self.display'))?_c('KTAside',{scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('KTBrand',{ref:"brand",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"mt-5 w-150px mx-auto pt-3 text-center",attrs:{"src":require('@/assets/images/logo.light.svg'),"alt":"Logo"}})])]},proxy:true},{key:"toggle",fn:function(){return [_c('span',{staticClass:"svg-icon svg-icon svg-icon-xl"})]},proxy:true}],null,false,2296226165)})]},proxy:true},{key:"menu",fn:function(){return [_c('portal-layout-menu-aside',{ref:"MenuAside"})]},proxy:true}],null,false,1081614766)}):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper"},[_c('KTHeader',{ref:"KTHeader",scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('portal-layout-menu-topbar',{ref:"MenuTopbar"})]},proxy:true},{key:"topbar",fn:function(){return [_c('portal-layout-topbar',{ref:"Topbar"})]},proxy:true}])}),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid"},[(_vm.isLayoutKeyEnabled('aside.self.display'))?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:[
              _vm.$metronic.config.layout.get('content.width') === 'fluid'
                ? 'container-fluid'
                : 'container',
            ]},[_c('transition',{attrs:{"name":"fade-in-up"}},[[(_vm.$organizations.organization)?_c('router-view',{key:`organization:${
                    (_vm.$organizations.organization && _vm.$organizations.organization._meta.guid) ||
                    undefined
                  }`,ref:"router"}):_vm._e()]],2)],1)])],1),(_vm.isLayoutKeyEnabled('footer.display'))?_c('portal-layout-footer',{ref:"KTFooter"}):_vm._e()],1)],1),_c('switch-organization-modal'),_c('confirmation-modal',{attrs:{"event-name":_vm.CONFIRMATION_MODAL_EVENT}}),_c('information-modal',{attrs:{"event-name":_vm.INFORMATION_MODAL_EVENT}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }