import { Entity } from '@vedicium/vue-core';

export interface PricelistItem {
  /**
   * Article number of the item.
   */
  article_number: string;

  /**
   * Purchase price in euro's.
   */
  purchase_price: number;

  /**
   * Retail selling price (RSP) in euro's.
   */
  retail_selling_price: number;
}

export type PricelistItemType =
  | 'color'
  | 'filter'
  | 'light source'
  | 'option'
  | 'package'
  | 'sunbed model type'
  | 'uv configuration'
  | 'unknown';

export interface PricelistItemWithProperties extends PricelistItem {
  /**
   * Type of entity
   */
  type: PricelistItemType;

  /**
   * Description of the pricelist item.
   */
  description: string;

  /**
   * Entity linked to article number
   */
  entity?: Entity;
}

export interface PricelistEntity<I extends PricelistItem = PricelistItem> extends Entity {
  description: string;

  items: Array<I>;
}

export class PricelistDto {
  description: string | undefined = undefined;
}
