
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required, requiredIf, numeric } from 'vuelidate/lib/validators';

import {
  OptionPartConfigurationID,
  OptionPartDto,
  OptionPartEntity,
} from '../../../../../services/parts';
import {
  SearchLightSourceModalOptions,
  SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN,
} from '../../light-sources';
import { SearchFilterModalOptions, SEARCH_FILTER_MODAL_EVENT_OPEN } from '../../filters';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'partsOptionsInformationForm',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<OptionPartDto>,
    },
  },
  data() {
    // Get configuration IDs by category
    const configurationIDs: Map<string, Array<OptionPartConfigurationID>> = new Map();
    Object.values(OptionPartConfigurationID).forEach((row) => {
      const translation = this.$t(`parts.options.configuration_ids.${row}`) as {
        name: string;
        category: string;
      };

      // When the category is already known, add the value
      if (configurationIDs.has(translation.category) === true) {
        configurationIDs.set(translation.category, [
          ...(configurationIDs.get(translation.category) || []),
          row,
        ]);

        return;
      }

      // If not, create the category
      configurationIDs.set(translation.category, [row]);
    });

    return {
      metadata: {
        types: ['standard', 'power', 'light'] as Array<OptionPartEntity['type']>,
        configuration_ids: configurationIDs,
      },

      OptionPartConfigurationID,
    };
  },
  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
      light_source: {
        article_number: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
          required: requiredIf(function (this: any) {
            const document = this.document as OptionPartDto;
            return document.type === 'light';
          }),
        },
        amount: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
          required: requiredIf(function (this: any) {
            const document = this.document as OptionPartDto;
            return document.type === 'light';
          }),
        },
        filter_article_number: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
        },
        filter_power: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
          required: requiredIf(function (this: any) {
            return this.isFilterPowerRequired();
          }),
        },
      },
      acrylic_thickness: {
        numeric,
      },
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:description'] as HTMLInputElement).focus();
    }
  },

  methods: {
    isFilterPowerRequired(): boolean {
      return (
        this.document.type === 'light' &&
        this.document.light_source?.filter_article_number?.length > 0
      );
    },

    openSearchLightSourceModal(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SEARCH_LIGHT_SOURCE_MODAL_EVENT_OPEN, {
        title: 'Search light source',
        async onSubmit(entity) {
          vm.$set(
            // eslint-disable-next-line @typescript-eslint/ban-types
            vm.document?.light_source as object,
            'article_number',
            entity?.article_number || undefined,
          );

          await vm.$nextTick();

          (vm.$refs['input:light-source:amount'] as HTMLInputElement).focus();
        },
      } as SearchLightSourceModalOptions);
    },
    openSearchFilterModal() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SEARCH_FILTER_MODAL_EVENT_OPEN, {
        title: 'Search filter glass',
        async onSubmit(entity) {
          vm.$set(
            // eslint-disable-next-line @typescript-eslint/ban-types
            vm.document?.light_source as object,
            'filter_article_number',
            entity?.article_number || undefined,
          );
        },
      } as SearchFilterModalOptions);
    },
  },
});
