
import Vue, { PropType } from 'vue';
import {
  ConfiguratorService,
  SunbedConfigurationSession,
  SunbedConfigurationType,
  SunbedConfigurationTypeColor,
} from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';
import { PartsColorsColorDisplayComponent } from '../../parts/colors';

export default Vue.extend({
  name: 'ordersColorsConfigurator',
  props: {
    type: {
      type: Object as PropType<SunbedConfigurationType>,
    },
    session: {
      type: Object as PropType<SunbedConfigurationSession>,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormatUtils,
    };
  },
  components: {
    ColorDisplayComponent: PartsColorsColorDisplayComponent,
  },
  computed: {
    colors(): Array<SunbedConfigurationTypeColor> {
      return this.type.parts.colors.filter((row) => {
        // In 'readonly' mode, only the selected color should be shown.
        if (this.isReadonly() === true) {
          return this.isSelectedColor(row);
        }

        return true;
      }, []);
    },
  },

  methods: {
    isReadonly(): boolean {
      return this.readonly === true;
    },
    isSelectedColor(color: SunbedConfigurationTypeColor): boolean {
      return this.session.configuration.color?.guid === color.guid;
    },
    isColorIncluded(color: SunbedConfigurationTypeColor): boolean {
      return ConfiguratorService.isColorIncluded(color);
    },
    getPrice(color: SunbedConfigurationTypeColor): number | undefined {
      if (ConfiguratorService.isLivePricingEnabled() === false) {
        return undefined;
      }

      if (this.isColorIncluded(color) === true) {
        return undefined;
      }

      return color.purchase_price;
    },
    onColorSelect(color: SunbedConfigurationTypeColor): void {
      if (this.isReadonly() === true) {
        return;
      }

      this.$set(this.session.configuration, 'color', color);
    },
  },
});
