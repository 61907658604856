
import { BDropdown } from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import { DateTime } from 'luxon';
import { SunbedOrderEntity, SunbedOrderUtils } from '../../../../../services/sunbeds';

export default Vue.extend({
  name: 'ordersDatatableInformationDropdown',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  data() {
    return {
      DateTime,
    };
  },
  computed: {
    dropdown(): BDropdown {
      return this.$refs.dropdown as BDropdown;
    },
    power_consumption(): string {
      return SunbedOrderUtils.getPowerConsumptionText(this.order);
    },
  },
});
