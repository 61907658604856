
import Vue, { PropType } from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { OrganizationEntityDto } from '../../../../services/organizations';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object as PropType<OrganizationEntityDto>,
    },
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled === true;
    },
  },
  validations: {
    document: {
      name: {
        required,
      },
      information: {
        organization_id: {
          required,
        },
        administration_email: {
          required,
          email,
        },
      },
    },
  },
  data() {
    return {
      isReadOnly: false,

      form: {
        location: null,
      },
    };
  },

  mounted() {
    if (this.document && this.document.location && this.document.location.description) {
      this.$set(this.form, 'location', {
        place_id: this.document.location.place_id,
        description: this.document.location.description,
      });
    }
  },
});
