
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';

import {
  SunbedUtils,
  SunbedModelEntity,
  SunbedModelTypeDto,
  SunbedModelTypeUVConfiguration,
  SunbedModelTypeUVConfigurationDto,
  SunbedModelTypeUVConfigurationMode,
} from '../../../../../../../services/sunbeds';
import { SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN } from '../constants';
import { SunbedModelTypeUVTypeConfigurationOptions } from '../interfaces';
import { ConfigurationViewTypesUVTypeConfigurationModal } from './uv-type-configuration-modal';
import { SearchLightSourceModal, SearchFilterModal } from '../../../../../parts';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../../../components/layout/portal/modals';
import { Core } from '../../../../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../../../../constants';
import { I18nUtils } from '../../../../../../../services/i18n';

export default Vue.extend({
  name: 'configurationViewTypesMarkingsForm',
  mixins: [FormMixin, DatatableMixin],
  components: {
    ConfigurationViewTypesUVTypeConfigurationModal,
    SearchLightSourceModal,
    SearchFilterModal,
  },
  props: {
    hideAddMarkingButton: {
      type: Boolean,
      default: false,
    },

    model: {
      type: Object as PropType<SunbedModelEntity>,
    },
    document: {
      type: Object as PropType<SunbedModelTypeDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'description',
            title: 'Description',
          },
          {
            name: 'article_number',
            title: 'Article number',
          },
          {
            name: 'production_article_number',
            title: 'Article nr. production',
          },
          {
            name: 'uv_type',
            title: this.$t('parts.uv_types.text'),
          },
          {
            name: 'facetanner',
            title: 'Facetanner',
          },
          {
            name: 'light_sources',
            title: 'Light sources',
          },
          {
            name: 'actions',
            title: '',
          },
        ],
      } as DatatableConfig,

      I18nUtils,
    };
  },

  methods: {
    onAddMarking(): void {
      const configuration = new SunbedModelTypeUVConfigurationDto();
      if (this.model.unit_type === 'horizontal') {
        configuration.light_sources.push({ location: 'bench', article_number: '', amount: 1 });
        configuration.light_sources.push({ location: 'canopy', article_number: '', amount: 1 });
      } else {
        configuration.light_sources.push({ location: 'bench', article_number: '', amount: 1 });
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN, {
        title: 'Add UV configuration',
        configuration,
        unit_type: vm.model.unit_type,
        light_sources: SunbedUtils.getLightSourceLocationsByModel(vm.model),
        onSubmit: async (entity) => {
          try {
            if (vm.isConfigurationAllowed(entity) === false) {
              throw new Error('Configuration is not allowed for UV-type');
            }

            vm.document.uv_configurations.push(entity);

            if (entity.mode === SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT) {
              vm.setConfigurationAsDefault(entity);
            }

            await new Promise<void>((resolve, reject) => {
              vm.$emit('update', vm.document.uv_configurations, (err: Error | null) => {
                if (err) {
                  return reject(err);
                }
                return resolve();
              });
            });
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            throw e;
          }
        },
      } as SunbedModelTypeUVTypeConfigurationOptions);
    },
    onModifyUVConfiguration(configuration: SunbedModelTypeUVConfiguration): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN, {
        title: 'Modify UV configuration',
        configuration: JSON.parse(JSON.stringify(configuration)),
        unit_type: vm.model.unit_type,
        light_sources: SunbedUtils.getLightSourceLocationsByModel(vm.model),
        onSubmit: async (entity) => {
          try {
            if (vm.isConfigurationAllowed(entity) === false) {
              throw new Error('Configuration is not allowed for UV-type');
            }

            const idx = vm.document.uv_configurations.findIndex(
              (row) => row.identifier === configuration.identifier,
            );

            if (idx === -1) {
              throw new Error('Configuration unknown');
            }

            vm.$set(vm.document.uv_configurations, idx, entity);

            if (entity.mode === SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT) {
              vm.setConfigurationAsDefault(entity);
            }

            await new Promise<void>((resolve, reject) => {
              vm.$emit('update', vm.document.uv_configurations, (err: Error | null) => {
                if (err) {
                  return reject(err);
                }

                return resolve();
              });
            });
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            throw e;
          }
        },
      } as SunbedModelTypeUVTypeConfigurationOptions);
    },
    onCopyUVConfiguration(configuration: SunbedModelTypeUVConfiguration): void {
      // Duplicate the configuration
      const duplicate: SunbedModelTypeUVConfiguration = JSON.parse(JSON.stringify(configuration));

      // Remove the description
      duplicate.identifier = undefined;
      duplicate.description = undefined;
      duplicate.article_number = undefined;

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SUNBED_MODEL_TYPE_UV_TYPE_CONFIGURATION_MODAL_OPEN, {
        title: 'Add UV configuration',
        configuration: duplicate,
        unit_type: vm.model.unit_type,
        light_sources: SunbedUtils.getLightSourceLocationsByModel(vm.model),
        onSubmit: async (entity) => {
          try {
            if (vm.isConfigurationAllowed(entity) === false) {
              throw new Error('Configuration is not allowed for UV-type');
            }

            vm.document.uv_configurations.push(entity);

            if (entity.mode === SunbedModelTypeUVConfigurationMode.SELECTED_BY_DEFAULT) {
              vm.setConfigurationAsDefault(entity);
            }

            await new Promise<void>((resolve, reject) => {
              vm.$emit('update', vm.document.uv_configurations, (err: Error | null) => {
                if (err) {
                  return reject(err);
                }
                return resolve();
              });
            });
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            throw e;
          }
        },
      } as SunbedModelTypeUVTypeConfigurationOptions);
    },
    onRemoveConfiguration(configuration: SunbedModelTypeUVConfiguration): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove UV configuration',
        message: `Are you sture that you want to remove this UV configuration?`,
        onSubmit: async () => {
          try {
            vm.$set(
              vm.document,
              'uv_configurations',
              vm.document.uv_configurations.filter((row) => row !== configuration, []),
            );

            await new Promise<void>((resolve, reject) => {
              vm.$emit('update', vm.document.uv_configurations, (err: Error | null) => {
                if (err) {
                  return reject(err);
                }
                return resolve();
              });
            });
          } catch (e) {
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            throw e;
          }
        },
      });
    },
    setConfigurationAsDefault(configuration: SunbedModelTypeUVConfiguration): void {
      this.$set(
        this.document,
        'uv_configurations',
        this.document.uv_configurations.map((row) => {
          if (row.uv_type === configuration.uv_type && row !== configuration) {
            // eslint-disable-next-line no-param-reassign
            row.mode = SunbedModelTypeUVConfigurationMode.AVAILABLE;
          }

          return row;
        }, []),
      );
    },

    isConfigurationAllowed(configuration: SunbedModelTypeUVConfiguration): boolean {
      const configurationsOfUVType = this.document.uv_configurations.filter(
        (row) => row.uv_type === configuration.uv_type,
        [],
      );

      // When no UV configuration are found, the configuration is always allowed
      if (configurationsOfUVType.length === 0) {
        return true;
      }

      // When current UV configuration is set to 'included by default',
      // but there are more UV configurations, the change isn't allowed.
      if (
        configuration.mode === SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT &&
        configurationsOfUVType.some((row) => row.identifier !== configuration.identifier, [])
      ) {
        return false;
      }

      // Find if there's a included configuration.
      const includedConfiguration = configurationsOfUVType.find(
        (row) => row.mode === SunbedModelTypeUVConfigurationMode.INCLUDED_BY_DEFAULT,
      );

      // If there isn't an included configuration, the configuration is allowed
      if (includedConfiguration === undefined) {
        return true;
      }

      // Only allow changing the included configuration if the identifiers match
      if (includedConfiguration.identifier === configuration.identifier) {
        return true;
      }

      return false;
    },

    getLightSourcesOfLocation(
      marking: SunbedModelTypeUVConfiguration,
      location: SunbedModelTypeUVConfiguration['light_sources'][number]['location'],
    ): SunbedModelTypeUVConfiguration['light_sources'] {
      return marking.light_sources.filter((row) => row.location === location, []);
    },
  },
});
