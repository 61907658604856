export default {
  switch_organizations_modal: {
    title: 'Select an organization',

    no_organizations: {
      title: 'No organizations found',
      message: `There are no organizations linked to your account.`,
    },
  },

  type: {
    vedicium: {
      name: 'VeDicium',
      groups: {
        vedicium: {
          sysadmin: {
            name: 'System administrator',
            description:
              'Has all permissions, including system critical permissions and dangerous permissions',
          },

          admin: {
            name: 'Super administrator',
            description: 'Has all permissions, including system critical permissions',
          },
        },
      },
    },

    manufacturer: {
      name: 'Manufacturer',
      groups: {
        manufacturer: {
          admin: {
            name: 'Administrator',
            description:
              'Capable of creating and updating configurations, review all information and execute all tasks.',
          },
          sales_admin: {
            name: 'Sales administrator',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of sales administrators.',
          },
          sales_office: {
            name: 'Sales office',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of sales office employees.',
          },
          sales_field: {
            name: 'Sales field',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of sales field employees.',
          },
          work_preperation_admin: {
            name: 'Work preperation administrator',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of work preparation administrators.',
          },
          work_preperation: {
            name: 'Work preperation',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of work preparation employees.',
          },
          logistics: {
            name: 'Logistics',
            description:
              'Capable of reviewing all information associated with- and executing all tasks under the responsibility of logistics employees.',
          },
        },
      },
    },

    client: {
      name: 'Customer',
      groups: {
        client: {
          admin: {
            name: 'Administrator',
          },
        },
      },
    },
  },
};
