
import { FormMixin } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { required, minValue } from 'vuelidate/lib/validators';
import world from '@/assets/js/world.json';
import { OrganizationEntityDto } from '../../../../services/organizations';
import { PARTS_UV_TYPES } from '../../../../services/parts';
import { Core } from '../../../../services/core';
import { APP_ERROR_EVENT } from '../../../../constants';
import {
  getRatedVoltages,
  SUNBED_LANGUAGES,
  SUNBED_SMARTVOICE_LANGUAGES,
  SUNBED_TANNING_INTENSITIES,
} from '../../../../services/sunbeds';

export default Vue.extend({
  name: 'organizationPreferences',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<OrganizationEntityDto>,
    },
  },
  data() {
    return {
      metadata: {
        countries: world.map(
          (row) => ({
            code: row.alpha3.toUpperCase(),
            name: row.en,
          }),
          [],
        ),

        languages: SUNBED_LANGUAGES,
        smartvoice_languages: SUNBED_SMARTVOICE_LANGUAGES,

        rated_voltages: [],
        uv_types: PARTS_UV_TYPES,
        tanning_intensities: SUNBED_TANNING_INTENSITIES,
      },
    };
  },
  validations: {
    document: {
      information: {
        preferences: {
          country: {
            required,
          },
          language: {
            required,
          },
          smartvoice_language: {
            required,
            minValue: minValue(1),
          },
          rated_voltage: {
            required,
          },
          uv_type: {
            required,
          },
        },
      },
    },
  },

  async mounted() {
    try {
      await this.onMount();
    } catch (e) {
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      console.error(e);
    }
  },

  methods: {
    async onMount(): Promise<void> {
      const [rated_voltages] = await Promise.all([getRatedVoltages()]);

      this.$set(this.metadata, 'rated_voltages', rated_voltages);
    },
  },
});
