import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import MetronicVue from '@vedicium/metronic-vue';
import Vuelidate from 'vuelidate';
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  AuthenticationLogoutOptions,
  AUTHENTICATION_LOGOUT_EVENT,
  Entity,
} from '@vedicium/vue-core';
import { LogoutOptions } from '@auth0/auth0-spa-js';

import App from './App.vue';
import { getAuthenticationRoute, Router } from './router';
import { Core } from './services/core';
import { i18n } from './services/i18n';
import { APP_ERROR_EVENT } from './constants';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(MetronicVue);
Vue.use(Vuelidate);
Vue.use(Core);

FontAwesomeLibrary.add(FontAwesomeSolid);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const VueRouter = Router({ mode: 'history' });

// Listen to logout event
Core.Eventhub.on(
  AUTHENTICATION_LOGOUT_EVENT,
  (_entity: Entity, options?: AuthenticationLogoutOptions<LogoutOptions>) => {
    // When logout is handled, don't replace the route
    if (Core.Memory.get<boolean>('logout:handled') === true) {
      Core.Memory.remove('logout:handled');
      return;
    }

    // When 'returnTo' is set and 'sessionOnly' isn't set, the logout is handled as well.
    if (options?.sessionOnly !== true && options?.logoutParams?.returnTo) {
      return;
    }

    VueRouter.replace(getAuthenticationRoute({ prompt: 'login' }));
  },
);

async function AppBootstrap(): Promise<Vue> {
  try {
    await Core.Authentication.init();
  } catch (e) {
    console.error('Failed to initialize Authentication', e);
  }

  if (Core.Authentication.isAuthenticatedSync() === true) {
    await Core.Organizations.get();
  }

  // Create VueApp
  const VueApp = new Vue({
    render: (h) => h(App),
    i18n,
    router: VueRouter,
  });

  // Listen to errors
  Core.Eventhub.on(APP_ERROR_EVENT, (e: Error) => {
    VueApp.$bvToast.toast(e.message, {
      title: 'An error occurred',
      variant: 'danger',
      solid: true,
    });
  });

  return VueApp.$mount('#app');
}

AppBootstrap();
