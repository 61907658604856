
import { DotNotatedPath, MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../constants';
import { Core } from '../../../../../services/core';

import { OptionPartEntity, OptionPartsStore } from '../../../../../services/parts';
import { SearchFilterModal } from '../../filters';
import { SearchLightSourceModal } from '../../light-sources';
import { PartsOptionsInformationForm } from '../components';

export default Vue.extend({
  name: 'partsOptionsViewInformation',
  props: {
    option: {
      type: Object as PropType<OptionPartEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        option: JSON.parse(JSON.stringify(this.option)) as OptionPartEntity,
      },
    };
  },
  components: {
    PartsOptionsInformationForm,
    SearchLightSourceModal,
    SearchFilterModal,
  },

  beforeMount() {
    if (this.metadata.option.type === 'light') {
      this.metadata.option.light_source = {
        article_number: this.metadata.option.light_source?.article_number || null,
        amount: this.metadata.option.light_source?.amount || null,
        filter_article_number: this.metadata.option.light_source?.filter_article_number || null,
        filter_power: this.metadata.option.light_source?.filter_power || null,
      };
    }
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([(this.$refs.informationForm as Vue).$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        // Remove filter glass article number & power when the article number isn't set.
        if (
          this.metadata.option.type === 'light' &&
          this.metadata.option.light_source !== undefined
        ) {
          if (!this.metadata.option.light_source?.filter_article_number) {
            this.metadata.option.light_source.filter_article_number = null;
            this.metadata.option.light_source.filter_power = null;
          }
        }

        // Create mutations
        const mutations = MutationsService.getMutationsByComparison<OptionPartEntity>(
          this.option,
          this.metadata.option,
          [
            'description',
            'configuration_id',
            'order_sequence',
            'internal_only',
            'light_source.amount' as DotNotatedPath<OptionPartEntity>,
            'light_source.article_number' as DotNotatedPath<OptionPartEntity>,
            'light_source.filter_article_number' as DotNotatedPath<OptionPartEntity>,
            'light_source.filter_power' as DotNotatedPath<OptionPartEntity>,
            'acrylic_thickness',
          ],
        );

        if (mutations.length > 0) {
          const entity = await OptionPartsStore.update(this.option._meta.guid, mutations);

          this.$emit('update:option', entity);
          this.$set(this.metadata, 'option', JSON.parse(JSON.stringify(entity)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
