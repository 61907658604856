
import Vue, { PropType } from 'vue';
import { DateTime } from 'luxon';
import { SunbedModelImage } from '../../components';
import {
  SunbedConfigurationID,
  SunbedOrderEntity,
  SunbedOrderState,
  SunbedOrderUtils,
} from '../../../../../services/sunbeds';
import {
  SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN,
  UpdateOrderNumberModalOptions,
  UPDATE_ORDER_NUMBER_MODAL_OPEN,
  SunbedOrderInternalRemarkModal,
  SunbedOrderInternalRemarkModalOptions,
  UPDATE_CUSTOMER_REFERENCE_MODAL_OPEN,
  SunbedOrderCustomerReferenceModal,
  UpdateCustomerReferenceModalOptions,
  SUNBED_ORDER_CUSTOMER_REMARK_MODAL_OPEN,
  SunbedOrderCustomerRemarkModal,
  SunbedOrderCustomerRemarkModalOptions,
} from '../../modals';
import { Core } from '../../../../../services/core';
import { WeekCalendarUtils } from '../../../../../components/week-calendar';

export default Vue.extend({
  name: 'ordersViewGeneral',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  components: {
    SunbedModelImage,
    InternalRemarkModal: SunbedOrderInternalRemarkModal,
    CustomerReferenceModal: SunbedOrderCustomerReferenceModal,
    CustomerRemarkModal: SunbedOrderCustomerRemarkModal,
  },
  data() {
    return {
      metadata: {
        progress_states: [
          SunbedOrderState.REQUESTED,
          SunbedOrderState.SCHEDULING_PRODUCTION,
          SunbedOrderState.SCHEDULING_DELIVERY,
          SunbedOrderState.READY_FOR_PRODUCTION,
          SunbedOrderState.IN_PRODUCTION,
          SunbedOrderState.READY_TO_SHIP,
          SunbedOrderState.SHIPPED,
        ] as Array<SunbedOrderState>,
      },

      DateTime,
    };
  },
  computed: {
    power_consumption(): string {
      return SunbedOrderUtils.getPowerConsumptionText(this.order);
    },
    configurationIDs(): Array<SunbedConfigurationID> {
      return SunbedOrderUtils.getConfigurationIDsOfOrder(this.order);
    },
  },

  methods: {
    onEditSalesOrderNumberClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(UPDATE_ORDER_NUMBER_MODAL_OPEN, {
        title: 'Update sales order number',

        label: 'Sales order number',
        value: this.order.sales_order_number,

        async onSubmit(value: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/sales_order_number`,
            { sales_order_number: value },
          );

          vm.$emit('update:order', response.data);
        },
      } as UpdateOrderNumberModalOptions);
    },
    onEditProductionOrderNumberClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(UPDATE_ORDER_NUMBER_MODAL_OPEN, {
        title: 'Update production order number',

        label: 'Production order number',
        value: this.order.production_order_number,

        async onSubmit(value: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/production_order_number`,
            { production_order_number: value },
          );

          vm.$emit('update:order', response.data);
        },
      } as UpdateOrderNumberModalOptions);
    },
    onEditCustomerReferenceClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(UPDATE_CUSTOMER_REFERENCE_MODAL_OPEN, {
        title: 'Update customer reference',

        label: 'Customer reference',
        value: this.order.reference,

        async onSubmit(value: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/reference`,
            { reference: value },
          );

          vm.$emit('update:order', response.data);
        },
      } as UpdateCustomerReferenceModalOptions);
    },

    onEditInternalRemark(): void {
      const vm = this;
      this.$eventhub.emit(SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN, {
        title: 'Modify internal remark',
        value: this.order.internal_remark?.toString() || '',

        async onSubmit(remark: string) {
          // Only perform request when remark has changed
          if (remark !== vm.order.internal_remark) {
            const response = await Core.getAdapter().put<SunbedOrderEntity>(
              `/sunbeds/orders/${vm.order._meta.guid}/internal-remark`,
              { internal_remark: remark },
            );

            vm.$emit('update:order', response.data);
          }
        },
      } as SunbedOrderInternalRemarkModalOptions);
    },
    onEditCustomerRemark(): void {
      const vm = this;
      this.$eventhub.emit(SUNBED_ORDER_CUSTOMER_REMARK_MODAL_OPEN, {
        title: 'Modify customer remark',
        value: this.order.remark?.toString() || '',

        async onSubmit(remark: string) {
          // Only perform request when remark has changed
          if (remark !== vm.order.remark) {
            const response = await Core.getAdapter().put<SunbedOrderEntity>(
              `/sunbeds/orders/${vm.order._meta.guid}/remark`,
              { remark: remark },
            );

            vm.$emit('update:order', response.data);
          }
        },
      } as SunbedOrderCustomerRemarkModalOptions);
    },

    getProgressVariant(): string {
      return SunbedOrderUtils.getVariantByState(this.order.state);
    },
    getProgressInPercentage(): number {
      switch (this.order.state) {
        case SunbedOrderState.REQUESTED: {
          return 0.05;
        }

        case SunbedOrderState.SCHEDULING_PRODUCTION: {
          return 0.2;
        }

        case SunbedOrderState.SCHEDULING_DELIVERY: {
          return 0.35;
        }

        case SunbedOrderState.READY_FOR_PRODUCTION: {
          return 0.5;
        }

        case SunbedOrderState.IN_PRODUCTION: {
          return 0.65;
        }

        case SunbedOrderState.READY_TO_SHIP: {
          return 0.8;
        }

        case SunbedOrderState.SHIPPED:
        case SunbedOrderState.MODIFICATION_IN_PROCESS:
        case SunbedOrderState.CANCELLED: {
          return 1;
        }

        default: {
          return 0;
        }
      }
    },
    getProgressStateDescription(state: SunbedOrderState): string | undefined {
      switch (state) {
        case 'requested': {
          return DateTime.fromMillis(this.order._meta.created).toFormat('dd-MM-yyyy');
        }

        case 'ready_to_ship': {
          return this.order.schedule.shipping_week
            ? WeekCalendarUtils.getHumanReadableISOWeek(this.order.schedule.shipping_week)
            : undefined;
        }

        default: {
          return undefined;
        }
      }
    },

    getCountryNameByCode(code: string) {
      return SunbedOrderUtils.getCountryNameByCode(code);
    },
    getOrderValueDescription(): string {
      return SunbedOrderUtils.getOrderValueDescription(this.order);
    },
  },
});
