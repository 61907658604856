import Organizations from './organizations';
import Sunbeds from './sunbeds';
import Parts from './parts';

export const EnglishLocale = {
  plural: {
    seconds: 'second | seconds',
    minutes: 'minute | minutes',
    hours: 'hour | hours',
    days: 'day | days',

    orders: 'order | orders',
  },

  datatable: {
    search: 'Search...',
    loading: 'Gathering data...',
    no_data: 'No results found',
    info: 'Showing {from} - {to} of {total}',

    actions: {
      choose_action: 'Choose an action',
    },
  },

  authentication: {
    logout: 'Logout',
  },

  forms: {
    create: 'Submit',
    update: 'Update',

    required: 'Required',
  },

  modals: {
    cancel: 'Cancel',
  },

  wizard: {
    previous: 'Previous',
    next: 'Next step',
  },

  organizations: Organizations,
  sunbeds: Sunbeds,
  parts: Parts,
};
