
import Vue, { PropType } from 'vue';
import { Calendar } from 'v-calendar';
import { DateTime } from 'luxon';
import { WeekCalendarUtils } from '../utils';
import { CalendarAttribute, CalendarDate, DatePickerRange } from '../interfaces';

export default Vue.extend({
  name: 'weekCalendarComponent',
  components: {
    // DatePicker,
    Calendar,
  },
  props: {
    value: {
      type: String as PropType<string>,
    },
    minDate: {
      type: Date as PropType<Date>,
      default(): Date {
        return DateTime.now().startOf('week').startOf('day').toJSDate();
      },
    },
    maxDate: {
      type: Date as PropType<Date | undefined>,
    },
  },
  data() {
    return {
      metadata: {
        value: undefined as undefined | string,
        range: undefined as DatePickerRange | undefined,
        attributes: [] as Array<CalendarAttribute>,
      },
    };
  },
  computed: {
    calendarAttributes(): Array<CalendarAttribute> {
      const attributes = (this.metadata.attributes || []).filter(
        (row) => row.key !== 'ISO_WEEK',
        [],
      );

      if (this.metadata.range) {
        attributes.push({
          key: 'ISO_WEEK',
          highlight: true,
          dates: this.metadata.range,
        });
      }

      return attributes;
    },
  },

  beforeMount() {
    this.$set(this.metadata, 'value', this.value);
    if (this.metadata.value === undefined) {
      const isoWeek = WeekCalendarUtils.getISOWeekFromDateTime(DateTime.fromJSDate(this.minDate));
      this.$set(this.metadata, 'value', isoWeek);
      this.$emit('input', isoWeek);
    }

    if (!this.metadata.value) {
      return;
    }

    // Set range based on value
    this.$set(this.metadata, 'range', this.getDateRangeFromISOWeek(this.metadata.value as string));
  },
  async mounted() {
    await this.$nextTick();

    if (this.metadata.value) {
      // Move to value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.calendar as any).move(
        WeekCalendarUtils.getDateTimeFromISOWeek(this.metadata.value as string),
      );
    }
  },

  methods: {
    onCalendarDateClick(date: CalendarDate): void {
      // Prohibit disabled dates
      if (date.isDisabled !== false) {
        return;
      }

      const dateTime = DateTime.fromJSDate(date.date);
      const isoWeek = WeekCalendarUtils.getISOWeekFromDateTime(dateTime);
      const range = this.getDateRangeFromISOWeek(isoWeek);

      this.$set(this.metadata, 'range', range);
      this.$set(this.metadata, 'value', isoWeek);
      this.$emit('input', isoWeek);
    },

    getDateRangeFromISOWeek(isoWeek: string): DatePickerRange {
      const dateTime = WeekCalendarUtils.getDateTimeFromISOWeek(isoWeek);
      return {
        start: dateTime.startOf('week').startOf('day').toJSDate(),
        end: dateTime.endOf('week').endOf('day').toJSDate(),
      };
    },
  },
});
