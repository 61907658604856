var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 form-group"},[_c('label',[_vm._v(" Description "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'text']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.description.$model),expression:"$v.document.description.$model",modifiers:{"trim":true}}],ref:"input:description",class:[
          'form-control form-control-lg',
          { 'is-invalid': _vm.$v.document.description.$error },
        ],attrs:{"type":"text","disabled":_vm.isDisabled,"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.description.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.description, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-3 form-group"},[_c('label',[_vm._v(" Article number "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tag']}})],1)])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.document.article_number.$model),expression:"$v.document.article_number.$model",modifiers:{"trim":true}}],class:[
          'form-control form-control-lg',
          { 'is-invalid': _vm.$v.document.article_number.$error },
        ],attrs:{"type":"text","disabled":_vm.isDisabled || _vm.action !== 'CREATE',"readonly":_vm.isReadOnly},domProps:{"value":(_vm.$v.document.article_number.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.document.article_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-12 col-lg-3 form-group"},[_c('label',[_vm._v(" Model "),(_vm.action === 'CREATE')?_c('sup',{domProps:{"textContent":_vm._s(_vm.$t('forms.required'))}}):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tools']}})],1)])]),_c('sunbed-model-vue-select',{class:['form-control-lg', { 'is-invalid': _vm.$v.document.model.$error }],attrs:{"disabled":_vm.isDisabled || _vm.action !== 'CREATE',"value":_vm.metadata.model},on:{"input":_vm.onSunbedModel}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }