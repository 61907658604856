
import {
  DatatableConfig,
  DatatableHttpFetch,
  DatatableHttpOptions,
  DatatableInstance,
  DatatableMixin,
  DatatableResponseLegacy,
} from '@vedicium/metronic-vue';
import { DotNotatedPath } from '@vedicium/object-mutations';
import { DatatableUtil, HttpRequestConfig } from '@vedicium/vue-core';
import Vue from 'vue';
import { WeekCalendarUtils } from '../../../components/week-calendar';
import { APP_ERROR_EVENT } from '../../../constants';
import { Core } from '../../../services/core';
import { SunbedOrderEntity, SunbedOrdersStore, SunbedOrderUtils } from '../../../services/sunbeds';

import { OrdersDatatableInformationDropdown, OrdersDatatableFiltersDropdown } from './components';

export default Vue.extend({
  name: 'ordersOverviewView',
  mixins: [DatatableMixin],
  components: {
    OrdersDatatableInformationDropdown,
    OrdersDatatableFiltersDropdown,
  },
  data() {
    const session = DatatableUtil.getSessionFromQuery(this.$route.query);

    return {
      isExportLoading: false,

      datatableSession: session,
      datatable: {
        url: SunbedOrdersStore.url,
        fields: [
          {
            name: 'state',
            title: 'Status',
            sortField: 'state',
            titleClass: 'w-50px',
            dataClass: 'w-50px',
          },
          {
            name: 'configuration.model.name',
            title: 'Model',
            sortField: 'configuration.model.name',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
          {
            name: 'configuration.type.name',
            title: 'Type',
            sortField: 'configuration.type.name',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
          {
            name: 'amount',
            title: 'No. of items',
            sortField: 'amount',
            titleClass: 'w-50px',
            dataClass: 'w-50px',
          },
          {
            name: 'production_order_number',
            title: 'Order number',
            sortField: 'production_order_number',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
          {
            name: 'reference',
            title: 'Customer reference',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
          {
            name: 'organization.name',
            title: 'Customer',
            sortField: 'organization.name',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
          {
            name: '',
            title: 'Value',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
            formatter: (entity: SunbedOrderEntity) =>
              SunbedOrderUtils.getOrderValueDescription(entity),
          },
          {
            name: 'schedule.production_week',
            title: 'Production week',
            titleClass: 'w-100px',
            dataClass: 'w-200px',
            formatter: (value: string) =>
              value ? WeekCalendarUtils.getHumanReadableISOWeek(value) : '-',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
        ],

        searchFields: [
          'configuration.model.name',
          'configuration.type.name',
          'organization.name',
          'production_order_number',
          'sales_order_number',
          'production.identification.serial_numbers.serial_number',
        ] as Array<DotNotatedPath<SunbedOrderEntity>>,

        filters: session.filters || {},
        sort: [],

        fetch: this.onFetch(),
      } as DatatableConfig,

      SunbedOrderUtils,
    };
  },

  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Orders' }]);

    // Next tick
    await this.$nextTick();

    // Set datatable config
    const session = this.datatableSession;

    // Get datatable
    const datatable = this.$refs['datatable'] as DatatableInstance<SunbedOrderEntity>;

    // Configure datatable
    DatatableUtil.setSession(datatable, session);

    await this.$nextTick();

    datatable.reload();
  },

  methods: {
    onFetch(): DatatableHttpFetch<SunbedOrderEntity> {
      return async (
        url: string,
        httpOptions: DatatableHttpOptions,
      ): Promise<DatatableResponseLegacy<SunbedOrderEntity>> => {
        // Set route
        const session = DatatableUtil.getSessionFromOptions(httpOptions);
        const query = DatatableUtil.getQueryFromSession(session);

        // Replace router
        this.$router
          .replace({
            path: this.$route.path,
            query: query,
          })
          .catch(() => false);

        return DatatableUtil.fetch<SunbedOrderEntity>(Core)(
          url,
          httpOptions,
        ) as DatatableResponseLegacy<SunbedOrderEntity>;
      };
    },

    async onFiltersChange(filters: DatatableConfig['filters']): Promise<void> {
      this.$set(this.datatable, 'filters', filters);

      await this.$nextTick();

      (this.$refs['datatable'] as DatatableInstance).refresh();
    },

    async onExportButtonClick(): Promise<void> {
      this.$set(this, 'isExportLoading', true);
      try {
        const httpRequestConfig: HttpRequestConfig = {
          params: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };

        // Add filters
        Object.entries(this.datatable.filters as Record<string, unknown>).forEach(
          ([key, value]) => {
            httpRequestConfig.params[`filter[${key}]`] = value;
          },
        );

        await Core.getAdapter().get(`/sunbeds/orders/export`, httpRequestConfig);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isExportLoading', false);
      }
    },
  },
});
