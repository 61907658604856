import { SunbedConfigurationSession } from './sunbed-configuration.interface';

export class SunbedOrderDto {
  organization: string | undefined;
  country: string | undefined;
  amount: number | undefined;
  reference: string | undefined;
  remark: string | undefined;
  desired_delivery_week: string | undefined;
  custom_price: number | undefined;
  configuration:
    | {
        type: string;
        uv_configuration: {
          uv_type: string;
          identifier: string;
        };

        rated_voltage: string;
        color: string;
        packages: Array<string>;
        options: Array<string>;

        language: string | undefined;
        smartvoice_language: number | undefined;
      }
    | undefined;

  static createFromConfigurationSession(session: SunbedConfigurationSession): SunbedOrderDto {
    const dto = new SunbedOrderDto();

    dto.organization = session.organization?._meta.guid;
    dto.country = session.country;
    dto.amount = session.configuration.amount;
    dto.reference = session.configuration.reference;
    dto.remark = session.configuration.remark;
    dto.desired_delivery_week = session.desired_delivery_week;

    // Add custom price, but only if defined
    if (session.configuration.custom_price) {
      dto.custom_price = session.configuration.custom_price;
    }

    // Map configuration
    dto.configuration = {
      type: session.configuration.type?._meta.guid as string,
      uv_configuration: {
        uv_type: session.configuration.uv_configuration?.uv_type as string,
        identifier: session.configuration.uv_configuration?.identifier as string,
      },

      rated_voltage: session.configuration.rated_voltage?.rated_voltage as string,
      color: session.configuration.color?.guid as string,
      packages: session.configuration.packages.map((row) => row.guid, []),
      options: session.configuration.options
        .filter((row) => row.package === undefined, [])
        .map((row) => row.guid, []),

      language: session.language,
      smartvoice_language: session.smartvoice_language,
    };

    return dto;
  }
}
