
import Vue, { VueConstructor } from 'vue';

import { StateFormMixin } from '../mixins';
import { StateFormInstance } from '../interfaces';
import { Core } from '../../../../../../services/core';
import { SunbedOrderEntity, SunbedOrderState } from '../../../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & StateFormInstance>).extend({
  name: 'cancelledStatusFormComponent',
  mixins: [StateFormMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    humanReadableState(): string {
      return (
        this.$tu(`sunbeds.orders.states.${SunbedOrderState.CANCELLED}`)?.toString() ||
        SunbedOrderState.CANCELLED
      );
    },
  },

  methods: {
    async onSubmit(): Promise<SunbedOrderEntity> {
      this.$set(this, 'isLoading', true);
      try {
        // Update state
        const response = await Core.getAdapter().put<SunbedOrderEntity>(
          `/sunbeds/orders/${this.order._meta.guid}/state`,
          {
            state: SunbedOrderState.CANCELLED,
          },
        );

        return response.data;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
