
import Vue, { PropType } from 'vue';
import {
  SunbedModelConfigurationID,
  SunbedModelEntity,
  SunbedModelTypeEntity,
} from '../../../../services/sunbeds';

import SunbedModelImage from './model.image.vue';

export default Vue.extend({
  name: 'sunbedModelCard',
  props: {
    model: {
      type: Object as PropType<SunbedModelEntity>,
    },
    types: {
      type: Array as PropType<Array<SunbedModelTypeEntity>>,
    },
  },
  components: {
    SunbedModelImage,
  },
  computed: {
    sunbedImageClasses(): Array<string> {
      const classes: Array<string> = ['w-100 justify-content-center align-self-center'];

      switch (this.model.configuration_id) {
        case SunbedModelConfigurationID.LUXURA_X10:
        case SunbedModelConfigurationID.SEECRET_C700: {
          break;
        }

        case SunbedModelConfigurationID.LUXURA_JEWEL:
        case SunbedModelConfigurationID.LUXURA_VEGAZ_9200:
        case SunbedModelConfigurationID.LUXURA_VEGAZ_8200:
        case SunbedModelConfigurationID.LUXURA_X7:
        case SunbedModelConfigurationID.LUXURA_X5:
        case SunbedModelConfigurationID.LUXURA_X3:
        case SunbedModelConfigurationID.LUXURA_V6:
        case SunbedModelConfigurationID.LUXURA_V8:
        default: {
          classes.push('ml-n4');
          break;
        }
      }

      return classes;
    },
  },
});
