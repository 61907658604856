
import { MutationsService } from '@vedicium/object-mutations';
import { OrganizationEntity, ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../../constants';
import { Core } from '../../../../../../services/core';
import {
  OrganizationEntityDto,
  OrganizationInformationEntity,
} from '../../../../../../services/organizations';

import { OrganizationPreferencesForm } from '../../../components';

export default Vue.extend({
  name: 'organizationsViewPreferencesCard',
  components: {
    OrganizationPreferencesForm,
  },
  props: {
    organization: {
      type: Object as PropType<OrganizationEntity>,
    },
    information: {
      type: Object as PropType<OrganizationInformationEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        organization: JSON.parse(JSON.stringify(this.organization)) as OrganizationEntity,
        information: JSON.parse(JSON.stringify(this.information)) as OrganizationInformationEntity,

        dto: undefined as OrganizationEntityDto | undefined,
      },
    };
  },

  beforeMount() {
    this.$set(this.metadata, 'dto', {
      ...this.metadata.organization,
      information: this.metadata.information,
    });
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([(this.$refs.preferencesForm as Vue).$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(
          this.information,
          this.metadata.dto?.information as unknown as OrganizationInformationEntity,
          [
            'preferences.country',
            'preferences.language',
            'preferences.smartvoice_language',
            'preferences.rated_voltage',
            'preferences.uv_type',
            'preferences.tanning_intensity',
            'settings.prohibit_uv_type_5',
          ],
        );

        if (mutations.length > 0) {
          const { data: entity } = await Core.getAdapter().patch<OrganizationInformationEntity>(
            `/organizations/${this.organization._meta.guid}/information`,
            mutations,
          );

          (this.$parent as Vue).$emit('update:information', entity);
          this.$set(this.metadata, 'information', JSON.parse(JSON.stringify(entity)));
          this.$set(
            this.metadata.dto as OrganizationEntityDto,
            'information',
            this.metadata.information,
          );
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
