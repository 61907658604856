<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15 bg-primary"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <a href="#" class="flex-column-auto">
            <img :src="require('@/assets/images/logo.light.svg')" class="h-65px w-auto" />
          </a>
          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mt-10 mb-5 text-white">VDL Hapro - LuCAS</h3>
            <!-- <p class="font-weight-lighter text-white opacity-80">Lorem ipsum</p> -->
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-15">
            <a
              href="https://vedicium.com"
              target="_blank"
              class="opacity-70 font-weight-bold text-white"
            >
              &copy; {{ new Date().getFullYear() }} VeDicium
            </a>

            <div class="d-flex">
              <a href="#" class="text-white">Privacy</a>
              <a href="#" class="text-white ml-10">Legal</a>
              <a href="#" class="text-white ml-10">Contact</a>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div class="d-flex flex-column-fluid flex-center mt-10 mt-lg-n20">
          <slot name="default" />
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '~@vedicium/metronic-vue/src/assets/sass/pages/login/login-1.scss';
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'layout-public',
  data() {
    return {
      isMounted: false,
    };
  },
});
</script>
