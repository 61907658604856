
import { MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../constants';
import { Core } from '../../../../../services/core';

import { OptionPartEntity, OptionPartsStore } from '../../../../../services/parts';
import { PartsOptionsRatedVoltagesForm } from '../components';

export default Vue.extend({
  name: 'partsOptionsViewInformation',
  props: {
    option: {
      type: Object as PropType<OptionPartEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        option: JSON.parse(JSON.stringify(this.option)) as OptionPartEntity,
      },
    };
  },
  components: {
    PartsOptionsRatedVoltagesForm,
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([]);
    },
    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        // Filter out power consumption voltages without a wattage.
        this.metadata.option.power_consumption = this.metadata.option.power_consumption.filter(
          (row) => row.voltages?.some((voltage) => voltage.wattage !== undefined, []),
        );

        const mutations = MutationsService.getMutationsByComparison(
          this.option,
          this.metadata.option,
          ['power_consumption'],
        );

        if (mutations.length > 0) {
          const entity = await OptionPartsStore.update(this.option._meta.guid, mutations);

          this.$emit('update:option', entity);
          this.$set(this.metadata, 'option', JSON.parse(JSON.stringify(entity)));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.ratedVoltagesForm as any).applyRatedVoltages();

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
