
import Vue, { PropType } from 'vue';
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { MutationsService } from '@vedicium/object-mutations';

import { OptionPartEntity, OptionPartsStore, PackagePartDto } from '../../../../../services/parts';
import { Core } from '../../../../../services/core';

export default Vue.extend({
  name: 'partsPackagesOptionsForm',
  mixins: [DatatableMixin],
  props: {
    document: {
      type: Object as PropType<PackagePartDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: OptionPartsStore.url,
        fields: [
          {
            name: 'description',
            title: 'Description',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'article_number',
            title: 'Article number',
            sortField: 'article_number',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'type',
            title: 'Type',
            sortField: 'type',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
            formatter: (type: string) => this.$tu(`parts.options.types.${type}`) || type,
          },
          {
            name: 'package:option:included',
            title: 'Included',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
        ],

        sort: [{ field: 'description', direction: 'asc' }],

        fetch: DatatableUtil.fetch(Core),
        httpOptions: {
          params: {
            'filter[models]': this.document.model,
          },
        },
      } as DatatableConfig,
    };
  },
  validations: {},

  methods: {
    setIncluded(option: OptionPartEntity, value: boolean): void {
      if (value === true) {
        const { options } = MutationsService.apply(this.document, [
          { action: 'array:push_unique', field: 'options', value: option._meta.guid },
        ]);

        this.$set(this.document, 'options', options);
      } else {
        const { options } = MutationsService.apply(this.document, [
          { action: 'array:remove', field: 'options', value: option._meta.guid },
        ]);

        this.$set(this.document, 'options', options);
      }
    },
  },
});
