
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

import { APP_ERROR_EVENT } from '../../../constants';
import { Core } from '../../../services/core';
import { WeekCalendarModalOptions } from '../interfaces';
import { WEEK_CALENDAR_MODAL_CLOSE, WEEK_CALENDAR_MODAL_OPEN } from '../week-calendar.constants';

import WeekCalendarComponent from './week-calendar.component.vue';

export default Vue.extend({
  name: 'weekCalendarModalComponent',
  mixins: [Mixins.Modal],
  components: {
    WeekCalendarComponent,
  },
  data() {
    return {
      isLoading: false,

      options: undefined as WeekCalendarModalOptions | undefined,

      metadata: {
        value: undefined as string | undefined,
      },
    };
  },
  validations: {
    metadata: {
      value: {
        required,
      },
    },
  },

  mounted() {
    this.$eventhub.on(WEEK_CALENDAR_MODAL_OPEN, this.open);
    this.$eventhub.on(WEEK_CALENDAR_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(WEEK_CALENDAR_MODAL_OPEN, this.open);
    this.$eventhub.off(WEEK_CALENDAR_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit() {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.metadata.value as string);
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: WeekCalendarModalOptions): void {
      this.$set(this, 'options', options || undefined);
      this.$set(this.metadata, 'value', options?.value || undefined);

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },
  },
});
