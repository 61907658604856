import { render, staticRenderFns } from "./status-dropdown.component.vue?vue&type=template&id=2aa1cd60&"
import script from "./status-dropdown.component.vue?vue&type=script&lang=ts&"
export * from "./status-dropdown.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports