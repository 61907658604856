
import Vue, { PropType } from 'vue';
import { SunbedOrderEntity, SunbedOrderUtils, SunbedUtils } from '../../../../../services/sunbeds';

export default Vue.extend({
  name: 'ordersViewConfiguration',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },

  methods: {
    getCountryName(): string | undefined {
      return SunbedOrderUtils.getCountryNameByCode(this.order.country);
    },
    getLightSourceLocations() {
      return SunbedUtils.getLightSourceLocationsByModel(this.order.configuration.model);
    },
    getLightSourcesOfLocation(location: 'bench' | 'canopy') {
      return this.order.configuration.uv_configuration.light_sources.filter(
        (row) => row.location === location,
        [],
      );
    },
    isFacetannerEnabled(): boolean {
      return SunbedOrderUtils.isFacetannerEnabled(this.order);
    },
    getHumanReadableSmartvoiceLanguage(value: number): string {
      return SunbedOrderUtils.getHumanReadableSmartvoiceLanguage(value);
    },

    hasPackages(): boolean {
      return this.order.configuration.packages.length > 0;
    },
    hasOptions(): boolean {
      return this.order.configuration.options.length > 0;
    },
  },
});
