
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { Validation } from 'vuelidate';

import { ConfiguratorService, SunbedOrderDto, SunbedOrderEntity } from '../../../services/sunbeds';
import {
  OrdersDetailsForm,
  OrdersModelsForm,
  OrdersConfigurationForm,
  OrdersPriceAndAmountForm,
  OrdersSummaryForm,
} from './components';
import { Core } from '../../../services/core';
import { APP_ERROR_EVENT } from '../../../constants';
import { PropType } from 'vue/types/v3-component-props';

export default Vue.extend({
  name: 'ordersCreateView',
  mixins: [Mixins.KTWizardV4],
  props: {
    action: {
      type: String as PropType<'CREATE' | 'UPDATE'>,
      default: 'CREATE',
    },
  },
  components: {
    OrdersDetailsForm,
    OrdersModelsForm,
    OrdersConfigurationForm,
    OrdersPriceAndAmountForm,
    OrdersSummaryForm,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      metadata: {
        session: ConfiguratorService.createSession(),
        debug: this.$route.query.debug !== undefined,

        wizard: {
          index: 0,
        },
      },
    };
  },

  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Orders', route: '/orders' },
      { title: 'Configurator' },
    ]);

    // Get session
    if (this.$route.query.session !== undefined) {
      const session = ConfiguratorService.getSession(this.$route.query.session as string);
      ConfiguratorService.setSession(session);
      this.$set(this.metadata, 'session', session);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    // (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async onNext() {
      const currentStep = this.metadata.wizard.index;

      try {
        await this.beforeWizardStepChange(currentStep + 1, currentStep);
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.wizard as any).next();
    },

    async onSubmit() {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        // Create DTO and create the order
        const dto = SunbedOrderDto.createFromConfigurationSession(this.metadata.session);

        switch (this.action) {
          case 'CREATE': {
            const response = await Core.getAdapter().post<SunbedOrderEntity>(
              '/sunbeds/configuration/order',
              dto,
            );

            this.$router.push(`/orders/view/${response.data._meta.guid}`);
            break;
          }

          case 'UPDATE': {
            const response = await Core.getAdapter().put<SunbedOrderEntity>(
              `/sunbeds/configuration/order/${this.$route.params.guid}`,
              dto,
            );

            this.$bvToast.toast('Document successfully updated', {
              title: 'Update successful',
              variant: 'success',
              solid: true,
            });

            this.$router.push(`/orders/view/${response.data._meta.guid}`);
            break;
          }

          default: {
            throw new Error(`Form action '${this.action}' not supported`);
          }
        }
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },

    async beforeWizardStepChange(step: number, oldStep: number): Promise<void> {
      // When going to the next step, validate the current step
      if (step > oldStep) {
        await this.validate(oldStep);
      }
    },

    async validate(step?: number): Promise<void> {
      const validationArray: Array<Validation> = [];

      if (step === 0) {
        validationArray.push((this.$refs.detailsForm as Vue).$v);
      }

      if (step === 1) {
        validationArray.push((this.$refs.modelsForm as Vue).$v);
      }

      if (step === 2) {
        validationArray.push((this.$refs.configurationForm as Vue).$v);
      }

      if (step === 3) {
        validationArray.push((this.$refs.priceAndAmountForm as Vue).$v);
      }

      if (step === undefined || step === 4) {
        validationArray.push((this.$refs.summaryForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(validationArray);
    },
  },
});
