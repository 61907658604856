
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { BModal } from 'bootstrap-vue';
import { required, numeric } from 'vuelidate/lib/validators';

import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN,
  SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_CLOSE,
} from './constants';
import { SunbedOrderPrintIdentificationLabelOptions } from './interfaces';

export default Vue.extend({
  name: 'printIdentificationLabelModal',
  mixins: [Mixins.Modal],
  data() {
    return {
      isLoading: false,

      options: undefined as SunbedOrderPrintIdentificationLabelOptions | undefined,

      metadata: {
        count: undefined as number | undefined,
      },
    };
  },
  validations: {
    metadata: {
      count: {
        required,
        numeric,
      },
    },
  },

  mounted() {
    this.$eventhub.on(SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN, this.open);
    this.$eventhub.on(SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_CLOSE, this.close);
  },
  beforeDestroy() {
    this.$eventhub.off(SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN, this.open);
    this.$eventhub.off(SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_CLOSE, this.close);
  },

  methods: {
    async validate() {
      return ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit({
            count: Number(this.metadata.count) as number,
          });
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open(options?: SunbedOrderPrintIdentificationLabelOptions): void {
      this.$set(this, 'options', options || undefined);
      this.$set(this, 'metadata', { count: 8 });

      this.$nextTick(() => this.$v.$reset());

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();

      (this.$refs['input:count'] as HTMLInputElement).focus();
    },
  },
});
