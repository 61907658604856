import { DateTime } from 'luxon';

export class WeekCalendarUtils {
  static DATETIME_WEEK_FORMAT = "kkkk-'W'WW";

  /**
   * Get Date Time from ISO week
   *
   * @param isoWeek ISO week (format: kkkk-'W'WW)
   * @returns DateTime
   */
  static getDateTimeFromISOWeek(isoWeek: string): DateTime {
    return DateTime.fromFormat(isoWeek, this.DATETIME_WEEK_FORMAT);
  }

  /**
   * Get ISO week from date time
   * @param dateTime DateTime
   * @returns ISO Week (format: kkkk-'W'WW)
   */
  static getISOWeekFromDateTime(dateTime: DateTime): string {
    return dateTime.toFormat(this.DATETIME_WEEK_FORMAT);
  }

  /**
   * Get human-readable ISO week (format: 'Week' WW, kkkk)
   *
   * @param isoWeek ISO week (format: kkkk-'W'WW)
   * @returns String
   */
  static getHumanReadableISOWeek(isoWeek: string): string {
    return this.getDateTimeFromISOWeek(isoWeek).toFormat("'Week' WW',' kkkk");
  }
}
