
import Vue, { PropType } from 'vue';
import { DateTime } from 'luxon';
import { BDropdown } from 'bootstrap-vue';
import { SunbedModelVueSelect } from '../../../configurations/components';
import {
  SunbedModelEntity,
  SunbedModelsStore,
  SunbedOrderState,
  SunbedOrderUtils,
} from '../../../../../services/sunbeds';
import { OrganizationVueSelect } from '../../../organizations';
import { DatatableFiltersDropdownFilters } from '../../interfaces';
import { DatatableConfig } from '@vedicium/metronic-vue';
import { WeekCalendarUtils, WeekCalendarComponent } from '../../../../../components/week-calendar';
import { OrganizationsStore } from '../../../../../services/organizations';

const SUNBED_MODEL_FILTER = 'configuration.model.guid|eq';
const ORGANIZATIONS_FILTER = 'organization.guid|eq';
const STATE_FILTER = 'state|eq';
const PRODUCTION_WEEK_FILTER = 'schedule.production_week|eq';

export default Vue.extend({
  name: 'ordersDatatableFiltersDropdown',
  components: {
    WeekCalendarComponent,
    SunbedModelVueSelect,
    OrganizationVueSelect,
  },
  props: {
    filters: {
      type: Object as PropType<DatatableConfig['filters']>,
    },
  },
  data() {
    return {
      isDropdownHideable: true,
      isApplied: false,

      metadata: {
        filters: {
          model: undefined as SunbedModelEntity | undefined, // SUNBED_MODEL_FILTER
          organization: undefined as OrganizationEntity | undefined, // ORGANIZATIONS_FILTER
          states: [], // STATE_FILTER
          production_week: null, // PRODUCTION_WEEK_FILTER
        } as DatatableFiltersDropdownFilters,

        production_week: {
          minDate: null,
          maxDate: null,
        },
        states: [
          SunbedOrderState.REQUESTED,
          SunbedOrderState.SCHEDULING_PRODUCTION,
          SunbedOrderState.SCHEDULING_DELIVERY,
          SunbedOrderState.READY_FOR_PRODUCTION,
          SunbedOrderState.IN_PRODUCTION,
          SunbedOrderState.READY_TO_SHIP,
          SunbedOrderState.SHIPPED,
          SunbedOrderState.MODIFICATION_IN_PROCESS,
          SunbedOrderState.CANCELLED,
        ],

        filtersOnShow: undefined as DatatableFiltersDropdownFilters | undefined,
      },

      SunbedOrderUtils,
      DateTime,
    };
  },

  computed: {
    productionWeekText(): string {
      if (!this.metadata.filters.production_week) {
        return 'Please select...';
      }

      return WeekCalendarUtils.getHumanReadableISOWeek(this.metadata.filters.production_week);
    },
  },

  mounted() {
    this.setFilterValues(this.filters);
  },

  methods: {
    async setFilterValues(filters: DatatableConfig['filters']): Promise<void> {
      if (filters === undefined) {
        return;
      }

      // Set sunbed model filter
      if (
        filters[SUNBED_MODEL_FILTER] !== undefined &&
        this.metadata.filters.model?._meta.guid !== filters[SUNBED_MODEL_FILTER]
      ) {
        try {
          const sunbedModelEntity = await SunbedModelsStore.get(
            filters[SUNBED_MODEL_FILTER] as string,
          );

          this.$set(this.metadata.filters, 'model', sunbedModelEntity);
        } catch (e) {
          console.warn(e);
        }
      }

      // Set organization filter
      if (
        filters[ORGANIZATIONS_FILTER] !== undefined &&
        this.metadata.filters.organization?._meta.guid !== filters[ORGANIZATIONS_FILTER]
      ) {
        try {
          const organizationEntity = await OrganizationsStore.get(
            filters[ORGANIZATIONS_FILTER] as string,
          );
          this.$set(this.metadata.filters, 'organization', organizationEntity);
        } catch (e) {
          console.warn(e);
        }
      }

      // Set states filter
      if (filters[STATE_FILTER] !== undefined) {
        this.$set(this.metadata.filters, 'states', (filters[STATE_FILTER] as string).split(','));
      }

      // Set production week filter
      if (filters[PRODUCTION_WEEK_FILTER] !== undefined) {
        this.$set(this.metadata.filters, 'production_week', filters[PRODUCTION_WEEK_FILTER]);
      }
    },

    async onSelectInput() {
      // Disable the ability to hide the dropdown
      // This is because of issues when selection an option which is outside of the dropdown
      this.$set(this, 'isDropdownHideable', false);
      setTimeout(() => this.$set(this, 'isDropdownHideable', true), 400);
    },

    onDatePickerInput(isoWeek: string | null) {
      if (!isoWeek || isoWeek == 'Invalid DateTime') {
        return;
      }

      // Hide dropdown and set production week
      (this.$refs['select:production_week:dropdown'] as BDropdown).hide();
      this.$set(this.metadata.filters, 'production_week', isoWeek);

      this.onSelectInput();
    },
    onStateCheckbox(state: SunbedOrderState, value: boolean): void {
      switch (value) {
        case true: {
          if (this.metadata.filters.states.includes(state) === true) {
            return;
          }

          this.metadata.filters.states.push(state);
          break;
        }

        case false:
        default: {
          this.$set(
            this.metadata.filters,
            'states',
            this.metadata.filters.states.filter((row) => row !== state, []),
          );
        }
      }
    },

    onApplyButtonClick(): void {
      this.$set(this, 'isApplied', true);

      // Send a filter change
      this.onFilterChange();

      // Hide dropdown
      (this.$refs['dropdown'] as BDropdown).hide();
    },
    onResetButtonClick(): void {
      this.$set(this, 'isApplied', true);

      // Reset all filters
      this.$set(this.metadata.filters, 'model', undefined);
      this.$set(this.metadata.filters, 'organization', undefined);
      this.$set(this.metadata.filters, 'states', []);
      this.$set(this.metadata.filters, 'production_week', null);

      // Send a filter change
      this.onFilterChange();

      // Hide dropdown
      (this.$refs['dropdown'] as BDropdown).hide();
    },
    onFilterChange(): void {
      // Create filters
      const filters: DatatableConfig['filters'] = {
        [SUNBED_MODEL_FILTER]: this.metadata.filters.model?._meta.guid,
        [ORGANIZATIONS_FILTER]: this.metadata.filters.organization?._meta.guid,
        [STATE_FILTER]: this.metadata.filters.states.join(',') || undefined,
        [PRODUCTION_WEEK_FILTER]: this.metadata.filters.production_week || undefined,
      };

      this.$emit('change', filters);
    },

    onDropdownShow(): void {
      this.$set(this, 'isApplied', false);
      this.$set(this.metadata, 'filtersOnShow', Object.assign({}, this.metadata.filters));
    },
    onDropdownHide(e: Event): void {
      if (this.isDropdownHideable === false) {
        e.preventDefault();
        return;
      }

      // If fitlers are not applied, revert to filters on show
      if (this.isApplied === false) {
        this.$set(this, 'filters', this.metadata.filtersOnShow);
        return;
      }
    },
  },
});
