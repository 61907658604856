
import Vue, { PropType } from 'vue';
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { DatatableUtil } from '@vedicium/vue-core';
import { Mutation, MutationsService } from '@vedicium/object-mutations';

import {
  SunbedModelEntity,
  SunbedModelTypeColorMode,
  SunbedModelTypeDto,
  SunbedModelTypeEntity,
} from '../../../../../../../services/sunbeds';
import { Core } from '../../../../../../../services/core';
import { ColorPartEntity, ColorPartsStore } from '../../../../../../../services/parts';

export default Vue.extend({
  name: 'configurationViewTypesColorsCompatibilityForm',
  mixins: [DatatableMixin],
  props: {
    model: {
      type: Object as PropType<SunbedModelEntity>,
    },
    document: {
      type: Object as PropType<SunbedModelTypeDto>,
    },
  },
  data() {
    return {
      datatable: {
        url: ColorPartsStore.url,
        fields: [
          {
            name: 'description',
            title: 'Description',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'article_number',
            title: 'Article number',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'mode',
            title: 'Mode',
            titleClass: 'w-150px',
            dataClass: 'w-150px',
          },
        ],

        fetch: DatatableUtil.fetch(Core),
        httpOptions: {
          params: {
            'filter[models]': this.model._meta.guid,
          },
        },
      } as DatatableConfig,

      metadata: {
        modes: Object.values(SunbedModelTypeColorMode),
      },
    };
  },

  methods: {
    getColorCompatibilityItem(
      entity: ColorPartEntity,
    ): SunbedModelTypeEntity['parts']['colors'][number] | undefined {
      return this.document.parts.colors.find((row) => row.guid === entity._meta.guid);
    },
    getMode(entity: ColorPartEntity): SunbedModelTypeColorMode {
      return this.getColorCompatibilityItem(entity)?.mode || SunbedModelTypeColorMode.NOT_AVAILABLE;
    },

    isAvailable(entity: ColorPartEntity): boolean {
      return this.getColorCompatibilityItem(entity) !== undefined;
    },
    isModeSelectorDisabled(entity: ColorPartEntity): boolean {
      const includedByDefaultOption = this.document.parts.colors.find(
        (row) => row.mode === SunbedModelTypeColorMode.INCLUDED_BY_DEFAULT,
      );

      if (includedByDefaultOption === undefined) {
        return false;
      }

      return includedByDefaultOption.guid !== entity._meta.guid;
    },

    onModeChange(entity: ColorPartEntity, value: SunbedModelTypeColorMode): void {
      const mutations: Array<Mutation<SunbedModelTypeDto>> = [];

      // Create mutations based on the mode
      switch (value) {
        case SunbedModelTypeColorMode.AVAILABLE:
        case SunbedModelTypeColorMode.SELECTED_BY_DEFAULT: {
          if (this.getColorCompatibilityItem(entity) !== undefined) {
            mutations.push({
              action: 'array:objects:set_field',
              field: 'parts.colors',
              query: {
                filter: 'eq',
                field: 'guid',
                value: entity._meta.guid,
              },
              mutation: {
                field: 'mode',
                value: value,
              },
            });

            break;
          }

          mutations.push({
            action: 'array:push_unique',
            field: 'parts.colors',
            value: {
              guid: entity._meta.guid,
              mode: value,
            },
          });

          break;
        }

        case SunbedModelTypeColorMode.INCLUDED_BY_DEFAULT: {
          mutations.push({
            action: 'set_field',
            field: 'parts.colors',
            value: [
              {
                guid: entity._meta.guid,
                mode: value,
              },
            ],
          });

          break;
        }

        case SunbedModelTypeColorMode.NOT_AVAILABLE:
        default: {
          mutations.push({
            action: 'array:objects:remove',
            field: 'parts.colors',
            query: {
              filter: 'eq',
              field: 'guid',
              value: entity._meta.guid,
            },
          });

          break;
        }
      }

      // When 'selected by default' is selected, set all
      if (value === SunbedModelTypeColorMode.SELECTED_BY_DEFAULT) {
        mutations.push({
          action: 'array:objects:set_field',
          field: 'parts.colors',
          query: [
            {
              filter: 'eq',
              field: 'mode',
              value: SunbedModelTypeColorMode.SELECTED_BY_DEFAULT,
            },
            {
              filter: 'neq',
              field: 'guid',
              value: entity._meta.guid,
            },
          ],
          mutation: {
            field: 'mode',
            value: SunbedModelTypeColorMode.AVAILABLE,
          },
        });
      }

      const document = MutationsService.apply(this.document, mutations);
      this.$set(this.document.parts, 'colors', document.parts.colors);
    },
  },
});
