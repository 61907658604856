
import Vue, { PropType } from 'vue';
import {
  ConfiguratorService,
  SunbedConfigurationSession,
  SunbedConfigurationType,
  SunbedConfigurationTypeUVConfiguration,
  SunbedModelTypeEntity,
  SunbedModelTypeUVConfiguration,
} from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';

export default Vue.extend({
  name: 'ordersUVConfigurationConfiguration',
  props: {
    model: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
    type: {
      type: Object as PropType<SunbedConfigurationType>,
    },
    session: {
      type: Object as PropType<SunbedConfigurationSession>,
    },
    light_sources: {
      type: Array as PropType<Array<'bench' | 'canopy'>>,
      default: () => ['bench', 'canopy'],
    },
  },
  data() {
    return {
      metadata: {
        colClasses: 'col-12 col-lg-6 form-group',
      },

      FormatUtils,
    };
  },

  methods: {
    getPrice(uv_configuration: SunbedConfigurationTypeUVConfiguration): number | undefined {
      if (ConfiguratorService.isLivePricingEnabled() === false) {
        return undefined;
      }

      return uv_configuration.purchase_price;
    },
    getLightsourcesOfLocation(
      location: SunbedModelTypeUVConfiguration['light_sources'][number]['location'],
    ): SunbedConfigurationType['uv_configurations'][number]['light_sources'] {
      if (this.session.configuration.uv_configuration === undefined) {
        return [];
      }

      return this.session.configuration.uv_configuration.light_sources.filter(
        (row) => row.location === location,
        [],
      ) as SunbedConfigurationType['uv_configurations'][number]['light_sources'];
    },

    hasFacetanner(): boolean {
      return this.session.configuration.uv_configuration?.facetanner.enabled === true || false;
    },
  },
});
