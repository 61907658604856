
import { BDropdown } from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import {
  SunbedOrderEntity,
  SunbedOrderState,
  SunbedOrderUtils,
} from '../../../../../../services/sunbeds';
import {
  SetSunbedOrderStateModalOptions,
  SET_SUNBED_ORDER_STATE_MODAL_OPEN,
} from '../../../modals';

export default Vue.extend({
  name: 'ordersViewTopbarStatusDropdown',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  computed: {
    dropdown(): BDropdown {
      return this.$refs.dropdown as BDropdown;
    },
    classes(): string {
      const classes = ['w-100', 'text-left'] as Array<string>;

      // Remove opacity when disabled
      if (this.hasSelectableActions() === false) {
        classes.push('opacity-100', 'cursor-default');
      }

      return classes.join(' ');
    },
    variant(): string {
      return ['outline-secondary', this.classes].join(' ');
    },

    selectableActionStates(): Array<SunbedOrderState> {
      switch (this.order.state) {
        case SunbedOrderState.REQUESTED:
        case SunbedOrderState.SCHEDULING_PRODUCTION:
        case SunbedOrderState.SCHEDULING_DELIVERY:
        case SunbedOrderState.READY_FOR_PRODUCTION:
        case SunbedOrderState.IN_PRODUCTION: {
          return [SunbedOrderState.MODIFICATION_IN_PROCESS, SunbedOrderState.CANCELLED];
        }

        case SunbedOrderState.READY_TO_SHIP:
        case SunbedOrderState.SHIPPED: {
          return [SunbedOrderState.MODIFICATION_IN_PROCESS];
        }

        case SunbedOrderState.MODIFICATION_IN_PROCESS: {
          return [SunbedOrderState.CANCELLED];
        }

        case SunbedOrderState.CANCELLED:
        default: {
          return [];
        }
      }
    },
  },

  methods: {
    getIcon(): string {
      return SunbedOrderUtils.getIconByOrder(this.order);
    },
    getVariant(): string {
      return SunbedOrderUtils.getVariantByOrder(this.order);
    },
    getIconByState(state: SunbedOrderState): string {
      return SunbedOrderUtils.getIconByState(state);
    },
    getVariantByState(state: SunbedOrderState): string {
      return SunbedOrderUtils.getVariantByState(state);
    },
    hasSelectableActions(): boolean {
      return this.selectableActionStates.length > 0;
    },

    onStateChangeLink(state: SunbedOrderState): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SET_SUNBED_ORDER_STATE_MODAL_OPEN, {
        state,
        order: this.order,

        title: `Move to ${this.$tu(`sunbeds.orders.states.${state}`) || state}`,
        variant: SunbedOrderUtils.getVariantByState(state),

        async onSubmit(entity) {
          vm.$parent.$emit('update:order', entity);
        },
      } as SetSunbedOrderStateModalOptions);
    },
  },
});
