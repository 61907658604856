
import Vue from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import { ColorPartEntity, ColorPartsStore } from '../../../../services/parts';

export default Vue.extend({
  name: 'partsPackagesView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        color: undefined as ColorPartEntity | undefined,
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Colors', route: '/parts/colors' },
    ]);
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      const [color] = await Promise.all([ColorPartsStore.get(this.$route.params.guid)]);

      this.$set(this.metadata, 'color', color);
    } catch (e) {
      console.error(e);
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
      this.$set(this, 'isLoading', false);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: this.metadata.color?.description as string,
    });
  },
});
