import { ColorPartConfigurationID } from './colors.interface';
import { OptionPartConfigurationID } from './options.interface';

/**
 * begin::Manufacturing subline filters
 */
export enum ManufacturingSublineFilterDomain {
  COLOR = 'color',
  OPTION = 'option',
  COUNTRY = 'country',
}

export enum ManufacturingSublineFilterType {
  OR = 'OR',
  AND = 'AND',
  NOT_OR = 'NOT_OR',
  NOT_AND = 'NOT_AND',
}

export interface IManufacturingSublineFilter<Domain> {
  /**
   * The domain of the filter.
   *
   * For example, filtering on colors will have the domain 'color'.
   */
  domain: Domain;

  /**
   * Type of filter.
   *
   * - 'OR': should contain one of the given values.
   * - 'AND': should contain all of the given values.
   * - 'NOT OR': should not contain one of the given values.
   * - 'NOT AND': should not contain any of the given values.
   */
  type: ManufacturingSublineFilterType;

  /**
   * Values of the filter.
   */
  values: Array<string>;
}

export interface ManufacturingSublineColorFilter
  extends IManufacturingSublineFilter<ManufacturingSublineFilterDomain.COLOR> {
  values: Array<ColorPartConfigurationID>;
}

export interface ManufacturingSublineOptionFilter
  extends IManufacturingSublineFilter<ManufacturingSublineFilterDomain.OPTION> {
  values: Array<OptionPartConfigurationID>;
}

export interface ManufacturingSublineCountryFilter
  extends IManufacturingSublineFilter<ManufacturingSublineFilterDomain.COUNTRY> {
  values: Array<string>;
}

export type ManufacturingSublineFilter =
  | ManufacturingSublineColorFilter
  | ManufacturingSublineOptionFilter
  | ManufacturingSublineCountryFilter;
/**
 * end::Manufacturing subline filters
 */

/**
 * begin::Manufacturing sublines
 */
export enum ManufacturingSublineType {
  DEFAULT = 'default',
  NON_INVENTORY = 'non_inventory',
  ACCESSORY = 'accessory',
}

export enum ManufacturingSublineMode {
  SELECTED_BY_DEFAULT = 'selected_by_default',
  INCLUDED_BY_DEFAULT = 'included_by_default',
}

export interface ManufacturingSubline {
  identifier: string;

  type: ManufacturingSublineType;
  mode: ManufacturingSublineMode;

  description: string;
  article_number: string;
  amount: number;

  filters: Array<ManufacturingSublineFilter>;
}

export class ManufacturingSublineDto {
  identifier: string | undefined = undefined;

  type: ManufacturingSublineType = ManufacturingSublineType.DEFAULT;
  mode: ManufacturingSublineMode = ManufacturingSublineMode.SELECTED_BY_DEFAULT;

  description: string = '';
  article_number: string = '';
  amount: number = 1;

  filters: Array<ManufacturingSublineFilter> = [];
}
/**
 * end::Manufacturing sublines
 */
