
import { DatatableUtil } from '@vedicium/vue-core';
import { DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { Core } from '../../../services/core';
import { getOrganizationLink, UserEntity, UsersStore } from '../../../services/users';
import {
  ModifyPermissionGroupModal,
  ModifyPermissionGroupModalOptions,
  MODIFY_PERMISSION_GROUP_MODAL_EVENT_OPEN,
} from './modals';

export default Vue.extend({
  name: 'usersOverviewView',
  mixins: [DatatableMixin],
  components: {
    ModifyPermissionGroupModal,
  },
  data() {
    return {
      datatable: {
        url: '/users',
        fields: [
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'email',
            title: 'Email address',
            sortField: 'email',
            titleClass: 'w-300px',
            dataClass: 'w-300px',
          },
          {
            name: '',
            title: 'Permission group',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
            formatter: (user: UserEntity): string => {
              const organizationLink = getOrganizationLink(
                user,
                Core.Organizations.organization._meta.guid,
                { include_invited: true },
              );

              return (
                this.$tu(
                  `organizations.type.${Core.Organizations.organization.type}.groups.${organizationLink?.permission_id}.name`,
                )?.toString() || 'Unknown'
              );
            },
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-125px',
            dataClass: 'w-125px',
          },
        ],

        sort: [{ field: 'email', direction: 'asc' }],
        searchFields: ['name', 'email', 'given_name', 'family_name'],
        filters: {
          'organizations.organization': Core.Organizations.organization._meta.guid,
        },

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },

  mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Users' }]);
  },

  methods: {
    modifyPermissionGroup(user: UserEntity): void {
      if (!Core.Permissions.hasPermission('organizations.users.update.permission-group')) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$eventhub.emit(MODIFY_PERMISSION_GROUP_MODAL_EVENT_OPEN, {
        user,
        organization: this.$organizations.organization,
        async onSubmit(permission_id) {
          const link = getOrganizationLink(user, vm.$organizations.organization._meta.guid);

          // Don't do anything if permission id is undefined or is the same as current
          if (!permission_id || link?.permission_id === permission_id) {
            return;
          }

          await Core.getAdapter().put(
            `${UsersStore.url}/${user._meta.guid}/organization/permission_group`,
            { permission_id },
            { params: { persistent: true } },
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (vm.$refs.datatable as any).refresh();
        },
      } as ModifyPermissionGroupModalOptions);
    },

    removeUserFromOrganization(user: UserEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove user',
        message: `Are you sure that you want to remove <strong>${
          user.name || user.email
        }</strong>?<br />Please type the ${
          user.name !== undefined ? 'name' : 'email address'
        } of the user to confirm`,

        verifyString: user.name || user.email,
        buttonText: 'Confirm',

        async onSubmit() {
          await Core.getAdapter().delete(`${UsersStore.url}/${user._meta.guid}/organization`, {
            params: {
              persistent: true,
            },
          });

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (vm.$refs.datatable as any).refresh();
        },
      });
    },
  },
});
