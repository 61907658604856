
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  ConfiguratorService,
  SunbedConfigurationSession,
  SunbedConfigurationType,
  SunbedConfigurationTypePackage,
} from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';
import {
  ConfiguratorConflictErrorModalOptions,
  CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN,
} from '../modals';

export default Vue.extend({
  name: 'ordersPackagesConfigurator',
  props: {
    type: {
      type: Object as PropType<SunbedConfigurationType>,
    },
    session: {
      type: Object as PropType<SunbedConfigurationSession>,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormatUtils,
    };
  },
  computed: {
    packages(): Array<SunbedConfigurationTypePackage> {
      return this.type.parts.packages.filter((row) => {
        // In 'readonly' mode, only the selected packages should be shown.
        if (this.isReadonly() === true) {
          return this.isPackageSelected(row);
        }

        return true;
      }, []);
    },
  },

  methods: {
    isReadonly(): boolean {
      return this.readonly === true;
    },
    isPackageSelected(entity: SunbedConfigurationTypePackage): boolean {
      return ConfiguratorService.isPackageSelected(entity);
    },
    isPackageIncluded(entity: SunbedConfigurationTypePackage): boolean {
      return ConfiguratorService.isPackageIncluded(entity);
    },
    getPrice(entity: SunbedConfigurationTypePackage): number | undefined {
      if (ConfiguratorService.isLivePricingEnabled() === false) {
        return undefined;
      }

      return entity.purchase_price;
    },
    onPackageClick(entity: SunbedConfigurationTypePackage, event?: PointerEvent): void {
      if (this.isReadonly() === true) {
        return;
      }

      try {
        switch (this.isPackageSelected(entity)) {
          case false: {
            ConfiguratorService.addPackage(entity);
            break;
          }

          case true:
          default: {
            ConfiguratorService.removePackage(entity);
            break;
          }
        }
      } catch (e) {
        if (ConfiguratorService.isConflictError(e) === false) {
          console.error(e);
          Core.Eventhub.emit(APP_ERROR_EVENT, e);
          return;
        }

        Core.Eventhub.emit(CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN, {
          action: ConfiguratorService.isPackageSelected(entity) === false ? 'add' : 'remove',
          error: e,
        } as ConfiguratorConflictErrorModalOptions);
      }

      // Blur pointer
      if (event) {
        this.$nextTick(() => (event.target as HTMLElement).blur());
      }
    },
    onPackageInformationTooltip(entity: SunbedConfigurationTypePackage): Record<string, any> {
      const options = ConfiguratorService.getOptionsOfPackage(entity);
      return {
        trigger: 'hover focus',
        placement: 'bottomleft',
        html: true,
        title:
          `<span class="d-block font-weight-bold text-left">This package includes:</span>` +
          options
            .map((row) => `<span class="d-block text-left">${row.description}</span>`, [])
            .join(''),
        customClass: 'text-left',
        boundary: 'scrollParent',
      };
    },
  },
});
