
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';

import { FormatUtils } from '../../../../utils';
import {
  ConfiguratorService,
  SunbedConfigurationSession,
  SunbedConfigurationType,
  SunbedConfigurationTypeOption,
  SunbedConfigurationTypePackage,
  SunbedModelEntity,
  SunbedOrderUtils,
  SunbedUtils,
} from '../../../../services/sunbeds';
import { WeekCalendarComponent, WeekCalendarUtils } from '../../../../components/week-calendar';

import SunbedModelImage from './model.image.vue';

export default Vue.extend({
  name: 'ordersSummaryForm',
  components: {
    SunbedModelImage,
    WeekCalendarComponent,
  },
  data() {
    return {
      session: ConfiguratorService.session as SunbedConfigurationSession,
      has_agreed: false,

      metadata: {
        classes: {
          specifications_cols: ['col border-bottom mt-3 pb-3 px-3'],
          specifications_labels: ['d-block font-weight-bold font-size-lg'],
        },
      },

      FormatUtils,
    };
  },
  computed: {
    light_source_types(): Array<'bench' | 'canopy'> {
      return SunbedUtils.getLightSourceLocationsByModel(this.session.model as SunbedModelEntity);
    },

    price_per_unit(): number {
      return ConfiguratorService.getPurchasePrice();
    },
    total_unit_price(): number {
      return ConfiguratorService.getPurchasePrice() * this.session.configuration.amount;
    },
    discount(): number | undefined {
      if (
        this.session.configuration?.custom_price === undefined ||
        this.session.configuration?.custom_price === null
      ) {
        return undefined;
      }

      return this.total_unit_price - this.session.configuration.custom_price;
    },
    configurationIDs(): Array<string> {
      return ConfiguratorService.getConfigurationIDs();
    },
    total_price(): number {
      return this.session.configuration.custom_price || this.total_unit_price;
    },

    power_consumptions(): string {
      return SunbedOrderUtils.getPowerConsumptionText(ConfiguratorService.getPowerConsumption());
    },
  },
  validations: {
    has_agreed: {
      required,
      hasAgreed: (value: boolean) => {
        return value === true;
      },
    },
  },

  methods: {
    getCountryName(country_code: string): string | undefined {
      return SunbedOrderUtils.getCountryNameByCode(country_code);
    },
    getLightsourcesOfLocation(
      location: string,
    ): SunbedConfigurationType['uv_configurations'][number]['light_sources'] {
      return (this.session.configuration.uv_configuration?.light_sources.filter(
        (row) => row.location === location,
      ) || []) as SunbedConfigurationType['uv_configurations'][number]['light_sources'];
    },
    getOption(
      option: SunbedConfigurationSession['configuration']['options'][number],
    ): SunbedConfigurationTypeOption | undefined {
      return ConfiguratorService.getOption(option.guid);
    },
    getPackage(
      entity: SunbedConfigurationSession['configuration']['packages'][number],
    ): SunbedConfigurationTypePackage | undefined {
      return ConfiguratorService.getPackage(entity.guid);
    },
    isOptionIncluded(entity: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionIncluded(entity);
    },
    isOptionSelectedFromPackage(entity: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionSelectedFromPackage(entity);
    },

    hasFacetanner(): boolean {
      return this.session.configuration.uv_configuration?.facetanner.enabled === true || false;
    },
    hasPackages(): boolean {
      return this.session.configuration.packages.length > 0;
    },
    hasOptions(): boolean {
      return this.session.configuration.options.length > 0;
    },

    onDesiredAtChange(event: Event): void {
      const target = event.target as HTMLSelectElement;
      const value = target.value as 'asap' | 'date';

      switch (value) {
        case 'date': {
          this.session.desired_delivery_week = WeekCalendarUtils.getISOWeekFromDateTime(
            DateTime.now(),
          );

          break;
        }

        case 'asap':
        default: {
          this.session.desired_delivery_week = 'asap';
          break;
        }
      }
    },
  },
});
