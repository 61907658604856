import world from '@/assets/js/world.json';
import { FormatUtils } from '../../../utils';
import { FilterPartConfigurationID, OptionPartConfigurationID } from '../../parts';
import { SunbedConfigurationID, SunbedOrderEntity, SunbedOrderState } from '../interfaces';
import { SUNBED_SMARTVOICE_LANGUAGES } from '../sunbeds.constants';

export class SunbedOrderUtils {
  /**
   * Get the configuration ID's of an order.
   *
   * @param order SunbedOrderEntity
   * @returns Array of SunbedConfigurationID
   */
  static getConfigurationIDsOfOrder(order: SunbedOrderEntity): Array<SunbedConfigurationID> {
    const configurationIDs: Array<SunbedConfigurationID> = [];

    // Get configuration ID of type
    if (order.configuration.type.configuration_id !== undefined) {
      configurationIDs.push(order.configuration.type.configuration_id);
    }

    // Get configuration ID of colors
    if (order.configuration.color?.configuration_id !== undefined) {
      configurationIDs.push(order.configuration.color.configuration_id);
    }

    // Get configuration IDs of options
    order.configuration.options
      .filter((row) => row.configuration_id !== undefined, [])
      .forEach((row) => configurationIDs.push(row.configuration_id as OptionPartConfigurationID));

    // Get configuration IDs of filters of options
    order.configuration.options
      .filter((row) => row.filter?.configuration_id !== undefined, [])
      .forEach((row) =>
        configurationIDs.push(row.filter?.configuration_id as FilterPartConfigurationID),
      );

    // Get the configuration ID of facetanner
    if (order.configuration.uv_configuration.facetanner !== undefined) {
      order.configuration.uv_configuration.facetanner.filters
        ?.filter((row) => row.configuration_id !== undefined, [])
        .forEach((row) => configurationIDs.push(row.configuration_id as FilterPartConfigurationID));
    }

    return configurationIDs;
  }

  /**
   * Get human-readable order value description
   *
   * @param order SunbedOrderEntity
   * @returns String
   */
  static getOrderValueDescription(order: SunbedOrderEntity): string {
    if (order.amount > 1) {
      return `
        ${FormatUtils.currency(order.pricing.custom_price || order.pricing.purchase_price)}
        (${FormatUtils.currency(
          order.pricing.custom_price
            ? order.pricing.custom_price / order.amount
            : order.configuration.purchase_price,
        )} ea.)
      `;
    }
    return FormatUtils.currency(order.pricing.custom_price || order.pricing.purchase_price);
  }

  /**
   * Get human-readable power consumption text.
   *
   * @param order SunbedOrderEntity
   * @returns string
   */
  static getPowerConsumptionText(order: SunbedOrderEntity): string;

  /**
   * Get power consumption text.
   *
   * @param voltages Array of voltages
   * @returns string
   */
  static getPowerConsumptionText(
    voltages: SunbedOrderEntity['configuration']['power_consumption'],
  ): string;
  static getPowerConsumptionText(
    value: SunbedOrderEntity | SunbedOrderEntity['configuration']['power_consumption'],
  ): string {
    const voltages = Array.isArray(value) ? value : value.configuration.power_consumption;

    return voltages
      .sort((a) => (a.voltage === '230V' ? -1 : 0))
      .map((row) => `${(row.wattage / 1000).toFixed(1)} kW`, [])
      .join(' / ');
  }

  /**
   * Get variant by order.
   *
   * @param order SunbedOrderEntity
   * @returns Variant
   */
  static getVariantByOrder(order: SunbedOrderEntity): string {
    return this.getVariantByState(order.state);
  }

  /**
   * Get variant by sunbed order state.
   *
   * @param state SunbedOrderState
   * @returns Variant
   */
  static getVariantByState(state: SunbedOrderState): string {
    switch (state) {
      case SunbedOrderState.SCHEDULING_DELIVERY:
      case SunbedOrderState.SCHEDULING_PRODUCTION:
      case SunbedOrderState.READY_TO_SHIP:
      case SunbedOrderState.REQUESTED: {
        return 'info';
      }

      case SunbedOrderState.READY_FOR_PRODUCTION:
      case SunbedOrderState.IN_PRODUCTION: {
        return 'primary';
      }

      case SunbedOrderState.SHIPPED: {
        return 'success';
      }

      case SunbedOrderState.MODIFICATION_IN_PROCESS: {
        return 'warning';
      }

      case SunbedOrderState.CANCELLED: {
        return 'danger';
      }

      default: {
        return 'secondary';
      }
    }
  }

  /**
   * Get state order icon by order.
   *
   * @param order SunbedOrderEntity
   * @returns String
   */
  static getIconByOrder(order: SunbedOrderEntity): string {
    return this.getIconByState(order.state);
  }

  /**
   * Get state order icon by state.
   *
   * @param state SunbedOrderState
   * @returns String
   */
  static getIconByState(state: SunbedOrderState): string {
    switch (state) {
      case SunbedOrderState.REQUESTED: {
        return 'spinner';
      }

      case SunbedOrderState.SCHEDULING_DELIVERY: {
        return 'file-import';
      }

      case SunbedOrderState.SCHEDULING_PRODUCTION: {
        return 'calendar-week';
      }

      case SunbedOrderState.READY_FOR_PRODUCTION: {
        return 'play-circle';
      }

      case SunbedOrderState.IN_PRODUCTION: {
        return 'industry';
      }

      case SunbedOrderState.READY_TO_SHIP: {
        return 'box-check';
      }

      case SunbedOrderState.SHIPPED: {
        return 'truck-container';
      }

      case SunbedOrderState.MODIFICATION_IN_PROCESS: {
        return 'edit';
      }

      case SunbedOrderState.CANCELLED: {
        return 'times-circle';
      }

      default: {
        return 'question';
      }
    }
  }

  /**
   * Get country name by code.
   *
   * @param code Alpha-3 code.
   * @returns String
   */
  static getCountryNameByCode(code: string): string | undefined {
    return world.find((row) => row.alpha3.toUpperCase() === code)?.en;
  }

  /**
   * Determines whether facetanner is enabled.
   *
   * @param order SunbedOrderEntity
   * @returns Boolean
   */
  static isFacetannerEnabled(order: SunbedOrderEntity): boolean {
    return order.configuration.uv_configuration.facetanner !== undefined;
  }

  /**
   * Get next Sunbed Order State by current state.
   * When undefined, there's no "next" state.
   *
   * @param state SunbedOrderState
   * @returns SunbedOrderState or undefined
   */
  static getNextStateByState(state: SunbedOrderState): SunbedOrderState | undefined {
    switch (state) {
      case SunbedOrderState.REQUESTED: {
        return SunbedOrderState.SCHEDULING_PRODUCTION;
      }

      case SunbedOrderState.SCHEDULING_PRODUCTION: {
        return SunbedOrderState.SCHEDULING_DELIVERY;
      }

      case SunbedOrderState.SCHEDULING_DELIVERY: {
        return SunbedOrderState.READY_FOR_PRODUCTION;
      }

      case SunbedOrderState.READY_FOR_PRODUCTION: {
        return SunbedOrderState.IN_PRODUCTION;
      }

      case SunbedOrderState.IN_PRODUCTION: {
        return SunbedOrderState.READY_TO_SHIP;
      }

      case SunbedOrderState.READY_TO_SHIP: {
        return SunbedOrderState.SHIPPED;
      }

      case SunbedOrderState.MODIFICATION_IN_PROCESS: {
        return SunbedOrderState.REQUESTED;
      }

      default: {
        return undefined;
      }
    }
  }

  /**
   * Get the human-readable smartvoice language by value.
   *
   * @param value Smartvoice value.
   * @returns Human-readable language.
   */
  static getHumanReadableSmartvoiceLanguage(value: number): string {
    return (
      SUNBED_SMARTVOICE_LANGUAGES.find((row) => row.value === value)?.language || value.toString()
    );
  }
}
