
import Vue, { PropType } from 'vue';
import { DateTime } from 'luxon';
import { ConfirmationModalOptions } from '@vedicium/metronic-vue';

import {
  ConfiguratorService,
  SunbedConfigurationID,
  SunbedOrderEntity,
  SunbedOrdersStore,
  SunbedOrderState,
  SunbedOrderUtils,
} from '../../../../../../services/sunbeds';
import { SunbedModelImage } from '../../index';

import StatusDropdown from './status-dropdown.component.vue';
import {
  WeekCalendarModalOptions,
  WeekCalendarUtils,
  WEEK_CALENDAR_MODAL_OPEN,
} from '../../../../../../components/week-calendar';
import { Core } from '../../../../../../services/core';
import {
  SetSunbedOrderStateModalOptions,
  SET_SUNBED_ORDER_STATE_MODAL_OPEN,
  UpdateOrderNumberModalOptions,
  UPDATE_ORDER_NUMBER_MODAL_OPEN,
} from '../../../modals';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../../components/layout/portal/modals';
import { BDropdown } from 'bootstrap-vue';
import { APP_ERROR_EVENT } from '../../../../../../constants';

export default Vue.extend({
  name: 'ordersViewTopbar',
  props: {
    order: {
      type: Object as PropType<SunbedOrderEntity>,
    },
  },
  components: {
    SunbedModelImage,
    StatusDropdown,
  },
  data() {
    return {
      isDropdownLoading: false,

      EQUIPMENT_NAV_TABS_CLASS: 'nav nav-tabs nav-tabs-line border-bottom',
      EQUIPMENT_NAV_ITEM_CLASS: 'nav-item mr-14 mt-4',
      EQUIPMENT_NAV_LINK_CLASS: 'nav-link font-weight-bold border-2 pb-6 mx-0',

      SunbedOrderState,
      DateTime,
      WeekCalendarUtils,
    };
  },
  computed: {
    configurationName(): string {
      return `${this.order.configuration.model.name} ${this.order.configuration.type.name}`;
    },
    orderValueDescription(): string {
      return SunbedOrderUtils.getOrderValueDescription(this.order);
    },
    desiredByString(): string {
      if (this.order.schedule.desired_delivery_week === 'asap') {
        return 'Desired as soon as possible';
      }

      return `Desired by ${WeekCalendarUtils.getHumanReadableISOWeek(
        this.order.schedule.desired_delivery_week,
      )}`;
    },
    configurationIDs(): Array<SunbedConfigurationID> {
      return SunbedOrderUtils.getConfigurationIDsOfOrder(this.order);
    },
  },

  methods: {
    hasNextState(): boolean {
      return SunbedOrderUtils.getNextStateByState(this.order.state) !== undefined;
    },
    onNextStateClick(): void {
      const nextState = SunbedOrderUtils.getNextStateByState(this.order.state);
      if (nextState === undefined) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(SET_SUNBED_ORDER_STATE_MODAL_OPEN, {
        state: nextState,
        order: this.order,

        title: `Move to ${this.$tu(`sunbeds.orders.states.${nextState}`) || nextState}`,
        variant: SunbedOrderUtils.getVariantByState(nextState),

        async onSubmit(entity) {
          vm.$emit('update:order', entity);
        },
      } as SetSunbedOrderStateModalOptions);
    },

    onEditSalesOrderNumberClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(UPDATE_ORDER_NUMBER_MODAL_OPEN, {
        title: 'Update sales order number',

        label: 'Sales order number',
        value: this.order.sales_order_number,

        async onSubmit(value: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/sales_order_number`,
            { sales_order_number: value },
          );

          vm.$emit('update:order', response.data);
        },
      } as UpdateOrderNumberModalOptions);
    },

    async onDownloadOrderForm(): Promise<void> {
      this.$set(this, 'isDropdownLoading', true);
      try {
        await Core.Http.get(`/sunbeds/orders/${this.order._meta.guid}/document/order-form`, {
          responseType: 'blob',
        });
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isDropdownLoading', false);
      }
    },
    async onDownloadOrderConfirmation(): Promise<void> {
      this.$set(this, 'isDropdownLoading', true);
      try {
        await Core.Http.get(
          `/sunbeds/orders/${this.order._meta.guid}/document/order-confirmation`,
          {
            responseType: 'blob',
          },
        );
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isDropdownLoading', false);
      }
    },

    onEditProductionDateClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$eventhub.emit(WEEK_CALENDAR_MODAL_OPEN, {
        title: 'Modify production week',
        label: 'Production week',
        value: this.order.schedule.production_week,
        minDate: DateTime.now().startOf('week').startOf('day').plus({ weeks: 1 }).toJSDate(),

        async onSubmit(isoWeek: string) {
          const response = await Core.getAdapter().put<SunbedOrderEntity>(
            `/sunbeds/orders/${vm.order._meta.guid}/production_week`,
            { production_week: isoWeek },
          );

          vm.$emit('update:order', response.data);
        },
      } as WeekCalendarModalOptions);
    },

    async onReorderConfigurationClick(): Promise<void> {
      this.$set(this, 'isDropdownLoading', true);
      try {
        // Create the session from the order
        const session = await ConfiguratorService.createSessionFromOrder(this.order);

        // Remove some details
        session.configuration.reference = undefined;
        session.configuration.custom_price = undefined;
        session.configuration.remark = undefined;
        session.desired_delivery_week = 'asap';

        // Save session
        const key = ConfiguratorService.saveSession(session);

        this.$router.push(`/orders/create?session=${key}`);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isDropdownLoading', false);
      }
    },
    async onEditConfigurationClick(): Promise<void> {
      (this.$refs['dropdown:quick-actions'] as BDropdown).hide();

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Edit configuration',
        variant: 'warning',
        message: `
          <div class="alert alert-custom alert-light-warning">
            <span class="alert-text">
              <strong>Let op!</strong><br />
              Je gaat een order wijzigen.<br /><br />

              Betreft het een wijziging van verkoop binnendienst op een order die reeds naar werkvoorbereiding is gestuurd, dient de wijziging expliciet gemeld te worden bij werkvoorbereiding.<br /><br />

              Betreft het een wijziging door werkvoorbereiding die reeds door verkoop binnendienst aan de klant is bevestigd, dient de wijziging expliciet gemeld te worden bij verkoop binnendienst.
            </span>
          </div>
        `,

        buttonText: 'Confirm',
        async onSubmit() {
          try {
            const session = await ConfiguratorService.createSessionFromOrder(vm.order);
            const key = ConfiguratorService.saveSession(session);

            // Redirect to configurator
            vm.$router.push(`/orders/update/${vm.order._meta.guid}?session=${key}`);
          } catch (e) {
            console.error(e, JSON.stringify(e));
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
            throw e;
          }
        },
      } as ConfirmationModalOptions);
    },

    onDeleteClick(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Delete order',
        message: `Are you sure that you want to delete the order?<br />Please type the name of the customer <strong>${this.order.organization.name}</strong> to confirm.`,
        verifyString: this.order.organization.name,
        variant: 'danger',
        cancelButtonText: 'Cancel',
        async onSubmit() {
          await SunbedOrdersStore.delete(vm.order._meta.guid, { persistent: true });
          await vm.$nextTick();
          vm.$router.push(`/orders/overview`);
        },
      } as ConfirmationModalOptions);
    },
  },
});
