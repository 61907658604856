import VueI18n from 'vue-i18n';

export function getTranslationOrUndefined(
  i18n: VueI18n,
  path: string,
  values?: VueI18n.Values,
): VueI18n.TranslateResult | undefined {
  if (i18n.te(path) === false) {
    return undefined;
  }
  return i18n.t(path, values);
}
