
import { DatatableConfig, DatatableInstance, Mixins } from '@vedicium/metronic-vue';
import { Mutation } from '@vedicium/object-mutations';
import { DatatableUtil, OrganizationEntity } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../../../../components/layout/portal/modals';
import { APP_ERROR_EVENT } from '../../../../../../../constants';
import { Core } from '../../../../../../../services/core';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../services/sunbeds';
import {
  SearchOrganizationModal,
  SearchOrganizationModalOptions,
  SEARCH_ORGANIZATION_MODAL_OPEN,
} from '../../../../../organizations';

export default Vue.extend({
  name: 'configurationViewTypesViewSpecialPreferences',
  mixins: [Mixins.Datatable],
  components: {
    SearchOrganizationModal,
  },
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  data() {
    return {
      datatable: {
        url: '/organizations',
        fields: [
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
          },
          {
            name: 'actions',
            title: 'Actions',
          },
        ],

        searchFields: ['name'],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },

  beforeMount() {
    this.setDatatableFilters();
  },

  methods: {
    setDatatableFilters(): void {
      this.$set(this.datatable, 'filters', {
        '_meta.guid|eq': (this.metadata.type.special_preferences.organizations || []).join(','),
      });
    },

    onLinkOrganization(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      Core.Eventhub.emit(SEARCH_ORGANIZATION_MODAL_OPEN, {
        title: 'Link organization',
        requestOptions: {
          params: {
            'filter[_meta.guid|neq]': (
              this.metadata.type.special_preferences.organizations || []
            ).join(','),
          },
        },

        async onSubmit(organization) {
          try {
            const mutations: Array<Mutation<SunbedModelTypeEntity>> = [
              {
                action: 'array:push_unique',
                field: 'special_preferences.organizations',
                value: organization._meta.guid,
              },
            ];

            const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
              `/sunbeds/models/${vm.configuration._meta.guid}/types/${vm.type._meta.guid}`,
              mutations,
            );

            vm.$emit('update:type', response.data);
            vm.$set(vm.metadata, 'type', JSON.parse(JSON.stringify(response.data)));

            vm.setDatatableFilters();
            await vm.$nextTick();
            (vm.$refs.datatable as DatatableInstance).reload();
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }
        },
      } as SearchOrganizationModalOptions);
    },
    onUnlinkOrganization(entity: OrganizationEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Unlink organization',
        message: `Are your sure that you want to unlink the organization <strong>${entity.name}</strong> from the current type?<br />Please fill in the name to confirm.`,
        verifyString: entity.name,
        buttonText: 'Unlink',
        variant: 'warning',
        async onSubmit() {
          try {
            const mutations: Array<Mutation<SunbedModelTypeEntity>> = [
              {
                action: 'array:remove',
                field: 'special_preferences.organizations',
                value: entity._meta.guid,
              },
            ];

            const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
              `/sunbeds/models/${vm.configuration._meta.guid}/types/${vm.type._meta.guid}`,
              mutations,
            );

            vm.$emit('update:type', response.data);
            vm.$set(vm.metadata, 'type', JSON.parse(JSON.stringify(response.data)));

            vm.setDatatableFilters();
            await vm.$nextTick();
            (vm.$refs.datatable as DatatableInstance).reload();
          } catch (e) {
            console.error(e);
            Core.Eventhub.emit(APP_ERROR_EVENT, e);
          }
        },
      });
    },
  },
});
