
import Vue from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { BModal } from 'bootstrap-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { required, decimal } from 'vuelidate/lib/validators';

import {
  MANUFACTURING_SUBLINE_MODAL_OPEN,
  MANUFACTURING_SUBLINE_MODAL_CLOSE,
} from './sub-line-modal.constants';
import { ManufacturingSublineModalOptions } from './interfaces';
import { ManufacturingSublineFiltersTableComponent } from './components';
import {
  ManufacturingSubline,
  ManufacturingSublineMode,
  ManufacturingSublineType,
} from '../../../../../services/parts';
import { ManufacturingSublineFilterModal } from '../subline-filter-modal';

export default Vue.extend({
  name: 'manufacturingSublineModal',
  mixins: [Mixins.Modal],
  components: {
    filtersTable: ManufacturingSublineFiltersTableComponent,
    sublineFilterModal: ManufacturingSublineFilterModal,
  },
  data() {
    return {
      options: undefined as ManufacturingSublineModalOptions | undefined,

      document: undefined as ManufacturingSubline | undefined,

      isLoadingForm: false,

      metadata: {
        types: Object.values(ManufacturingSublineType),
        modes: Object.values(ManufacturingSublineMode),
      },
    };
  },

  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
      amount: {
        required,
        decimal,
      },
    },
  },

  mounted(): void {
    this.$eventhub.on(MANUFACTURING_SUBLINE_MODAL_OPEN, this.open);
    this.$eventhub.on(MANUFACTURING_SUBLINE_MODAL_CLOSE, this.close);
  },
  beforeDestroy(): void {
    this.$eventhub.off(MANUFACTURING_SUBLINE_MODAL_OPEN, this.open);
    this.$eventhub.off(MANUFACTURING_SUBLINE_MODAL_CLOSE, this.close);
  },

  methods: {
    isSublineTypeAvailable(): boolean {
      return this.options?.type === 'option';
    },
    isSublineModeAvailable(): boolean {
      return this.options?.type === 'option' || this.options?.type === 'color';
    },
    isSublineFiltersAvailable(): boolean {
      return this.options?.type === 'option';
    },

    onAddSublineFilter(): void {
      if (this.document === undefined) {
        return;
      }

      (this.$refs['filtersTable'] as any).onAddSublineFilter();
    },

    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.document as ManufacturingSubline);
        }
      } catch (e) {
        console.error(e);
        this.options?.onError?.(e as Error);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    async open(options?: ManufacturingSublineModalOptions): Promise<void> {
      this.$set(this, 'options', options || undefined);
      this.$set(this, 'document', options?.entity || undefined);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();

      (this.$refs['input:description'] as HTMLInputElement).focus();
    },
  },
});
