
import { MutationsService } from '@vedicium/object-mutations';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../../../../../constants';
import { Core } from '../../../../../../../../services/core';
import { SunbedModelEntity, SunbedModelTypeEntity } from '../../../../../../../../services/sunbeds';
import { ConfigurationViewTypesOptionsCompatibilityForm } from '../../components';

export default Vue.extend({
  name: 'configurationViewTypesViewCompatibilityOptions',
  props: {
    configuration: {
      type: Object as PropType<SunbedModelEntity>,
    },
    type: {
      type: Object as PropType<SunbedModelTypeEntity>,
    },
  },
  components: {
    OptionsCompatibilityForm: ConfigurationViewTypesOptionsCompatibilityForm,
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        type: JSON.parse(JSON.stringify(this.type)) as SunbedModelTypeEntity,
      },
    };
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(this.type, this.metadata.type, [
          'parts.options',
        ]);

        if (mutations.length > 0) {
          const response = await Core.getAdapter().patch<SunbedModelTypeEntity>(
            `/sunbeds/models/${this.configuration._meta.guid}/types/${this.type._meta.guid}`,
            mutations,
          );

          (this.$parent as Vue).$emit('update:type', response.data);
          this.$set(this.metadata, 'type', JSON.parse(JSON.stringify(response.data)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
