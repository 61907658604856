
import Vue, { PropType } from 'vue';
import { APP_ERROR_EVENT } from '../../../../constants';
import { Core } from '../../../../services/core';
import {
  ConfiguratorService,
  SunbedConfigurationSession,
  SunbedConfigurationType,
  SunbedConfigurationTypeOption,
} from '../../../../services/sunbeds';
import { FormatUtils } from '../../../../utils';
import {
  ConfiguratorConflictErrorModalOptions,
  CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN,
} from '../modals';

export default Vue.extend({
  name: 'ordersOptionsConfigurator',
  props: {
    type: {
      type: Object as PropType<SunbedConfigurationType>,
    },
    session: {
      type: Object as PropType<SunbedConfigurationSession>,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormatUtils,
    };
  },
  computed: {
    options(): Array<SunbedConfigurationTypeOption> {
      if (!this.type) {
        return [];
      }

      return [...this.type.parts.options]
        .filter((row) => {
          if (this.isOptionAvailable(row) === false) {
            return false;
          }

          if (this.isReadonly() === true) {
            return this.isOptionSelected(row);
          }

          return true;
        }, [])
        .sort((a, b) => {
          // Included options always come first
          if (this.isOptionIncluded(a) === true) {
            return -1;
          }

          // Add sorting when in 'readonly' mode
          if (this.isReadonly() === true) {
            // Options from packages are always before normal options.
            if (
              this.isOptionIncluded(b) === false &&
              this.isOptionSelectedFromPackage(a) === true
            ) {
              return -1;
            }
          }

          return 1;
        });
    },
  },

  methods: {
    isReadonly(): boolean {
      return this.readonly === true;
    },
    isOptionAvailable(option: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionAvailable(option);
    },
    isOptionSelected(option: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionSelected(option);
    },
    isOptionIncluded(option: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionIncluded(option);
    },
    isOptionSelectedByDefault(option: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionSelectedByDefault(option);
    },
    isOptionSelectedFromPackage(option: SunbedConfigurationTypeOption): boolean {
      return ConfiguratorService.isOptionSelectedFromPackage(option);
    },
    isOptionDisabled(option: SunbedConfigurationTypeOption): boolean {
      // If option is included, user shouldn't be able to change the option
      if (this.isOptionIncluded(option) === true) {
        return true;
      }

      // When the option is selected by default and is selected, user shouldn't be able to change the option.
      if (
        this.isOptionSelectedByDefault(option) === true &&
        this.isOptionSelected(option) === true
      ) {
        return true;
      }

      // If the option is selected by a package, user shouldn't be able to change the option.
      if (this.isOptionSelectedFromPackage(option) === true) {
        return true;
      }

      return false;
    },

    getPrice(option: SunbedConfigurationTypeOption): number | undefined {
      if (ConfiguratorService.isLivePricingEnabled() === false) {
        return undefined;
      }

      return option.purchase_price;
    },
    getDescription(option: SunbedConfigurationTypeOption): string | undefined {
      if (this.isOptionSelected(option) === true && this.isOptionIncluded(option) === true) {
        return `Included`;
      }

      if (
        this.isOptionSelected(option) === true &&
        this.isOptionSelectedFromPackage(option) === true
      ) {
        const packageEntity = ConfiguratorService.getPackageOfSelectedOption(option);
        if (packageEntity === undefined) {
          return undefined;
        }

        return `Part of ${packageEntity.description}`;
      }

      return undefined;
    },

    onOptionChange(option: SunbedConfigurationTypeOption, value: boolean, event: Event): void {
      if (this.isReadonly() === true) {
        return;
      }

      if (this.isOptionDisabled(option) === true) {
        return;
      }

      try {
        switch (value) {
          case true: {
            ConfiguratorService.addOption(option);
            break;
          }

          case false:
          default: {
            ConfiguratorService.removeOption(option);
            break;
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-param-reassign
        (event.target as HTMLInputElement).checked = !value;

        if (ConfiguratorService.isConflictError(e) === false) {
          console.error(e);
          Core.Eventhub.emit(APP_ERROR_EVENT, e);
          return;
        }

        Core.Eventhub.emit(CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN, {
          action: value === true ? 'add' : 'remove',
          error: e,
        } as ConfiguratorConflictErrorModalOptions);
      }
    },
  },
});
