
import { ConfirmationModalOptions, DatatableConfig, DatatableMixin } from '@vedicium/metronic-vue';
import { FormMixin } from '@vedicium/vue-core';
import Vue from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../../components/layout/portal/modals';
import {
  SunbedModelDto,
  SunbedModelProductionChecklistItem,
  SunbedModelProductionChecklistItemDto,
} from '../../../../services/sunbeds';
import {
  ConfigurationProductionChecklistItemModal,
  ConfigurationProductionChecklistItemModalOptions,
  CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN,
} from '../modals/production-checklist-item';

export default Vue.extend({
  name: 'sunbedModelProductionForm',
  mixins: [FormMixin, DatatableMixin],
  props: {
    document: {
      type: Object as PropType<SunbedModelDto>,
    },
  },
  components: {
    ConfigurationProductionChecklistItemModal,
  },
  data() {
    return {
      datatable: {
        url: '',
        data: [],
        fields: [
          {
            name: 'item',
            title: 'Checklist item',
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-100px',
            dataClass: 'w-100px',
          },
        ],
      } as DatatableConfig,
    };
  },

  methods: {
    onAddChecklistItem(): void {
      const vm = this;
      this.$eventhub.emit(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN, {
        title: 'Add checklist item',
        entity: new SunbedModelProductionChecklistItemDto(),
        onSubmit: (entity: SunbedModelProductionChecklistItem) => {
          if (vm.document.manufacturing.checklist_items.length >= 12) {
            this.$bvToast.toast(
              'The maximum number of items is exceeded, please delete another item and retry.',
              {
                title: 'Unable to add item',
                variant: 'danger',
                solid: true,
              },
            );

            return;
          }

          vm.document.manufacturing.checklist_items.push(entity);
          vm.$emit('update', vm.document.manufacturing.checklist_items);
        },
      } as ConfigurationProductionChecklistItemModalOptions);
    },
    onModifyChecklistItem(item: SunbedModelProductionChecklistItem): void {
      const vm = this;
      this.$eventhub.emit(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN, {
        title: 'Edit checklist item',
        entity: JSON.parse(JSON.stringify(item)),
        onSubmit: (entity: SunbedModelProductionChecklistItem) => {
          const idx = vm.document.manufacturing.checklist_items.indexOf(item);
          vm.$set(vm.document.manufacturing.checklist_items, idx, entity);

          vm.$emit('update', vm.document.manufacturing.checklist_items);
        },
      } as ConfigurationProductionChecklistItemModalOptions);
    },
    onRemoveChecklistItem(item: SunbedModelProductionChecklistItem): void {
      const vm = this;
      this.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Remove checklist item',
        message: `Are you sure that you want to delete the checklist item <strong>${item.item}</strong?`,
        buttonText: 'Confirm',
        onSubmit: () => {
          vm.$set(
            vm.document.manufacturing,
            'checklist_items',
            vm.document.manufacturing.checklist_items.filter((row) => row !== item, []),
          );

          vm.$emit('update', vm.document.manufacturing.checklist_items);
        },
      } as ConfirmationModalOptions);
    },
  },
});
