
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';

import { FilterPartDto, FilterPartConfigurationID } from '../../../../../services/parts';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'partsFiltersInformationForm',
  mixins: [FormMixin],
  props: {
    document: {
      type: Object as PropType<FilterPartDto>,
    },
  },
  data() {
    return {
      metadata: {
        configuration_ids: Object.values(FilterPartConfigurationID),
      },
    };
  },
  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:description'] as HTMLInputElement).focus();
    }
  },
});
