
import { BModal } from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';

import { Core } from '../../../../services/core';
import { ConfiguratorUVConfigurationsModalOptions } from './interfaces';
import {
  CONFIGURATOR_UV_CONFIGURATIONS_MODAL_CLOSE,
  CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN,
} from './constants';
import {
  SunbedConfigurationType,
  SunbedConfigurationTypeUVConfiguration,
} from '../../../../services/sunbeds';
import { APP_ERROR_EVENT } from '../../../../constants';
import { FormatUtils } from '../../../../utils';

export default Vue.extend({
  name: 'configuratorUVConfigurationsModal',
  mixins: [Mixins.Modal],
  props: {
    uv_configurations: {
      type: Array as PropType<SunbedConfigurationType['uv_configurations']>,
    },
  },
  data() {
    return {
      options: {} as ConfiguratorUVConfigurationsModalOptions,

      isLoading: false,

      metadata: {
        uv_configuration: undefined as SunbedConfigurationType['uv_configurations'] | undefined,
        light_sources: ['bench', 'canopy'] as Array<'bench' | 'canopy'>,
      },

      FormatUtils,
    };
  },
  validations: {
    metadata: {
      uv_configuration: {
        required,
      },
    },
  },

  mounted() {
    Core.Eventhub.on(CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN, this.open);
    Core.Eventhub.off(CONFIGURATOR_UV_CONFIGURATIONS_MODAL_CLOSE, this.close);
  },
  beforeDestroy() {
    Core.Eventhub.off(CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN, this.open);
    Core.Eventhub.off(CONFIGURATOR_UV_CONFIGURATIONS_MODAL_CLOSE, this.close);
  },

  methods: {
    getPrice(uv_configuration: SunbedConfigurationTypeUVConfiguration): number | undefined {
      if (this.options?.include_prices !== true) {
        return undefined;
      }

      return uv_configuration.purchase_price;
    },
    onUVConfigurationChange(
      uv_configuration: SunbedConfigurationType['uv_configurations'] | undefined,
    ): void {
      this.$set(this.metadata, 'uv_configuration', uv_configuration);
    },

    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();

        if (typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(
            this.metadata.uv_configuration as SunbedConfigurationType['uv_configurations'],
          );
        }
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    async open(options?: ConfiguratorUVConfigurationsModalOptions): Promise<void> {
      this.$set(this, 'options', options || {});
      this.$set(this.metadata, 'light_sources', options?.light_sources || ['bench', 'canopy']);
      this.onUVConfigurationChange(options?.value || undefined);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },
  },
});
