
import Vue from 'vue';
import { APP_ERROR_EVENT } from '../../../constants';
import { Core } from '../../../services/core';
import {
  PricelistEntity,
  PricelistItemWithProperties,
  PricelistsStore,
} from '../../../services/pricelists';

export default Vue.extend({
  name: 'pricelistsView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        pricelist: undefined as PricelistEntity<PricelistItemWithProperties> | undefined,
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Pricelists', route: '/pricelists/overview' },
    ]);
  },
  async mounted() {
    try {
      await this.onMount();
    } catch (e) {
      console.error(e);
      Core.Eventhub.emit(APP_ERROR_EVENT, e);
    } finally {
      this.$set(this, 'isMounted', true);
    }

    this.$metronic.breadcrumbs.appendBreadcrumb({
      title: this.metadata.pricelist?.description as string,
    });
  },

  methods: {
    async onMount() {
      this.$set(this, 'isLoading', true);
      try {
        const pricelist = await PricelistsStore.get(this.$route.params.guid, {
          params: { include_item_properties: true },
        });

        this.$set(this.metadata, 'pricelist', pricelist);
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
});
