
import { Mixins } from '@vedicium/metronic-vue';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';
import { SunbedModelProductionChecklistItem } from '../../../../../services/sunbeds';
import { ValidatorUtil } from '@vedicium/vue-core';
import {
  CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN,
  CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_CLOSE,
} from './production-checklist-item-modal.constants';
import { ConfigurationProductionChecklistItemModalOptions } from './interfaces';

export default Vue.extend({
  name: 'configurationProductionChecklistItemModal',
  mixins: [Mixins.Modal],
  components: {},
  data() {
    return {
      options: undefined as ConfigurationProductionChecklistItemModalOptions | undefined,

      document: undefined as SunbedModelProductionChecklistItem | undefined,

      isLoadingForm: false,
    };
  },
  validations: {
    document: {
      item: {
        required,
      },
    },
  },

  mounted(): void {
    this.$eventhub.on(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN, this.open);
    this.$eventhub.on(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_CLOSE, this.close);
  },
  beforeDestroy(): void {
    this.$eventhub.off(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_OPEN, this.open);
    this.$eventhub.off(CONFIGURATIONS_PRODUCTION_CHECKLIST_ITEM_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        if (typeof this.options?.onSubmit === 'function') {
          await this.options.onSubmit(this.document as SunbedModelProductionChecklistItem);
        }
      } catch (e) {
        console.error(e);
        this.options?.onError?.(e as Error);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    async open(options?: ConfigurationProductionChecklistItemModalOptions): Promise<void> {
      this.$set(this, 'options', options || undefined);
      this.$set(this, 'document', options?.entity || undefined);

      await this.$nextTick();
      this.$v.$reset();

      (this.$refs.modal as BModal).show();
    },
    close(): void {
      (this.$refs.modal as BModal).hide();
    },

    async onShown(): Promise<void> {
      await this.$nextTick();

      (this.$refs['input:item'] as HTMLInputElement).focus();
    },
  },
});
