
import Vue, { PropType, VueConstructor } from 'vue';
import { FormMixin } from '@vedicium/vue-core';
import { required } from 'vuelidate/lib/validators';

import { PackagePartDto } from '../../../../../services/parts';
import { SunbedModelVueSelect } from '../../../configurations/components';
import { SunbedModelEntity, SunbedModelsStore } from '../../../../../services/sunbeds';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormMixin>>).extend({
  name: 'partsPackageInformationForm',
  mixins: [FormMixin],
  components: {
    SunbedModelVueSelect,
  },
  props: {
    document: {
      type: Object as PropType<PackagePartDto>,
    },
  },
  data() {
    return {
      metadata: {
        model: undefined as SunbedModelEntity | undefined,
      },
    };
  },
  validations: {
    document: {
      description: {
        required,
      },
      article_number: {
        required,
      },
      model: {
        required,
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.document.model) {
      this.$set(this.metadata, 'model', await SunbedModelsStore.get(this.document.model));
    }

    await this.$nextTick();

    if (this.action === 'CREATE') {
      (this.$refs['input:description'] as HTMLInputElement).focus();
    }
  },

  methods: {
    onSunbedModel(model: SunbedModelEntity | undefined): void {
      this.$set(this.metadata, 'model', model);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$set((this.$v.document as any).model, '$model', model?._meta.guid || undefined);
    },
  },
});
