
import Vue from 'vue';
import { DatatableMixin, DatatableConfig } from '@vedicium/metronic-vue';
import { OrganizationEntity, DatatableUtil } from '@vedicium/vue-core';
import { CONFIRMATION_MODAL_EVENT_OPEN } from '../../../components/layout/portal/modals';
import { OrganizationsStore } from '../../../services/organizations';
import { getOrganizationLink, UserEntity } from '../../../services/users';
import { Core } from '../../../services/core';

export default Vue.extend({
  name: 'organizationsOverviewView',
  mixins: [DatatableMixin],
  data() {
    return {
      datatable: {
        url: '/organizations',
        fields: [
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
          },
          {
            name: 'type',
            title: 'Type',
            sortField: 'type',
            titleClass: 'w-200px',
            dataClass: 'w-200px',
            formatter: (value: OrganizationEntity['type']): string => {
              return this.$tu(`organizations.type.${value}.name`)?.toString() || value;
            },
          },
          {
            name: 'actions',
            title: 'Actions',
            titleClass: 'w-125px',
            dataClass: 'w-125px',
          },
        ],

        filters: {},
        sort: [{ field: 'name', direction: 'asc' }],
        searchFields: ['name'],

        fetch: DatatableUtil.fetch(Core),
      } as DatatableConfig,
    };
  },
  async mounted() {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Organizations' }]);
  },

  methods: {
    removeOrganization(organization: OrganizationEntity): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: 'Delete organization',
        message: `Are you sure that you want to delete <strong>${organization.name}</strong>?<br /><br />Please type the name of the organization to confirm:`,
        verifyString: organization.name,
        buttonText: 'Confirm',
        async onSubmit() {
          await OrganizationsStore.delete(organization._meta.guid, { persistent: true });

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (vm.$refs.datatable as any).refresh();
        },
      });
    },

    async gainAccessToOrganization(organization: OrganizationEntity): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.$metronic.eventhub.emit(CONFIRMATION_MODAL_EVENT_OPEN, {
        title: `Gain access to ${organization.name}`,
        message: `Are you sure that you want to gain access to <strong>${organization.name}</strong>?`,
        variant: 'info',
        buttonText: 'Gain access',
        async onSubmit() {
          try {
            await Core.getAdapter().put(
              `${OrganizationsStore.url}/${organization._meta.guid}/access`,
              undefined,
              {
                params: {
                  persistent: true,
                },
              },
            );

            await vm.switchToOrganization(organization);
          } catch (e) {
            console.error(e);
          }
        },
      });
    },
    async switchToOrganization(organization: OrganizationEntity): Promise<void> {
      await this.$organizations.set(organization._meta.guid);
      await Core.Authentication.getEntity();
      this.$router.push({ path: '/' });
    },

    isLinkedToOrganization(organization: OrganizationEntity): boolean {
      const link = getOrganizationLink(
        this.$authentication.entity as UserEntity,
        organization._meta.guid,
      );

      return link !== undefined;
    },
  },
});
