import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locales } from './interfaces';
import { getTranslationOrUndefined } from './utils';
import { EnglishLocale } from './locales/en';

Vue.use(VueI18n);

export const LocaleMessages = {
  [Locales.EN]: EnglishLocale,
};

const i18n = new VueI18n({
  locale: Locales.EN,
  messages: LocaleMessages,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
});

// Add 'tu' function, which returns undefined if translation doesn't exist
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(i18n as any).tu = (path: string, values?: VueI18n.Values): VueI18n.TranslateResult | undefined => {
  return getTranslationOrUndefined(i18n, path, values);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.prototype.$tu = (i18n as any).tu;

export { i18n };
