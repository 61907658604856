
import { MutationsService } from '@vedicium/object-mutations';
import { OrganizationEntity, ValidatorUtil } from '@vedicium/vue-core';
import Vue, { PropType } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { APP_ERROR_EVENT } from '../../../../../../constants';
import { Core } from '../../../../../../services/core';

import { OrganizationInformationEntity } from '../../../../../../services/organizations';

export default Vue.extend({
  name: 'organizationsViewProductsSettingsCard',
  props: {
    organization: {
      type: Object as PropType<OrganizationEntity>,
    },
    information: {
      type: Object as PropType<OrganizationInformationEntity>,
    },
  },
  data() {
    return {
      isLoadingForm: false,

      metadata: {
        information: JSON.parse(JSON.stringify(this.information)) as OrganizationInformationEntity,
      },
    };
  },
  validations: {
    metadata: {
      information: {
        settings: {
          regular_products_available: {
            required,
          },
        },
      },
    },
  },

  methods: {
    async validate(): Promise<void> {
      await ValidatorUtil.validateArray([this.$v]);
    },
    async onSubmit(): Promise<void> {
      this.$set(this, 'isLoadingForm', true);
      try {
        await this.validate();

        const mutations = MutationsService.getMutationsByComparison(
          this.information,
          this.metadata.information,
          ['settings.regular_products_available'],
        );

        if (mutations.length > 0) {
          const response = await Core.getAdapter().patch(
            `/organizations/${this.organization._meta.guid}/information`,
            mutations,
          );

          this.$parent.$emit('update:information', response.data);
          this.$set(this.metadata, 'information', JSON.parse(JSON.stringify(response.data)));
        }

        this.$bvToast.toast('Document successfully updated', {
          title: 'Update successful',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        console.error(e);
        Core.Eventhub.emit(APP_ERROR_EVENT, e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
});
