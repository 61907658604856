var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('h5',{domProps:{"textContent":_vm._s(
        `${_vm.session.configuration.uv_configuration.description} (UV-type ${_vm.session.configuration.uv_configuration.uv_type})`
      )}}),(_vm.getPrice(_vm.session.configuration.uv_configuration) !== undefined)?_c('span',{staticClass:"text-muted font-size-lg",domProps:{"textContent":_vm._s(_vm.FormatUtils.currency(_vm.getPrice(_vm.session.configuration.uv_configuration)))}}):_vm._e()]),_vm._l((_vm.light_sources),function(light_source_type){return _c('div',{key:`light_source.${light_source_type}`,class:[_vm.metadata.colClasses]},[_c('label',{staticClass:"d-block font-weight-bold border-bottom",domProps:{"textContent":_vm._s(
        `Light sources ${
          _vm.$tu(`sunbeds.unit_types.${_vm.model.unit_type}.light_sources.${light_source_type}`) ||
          light_source_type
        }`
      )}}),_vm._l((_vm.getLightsourcesOfLocation(light_source_type)),function(light_source,idx){return _c('div',{key:`light_source.${light_source_type}.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(
        `${light_source.amount}x ${light_source.description} (${light_source.article_number})`
      )}})})],2)}),(_vm.hasFacetanner() === true)?_c('div',{class:[_vm.metadata.colClasses]},[_c('label',{staticClass:"d-block font-weight-bold border-bottom"},[_vm._v("Face tanner")]),_vm._l(([
        _vm.session.configuration.uv_configuration.facetanner.light_source,
      ]),function(light_source,idx){return _c('div',{key:`light_source.facetanner.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(
        `${light_source.amount}x ${light_source.description} (${light_source.article_number})`
      )}})}),_vm._l((_vm.session.configuration.uv_configuration.facetanner.filters),function(filter,idx){return _c('div',{key:`filters.facetanner.${idx}`,staticClass:"d-block",domProps:{"textContent":_vm._s(`${filter.description} (${filter.power}W)`)}})})],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }