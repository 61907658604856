
import { Mixins } from '@vedicium/metronic-vue';
import { ValidatorUtil } from '@vedicium/vue-core';
import Vue from 'vue';
import { Validation } from 'vuelidate';

import { OptionPartDto, OptionPartsStore } from '../../../../services/parts';
import { SearchLightSourceModal } from '../light-sources';
import { SearchFilterModal } from '../filters';
import {
  PartsOptionsInformationForm,
  PartsOptionsRatedVoltagesForm,
  PartsOptionsCompatibilityForm,
  PartsOptionsSublinesForm,
} from './components';

export default Vue.extend({
  name: 'partsOptionsCreate',
  mixins: [Mixins.KTWizardV4],
  components: {
    PartsOptionsInformationForm,
    PartsOptionsRatedVoltagesForm,
    PartsOptionsCompatibilityForm,
    PartsOptionsSublinesForm,
    SearchLightSourceModal,
    SearchFilterModal,
  },
  data() {
    return {
      isMounted: false,
      isLoadingForm: false,

      option: new OptionPartDto(),

      metadata: {
        wizard: {
          index: 0,
        },
      },
    };
  },

  beforeMount() {
    this.$metronic.breadcrumbs.setBreadcrumbs([
      { title: 'Parts', route: '/parts' },
      { title: 'Options', route: '/parts/options' },
      { title: 'Create option' },
    ]);
  },
  async mounted() {
    await this.$nextTick();

    this.$set(this, 'isMounted', true);
    (this.$refs.wizard as Vue).$on('step-change', this.onWizardStepChange.bind(this));
  },
  beforeDestroy() {
    (this.$refs.wizard as Vue).$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    onAddSubline(): void {
      (this.$refs.sublinesForm as any).onAddSubline();
    },

    async submitForm() {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        // Copy option, so we can modify it freely
        const option: OptionPartDto = JSON.parse(JSON.stringify(this.option));

        // Filter out power consumption voltages without a wattage.
        option.power_consumption = option.power_consumption.filter((row) =>
          row.voltages?.some((voltage) => voltage.wattage !== undefined, []),
        );

        // Prepare for create
        switch (option.type) {
          case 'standard': {
            option.power_consumption = [];
            delete option.light_source;
            delete option.acrylic_thickness;
            break;
          }

          case 'power': {
            delete option.light_source;
            break;
          }

          case 'light':
          default: {
            break;
          }
        }

        // Create the option
        await OptionPartsStore.create(option, {
          persistent: true,
        });
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$router.push('/parts/options');
    },

    async onWizardStepChange(step: number, oldStep: number) {
      // When going to step 2 (in computer language, 1), we're going to check if it isn't a standard option
      if (step === 1) {
        if (this.option.type === 'standard') {
          // When going up, we're setting the step to 3
          if (step > oldStep) {
            await this.$nextTick();

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.wizard as any).setStep(2);
          } else {
            await this.$nextTick();

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.wizard as any).setStep(0);
          }
        }
      }

      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.wizard as any).setStep(oldStep);
        }
      }
    },

    async validate(step?: number) {
      const validationArray: Array<Validation> = [];

      // Add validators based by step
      if (step === undefined || step === 0) {
        validationArray.push((this.$refs.informationForm as Vue).$v);
      }

      if (step === undefined || step === 1) {
        validationArray.push((this.$refs.ratedVoltagesForm as Vue).$v);
      }

      if (step === undefined || step === 2) {
        validationArray.push((this.$refs.compatibilityForm as Vue).$v);
      }

      await ValidatorUtil.validateArray(
        validationArray.filter((row) => row !== undefined, []),
        {
          reset: true,
          pending_delay: 50,
        },
      );
    },
  },
});
